import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Button, Typography } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import { Grid, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import AuthSagaActions from "../../../redux/authsaga/action";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "@mui/material/Modal";
import Stack from '@mui/material/Stack';

const { getReferralUser, addUserSaga, updateUserSaga } = AuthSagaActions;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  maxHeight: "70vh",
  bgcolor: "background.paper",
  overflow: "auto",
  boxShadow: 24,
  p: 4,
};

const ReferralSystem = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userList = useSelector((state) => {
    return state.authSaga.referralUser;
  });
  

  const token = useSelector((state) => {
    return state.authSaga.idToken;
  });

  const [page, setPage] = useState(0);
  const [users, setUserlist] = useState([]);
  const [whiteList, setWhiteList] = useState([]);
  const [rows, Setrows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [count, Setcount] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [mobile, setMobile] = useState("");
  const [country, setCountry] = useState("");
  const [password, setPassword] = useState("");
  const [Editid, Seteditid] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [userStatus , setUserStatus] = useState(false);

  const columns = [
    { id: "userName", label: "User Name" },
    { id: "coin", label: "Coin" },
    { id: "referredUser", label: "Referred User", minWidth: 100 },
    { id: "status", label: "Status", minWidth: 100 },
    { id: "createdAt", label: "Created", minWidth: 100 }
  ];

  function createData(userName, coin, referredUser, status, createdAt) {
    return {userName, coin, referredUser, status, createdAt}
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const validationFun=()=>{
    let regEmail = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if(Editid === "") 
  { // Add form validation
    if(firstName==="" || firstName?.length<3){
        setError("Enter at least 3 characters in firstName")
    }
    else if(firstName==="" || firstName?.length>25){
      setError("Enter not more then 25 characters in firstName")
    }
    else if(lastName==="" || lastName?.length<3){
        setError("Enter at least 3 characters in lastName")
    }
    else if(lastName==="" || lastName?.length>25){
      setError("Enter not more then 25 characters in lastName")
    }
    else if(email==="" || !regEmail.test(email)){
      setError("Enter valid email")
    }
    else if(userName==="" || userName?.length<3){
        setError("Enter at least 3 characters in userName")
    }
    else if(userName==="" || userName?.length>25){
      setError("Enter not more then 25 characters in userName")
    }
    else if(mobile==="" || mobile?.length!==10){
        setError("Enter at least 10 characters in mobile")
    }
    else if(country==="" || country?.length<3){
        setError("Enter at least 3 characters in country")
    }
    else if(country==="" || country?.length>25){
      setError("Enter not more then 25 characters in country")
    }
    else if(password==="" || password?.length<8){
        setError("Enter at least 8 characters in password")
    }
    else if(confirmPassword!==password){
        setError("Password and confirm password are not matched")
    }
    else{
      handelSubmit();
    }
  } else 
  {
    // Edit record then password validation hide

    if(firstName==="" || firstName?.length<3){
      setError("Enter at least 3 characters in firstName")
    }
    else if(firstName==="" || firstName?.length>25){
      setError("Enter not more then 25 characters in firstName")
    }
    else if(lastName==="" || lastName?.length<3){
        setError("Enter at least 3 characters in lastName")
    }
    else if(lastName==="" || lastName?.length>25){
      setError("Enter not more then 25 characters in lastName")
    }
    else if(email==="" || !regEmail.test(email)){
      setError("Enter valid email")
    }
    else if(userName==="" || userName?.length<3){
        setError("Enter at least 3 characters in userName")
    }
    else if(userName==="" || userName?.length>25){
      setError("Enter not more then 25 characters in userName")
    }
    else if(mobile==="" || mobile?.length!==10){
        setError("Enter at least 10 characters in mobile")
    }
    else if(country==="" || country?.length<3){
        setError("Enter at least 3 characters in country")
    }
    else if(country==="" || country?.length>25){
      setError("Enter not more then 25 characters in country")
    }
    else{
      handelSubmit();
    }
  }
}

const handelSubmit = () => {
    setError("");
    const apidata = new URLSearchParams();
    apidata.append("id", Editid);
    apidata.append("email", email);
    apidata.append("firstName", firstName);
    apidata.append("lastName", lastName);
    apidata.append("userName", userName);
    apidata.append("mobileNo", mobile);
    apidata.append("country", country);
    apidata.append("password", password);
    apidata.append("isActive", userStatus === true ? '1' : '0');

    Editid===""?dispatch(addUserSaga(apidata, navigate, token)):dispatch(updateUserSaga(apidata, navigate, token))
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch(getReferralUser(navigate));
    setUserlist(userList);
    
  }, [])

  useEffect(() => {
    setUserlist(userList);
   
  }, [userList]); 

  useEffect(() => {
    users &&
      users[0] &&
      users.map((element, index) => {
        if (element._id === Editid) {
            setFirstName(element.firstName);
            setLastName(element.lastName);
            setEmail(element.email);
            setUserName(element.userName);
            setMobile(element.mobileNo);
            setCountry(element.country);
            setUserStatus(element.isActive == '1' ? true : false)
        }
      });
    if (Editid === "") {
        setFirstName("");
        setLastName("");
        setEmail("");
        setUserName("");
        setMobile("");
        setCountry("");
    }
  }, [Editid, count]);

  useEffect(() => {
    if(users){
      let data = [];
      users?.map((element, i) => {
        let matchedWhiteList =  whiteList.length&&whiteList.filter(single=>single?.email===element?.email)?.length;
        const whiteListStatus = matchedWhiteList?'Yes':'No';
        data = [
          ...data,
          createData(element?.userId?.userName, element.coin, element?.referredUserId?.userName, element.status, new Date(element.createdAt).toLocaleString()),
        ];
      });
      Setrows(data);
    }
  }, [users]);


  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Box className="page-content-main" sx={{mt:3}}>
      <ToastContainer />
      <Box className='main-title-box'>
        <Typography className='main-title' variant="h4">Referral System</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        <FormControl sx={{ m: 1, minWidth: 220, maxHeight: 50 }}></FormControl>
      </Box>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer className="table-main-box">
          <Table stickyHeader aria-label="sticky table" className="table-main">
            <TableHead className="table-head">
              <TableRow className="table-row">
                {columns?.map((column) => (
                  <TableCell className="table-cell"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <h4 style={{ margin: 0 }}>{column.label}</h4>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody className="table-body" sx={{ padding: '18px' }}>

              {rows.length ? rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row) => {
                return (
                  <TableRow className="table-row"
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}

                  >
                    {columns?.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} className="table-cell">
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              }) : "No Data"}
            </TableBody>
          </Table>
        </TableContainer>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid item xs={6}>
              <Box
                sx={{
                  margin: "auto",
                  width: 400,
                  maxWidth: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}>
                  <TextField
                    sx={{width: "400px"}}
                    fullWidth
                    required
                    label="First Name"
                    id="firstName"
                    value={firstName}
                    name="firstName"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <TextField
                    sx={{ marginTop: 2, width: "400px" }}
                    fullWidth
                    required
                    label="Last Name"
                    id="lastName"
                    name="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />

                  <TextField
                    sx={{ marginTop: 2, width: "400px" }}
                    fullWidth
                    required
                    label="Email"
                    id="email"
                    value={email}
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                  /> 

                  <TextField
                    sx={{ marginTop: 2, width: "400px" }}
                    required
                    fullWidth
                    label="User Name"
                    id="userName"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
             
                  <TextField
                    sx={{ marginTop: 2, width: "400px" }}
                    required
                    fullWidth
                    label="Mobile No"
                    id="mobile"
                    name="mobile"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                  />
                  <TextField
                    sx={{ marginTop: 2, width: "400px" }}
                    fullWidth
                    required
                    label="Country"
                    id="country"
                    name="country"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  />

                  {
                    Editid === '' ? 
                    <>
                      <TextField
                        sx={{ marginTop: 2, width: "400px" }}
                        fullWidth
                        required
                        label="Password"
                        id="password"
                        type="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <TextField
                        sx={{ marginTop: 2, width: "400px" }}
                        fullWidth
                        required
                        label="Confirm Password"
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </>
                    : ""
                  }
                  
                  <Box sx={{ marginTop: 2, width: "400px" }}>
                    <Typography>Change User Status</Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>Inactive</Typography>
                      <Typography>Active</Typography>
                    </Stack>
                  </Box> 
  

                  <br />
                  <Box className='register-error'>
                    {error}
                  </Box>
                  <br />
                  <Box className="save-btn-box">
                    <Button className="save-btn"
                      variant="contained"
                      onClick={() => validationFun()}
                    >
                      Submit
                    </Button>
                  </Box>
              </Box>
            </Grid>
          </Box>
        </Modal>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default ReferralSystem;