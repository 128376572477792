import React, {useState, useEffect} from 'react'
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Button} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Grid, TextField, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import AuthSagaActions from "../../../redux/authsaga/action";
import FormHelperText from "@mui/material/FormHelperText";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from "@mui/material/Modal";
import { DataGrid } from '@mui/x-data-grid';
import Swal from "sweetalert2";

const { getSubscriberSaga, removeSubscriber, sendnewsSaga } = AuthSagaActions;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  maxHeight: "70vh",
  bgcolor: "background.paper",
  overflow: "auto",
  boxShadow: 24,
  p: 4,
};

const NewsLetter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getSubscriber = useSelector((state) => {
    return state.authSaga.subscriberList;
  });
  const token = useSelector((state) => {
    return state.authSaga.idToken;
  });

  const [Subscribers, SetSubscriberlist] = useState([]);
  const [rows, Setrows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [count, Setcount] = useState(0);
  const [Title, Settitle] = useState("");
  const [Description, Setdescription] = useState("");
  const [TitleError, Settitleerror] = useState("");
  const [DescriptionError, Setdescriptionerror] = useState("");
  const [theArray, setTheArray] = useState([]);
  const [buttonAction, setButtonAction] = useState(true);
  const open = Boolean(anchorEl);

  const deletehandler = async (id) => {
    Swal.fire({
      title: "",
      text: "Are you sure you want to delete email ?",
      icon: "",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let urlencoded = new URLSearchParams();
        urlencoded.append("id", id);
        dispatch(removeSubscriber(urlencoded, navigate, token));
      }
    });
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 300, hide: true},
    { field: 'email', headerName: 'Email', width: 300},
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 200,
      cellClassName: 'actions',
      renderCell: (params) => {
        return(
          <>
          <DeleteIcon
          onClick={()=>deletehandler(params.row.id)}
          />
          </>
        )
      },
    },
  ];

  useEffect(() => {
    dispatch(getSubscriberSaga(navigate));
    SetSubscriberlist(getSubscriber);
  }, []);

  useEffect(() => {
    SetSubscriberlist(getSubscriber);
  }, [getSubscriber, theArray]);
  
  useEffect(() => {
    if(Subscribers) {
      let data = [];
      Subscribers &&
        Subscribers[0] &&
        Subscribers.map((element, index) => {
          data = [
            ...data,
            { id: element._id, email: element.email}
          ];
        });
        Setrows(data);
    }
  }, [Subscribers]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handelSubmit = () => {
    if (Title === "") {
      Settitleerror("Please enter news title");
    } else {
      Settitleerror("");
    }
    if (Description === "") {
      Setdescriptionerror("Please enter Description");
    } else {
      Setdescriptionerror("");
    }

    if (Title !== "" && Description !== "" && theArray.length!==0) {
      const apidata = new URLSearchParams();
      apidata.append("data", theArray);
      apidata.append("title", Title);
      apidata.append("description", Description);
      dispatch(sendnewsSaga(apidata, navigate));
    }
    setTheArray(theArray)
    setAnchorEl(null);
  };

  return (
 
    <Box className="page-content-main">
    <ToastContainer />
    <Box className='main-title-box'>
      <Typography className='main-title'  variant="h4">Newsletter List</Typography>
    </Box>
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 2,
      }}
    >
      <FormControl sx={{ m: 1, minWidth: 220, maxHeight: 50 }}></FormControl>
    </Box>

    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 2,
      }}
    >
      <FormControl sx={{ m: 1, minWidth: 220, maxHeight: 50 }}></FormControl>
      <Button
        disabled={buttonAction}
        variant="contained"
        sx={{
          maxHeight: 50,
          marginTop: 2,
          marginRight: 3,
          backgroundColor: "#271e09",
          "&:hover": { backgroundColor: "#f49d04", opacity: [0.9, 0.8, 0.7] },
        }}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          Setcount(count + 1);
        }}
      >
        Send News
      </Button>
    </Box>

    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <Box sx={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRowData = rows.filter((row) =>
              selectedIDs.has(row.id.toString())
            );
            if(selectedRowData.length === 0){
              setButtonAction(true);
            } else {
              setButtonAction(false);
            }
            
            setTheArray(selectedRowData.map(function (obj) {
              return obj.email;
            }))
          }}
        />
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid item xs={6}>
            <Box
              sx={{
                margin: "auto",
                width: 500,
                maxWidth: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <TextField
                sx={{ marginTop: 2 }}
                required
                fullWidth
                label="Title"
                id="Title"
                value={Title}
                onChange={(e) => Settitle(e.target.value)}
              />
              <FormHelperText sx={{ color: "red" }}>
                {TitleError}
              </FormHelperText>
              <CKEditor
                editor={ClassicEditor}
                data={Description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  Setdescription(data);
                }}
              />

              <FormHelperText sx={{ color: "red" }}>
                {DescriptionError}
              </FormHelperText>
              <Box className="save-btn-box">
                <Button
                  className="save-btn"
                  variant="contained"
                  onClick={() => handelSubmit()}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Grid>
        </Box>
      </Modal>

    </Paper>
  </Box>
  )
}

export default NewsLetter