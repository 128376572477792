import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Button } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Grid, TextField, Typography } from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useSelector, useDispatch } from "react-redux";
import AuthSagaActions from "../../../redux/authsaga/action";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Parser from "html-react-parser";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import "react-toastify/dist/ReactToastify.css";
import Modal from "@mui/material/Modal";
import Swal from "sweetalert2";

const { addFaq, getFaqsaga, updateFaq, removeFaq } = AuthSagaActions;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  maxHeight: "70vh",
  bgcolor: "background.paper",
  overflow: "auto",
  boxShadow: 24,
  p: 4,
};
const FaqList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getFaqs = useSelector((state) => {
    return state.authSaga.faqsList;
  });

  const token = useSelector((state) => {
    return state.authSaga.idToken;
  });

  const [page, setPage] = useState(0);
  const [FaqList, SetFaqlist] = useState([]);
  const [rows, Setrows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [question, SetQuestion] = useState("");
  const [Editid, Seteditid] = useState("");
  const [count, Setcount] = useState(0);
  const [answer, SetAnswer] = useState("Answer...");
  const [errorObject,setErrorObject] = useState({});
 
  const columns = [
    { id: "Question", label: "Question", minWidth: 170 },
    {
      id: "Answer",
      label: "Answer",
      minWidth: 270,
    },
    {
      id: "b2",
      label: "Action",
      minWidth: 50,
      align: "right",
    },
    {
      id: "b3",
      label: "",
      minWidth: 50,
      align: "right",
    },
  ];

  function createData(Question, Answer, id) {
    let b2 = (
      <EditIcon 
        sx={{cursor:'pointer'}}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          Seteditid(id);
          Setcount((pre) => pre + 1);
        }}
      />
    );

    let b3 = (
      <DeleteIcon sx={{cursor:'pointer'}} onClick={() => deletehandler(id)} />
    );
    return { Question, Answer, b2, b3 };
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    FaqList &&
      FaqList[0] &&
      FaqList.map((element, index) => {
        if (element._id === Editid) {
            setErrorObject({});
            SetQuestion(element.question);
            SetAnswer(element.answer);
        }
      });
    if (Editid === "") {
        SetQuestion("");
        SetAnswer("");
        setErrorObject({});
    }
  }, [Editid, count]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const deletehandler = async (id) => {
    Swal.fire({
      title: "",
      text: "Are you sure you want to delete FAQ ?",
      icon: "",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let urlencoded = new URLSearchParams();
        urlencoded.append("id", id);
        dispatch(removeFaq(urlencoded, navigate, token));
      }
    });
  };

  
  const validationFun=(question,answer)=>{
    let errors = {};
    if(question == "" || question.replace( /\s\s+/g, ' ')?.length<3){
        errors.question = "Enter at least 3 characters in Question";
    } else if(question.replace( /\s\s+/g, ' ') == "" || question.replace( /\s\s+/g, ' ')?.length>100){
        errors.question = "Enter not more then 100 characters in Question";
    }
  
    if(answer == "" || answer.replace( /\s\s+/g, ' ')?.length<3){
        errors.answer = "Enter at least 3 characters in answer";
    } 
    return errors;
  }
  
  const handleValidation = (e) => {
    if (Object.keys(validationFun(question,answer)).length === 0) {
        handleSubmit();
        setErrorObject({});
    } else {
        setErrorObject(validationFun(question,answer))
    }
   // console.log(validationFun(question,answer));
  };

  const handleSubmit = () => {
      const apidata = new URLSearchParams();
      apidata.append("question", question);
      apidata.append("answer", answer);
      if (Editid !== "") {
        apidata.append("id", Editid);
      }
      Editid===""?dispatch(addFaq(apidata, navigate, token)):dispatch(updateFaq(apidata, navigate, token))
      setAnchorEl(null);
  };

  useEffect(() => {
    dispatch(getFaqsaga(navigate));
    SetFaqlist(getFaqs);
  }, []);

  useEffect(() => {
    SetFaqlist(getFaqs);
  }, [getFaqs]);
  
  useEffect(() => {
    let data = [];
    FaqList &&
      FaqList[0] &&
      FaqList.map((element, index) => {
        data = [
          ...data,
          createData(
            element.question,
            <td>{Parser(element.answer)}</td>,
            element._id
          ),
        ];
       
      });
      Setrows(data);
  }, [FaqList]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  console.log('AnswerAnswer', answer);
  return (
    <Box className="page-content-main">
      <ToastContainer />
      <Box className='main-title-box'>
        <Typography className='main-title'  variant="h4">FAQ List</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        <FormControl sx={{ m: 1, minWidth: 220, maxHeight: 50 }}></FormControl>
        <Button
          variant="contained"
          sx={{ maxHeight: 50, marginTop: 2 ,   backgroundColor:'#271e09',
          "&:hover": { backgroundColor: "#f49d04", opacity: [0.9, 0.8, 0.7] },}}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            Seteditid("");
            Setcount(count + 1);
          }}
        >
          Add New
        </Button>
      </Box>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer className="table-main-box">
          <Table stickyHeader aria-label="sticky table" className="table-main">
            <TableHead className="table-head">
              <TableRow className="table-row">
                {columns.map((column) => (
                  <TableCell className="table-cell"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <h4 style={{ margin: 0 }}>{column.label}</h4>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="table-body">
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow className="table-row"
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align} className="table-cell">
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid item xs={6}>
              <Box
                sx={{
                  margin: "auto",
                  width: 500,
                  maxWidth: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
                className="validation"
              >
             
                <TextField
                  sx={{ marginTop: 2, mb:2}}
                  required
                  fullWidth
                  error={errorObject['question'] !== undefined}
                  label="Question"
                  id="Question"
                  value={question}
                  onChange={(e) => SetQuestion(e.target.value)}
                  helperText={errorObject?.question}
                />

                <FormHelperText sx={{ fontSize: 14, color:'black' }}>Answer *</FormHelperText>
                <CKEditor
                  editor={ClassicEditor}
                  data={answer}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    SetAnswer(data);
                  }}
                />
                <FormHelperText sx={{ color: "#d32f2f", mb: 2 }}>{errorObject?.answer}</FormHelperText>

                <Box className="save-btn-box">
                  <Button className="save-btn"
                    variant="contained"
                    onClick={() => handleValidation()}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Box>
         
        </Modal>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default FaqList;
