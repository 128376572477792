import React, { useState, useEffect } from "react";
import Index from "../../Index";
import { Menu, MenuItem } from "@mui/material";
import Png from "../../../assets/png";
import svg from "../../../assets/svg";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuthSagaActions from "../../../redux/authsaga/action";
// import Png from '../assets/png';

const { userLogoutSaga, getNotificationUser } = AuthSagaActions;
export default function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoginUser = useSelector((state) => {
    return state.authSaga.isloginUser;
  });
  const userNotification = useSelector((state) => {
    return state.authSaga.userNotification;
  });
  const token = useSelector((state) => {
    return state.authSaga.idTokenUser;
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [notification, setNotification] = useState([])

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  //   handleMobileMenuClose();
  // };
  useEffect(() => {
    dispatch(getNotificationUser(navigate, token));
  }, []);

  useEffect(() => {
    setNotification(userNotification);
  }, [userNotification]);
  console.log(notification, "notification");

  //console.log('isLoginUser =', isLoginUser);
  const handleUserLogout = () => {
    dispatch(userLogoutSaga(navigate));
    navigate('/login');
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      className="mob-menu-set"
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        onClick={() => {
          navigate("/");
        }}
      >
        Home
      </MenuItem>

      {/* <MenuItem
        onClick={() => {
          navigate("/deposite");
        }}
      >
        Deposite
      </MenuItem> */}

      <MenuItem
        onClick={() => {
          navigate("/about");
        }}
      >
        About
      </MenuItem>

      <MenuItem
        onClick={() => {
          navigate('/whitepaper');
        }}
      >
        Whitepaper
      </MenuItem>
      {
        isLoginUser ?
          <MenuItem
            onClick={() => {
              navigate("/coinlogs");
            }}
          >
            Coinlogs
          </MenuItem>
          : ""
      }
      {
        isLoginUser ?
          <MenuItem
            onClick={() => {
              navigate("/referral-logs");
            }}
          >
            Referral logs
          </MenuItem>
          : ""
      }



      <MenuItem
        onClick={() => {
          navigate("/contact");
        }}
      >
        Contact
      </MenuItem>

      {isLoginUser ? (
        ""
      ) : (
        <>
          <MenuItem
            onClick={() => {
              navigate("/register");
            }}
          >
            Register
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/login");
            }}
          >
            Login
          </MenuItem>
        </>
      )}
    </Menu>
  );
  return (
    <Index.Box className="res-mob-box">
      <Index.Container maxWidth="false" className="container container-set  ">
        <Index.Box
          className="header-box"
          sx={{
            mx: { xs: 0, lg: 4 },
            boxShadow: "none",
            border: "none",
            "& .css-hip9hq-MuiPaper-root-MuiAppBar-root": {
              bgcolor: "white",
            },
          }}
        >
          <Index.AppBar
            sx={{}}
            style={{
              boxShadow: "none",
            }}
            position="static"
          >
            <Index.Toolbar
              className="header-set header-box"
              sx={{
                height: "120px",
                bgcolor: "white",
              }}
            >
              <Index.Link className="link-style" href="/dashboard">
                <Index.Box className="logo" component="img" src={Png.yourLogoHere} />
              </Index.Link>
              <Index.Box sx={{ flexGrow: 1 }}></Index.Box>

              <Index.Box
                className="header mob-menu"
                sx={{
                  display: {
                    // xs: 'none',
                    md: "flex",
                    justifyContent: "space-between",
                    width: "800px",
                  },
                }}
              >

                {isLoginUser ? (
                  ""
                ) : (
                  <>
                    <MenuItem
                      className="header"
                      onClick={() => {
                        navigate("/");
                      }}
                    >
                      Home
                    </MenuItem>

                    {/* <MenuItem
                      onClick={() => {
                        navigate("/deposite");
                      }}
                    >
                      Deposite
                    </MenuItem> */}

                    <MenuItem
                      className="header"
                      onClick={() => {
                        navigate("/about");
                      }}
                    >
                      About
                    </MenuItem>
                  </>
                )}


                <MenuItem
                  onClick={() => {
                    navigate('/whitepaper');
                  }}
                >
                  White paper
                </MenuItem>
                {
                  isLoginUser ?
                    <MenuItem
                      className="header"
                      onClick={() => {
                        navigate("/coinlogs");
                      }}
                    >
                      CoinLogs
                    </MenuItem> : ""
                }
                {
                  isLoginUser ?
                    <MenuItem
                      className="header"
                      onClick={() => {
                        navigate("/referral-logs");
                      }}
                    >
                      Referral Logs
                    </MenuItem> : ""
                }


                <MenuItem
                  className="header"
                  onClick={() => {
                    navigate("/contact");
                  }}
                >
                  Contact
                </MenuItem>

                {isLoginUser ? (
                  ""
                ) : (
                  <>
                    <MenuItem
                      className="header register"
                      onClick={() => {
                        navigate("/register");
                      }}
                    >
                      Register
                    </MenuItem>
                    <Index.Box className="header-btn-login">
                      <Index.Link className="link-style" to="/login">
                        <Index.Button
                          variant="contained"
                          onClick={() => {
                            navigate("/login");
                          }}
                        >
                          Login
                        </Index.Button>
                      </Index.Link>
                    </Index.Box>
                  </>
                )}
              </Index.Box>

              {isLoginUser ? (
                <>
                  {/* Profile, logout section */}
                  <Index.Box className="icone-profile">
                    <Index.Box className="icone-profile1 profile-set  coin-li">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="Component_3_2"
                        data-name="Component 3 – 2"
                        width="17.999"
                        height="17.999"
                        viewBox="0 0 17.999 17.999"
                        className="icon-color"
                      >
                        <path
                          id="Icon_awesome-user-alt"
                          data-name="Icon awesome-user-alt"
                          d="M9,10.124A5.062,5.062,0,1,0,3.937,5.062,5.064,5.064,0,0,0,9,10.124Zm4.5,1.125H11.562a6.12,6.12,0,0,1-5.126,0H4.5a4.5,4.5,0,0,0-4.5,4.5v.562A1.688,1.688,0,0,0,1.687,18H16.312A1.688,1.688,0,0,0,18,16.312v-.562A4.5,4.5,0,0,0,13.5,11.249Z"
                          fill="#0b1f1e"
                        />
                      </svg>
                      <Index.Box className="coinlogs-ul">
                        <MenuItem
                          className="header"
                          onClick={() => {
                            navigate("/myprofile");
                          }}
                        >
                          My profile
                        </MenuItem>

                        <MenuItem
                          className="header"
                          onClick={() => {
                            navigate("/changepassword");
                          }}
                        >
                          Change password
                        </MenuItem>

                        <MenuItem className="header" onClick={handleUserLogout}>
                          Logout
                        </MenuItem>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  {/* Notifications */}
                  <Index.Box className="icone-profile1 profile-set coin-li">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="Component_4_2"
                      data-name="Component 4 – 2"
                      width="17.5"
                      height="20"
                      viewBox="0 0 17.5 20"
                      className="icon-color"
                    >
                      <path
                        id="Icon_awesome-bell"
                        data-name="Icon awesome-bell"
                        d="M8.75,20a2.5,2.5,0,0,0,2.5-2.5h-5A2.5,2.5,0,0,0,8.75,20Zm8.414-5.848C16.409,13.341,15,12.121,15,8.125a6.169,6.169,0,0,0-5-6.061V1.25a1.249,1.249,0,1,0-2.5,0v.814a6.169,6.169,0,0,0-5,6.061c0,4-1.412,5.216-2.167,6.027A1.22,1.22,0,0,0,0,15a1.251,1.251,0,0,0,1.254,1.25H16.246A1.251,1.251,0,0,0,17.5,15a1.22,1.22,0,0,0-.336-.848Z"
                        transform="translate(0)"
                        fill="#0b1f1e"
                      />
                    </svg>
                    <Index.Box className="coinlogs-ul coinlogs-ul2">
                      <Index.Box className="notifications">
                        <Index.Typography variant="p" component="p">
                          Notifications
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="notifiction-box">
                        {
                          notification?.map((data) => {
                            return (
                              <>
                                <MenuItem className="header">
                                  <Index.Box className="main-notification">
                                    <Index.Box className="main-notification-inner">
                                      <Index.Box className="icone-noti">
                                        <img src={Png.notificationimg} alt=""></img>
                                      </Index.Box>
                                      <Index.Box className="noti-text">
                                        <Index.Typography variant="p" component="p">
                                          {/* Lorem ipsum{" "} */}
                                          <span className="noti-span">
                                            {data?.description}
                                          </span>{" "}
                                          {/* consetetur sadipscing elitr. */}
                                        </Index.Typography>
                                        <Index.Box className="text-number">
                                          <Index.Typography variant="p" component="p">
                                            {new Date(data.createdAt).toLocaleString()}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </MenuItem>
                              </>
                            )

                          })
                        }


                        {/* <MenuItem className="header">
                          <Index.Box className="main-notification">
                            <Index.Box className="main-notification-inner">
                              <Index.Box className="icone-noti">
                                <img src={Png.notificationimg}></img>
                              </Index.Box>
                              <Index.Box className="noti-text">
                                <Index.Typography variant="p" component="p">
                                  Lorem ipsum{" "}
                                  <span className="noti-span">
                                    dolor sit amet,
                                  </span>{" "}
                                  consetetur sadipscing elitr.
                                </Index.Typography>
                                <Index.Box className="text-number">
                                  <Index.Typography variant="p" component="p">
                                    10 April
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </MenuItem>

                        <MenuItem className="header">
                          <Index.Box className="main-notification">
                            <Index.Box className="main-notification-inner">
                              <Index.Box className="icone-noti">
                                <img src={Png.notificationimg}></img>
                              </Index.Box>
                              <Index.Box className="noti-text">
                                <Index.Typography variant="p" component="p">
                                  Lorem ipsum{" "}
                                  <span className="noti-span">
                                    dolor sit amet,
                                  </span>{" "}
                                  consetetur sadipscing elitr.
                                </Index.Typography>
                                <Index.Box className="text-number">
                                  <Index.Typography variant="p" component="p">
                                    12 March
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </MenuItem>

                        <MenuItem className="header">
                          <Index.Box className="main-notification">
                            <Index.Box className="main-notification-inner">
                              <Index.Box className="icone-noti">
                                <img src={Png.notificationimg}></img>
                              </Index.Box>
                              <Index.Box className="noti-text">
                                <Index.Typography variant="p" component="p">
                                  Lorem ipsum{" "}
                                  <span className="noti-span">
                                    dolor sit amet,
                                  </span>{" "}
                                  consetetur sadipscing elitr.
                                </Index.Typography>
                                <Index.Box className="text-number">
                                  <Index.Typography variant="p" component="p">
                                    12 March
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </MenuItem>

                        <MenuItem className="header">
                          <Index.Box className="main-notification">
                            <Index.Box className="main-notification-inner">
                              <Index.Box className="icone-noti">
                                <img src={Png.notificationimg}></img>
                              </Index.Box>
                              <Index.Box className="noti-text">
                                <Index.Typography variant="p" component="p">
                                  Lorem ipsum{" "}
                                  <span className="noti-span">
                                    dolor sit amet,
                                  </span>{" "}
                                  consetetur sadipscing elitr.
                                </Index.Typography>
                                <Index.Box className="text-number">
                                  <Index.Typography variant="p" component="p">
                                    12 March
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </MenuItem>

                        <MenuItem className="header">
                          <Index.Box className="main-notification">
                            <Index.Box className="main-notification-inner">
                              <Index.Box className="icone-noti">
                                <img src={Png.notificationimg}></img>
                              </Index.Box>
                              <Index.Box className="noti-text">
                                <Index.Typography variant="p" component="p">
                                  Lorem ipsum{" "}
                                  <span className="noti-span">
                                    dolor sit amet,
                                  </span>{" "}
                                  consetetur sadipscing elitr.
                                </Index.Typography>
                                <Index.Box className="text-number">
                                  <Index.Typography variant="p" component="p">
                                    12 March
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </MenuItem> */}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </>
              ) : (
                ""
              )}

              <Index.Box
                sx={{ display: { xs: "flex", md: "none", color: "black" } }}
                className="mob-menu-left"
              >
                <Index.IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <Index.Box className="mob-menu-img">
                    <img src={svg.menubtn} alt=""></img>
                  </Index.Box>
                  {/* <Index.MoreIcon > */}
                </Index.IconButton>
              </Index.Box>
            </Index.Toolbar>
          </Index.AppBar>
          {renderMobileMenu}
        </Index.Box>
      </Index.Container>
    </Index.Box>
  );
}
