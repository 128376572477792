import React, { useState, useEffect } from 'react'
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Button } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Grid, TextField,Typography} from '@mui/material';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useSelector, useDispatch } from 'react-redux'
import AuthSagaActions from "../../../redux/authsaga/action";
import { useNavigate } from "react-router-dom"
import { ToastContainer } from 'react-toastify';
import Parser from 'html-react-parser';
import 'react-toastify/dist/ReactToastify.css';
import Modal from '@mui/material/Modal';
const { addRemoveRoadmapsaga,getRoadmapsaga} = AuthSagaActions;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  maxHeight:'70vh',
  bgcolor: 'background.paper',
  overflow:'auto',
  boxShadow: 24,
  p: 4,
};

const WhitePaper= () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const newslist = useSelector((state) => { return state.authSaga.Road });
  const [page, setPage] = React.useState(0);
  const [Url, Seturl] = React.useState([]);
  const [NewsList, Setnewslist] = React.useState([]);
  const [rows, Setrows] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [Title, Settitle] = useState('');
  const [Editid, Seteditid] = useState('');
  const [count, Setcount] = useState(0);
  const [OldImages, SetOldimages] = useState([]);
  const [Images, Setimages] = useState();
  const [Position, Setposition] = useState(0);
  const [Description, Setdescription] = useState('Description...');
  const [TitleError, Settitleerror] = useState('');
  const [DescriptionError, Setdescriptionerror] = useState('');
  const [SubTitle, Setsubtitle] = useState('');
  const [YearError, SetyearError] = useState('');

  const columns = [
    { id: 'Title', label: 'Rodemap Title', minWidth: 170 },
    {
      id: 'Description',
      label: 'Description',
      minWidth: 270,
    },
    {
      id: 'Position',
      label: 'Position',
      minWidth: 170,

    },
    {
      id: 'Year',
      label: 'Year',
      minWidth: 170,

    },
    {
      id: 'b2',
      label: '',
      minWidth: 70,
      align: 'right',
    },
    {
      id: 'b3',
      label: '',
      minWidth: 70,
      align: 'right',
    },
  ];

  function createData(Title, Description, id,Position,Year) {
    let b2 = <Button variant="contained" sx={{bgcolor:'#e4a32e','&:hover': {backgroundColor: '#f49d04',opacity: [0.9, 0.8, 0.7]}}} onClick={(event) => { setAnchorEl(event.currentTarget);Seteditid(id); Setcount((pre) => pre + 1) }}>Edit</Button>
    let b3 = <Button variant="contained" sx={{bgcolor:'#e4a32e','&:hover': {backgroundColor: '#f49d04',opacity: [0.9, 0.8, 0.7]}}} onClick={()=>deletehandler(id)}>Remove</Button>
    return { Title, Description,Position,Year,b2,b3 };
  }


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  useEffect(() => {
    NewsList&&NewsList[0]&&NewsList.map((element, index) => {
      if (element._id === Editid) {
        Settitle(element.Title);
        Setsubtitle(element.SubTitle);
        Setdescription(element.Description);
        SetOldimages(element.Images);
        Setposition(element.Position);
        Seturl([]);
        Setimages([])
      }
    })
    if (Editid === '') {
      Settitle('');
      Setsubtitle('');
      Setposition('');
      Setdescription('');
      SetOldimages([]);
      Seturl([]);
      Setimages([])
    }
  }, [Editid, count])
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
const deletehandler=(id)=>{
  const apidata = new URLSearchParams();
  apidata.append('id', id);
  apidata.append('deletstatus', '1');
  dispatch(addRemoveRoadmapsaga(apidata, navigate))

}


  const handelSubmit = () => {
    if (Title === '') {
      Settitleerror('Please enter news title')
    } else {
      Settitleerror('')
    }
    if (SubTitle === '') {
      SetyearError('Please enter Year')
    } else {
      SetyearError('')
    } 
    if (Description === '') {
      Setdescriptionerror('Please enter Description')
    } else {
      Setdescriptionerror('')
    }

    if (Title !== '' && Description !== ''&&SubTitle!=='') {
      const apidata = new URLSearchParams();
      apidata.append('Title', Title);
      apidata.append('SubTitle', SubTitle);
      apidata.append('Description', Description);
      if (Editid !== '') {
        apidata.append('editid', Editid);
        // apidata.append('oldimagearray', JSON.stringify(OldImages))
      }
      dispatch(addRemoveRoadmapsaga(apidata, navigate))
      setAnchorEl(null);
    }
  }
  const removeurl = (element) => {
    let array = [...OldImages];
    array.splice(array.indexOf(element), 1);
    SetOldimages(array);
  }
  useEffect(() => {
    dispatch(getRoadmapsaga(navigate));
    Setnewslist(newslist);
  }, [])
  useEffect(() => {
    // dispatch(getnewssaga(navigate));
    Setnewslist(newslist);
  }, [newslist])
  useEffect(() => {
    let data = []
    NewsList&&NewsList[0]&&NewsList.map((element, index) => {
      data = [...data, createData(element.Title, <td>{Parser(element.Description)}</td>, element._id,element.Position,element.Year)];
      Setrows(data)
    })
  }, [NewsList])
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Box>
      <ToastContainer />
      <Typography variant="h4" align="center" sx={{mb:2}}>
        White Paper
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 2 }}><FormControl sx={{ m: 1, minWidth: 220, maxHeight: 50 }}>
      </FormControl><Button variant="contained" sx={{ maxHeight: 50, marginTop: 2,bgcolor:'#e4a32e','&:hover': {backgroundColor: '#f49d04',opacity: [0.9, 0.8, 0.7]}}} onClick={(event) => { setAnchorEl(event.currentTarget); Seteditid(''); Setcount(count + 1) }}>Add New</Button></Box>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <h4 style={{ margin: 0 }}>{column.label}</h4>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Grid item xs={6}>
              <Box
                sx={{
                  margin: 'auto',
                  width: 500,
                  maxWidth: '100%',
                  display: "flex",
                  flexDirection: 'column',
                  justifyContent: "center"
                }}
              >
                {/* <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>{OldImages.map((element, index) => (<Box sx={{ display: 'flex' }}><img height={80} width={80} style={{ margin: "auto" }} src={element}></img><CloseIcon sx={{ position: "relative", right: "20px" }} onClick={() => { removeurl(element) }} /></Box>))}{Url.map((element, index) => (<img height={80} width={80} style={{ margin: "auto" }} src={element}></img>))}</Box> */}
                <TextField sx={{ marginTop: 2 }} required fullWidth label="Title" id="Title" value={Title} onChange={(e) => Settitle(e.target.value)} />
                <FormHelperText sx={{ color: '#d32f2f' }}>{TitleError}</FormHelperText>
                {/* <TextField sx={{ marginTop: 2 }} required fullWidth type='number' label="Position" id="Position" value={Position} onChange={(e) => Setposition(e.target.value)} />
                <FormHelperText sx={{ color: 'red' }}>{PositionError}</FormHelperText> */}
                <TextField sx={{ marginTop: 2 }} required fullWidth  label="Subtitle" id="Subtitle" value={SubTitle} onChange={(e) => Setsubtitle(e.target.value)} />
                <FormHelperText sx={{ color: '#d32f2f' }}>{YearError}</FormHelperText>
                <CKEditor
                  editor={ClassicEditor}
                  data={Description}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    Setdescription(data)
                  }}
                />

                <FormHelperText sx={{ color: '#d32f2f' }}>{DescriptionError}</FormHelperText>
                <Box sx={{ display: "flex", justifyContent: "center" }}><Button sx={{ marginTop: 2, width: "20px",bgcolor:'#e4a32e','&:hover': {backgroundColor: '#f49d04',opacity: [0.9, 0.8, 0.7]} }} variant="contained" onClick={() => handelSubmit()}>Submit</Button></Box>
              </Box>
            </Grid>
          </Box>
        {/* </Popover> */}
        </Modal>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  )
}


export default WhitePaper