import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useSelector, useDispatch } from "react-redux";
import AuthSagaActions from "../../../redux/authsaga/action";
import FormHelperText from "@mui/material/FormHelperText";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Card, Typography, Container } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { makeStyles } from "@mui/styles";

const { getCoinPriceSaga, addCoinPriceSaga } = AuthSagaActions;

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "TextValidator",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const CoinPrice = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();

  const token = useSelector((state) => {
    return state.authSaga.idToken;
  });
  const coinPriceDetail = useSelector((state) => {
    return state.authSaga.coinPrice;
  });

  const [coinPrice, setCoinPrice] = useState("");
  const [minimum, setMinimum] = useState("");

  const [coinError, setCoinError] = useState("");
  const [value, setValue] = useState(dayjs(''));

  const handelSubmit = () => {
    if (coinPrice === "" || coinPrice === undefined) {
      setCoinError("Please enter coin price");
      return false;
    } else {
      setCoinError("");
      const apidata = new URLSearchParams();
      apidata.append("id", coinPriceDetail?._id);
      apidata.append("date", value);
      apidata.append("minimumWithdrwal", minimum);
      apidata.append("referralAmount", coinPrice);
      dispatch(addCoinPriceSaga(apidata, navigate, token));
    }
  };

  useEffect(() => {
    if (coinPriceDetail) {
      setCoinPrice(coinPriceDetail?.referralAmount ? coinPriceDetail.referralAmount : "");
      setMinimum(coinPriceDetail?.minimumWithdrwal ? coinPriceDetail.minimumWithdrwal : "");
      setValue(coinPriceDetail?.date ?dayjs( coinPriceDetail.date) : "");
    }
  }, [coinPriceDetail]);

  useEffect(() => {
    dispatch(getCoinPriceSaga(navigate, token));
  }, []);

  return (
    <Box className="page-content-main">
      <Container maxWidth="false" className="editor-main">
        <ToastContainer />
        <Box className="main-title-box">
          <Typography className="main-title" variant="h4">
            Coin Price
          </Typography>
        </Box>
        <Card
          sx={{
            maxWidth: "500px",
            display: "flex",
            flexDirection: "column",
            margin: "auto",
            marginTop: 5,
            padding: "20px",
            backgroundColor: "#f1f1f1",
          }}
        >
          <Box>Date for credit</Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              views={['day']}
              label="Just date"
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              renderInput={(params) => <TextField {...params} helperText={null} />}
            />
          </LocalizationProvider>
          <TextField
            className={classes.input}
            onWheel={(e) => e.target.blur()}
            sx={{ marginTop: 2, height: 50 }}
            required
            fullWidth
            label="Coin Price"
            type="number"
            id="coinPrice"
            value={coinPrice}
            onChange={(e) => setCoinPrice(e.target.value)}
          />
          <TextField
            className={classes.input}
            onWheel={(e) => e.target.blur()}
            sx={{ marginTop: 2, height: 50 }}
            required
            fullWidth
            label="Minimun amount"
            type="number"
            id="coinPrice"
            value={minimum}
            onChange={(e) => setMinimum(e.target.value)}
          />
          <FormHelperText sx={{ color: "#d32f2f" }}>{coinError}</FormHelperText>

          <br />
          <Box className="save-btn-box">
            <Button
              className="save-btn"
              variant="contained"
              onClick={() => handelSubmit()}
            >
              Save
            </Button>
          </Box>
        </Card>
      </Container>
    </Box>
  );
};

export default CoinPrice;
