import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import png from "../../../assets/images/ApnaCastLogoHeader2.png";
import { Outlet, Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
// import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import MoreIcon from "@mui/icons-material/MoreVert";
// import InputBase from '@mui/material/InputBase';
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
// import Typography from '@mui/material/Typography';
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AuthSagaActions from "../../../redux/authsaga/action";
import { useNavigate } from "react-router-dom";

const drawerWidth = 280;
const { logoutSaga } = AuthSagaActions;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function SideBar(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const { pathname } = useLocation();
  // const history = useHistory();
  // let dispatch = useDispatch();
  const theme = useTheme();
  const location = useLocation();
  const userData = useSelector((state) => state.AdminReducer);
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [openside1, setOpenSide1] = useState(false);
  const [openside2, setOpenSide2] = useState(false);
  const [openside3, setOpenSide3] = useState(false);
  const [openside4, setOpenSide4] = useState(false);
  const [sidemenu, setSideMenu] = useState([]);
  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  //   handleMobileMenuClose();
  // };
  const handleNexted = () => {
    console.log("111");
    setOpenSide1(!openside1);
  };
  const handleNexted2 = () => {
    console.log("222");
    setOpenSide2(!openside2);
  };
  const handleNexted3 = () => {
    console.log("333");
    setOpenSide3(!openside3);
  };

  const handleNexted4 = () => {
    console.log("333");
    setOpenSide4(!openside4);
  };
  useEffect(() => {
    let sideRoute = [
      {
        name: "Dashbord",
        Link: "/admin/dashboard",
      },
      {
        name: "User Management",
        Link: "/admin/users",
      },
      {
        name: "Referral System",
        Link: "/admin/referral-system",
      },
      {
        name: "Approve redeem coins",
        Link: "/admin/approve-coins",
      },
      {
        name: "Transaction History",
        Link: "/admin/transactionHistory",
      },
      {
        name:"Whitelist User",
        Link:"/admin/whitelistuser"
      },
      {
        name: "Team Members",
        Link: "/admin/teammembers",
      },
      // {
      //   name: "Transaction Hestory",
      //   Link: "/admin/transactionhestory"
      // },
      // {
      //   name: "White Paper",
      //   Link: "/admin/whitepaper",
      // },
     
      // {
      //   name:"News Management",
      //   Link:"/admin/newsmanagement"
      // },
      {
        name: "RoadMap Management",
        Link: "/admin/roadmap",
      },
      {
        name: "CMS",
        Link: "",
        Nexted: [
          {
            name: "Privacy Policy",
            Link: "/admin/securityandprivacy",
          },
          {
            name: "Terms and Conditions",
            Link: "/admin/termsandcondition",
          },
          {
            name: "About Us",
            Link: "/admin/aboutus",
          },
          {
            name: "Contact Us",
            Link: "/admin/contactus",
          },
          {
            name: "Contact Enquiry",
            Link: "/admin/contactEnquiry",
          },
        ],
      },
      {
        name: "ICO solution",
        Link: "",
        Nexted2: [
          {
            name: "ICO Web solution",
            Link: "/admin/icowebsolution",
          },
          {
            name: "ICO learn solution",
            Link: "/admin/icolearn",
          },
          {
            name: "ICO Lab Features",
            Link: "/admin/icolab",
          },
          {
            name: "ICO Management",
            Link: "/admin/calender",
          },
        ],
      },
      {
        name: "IDO solution",
        Link: "",
        Nexted4: [
          // {
          //   name: "IDO Web solution",
          //   Link: "/admin/idowebsolution",
          // },
          // {
          //   name: "IDO learn solution",
          //   Link: "/admin/idolearn",
          // },
          // {
          //   name: "IDO Lab Features",
          //   Link: "/admin/idolab",
          // },
          {
            name: "IDO Management",
            Link: "/admin/idocalender",
          },
        ],
      },
      {
        name: "Website Setting",
        Link: "/admin/websitesetting",
      },
      {
        name: "Our Clients",
        Link: "/admin/ourclients",
      },
      {
        name: "FAQ'S",
        Link: "/admin/faq",
      },
      {
        name: "Newsletter",
        Link: "/admin/subscribers",
      },
      {
        name: "Coin Price",
        Link: "/admin/coinprice",
      },
      //  {
      //   name: "System Management",
      //   Link: "",
      //   Nexted2: [ {
      //     name: "Edit Profile",
      //     Link: "/admin/editProfile"
      //   },{
      //     name: "Reset Password",
      //     Link: "/admin/resetPassword"
      //   }
      //   , {
      //     name: "Logout",
      //     Link: ""
      //   }]
      // }
    ];
    setSideMenu(sideRoute);
  }, []);

  const handleMenuLogout = () => {
    dispatch(logoutSaga(navigate));
  };

  const handleDefult = () => {
    // console.log("");
  };

  const menuId = "primary-search-account-menu";
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const Logouthandler = () => {
    dispatch(logoutSaga(navigate));
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleMenuLogout}>Logout</MenuItem>
    </Menu>
  );

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open} className="app-bar">
          <Toolbar sx={{ backgroundColor: "#271e09" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
            <img
              src={png}
              className="logo-img"
              style={{ marginLeft: "0px", ...(open && { display: "none" }) }}
              alt=""
            ></img>
            <Box sx={{ flexGrow: { xs: 1, lg: 1 } }} />
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Box>
                <Button
                  onClick={handleMenuLogout}
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                    textDecoration: "none",
                    boxShadow: "none",
                    ml: "auto",
                    width: "115px",
                    background: "linear-gradient(#e1c820, #f7931e)",
                    borderRadius: "33px",
                  }}
                  variant="contained"
                >
                  Logout
                </Button>
              </Box>
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        <Drawer
          className="admin-sidebar-main"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              backgroundColor: "#271e09",
            },
            "& .css-12i7wg6-MuiPaper-root-MuiDrawer-paper:hover": {
              overflowY: "auto",
            },
            "& .css-12i7wg6-MuiPaper-root-MuiDrawer-paper": {
              overflow: "hidden",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <Box className="admin-logo-main">
            <img
              src={png}
              className="logo-img admin-logo-img"
              alt=""
              style={{ margin: "auto" }}
            ></img>
            <IconButton onClick={handleDrawerClose} sx={{ color: "white" }}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
            <IconButton onClick={handleDrawerClose} sx={{ width: "20%" }}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon
                  sx={{ color: "#fff", margin: "15px 0px 0px 233px" }}
                />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </Box>

          <Divider />
          <List
            sx={{
              width: "100%",
              maxWidth: 360,
              bgcolor: "#271e09",
              height: "100em",
            }}
          >
            {sidemenu.map((text, index) => (
              <>
                <ListItem key={text.link} disablePadding>
                  {text.Link === "" ? (
                    text?.Nexted ? (
                      <ListItemButton
                        sx={{ color: "#FFF" }}
                        onClick={() => {
                          text?.Nexted
                            ? handleNexted()
                            : text?.Nexted2
                            ? handleNexted2()
                            : handleDefult();
                        }}
                      >
                        <ListItemText
                          sx={{ fontSize: "15px" }}
                          primary={text.name}
                        />
                        {openside1 ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                    ) : text?.Nexted2 ? (
                      text?.Nexted2 && (
                        <ListItemButton
                          sx={{ color: "#FFF" }}
                          selected={
                            pathname === text.Nexted
                              ? text.Nexted.Link
                              : text.Nexted2.Link
                          }
                          onClick={() => {
                            text?.Nexted
                              ? handleNexted()
                              : text?.Nexted2
                              ? handleNexted2()
                              : handleDefult();
                          }}
                        >
                          <ListItemText
                            sx={{
                              "& .MuiTypography-root": { fontSize: "15px" },
                            }}
                            primary={text.name}
                          />
                          {openside2 ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                      )
                    ) : (text?.Nexted4) && (
                      text?.Nexted4 && (
                        <ListItemButton
                          sx={{ color: "#FFF" }}
                          selected={
                            pathname === text.Nexted
                              ? text.Nexted.Link
                              : text.Nexted4.Link
                          }
                          onClick={() => {
                            text?.Nexted
                              ? handleNexted()
                              : text?.Nexted4
                              ? handleNexted4()
                              : handleDefult();
                          }}
                        >
                          <ListItemText
                            sx={{
                              "& .MuiTypography-root": { fontSize: "15px" },
                            }}
                            primary={text.name}
                          />
                          {openside4 ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                      )
                    )
                  ) : (
                    <Link
                      to={text.Link}
                      style={{
                        textDecoration: "none",
                        color: "#FFF",
                        width: "100%",
                      }}
                    >
                      <ListItemButton
                        selected={pathname === text.Link}
                        onClick={
                          text?.Nexted
                            ? handleNexted
                            : text?.Nexted2
                            ? handleNexted2
                            : handleDefult
                        }
                      >
                        <ListItemText
                          sx={{ "& .MuiTypography-root": { fontSize: "15px" } }}
                          primary={text.name}
                        />
                      </ListItemButton>
                    </Link>
                  )}
                </ListItem>
                <Collapse in={openside1} timeout="auto" unmountOnExit>
                  {text?.Nexted &&
                    text?.Nexted.map((text2, index) => (
                      <List component="div" disablePadding>
                        <Link
                          to={text2.Link}
                          style={{
                            textDecoration: "none",
                            color: "#FFF",
                            width: "100%",
                          }}
                        >
                          <ListItemButton
                            selected={pathname === text2.Link}
                            sx={{ pl: 4 }}
                          >
                            <ListItemText
                              sx={{
                                "& .MuiTypography-root": { fontSize: "15px" },
                              }}
                              primary={text2.name}
                            />
                          </ListItemButton>
                        </Link>
                      </List>
                    ))}
                </Collapse>
                <Collapse in={openside2} timeout="auto" unmountOnExit>
                  {text?.Nexted2 &&
                    text?.Nexted2.map((text2, index) =>
                      text2.name === "Logout" ? (
                        <List component="div" disablePadding>
                          <ListItemButton
                            sx={{ pl: 4, color: "#FFF" }}
                            onClick={() => Logouthandler()}
                          >
                            <ListItemText
                              sx={{
                                "& .MuiTypography-root": { fontSize: "15px" },
                              }}
                              primary={text2.name}
                            />
                          </ListItemButton>
                        </List>
                      ) : (
                        <List component="div" disablePadding>
                          <Link
                            to={text2.Link}
                            style={{ textDecoration: "none", color: "#FFF" }}
                          >
                            <ListItemButton
                              selected={pathname === text2.Link}
                              sx={{ pl: 4 }}
                            >
                              <ListItemText
                                sx={{
                                  "& .MuiTypography-root": { fontSize: "15px" },
                                }}
                                primary={text2.name}
                              />
                            </ListItemButton>
                          </Link>
                        </List>
                      )
                    )}
                </Collapse>
                <Collapse in={openside4} timeout="auto" unmountOnExit>
                  {text?.Nexted4 &&
                    text?.Nexted4.map((text2, index) =>
                      text2.name === "Logout" ? (
                        <List component="div" disablePadding>
                          <ListItemButton
                            sx={{ pl: 4, color: "#FFF" }}
                            onClick={() => Logouthandler()}
                          >
                            <ListItemText
                              sx={{
                                "& .MuiTypography-root": { fontSize: "15px" },
                              }}
                              primary={text2.name}
                            />
                          </ListItemButton>
                        </List>
                      ) : (
                        <List component="div" disablePadding>
                          <Link
                            to={text2.Link}
                            style={{ textDecoration: "none", color: "#FFF" }}
                          >
                            <ListItemButton
                              selected={pathname === text2.Link}
                              sx={{ pl: 4 }}
                            >
                              <ListItemText
                                sx={{
                                  "& .MuiTypography-root": { fontSize: "15px" },
                                }}
                                primary={text2.name}
                              />
                            </ListItemButton>
                          </Link>
                        </List>
                      )
                    )}
                </Collapse>
              </>
            ))}
          </List>
          <Divider />
        </Drawer>
        <Main open={open} sx={{p:3}} >
          <DrawerHeader />
          <Outlet />
        </Main>
      </Box>
    </>
  );
}
