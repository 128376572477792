import React, { useEffect, useState } from "react";
// import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import svg from "../../../assets/svg";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useDispatch, useSelector } from "react-redux";
import Parser from "html-react-parser";
import ScrollAnimation from "react-animate-on-scroll";
// import Png from "../../../assets/png";
import { useNavigate } from "react-router-dom";
import AuthSagaActions from "../../../redux/authsaga/action";

const { getRoadmapsaga } = AuthSagaActions;

var mS = [
  "",
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
  "Q1",
  "Q2",
  "Q3",
  "Q4",
];

const Roadmap = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [monthyers, setmonthyers] = useState([]);
  const [monthyersfinal, setmonthyersfinal] = useState([]);

  const roadmapData = useSelector((state) => {
    return state.authSaga.Road;
  });

  useEffect(() => {
    let monthyersarray = [...monthyers];
    // eslint-disable-next-line no-unused-expressions
    roadmapData?.map((row) => {
      // check for empty array
      //The indexOf() method returns -1 if the value is not found

      if (monthyersarray.indexOf({ m: row.Month, y: row.Year }) === -1) {
        monthyersarray = [...monthyersarray, { m: row.Month, y: row.Year }]; // if Value is not found in Array then we add data into Array
        setmonthyers(monthyersarray);
      } else {
      }
      return;
    });
  }, [roadmapData]);

  useEffect(() => {
    const uniqueData = [
      ...new Map(
        monthyers.map((v) => [JSON.stringify([v.m, v.y]), v])
      ).values(),
    ];

    setmonthyersfinal(uniqueData);
  }, [monthyers]);

  useEffect(() => {
    dispatch(getRoadmapsaga(navigate));
  }, []);

  console.log("monthyersfinal", monthyersfinal);
  return (
    <>
      <Box className="es-banner-sec roadmap-banner-sec">
        <Box className="container">
          <Box className="banner-content">
            <ScrollAnimation animateIn="animate__bounce" delay="0.5">
              <Box className="es-banner-title">Roadmap</Box>
            </ScrollAnimation>
          </Box>
        </Box>
      </Box>
      <Box className="body-bg">
        <Box className="roadmap-sec">
          <Box className="container">
            <Box className="row">
              <Box className="roadmap-main-box">
                <List className="roadmap-ul">
                  {roadmapData.map((row, index) => {
                    return (
                      <>
                        <ListItem key={index} className="roadmap-li active">
                          <Box className="roadmap-left-main">
                            <ScrollAnimation
                              animateIn="animate__fadeInLeft"
                              delay="0.5"
                            >
                              <Box className="roadmap-left">
                                <Typography
                                  className="roadmap-date"
                                  component="h2"
                                  variant="h2"
                                >
                                {row.title}
                                </Typography>
                              </Box>
                            </ScrollAnimation>
                          </Box>

                          <Box className="roadmap-content-main">
                            <Box className="roadmap-right-main">
                              <Box className="roadmap-right">
                                <Box className="roadmap-right-data-box">
                                  <Box className="roadmap-primary-img-box">
                                    <img
                                      src={svg.primaryclick}
                                      alt="primaryclick"
                                      className="primaryclick-img"
                                    ></img>
                                  </Box>
                                  <Box className="roadmap-inner-data-box">
                                    <Typography
                                      className="roadmap-sub-title"
                                      component="h4"
                                      variant="h4"
                                    >
                                      {row?.title}
                                    </Typography>
                                    <Typography
                                      className="roadmap-para"
                                      component="p"
                                      variant="p"
                                    >
                                      {row?.description
                                        ? Parser(row?.description)
                                        : ""}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </ListItem>
                      </>
                    );
                  })}
                </List>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Roadmap;
