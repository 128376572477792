import './App.css';
import Routes from './routes/Routes'
import { Provider } from "react-redux";
import { store, persistor } from "./redux/Store";
import { PersistGate } from "redux-persist/integration/react";
import SiteSetting from './SiteSetting';

import '../src/assets/css/style.css';
import '../src/assets/css/responsive.css';
function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SiteSetting />
          <Routes />
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
