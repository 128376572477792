import { Box,Container, Button, Tab, Tabs, Modal, Grid, Typography, TextField, FormHelperText, Paper, styled, List, ListItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link, FormControl, InputLabel, TextareaAutosize, Checkbox, FormControlLabel, } from "@mui/material";
import Png from '../../assets/png';
import Svg from '../../assets/svg';
import Jpg from "../../assets/jpg";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ScrollAnimation from 'react-animate-on-scroll';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import ButtonGroup from '@mui/material/ButtonGroup'
import Parser from "html-react-parser";
import AuthSagaActions from "../../redux/authsaga/action";
import {Link as RoutLink, useNavigate,useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Countdown from "react-countdown";
import { ToastContainer } from "react-toastify";

import Header from "../../Component/user/defaultLayout/Header";
import Footer from "../../Component/user/defaultLayout/Footer";
import RoadMap from "../screens/common/RoadMap";
import IcoLabFeatures from "../screens/common/IcoLabFeatures";
import IcoCalender from "../screens/common/IcoCalender";
import OurTeam from "../screens/common/OurTeam";
import Testimonials from "../screens/common/Testimonials";
import Faq from "../screens/common/Faq";
import Subscribe from "../screens/common/Subscribe";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const index = {
  Button,
  Box,
  Tab,
  Tabs,
  Modal,
  Container,
  Grid,
  Typography,
  TextField,
  FormHelperText,
  Png,
  Svg,
  Jpg,
  Paper,
  Item,
  styled,
  List,
  ListItem,
  Table,
  InputLabel,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Link,
  FormControl,
  TextareaAutosize,
  Checkbox,
  FormControlLabel,
  MenuItem,
  useNavigate,
  useParams,
  RoutLink,
  ToastContainer,
  Select,
  ScrollAnimation,
  ListItemButton,
  ListItemText,
  Collapse,
  ExpandLess,
  ExpandMore,
  Visibility,
  VisibilityOff,
  InputAdornment,
  IconButton,
  OutlinedInput,
  ButtonGroup,
  ValidatorForm,
  TextValidator,
  Parser,
  AuthSagaActions,
  useSelector,
  useDispatch,
  Countdown,
  Header,
  Footer,
  RoadMap,
  IcoLabFeatures,
  IcoCalender,
  OurTeam,
  Testimonials,
  Faq,
  Subscribe,
}

export default index;
