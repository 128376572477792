import React, {useState} from 'react'
import Index from '../Index';
const { userForgotPasswordSaga } = Index.AuthSagaActions;

export default function Forgotpassword() {
    const dispatch = Index.useDispatch();
    const navigate = Index.useNavigate();

    const [email, setEmail] = useState("");
    const [emailAlertError,setEmailAlertError] = useState(false);
    const [emailError,setEmailError] = useState("");

    const validationFun=()=>{
        let regEmail = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(email==="" || !regEmail.test(email)){
            setEmailAlertError(true);
            setEmailError("Enter valid Email");
        } else{
            setEmailAlertError(false);
            setEmailError("");
            handleForgotPassword();
        }
    }
    
      const handleForgotPassword = async () => {
        const apidata = new URLSearchParams();
        apidata.append("email", email.trim());
        dispatch(userForgotPasswordSaga(apidata, navigate));
        setEmail("");
      };

    return (
        <div>
            <Index.Box className=' login-main-box reset-bg  '>
                <Index.Box className='login-box'>
                    <Index.Box className='login-container'>
                        <Index.Link className="link-style" href="/">
                            <Index.Box className='login-logo'>
                                <img src={Index.Png.logo} className="" alt=""></img>
                            </Index.Box>
                        </Index.Link>
                        <Index.Box className='login-inner-text'>
                            <Index.Typography variant='p' component="p" >Forgot Your Password ? </Index.Typography>
                        </Index.Box >
                        <Index.Box className="user-box email">
                            <Index.Box className='gap-cus forgot-pass'>
                                <Index.Box className='connect-title '>
                                    <Index.Typography className='user-box' varient='p'>
                                        Email Address *
                                    </Index.Typography>
                                </Index.Box>

                                <Index.Box className="form-group txtfeld-cus forgotpass-input validation">
                                    <Index.TextField
                                        fullWidth
                                        id="fullWidth"
                                        error={emailAlertError}
                                        className="form-control form-control-login form-forgot-pass"
                                        placeholder="Enter Your Email"
                                        autocomplete="off"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        helperText={emailError}
                                    />
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>

                        <Index.Box className='login-but-account'>
                            <Index.Button onClick={()=>validationFun()} variant="">Forgot Password</Index.Button>
                        </Index.Box>
                        <Index.Box>
                            <Index.Box className='signup-text backto-text-fotgot'>
                                <Index.Typography variant='p' component="p" className='icon-text'>
                                    <Index.Box className='backto-text'>
                                        <Index.RoutLink className="link-style link-style2" to="/login">Back to</Index.RoutLink>
                                        <Index.RoutLink className="link-style" to="/login"><span>Login</span> </Index.RoutLink>
                                    </Index.Box>
                                </Index.Typography>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
                <Index.ToastContainer/>
            </Index.Box >
        </div >
    )
}