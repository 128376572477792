import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { ToastContainer } from "react-toastify";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuthSagaActions from "../../../../redux/authsaga/action"
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const { addEditIdoWeb, getIdoWeb } = AuthSagaActions;
const IdoWebSolution = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const idoList = useSelector((state) => {
    //console.log('Bharat == ', state.authSaga);
    return state.authSaga.IdoWebSolutionList;
  });
  
  const [idoWebList, setIdoWeblist] = useState([]);
  const [Title, Settitle] = useState("");
  const [currentPrice, SetCurrentPrice] = useState("");
  const [Editid, Seteditid] = useState("");
  const [Description, Setdescription] = useState("");
  const [TitleError, Settitleerror] = useState("");
  const [DescriptionError, Setdescriptionerror] = useState("");
  const [startDate, SetStartDate] = useState(dayjs(new Date()));
//   const [StartDateError, SetStartDateError] = useState("");

  useEffect(() => {
    idoWebList &&
      idoWebList[0] &&
      idoWebList.map((element, index) => {
        if (element._id || element._id === Editid) {
          Settitle(element?.title);
          SetCurrentPrice(element?.currentPrice);
          Setdescription(element?.description);
          SetStartDate(element?.date);
        }
      });
  }, [idoWebList]);

  const handelSubmit = () => {
    if (Title === "") {
      Settitleerror("Please enter title");
    } else {
      Settitleerror("");
    }

    if (Description === "") {
      Setdescriptionerror("Please enter Description");
    } else {
      Setdescriptionerror("");
    }

    if (Title !== "" && Description !== "") {
      const apidata = new URLSearchParams();
      apidata.append("title", Title);
      apidata.append("currentPrice", currentPrice);
      apidata.append("date", startDate);
      apidata.append("description", Description);
      if (Editid !== "") {
        apidata.append("id", Editid);
      }
      dispatch(addEditIdoWeb(apidata, navigate));
    }
  };

  useEffect(() => {
    dispatch(getIdoWeb(navigate));
    setIdoWeblist(idoList);
  }, []);

  useEffect(() => {
    setIdoWeblist(idoList);
  }, [idoList]);

  useEffect(() => {
    idoWebList &&
      idoWebList[0] &&
      idoWebList.map((element, index) => {
        Seteditid(element._id);
      });
  }, [idoWebList]);

  return (
    <>
      <Box className="page-content-main">
        <ToastContainer />
        <Box sx={{ w: 100 }}>
          <Box className='main-title-box'>
            <Typography className='main-title' variant="h4">IDO Website Solution</Typography>
          </Box>
          <Box>
            <Box sx={{ mt: 3 }}>
              <Grid container>
                <Grid item xs={12} sm={12} md={3} lg={7}>
                  <Box className="ckeditor-main">
                    <TextField
                      sx={{ marginTop: 2 }}
                      required
                      fullWidth
                      label="Title"
                      id="Title"
                      value={Title}
                      onChange={(e) => {
                        Settitle(e.target.value);
                      }}
                    />
                    <FormHelperText sx={{ color: "red", ml: 1, mb: 1 }}>
                      {TitleError}
                    </FormHelperText>

                    <TextField
                      sx={{ marginTop: 2 }}
                      required
                      fullWidth
                      label="Current Price"
                      id="currentPrice"
                      value={currentPrice}
                      onChange={(e) => {
                        SetCurrentPrice(e.target.value);
                      }}
                    />
                    <br /><br /> 

                    <Box className="ckeditor-main">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            renderInput={(props) => <TextField {...props} />}
                            label="Start Date"
                            value={startDate}
                            onChange={(newValue) => {
                                SetStartDate(newValue);
                            }}
                        />
                    </LocalizationProvider>
                    </Box>
                    <br /><br /> 
                    
                    <Box className="ckeditor-main">
                      <CKEditor
                        editor={ClassicEditor}
                        data={Description}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          //console.log(data);
                          Setdescription(data);
                        }}
                        className="ckeditor-tag"
                      />
                    </Box>
                    <FormHelperText sx={{ color: "red" }}>
                      {DescriptionError}
                    </FormHelperText>

                    <Box className="save-btn-box">
                      <Button className="save-btn"
                        variant="contained"
                        onClick={() => {
                          handelSubmit();
                        }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default IdoWebSolution;

