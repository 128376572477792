import React, { useState, useEffect } from "react";
import Index from "../Index";
const { getaboutdetailsaga } = Index.AuthSagaActions;

export default function About() {
  const dispatch = Index.useDispatch();
  const [aboutDetails, setAboutDetails] = useState("");
  const AboutDetail = Index.useSelector((state) => {
    return state.authSaga.aboutDetails;
  });

  useEffect(() => {
    dispatch(getaboutdetailsaga());
    setAboutDetails(AboutDetail);
  }, []);

  useEffect(() => {
    setAboutDetails(AboutDetail);
  }, [AboutDetail]);

  return (
    <div>
      <Index.Header />

      {/* Up Coming Third Phase*/}
      <Index.Box className="third-phase-section about-third-phase-section about-text-main">
        <Index.Box className="container">
          <Index.Box className="">
            <Index.Box className="about-text">
              <Index.Typography variant="p" component="p">
                About{" "}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="about-text-inner">
              <Index.Typography variant="p" component="p">
                {aboutDetails?.sortDescription
                  ? Index.Parser(aboutDetails?.sortDescription)
                  : ""}
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      {/* What is ICO solution */}
      <Index.Box className="ico-section blockchain-section">
        <Index.Box className="container">
          <Index.Box className="blockchain-sec">
            <Index.Box className="">
              <Index.Box className="row">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 6",
                      }}
                      className="grid-column "
                    >
                      <Index.Item className="grid-item">
                        <Index.Box className="blockchain-main-img-box">
                          {
                            aboutDetails?.images
                            ?  (
                            <img
                              src={aboutDetails?.images}
                              className=""
                              alt=""> 
                            </img>
                            )
                            : <img src={Index.Png.aboutimg} className="" alt=""></img>
                          }
                          
                        </Index.Box>
                      </Index.Item>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 8",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Item className="grid-item">
                        <Index.Box className="blockchain-solution-box ">
                          <Index.Box className="ico-solution blockchain-text">
                            <Index.Typography variant="p" component="p">
                              {" "}
                              {aboutDetails?.sortTitle
                                ? aboutDetails?.sortTitle
                                : ""}
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="ico-solution-inner blockchain-text-inner p">
                            <Index.Typography variant="p" component="p">
                              {aboutDetails?.description
                                ? Index.Parser(aboutDetails?.description)
                                : ""}
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="learn-now-btn">
                            <Index.Button variant="">Learn Now</Index.Button>
                          </Index.Box>
                        </Index.Box>
                      </Index.Item>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>


      {/* ICOLab Roadmap */}
        <Index.RoadMap />
      {/* End Roadmap Section */}


      {/* ICOLab Features */}
        <Index.IcoLabFeatures />
      {/* End ICOLab Features Section */}


      {/* ICO Calender */}
        <Index.IcoCalender />
      {/* End ICO Calender Section */}


      {/* Meet Our Team */}
        <Index.OurTeam />
      {/* End Our Team Section */}


      {/* Our client say about us */}
        <Index.Testimonials />
      {/* End Our client Section */}


      {/* FAQ Section */}
        <Index.Faq />
      {/* End FAQ Section */}


      {/* Subscribe to Our Newsletter */}
        <Index.Subscribe />
      {/* End Subscribe Section */}

      <Index.Footer />
    </div>
  );
}
