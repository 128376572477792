import React, { useEffect, useState } from "react";
import Index from "../Index";
import { ethers } from "ethers";
import { getAddress } from "ethers/lib/utils";

const { createTransaction, getTranscation,getPrice } = Index.AuthSagaActions;
const checkDateRange = (From, To) => {
  var dateFrom = new Date(From);
  var dateTo = new Date(To);
  var dateCheck = new Date();
  return dateCheck.getTime() > dateFrom.getTime() && dateCheck.getTime() < dateTo.getTime()
}

export const CoinLogs = () => {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();

  const token = Index.useSelector((state) => {
    return state.authSaga.idTokenUser;
  });

  const transcationData = Index.useSelector((state) => {
    return state.authSaga.transcation;
  });

  const priceData = Index.useSelector((state) => {
    return state.authSaga.price;
  });

  const calenderData = Index.useSelector((state) => {
    return state.authSaga.IcoCalenderList;
  });

  // console.log(token , "token")
  const [age, setAge] = useState("");

  const [error, setError] = useState();
  const [txs, setTxs] = useState([]);
  const [calenderList, SetCalenderList] = useState([]);
  const [Amount, setAmount] = useState("");
  const [Token, setToken] = useState("");
  const [price,setPrice]=useState(0);
  console.log(Number(calenderList[0]?.price?.split(" ")[0]),price,"price118");

  const [TranscationDetails, setTranscationDetails] = useState([]);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [data, setdata] = useState({
    address: "",
    Balance: null,
  });

  // Payment Start
  useEffect(() => {
    setTranscationDetails(transcationData);
    dispatch(getTranscation(navigate, token));
    dispatch(getPrice(navigate, token));
  }, []);

  useEffect(() => {
    setTranscationDetails(transcationData);
  }, [transcationData]);

  useEffect(() => {
    calenderData?.map((data,i,arr) => {
     
     if(checkDateRange(data.startDate, data.endDate) === true){
      SetCalenderList([arr[i]]);
     }
    })
  }, [calenderData]);

  useEffect(()=>{
    setPrice(Number(calenderList[0]?.price?.split(" ")[0]))
  },[calenderList])


  const handleSubmit = async (e) => {
    console.log(data, "data1");
    const curday = new Date()
    const formdata = new URLSearchParams();
    formdata.append("date", curday);
    formdata.append("coin", Token);
    formdata.append("rate", price);
    formdata.append("amount", Amount);
    formdata.append("balance", data.Balance);
    formdata.append("address", data.address);
    formdata.append("status", "1");
    formdata.append("details", "");

    dispatch(createTransaction(formdata, navigate, token));
  };

  // Payment End

  // Button handler button for handling a
  // request event for metamask
  const btnhandler = () => {
    // Asking if metamask is already present or not
    if (window.ethereum) {
      // res[0] for fetching a first wallet
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((res) => accountChangeHandler(res[0]));
    } else {
      alert("install metamask extension!!");
    }
  };

  // getbalance function for getting a balance in
  // a right format with help of ethers
  const getbalance = (address) => {
    // Requesting balance method
    window.ethereum
      .request({
        method: "eth_getBalance",
        params: [address, "latest"],
      })
      .then((balance) => {
        // Setting balance
        setdata({
          address: address,
          Balance: ethers.utils.formatEther(balance),
        });
      });
  };

  // Function for getting handling all events
  const accountChangeHandler = (account) => {
    // Setting an address data
    setdata({
      address: account,
    });

    // Setting a balance
    getbalance(account);
    getAddress(account);
  };
  return (
    <div>
      <Index.Header />

      {/* Up Coming Third Phase*/}
      <Index.Box className="third-phase-section about-third-phase-section about-text-main">
        <Index.Box className="container">
          <Index.Box className="">
            <Index.Box className="about-text">
              <Index.Typography variant="p" component="p">
                Coin Logs{" "}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="about-text-inner">
              <Index.Typography variant="p" component="p">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et{" "}
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      {/* ICO Calender */}

      <Index.Box className="coinlogs-section">
        <Index.Box className="container">
          <Index.Box
            className="header-btn-login2"
            sx={{ py: 2 }}
            onClick={btnhandler}
          >
            <Index.Link className="link-style">
              <Index.Button variant="contained">Connect</Index.Button>
            </Index.Link>

            {data ? (
              <>
                <Index.Paper>{data.Balance}</Index.Paper>
                <Index.Paper>{data.address}</Index.Paper>
              </>
            ) : (
              ""
            )}
          </Index.Box>
          <Index.Box className="coinlogs-btn">
            <Index.Box className="">
              <Index.Box className="add-copy-link-main2">
                <Index.Box className=" email-box3 email-btn-set position-relative email-box">
                  <Index.TextField
                    id="fullWidth"
                    type="number"
                    className="form-control  textfield-set  textfield-set2"
                    value={Amount}
                    onChange={(e) =>{
                      setToken(price * e.target.value)
                      setAmount(e.target.value)
                    } }
                    placeholder="Amount"
                  />
                  <Index.Box className="subscribe-btn subscribe-btn3  email-small-btn  email-small-btn3 ">
                    <Index.FormControl
                      sx={{ m: 1, minWidth: 118 }}
                      className="drop-btn-bnb "
                    >
                      <Index.Select
                        className="drop-btn-bnb2"
                        value={age}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <Index.MenuItem value="">
                          <em className="inpot-box-bnb">BNB</em>
                        </Index.MenuItem>
                        <Index.MenuItem value={10}>Ten</Index.MenuItem>
                        <Index.MenuItem value={20}>Twenty</Index.MenuItem>
                        <Index.MenuItem value={30}>Thirty</Index.MenuItem>
                      </Index.Select>
                      {/* <FormHelperText>Without label</FormHelperText> */}
                    </Index.FormControl>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="email-box email-box4 email-btn-set position-relative">
                  <Index.TextField
                    id="fullWidth"
                    type="text"
                    className="form-control  textfield-set  textfield-set2"
                    value={Token}
                    onChange={(e) =>{
                      setAmount(price / e.target.value)
                      setToken(e.target.value)
                    } }
                    placeholder="Token"
                  />
                </Index.Box>

                <Index.Box className="header-btn-login2">
                  {/* <Index.Link className="link-style" href=""> */}
                    <Index.Button variant="contained" onClick={handleSubmit}>
                      Buy Now
                    </Index.Button>
                  {/* </Index.Link> */}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box sx={{pb:2}}>
            <Index.TableContainer component={Index.Paper} className="tabel-box">
              <Index.Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
                className="icolab-tabel coinlogs-tabel"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell>Date</Index.TableCell>
                    <Index.TableCell align="right">Coin</Index.TableCell>
                    <Index.TableCell align="right">Rate</Index.TableCell>
                    <Index.TableCell align="right">Amount</Index.TableCell>
                    <Index.TableCell align="right">Balance </Index.TableCell>
                    <Index.TableCell align="right">Status</Index.TableCell>
                    <Index.TableCell align="right">Details</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody >
                  {TranscationDetails.length?TranscationDetails?.map((row, index) => {

                    console.log(row , "row" , index)
                    return (
                      <>
                        <Index.TableRow
                        key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <Index.TableCell>
                            {row?.date ? new Date(row?.date).toLocaleString() : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="right">
                            {row?.coin ? row?.coin : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="right">
                            {row?.rate ? row?.rate : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="right">
                            {row?.amount ? row?.amount : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="right">
                            {row?.balance ? row?.balance : "-"}{" "}
                          </Index.TableCell>
                          <Index.TableCell align="right">
                            {row?.status ? row?.status : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="right">
                            {row?.details ? row?.details : "-"}
                          </Index.TableCell>
                        </Index.TableRow>
                      </>
                    );
                  }):
                  <Index.TableRow
              
                  
                  >
                    <Index.TableCell colSpan={7} align="center">
                    No recent transactions
                    </Index.TableCell>
                    
                  </Index.TableRow>
                  }
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      {/* Subscribe to Our Newsletter */}
       <Index.Subscribe />
      {/* End Subscribe Section */}

      <Index.Footer />
      <Index.ToastContainer/>
    </div>
  );
};
