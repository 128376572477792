import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import AuthSagaActions from "../../../redux/authsaga/action";
import Parser from "html-react-parser";
import { useNavigate } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
// import { List, ListItem, Link } from "@mui/material";
// import Png from "../../../assets/png";
// import Svg from "../../../assets/svg";

const { getTeamMembersaga } = AuthSagaActions;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Team = ({ page }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [memberList, Setmemberlist] = useState([]);
  const TeamMemberList = useSelector((state) => {
    return state.authSaga.TeamMemberList;
  });

  useEffect(() => {
    Setmemberlist(TeamMemberList);
  }, [TeamMemberList]);

  useEffect(() => {
    dispatch(getTeamMembersaga(navigate));
  }, []);

  return (
    <div>
      <Box className="team-sec">
        <Box className="container">
          <Box className="team-row">
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={{ xl: 0, lg: 0, md: 0, sm: 0 }}
                className="team-container"
              >
                {memberList?.map((element, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={page === "white-paper" ? 4 : 3}
                    className="team-grid"
                  >
                    <Item className="grid-item">
                      <Box className="team-card">
                        <Box className="team-img-box">
                          <img
                            src={element.images}
                            className="team-img"
                            alt=""
                          ></img>
                        </Box>
                        <Box className="team-content-box">
                          <ScrollAnimation
                            animateIn="animate__fadeInUp"
                            delay="0.5">
                            <Typography
                              component="h6"
                              variant="h6"
                              className="team-main-text"
                            >
                              {element.name}
                            </Typography>

                            <Typography
                              component="h5"
                              variant="h5"
                              className="team-inner-position"
                            >
                              {element.designation}
                            </Typography>
                          </ScrollAnimation>
                        </Box>
                      </Box>
                    </Item>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Team;
