import React, {useState} from "react";
import Index from "../Index";
import Introduction from "../../../Component/user/whitePaper/Introduction";
import TechnicalOverview from "../../../Component/user/whitePaper/TechnicalOverview";
import WebCreatorEconomy from "../../../Component/user/whitePaper/WebCreatorEconomy";
import Roadmap from "../roadmapWhitePaper/Roadmap";
import Teams from "../../../Component/user/whitePaper/Team";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

function TabPanelList(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const WhitePaper = () => {
  const [value, setValue] = useState(0);
  const [valueList, setValueList] = useState(25);
  const [openList, setOpenList] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setValueList(-1);
  };
  const handleChangeList = (event, newValue) => {
    setValueList(newValue + 10);
    setValue(-1);
  };
  const handleClick = () => {
    setOpenList(openList === false ? true : false);
  };

  return (
    <div>
      <Index.Header />

      <Index.Box className="es-banner-sec w2-banner-sec">
        <Index.Box className="container">
          <Index.Box className="banner-content">
            <Index.ScrollAnimation animateIn="animate__bounce" delay="0.5">
              <Index.Box className="es-banner-title">Whitepaper</Index.Box>
            </Index.ScrollAnimation>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="w2-sec">
        <Index.Box className="container">
          <Index.Box className="row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="grid-container-user"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 4",
                    lg: "span 3",
                  }}
                  className="grid"
                >
                  <Index.Item className="grid-item">
                    <Index.Box className="w2-left-main-box">
                      <Index.Box className="w2-overview-box">
                        <Index.Box className="wp-tabs-main">
                          <Index.Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            className="wp-tabs"
                          >
                            <Index.Tab
                              label="Introduction"
                              {...a11yProps(0)}
                              className="wp-tabs"
                            />
                            <Index.Tab
                              label="Technical Overview"
                              {...a11yProps(1)}
                              className="wp-tabs wp-tabs2"
                            />
                            <Index.List
                              className=" wp-tabs2"
                              sx={{
                                width: "100%",
                                maxWidth: 360,
                                bgcolor: "background.paper",
                              }}
                              component="nav"
                              variant="scrollable"
                              aria-labelledby="nested-list-subheader"
                            >
                              <Index.ListItemButton onClick={handleClick}>
                                <Index.ListItemText primary="Apnacast" />
                                {openList ? (
                                  <Index.ExpandLess />
                                ) : (
                                  <Index.ExpandMore />
                                )}
                              </Index.ListItemButton>
                              <Index.Collapse
                                in={openList}
                                timeout="auto"
                                unmountOnExit
                              >
                                <Index.Box>
                                  <Index.Tabs
                                    orientation="vertical"
                                    variant="scrollable"
                                    value={valueList}
                                    onChange={handleChangeList}
                                    aria-label="Vertical tabs example"
                                    className="ovrview-custm wp-tabs "
                                  >
                                    <Index.Tab
                                      label="Overview"
                                      {...a11yProps(10)}
                                      className={
                                        valueList === 10
                                          ? ""
                                          : "wp-tabs tab-inner-web "
                                      }
                                    />
                                    <Index.Tab
                                      label="Vision Statement"
                                      {...a11yProps(11)}
                                      className={
                                        valueList === 11
                                          ? ""
                                          : "wp-tabs tab-inner-web"
                                      }
                                    />
                                    <Index.Tab
                                      label="Mission Statement"
                                      {...a11yProps(12)}
                                      className={
                                        valueList === 12
                                          ? ""
                                          : "wp-tabs tab-inner-web"
                                      }
                                    />
                                    <Index.Tab
                                      label="Problem & Solution Statement"
                                      {...a11yProps(13)}
                                      className={
                                        valueList === 13
                                          ? ""
                                          : "wp-tabs tab-inner-web"
                                      }
                                    />
                                  </Index.Tabs>
                                </Index.Box>
                              </Index.Collapse>
                            </Index.List>

                            <Index.Tab
                              label="Demo Model"
                              {...a11yProps(3)}
                              className="wp-tabs"
                            />
                            <Index.Tab
                              label="Technical Flow"
                              {...a11yProps(4)}
                              className="wp-tabs"
                            />
                            <Index.Tab
                              label="Tokenomics"
                              {...a11yProps(5)}
                              className="wp-tabs"
                            />
                            <Index.Tab
                              label="Roadmap"
                              {...a11yProps(6)}
                              className="wp-tabs"
                            />
                            <Index.Tab
                              label="Our Team"
                              {...a11yProps(7)}
                              className="wp-tabs"
                            />
                          </Index.Tabs>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Item>
                </Index.Box>

                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 8",
                    lg: "span 9",
                  }}
                  className="grid"
                >
                  <Index.Item className="grid-item">
                    <Index.Box className="w2-right-main-box">
                      <Index.Box className="wp-tabs-panel-main">
                        <TabPanel
                          value={value}
                          name="business"
                          index={0}
                          className="wp-tabs-panel"
                        >
                          <Introduction />
                        </TabPanel>
                        <TabPanel
                          value={value}
                          name="business"
                          index={1}
                          className="wp-tabs-panel"
                        >
                          <WebCreatorEconomy />
                        </TabPanel>
                        <TabPanel
                          value={value}
                          name="business"
                          index={2}
                          className="wp-tabs-panel"
                        >
                          Future of Creators Platform
                        </TabPanel>
                        <TabPanel
                          value={value}
                          name="business"
                          index={3}
                          className="wp-tabs-panel w2-technical-content-box"
                        >
                          <Index.Box className="w2-technical-text">
                            Ecosystem
                          </Index.Box>
                          <br />
                          <Index.Box className="tab-inner">
                            <Index.Container>
                              Demo was created with the intention of
                              bringing together traditional and digital assets
                              in the social media industry. We are of the
                              opinion that users and influencers will both play
                              important roles in the future of social media. In
                              addition, our system is built from the ground up
                              with DeFi consumers in mind. It shows that our
                              community and supporters actively collaborate with
                              us to build our platform, participate in its
                              management, and benefit from its operations. We
                              believe that this presents a once-in-a-lifetime
                              chance for the social media industry and that it
                              must be pursued enthusiastically.
                              <br />
                              <Index.Box
                                component="img"
                                sx={{
                                  height: "355px",
                                  width: "100%",
                                  objectFit: "contain",
                                }}
                                src={Index.Jpg.Ecosyatem}
                                className="intro-banner-img-grid"
                              />
                              <br />
                              <Index.Box className="w2-technical-text">
                                Demo Platform
                              </Index.Box>
                              <br />
                              <Index.Container>
                                The Demo platform is used for social media
                                purposes. Users of DEMOcan access the
                                platform's information and services through the
                                platform. The DEMOcurrency will be used by
                                users of social networking sites to pay for
                                content and by influencers to pay for the
                                service itself. The DEMOCurrency is allowed to
                                use the material in whatever way it deems
                                appropriate; there are no limits to what it may
                                do. In this part of the whitepaper, we are going
                                to discuss the functions of the platform, which
                                include the following things:
                                <br />
                                <br />
                                • Access the content
                                <br />
                                • Explore the NFT marketplace
                                <br />
                                • Direct messaging with content creators and
                                other users
                                <br />
                                • Tipping content creator with DEMOtoken
                                <br />
                                • Influencer marketplace
                                <br />• Secure payment
                              </Index.Container>
                              <br />
                              <Index.Box className="w2-technical-text">
                                Demo Wallet
                              </Index.Box>
                              <br />
                              <Index.Container>
                                Using a private key-protected wallet, we ensured
                                the protection of both the currency and the
                                information. The DEMOtoken will be the new,
                                fully independent standard for cryptocurrency
                                payments for users and content creators
                                worldwide. This section consists of the
                                following characteristics of the wallet's
                                functionality:
                                <br />
                                <br />
                                • Safe and secure transaction
                                <br />
                                • No chargeback option
                                <br />
                                • Low transaction fees
                                <br />
                                • Easy for users to transact
                                <br />
                                • Allow international transaction
                                <br />
                              </Index.Container>
                              <br />
                              <Index.Box className="w2-technical-text">
                                DEMOin Metaverse
                              </Index.Box>
                              <br />
                              <Index.Container>
                                The DEMOmetaverse is a decentralized
                                environment that allows users to explore the
                                digital environment through the use of virtual
                                reality (VR). The metaverse makes it possible to
                                buy and sell any element of the virtual
                                environment, including virtual real estate,
                                avatars, and digital products. The users of the
                                Demo platform are the ones who own and have
                                power over the DEMOmetaverse. Through the apna
                                metaverse marketplace, users will have the
                                ability to communicate with the content-created
                                content or the influencer. The following
                                features distinguish the DEMOmetaverse from
                                others.
                                <br />
                                <br />
                                • Additional income source for content creator
                                <br />
                                • Explore virtual environment
                                <br />
                                • Influencer event
                                <br />• Secure payment
                              </Index.Container>
                              <br />
                              <Index.Box className="w2-technical-text">
                                DEMOEvent
                              </Index.Box>
                              <br />
                              <Index.Container>
                                The Demo platform will provide access to
                                project events. However, our exclusive event
                                will set us apart from the competition. Events
                                are held several times a year in different
                                locations around the world, and only APNA
                                stakeholders are allowed to attend and
                                participate. The following advantages are
                                available to users: <br />
                                <br />
                                • High-level networking
                                <br />
                                • Meet content creator
                                <br />
                                • Attend exclusive events or parties
                                <br />• Events will attend by celebrities
                              </Index.Container>
                            </Index.Container>
                          </Index.Box>
                        </TabPanel>

                        <TabPanel
                          value={value}
                          name="business"
                          index={4}
                          className="wp-tabs-panel"
                        >
                          <Index.Box className="tab-inner">
                            Following an introduction to Demo, this part
                            will dive into the platform's technical
                            presentation, token ICO, and IDO. The Binance smart
                            blockchain, which hosts the unified platform's
                            tokens and smart contracts, is connected to the
                            Binance smart blockchain via the unified platform's
                            connectivity to the blockchain. Connecting the
                            blockchain's components to the Demo platform
                            requires no custodial involvement.
                          </Index.Box>
                          <br />
                          <TechnicalOverview />
                        </TabPanel>

                        <TabPanel
                          value={value}
                          name="demo"
                          index={5}
                          className="wp-tabs-panel"
                        >
                          <Index.Box className="w2-technical-text2">
                            Tokenomics
                          </Index.Box>
                          <Index.Box className="tab-inner">
                            • Token Name: DEMOToken
                            <br />
                            • Initial Token Type: BEP-20 Blockchain
                            <br />• Total Supply: 10 Billion Token
                          </Index.Box>
                          <Index.Box className="tab-inner">
                            ▪ The DEMOcoin is divided into the above portions.
                            The token’s purpose is specifically to facilitate
                            the Demo platform users and content creators to
                            utilise the ecosystem. It's also important to note
                            that, even though this section talks about our
                            plans, the specific mechanisms may need to change as
                            things change.
                          </Index.Box>
                          <Index.Box className="tab-inner">
                            ▪ Seed sales will account for 10% of the total
                            amount of DEMOtokens that will be distributed at
                            launch. The fact that it was designed to function in
                            this way makes this a crucial part of the
                            distribution. As a result, the number of potential
                            investors can expand and more people will be able to
                            get their hands on tokens. The combined contribution
                            of consumers in the general public and those in the
                            private sector brings our overall sales volume to
                            within 20 percentage points of its maximum
                          </Index.Box>
                          <Index.Box className="tab-inner">
                            ▪ In addition, given the importance of this aspect,
                            we will devote 10% of our total liquidity to the
                            acquisition of management in order to address any
                            concerns regarding liquidity.
                          </Index.Box>
                          <Index.Box className="tab-inner">
                            ▪ The budget for the partnership is 7 percent, and
                            the budget for the team is 16 percent. This will
                            assist in the burning of tokens, and if there is any
                            risk in the future, not only the stakeholders but
                            also the members of the team will have a portion of
                            it.
                          </Index.Box>

                          <Index.Box className="tab-inner">
                            ▪ The allocation of funds for marketing will bring
                            in new clients and investor
                          </Index.Box>

                          <br />
                          {/* <img src={Jpg.tokenomicsWhitePaper} className="intro-banner-img-grid" alt=""></img> */}
                        </TabPanel>
                        <TabPanel
                          value={value}
                          name="demo"
                          index={6}
                          className="wp-tabs-panel"
                        >
                          <Roadmap />
                        </TabPanel>
                        
                        <TabPanel
                          value={value}
                          name="Demo"
                          index={7}
                          className="wp-tabs-panel"
                        >
                          <Teams page={"white-paper"} />
                        </TabPanel>

                        <TabPanelList
                          value={valueList}
                          name="Demo"
                          index={10}
                          className="wp-tabs-panel "
                        >
                          <Index.Box className="w2-technical-text2">
                            overview
                          </Index.Box>
                          <Index.Box className="tab-inner">
                            We are responsible for defining the mission of
                            Demo token as well as finding a solution to the
                            problem. As part of the development of the token, we
                            aim to provide the ability for community members to
                            cast votes, send cryptocurrency, and receive
                            assistance. Influencers and content creators will be
                            benefited from this social platform. They can earn
                            an indefinite amount of profits by exchanging crypto
                            tokens with the users.
                          </Index.Box>
                        </TabPanelList>
                        <TabPanelList
                          value={valueList}
                          name="demo"
                          index={11}
                          className="wp-tabs-panel"
                        >
                          <Index.Box className="w2-technical-text2">
                            Vision Statement
                          </Index.Box>
                          <Index.Box className="tab-inner">
                            • Our vision statement for DEMOcurrency includes
                            the development of a fully encrypted community
                            platform. Essentially, our platform enables users
                            and influencers to earn money by displaying their
                            skills and communicating with one another through
                            broadcasting and chatting communities. Through the
                            use of this platform, people become more aware of
                            the applications of crypto technology in the real
                            world.
                          </Index.Box>

                          <br />
                          <Index.Box className="tab-inner">
                            • We rely on decentralized networks. Demo will
                            make progress in the vision of a decentralized
                            future by completing three chores:
                          </Index.Box>
                          <br />
                          <Index.Box className="tab-inner">
                            <Index.Container>
                              ◦ Social media users regaining ownership of their
                              content
                            </Index.Container>
                          </Index.Box>
                          <br />
                          <Index.Box className="tab-inner">
                            <Index.Container>
                              ◦ Our platform integrates the freedom of speech of
                              the users.
                            </Index.Container>
                          </Index.Box>
                          <br />
                          <Index.Box className="tab-inner">
                            <Index.Container>
                              ◦ Developing a unique rewarding system where the
                              influencers are compensated for making content. In
                              the end, we aim to open a new chapter in human
                              interaction and sharing of snapshots of life.
                            </Index.Container>
                          </Index.Box>
                        </TabPanelList>
                        <TabPanelList
                          value={valueList}
                          name="Demo"
                          index={12}
                          className="wp-tabs-panel"
                        >
                          <Index.Box className="w2-technical-text2">
                            Mission Statement
                          </Index.Box>
                          <Index.Box className="tab-inner">
                            As stated in our mission statement, DEMOtoken's
                            mission is to facilitate participation in future
                            significant events, and token holders will be able
                            to vote. In order to continue to meet future
                            development requirements, the financial health of
                            the platform must be maintained. The future scope of
                            this social community platform will comprise
                            exchanging NFTs and other crypto-based wallets.
                          </Index.Box>
                          <br />
                          <Index.Box className="tab-inner">
                            In further developments, we also aim to provide our
                            clients with the most suitable and convenient
                            experience till now.
                          </Index.Box>
                          <br />
                          <Index.Box className="tab-inner">
                            Our main purpose with this decentralized social
                            platform - Demo is to help users and influencers
                            connect with each other and initiate business as
                            well.
                          </Index.Box>
                        </TabPanelList>
                        <TabPanelList
                          value={valueList}
                          name="Demo"
                          index={13}
                          className="wp-tabs-panel"
                        >
                          <Index.Box className="w2-technical-text2">
                            Problems & Solution Statement
                          </Index.Box>
                          <Index.Box className="tab-inner">
                            There are a wide variety of platforms available for
                            use in today’s world. Content creators and
                            influencers are supported by only a few of them. We
                            offer our content creators broadcasting and chatting
                            platforms in order to address this problem. With our
                            application, creators can offer their skill-based
                            services as paid channels or groups to earn money
                            from their users. Additionally, creators can use our
                            application to raise profit from paid channels or
                            groups on Telegram or WhatsApp if they wish to offer
                            a paid service. Messages can be personalized by
                            users, producers, and influencers. Users and
                            creators will have more options in the future in
                            terms of producing content.
                          </Index.Box>
                        </TabPanelList>
                      </Index.Box>
                    </Index.Box>
                  </Index.Item>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Footer />
    </div>
  );
};

export default WhitePaper;
