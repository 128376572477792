import React, {useState} from 'react'
import Index from '../Index';
const { registerUserSaga } = Index.AuthSagaActions;

export default function Register() {
    const dispatch = Index.useDispatch();
    const navigate = Index.useNavigate();
    const params = Index.useParams();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [userName, setUserName] = useState("");
    const [mobile, setMobile] = useState("");
    const [country, setCountry] = useState("");
    const [email, setEmail] = useState("");
    const [code, setCode] = useState(params.referer);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [terms, setTerms] = useState(false);
    const [errorObject,setErrorObject] = useState({});

    const validationFun=(firstName,lastName,userName,mobile,country,email,password,confirmPassword,terms)=>{
        let errors = {};
        let regEmail =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(firstName == "" || firstName.replace( /\s\s+/g, ' ')?.length<3){
            errors.firstName = "Enter at least 3 characters in First Name";
        } else if(firstName.replace( /\s\s+/g, ' ') == "" || firstName.replace( /\s\s+/g, ' ')?.length>25){
            errors.firstName = "Enter not more then 25 characters in First Name";
        }

        if(lastName == "" || lastName.replace( /\s\s+/g, ' ')?.length<3){
            errors.lastName = "Enter at least 3 characters in Last Name";
        } else if(lastName.replace( /\s\s+/g, ' ') == "" || lastName.replace( /\s\s+/g, ' ')?.length>25){
            errors.lastName = "Enter not more then 25 characters in Last Name";
        } 

        if(userName == "" || userName.replace( /\s\s+/g, ' ')?.length<3){
            errors.userName = "Enter at least 3 characters in User Name";
        } else if(userName.replace( /\s\s+/g, ' ') == "" || userName.replace( /\s\s+/g, ' ')?.length>25){
            errors.userName = "Enter not more then 25 characters in User Name";
        } 
    
        if(mobile == "" || mobile.length!==10){
            errors.mobile = "Enter at least 10 numbers in Mobile Number";
        }

        if(country == "" || country.replace( /\s\s+/g, ' ')?.length<3){
            errors.country = "Enter at least 3 characters in Country";
        } else if(country.replace( /\s\s+/g, ' ') == "" || country.replace( /\s\s+/g, ' ')?.length>25){
            errors.country = "Enter not more then 25 characters in Country";
        } 

        if(email.trim()==="" || !regEmail.test(email)){
            errors.email = "Enter valid Email";
        }

        if(password==="" || password.length<8){
            errors.password = "Enter at least 8 characters in Password";
        } else if(password==="" || password.length>20){
            errors.password = "Enter not more then 20 characters in Password";
        }

        if(confirmPassword !== password){
            errors.confirmPassword = "Password and Confirm Password are not matched";
        } 

        if(terms === false){
            errors.terms = "Please check policy, terms & conditions";
        }
        return errors;
    }

    const handleSubmit = (e) => {
        if (Object.keys(validationFun(firstName,lastName,userName,mobile,country,email,password,confirmPassword,terms)).length === 0) {
            handleRegister();
            setErrorObject({});
        } else {
            setErrorObject(validationFun(firstName,lastName,userName,mobile,country,email,password,confirmPassword,terms))
        }
    };

    const handleRegister=async()=>{
        const apidata = new URLSearchParams();
        apidata.append("email", email);
        apidata.append("firstName", firstName);
        apidata.append("lastName", lastName);
        apidata.append("userName", userName);
        apidata.append("mobileNo", mobile);
        apidata.append("country", country);
        apidata.append("password", password);
        apidata.append("referrer", code);
        dispatch(registerUserSaga(apidata, navigate));
        setFirstName('');
        setLastName('');
        setUserName('');
        setMobile('');
        setCountry('');
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        setTerms(false);
    }

    return (
        <div>
            <Index.Box className='login-main-box'>

                <Index.Box className='login-box'>
                    <Index.Box className='login-container'>
                    <Index.Link className="link-style" href="/">
                        <Index.Box className='login-logo'>
                            <img src={Index.Png.logo} className="" alt=""></img>
                        </Index.Box>
                        </Index.Link>
                        <Index.Box className='main-login'>
                            <Index.Box className='login-inner-text'>
                                <Index.Typography variant='p' component="p">Create an account. </Index.Typography>
                            </Index.Box >

                            <Index.Box className="first-last">
                                <Index.Box className='gap-cus gap-cus-2'>
                                    <Index.Box className='connect-title '>
                                        <Index.Typography className='user-box' varient='p'>
                                            First Name *
                                        </Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="form-group txtfeld-cus register-input validation">
                                        <Index.TextField
                                            fullWidth
                                            error={errorObject['firstName'] !== undefined}
                                            id="fullWidth"
                                            className="form-control input-register"
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            helperText={errorObject?.firstName}
                                        />
                                    </Index.Box>
                                </Index.Box>
                                <Index.Box className='gap-cus gap-cus-2 sub-right-box'>
                                    <Index.Box className='connect-title '>
                                        <Index.Typography className='user-box' varient='p'>
                                            Last Name *
                                        </Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="form-group txtfeld-cus register-input validation">
                                        <Index.TextField
                                            fullWidth
                                            error={errorObject['lastName'] !== undefined}
                                            id="fullWidth"
                                            className="form-control input-register"
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                            helperText={errorObject?.lastName}
                                        />
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>

                            <Index.Box className="first-last">
                                <Index.Box className='gap-cus-2'>
                                    <Index.Box className='connect-title '>
                                        <Index.Typography className='user-box' varient='p'>
                                            User Name *
                                        </Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="form-group txtfeld-cus register-input validation">
                                        <Index.TextField
                                            fullWidth
                                            error={errorObject['userName'] !== undefined}
                                            id="fullWidth"
                                            className="form-control input-register"
                                            value={userName}
                                            onChange={(e) => setUserName(e.target.value)}
                                            helperText={errorObject?.userName}
                                        />
                                    </Index.Box>
                                </Index.Box>
                                <Index.Box className='gap-cus-2 sub-right-box'>
                                    <Index.Box className='connect-title '>
                                        <Index.Typography className='user-box' varient='p'>
                                            Mobile Number *
                                        </Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="form-group txtfeld-cus register-input validation">
                                        <Index.TextField
                                            fullWidth
                                            error={errorObject['mobile'] !== undefined}
                                            id="fullWidth"
                                            className="form-control input-register"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                            value={mobile}
                                            onChange={(e) => setMobile(e.target.value)}
                                            helperText={errorObject?.mobile}
                                        />
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>

                            <Index.Box className="first-last">
                                <Index.Box className='gap-cus-2'>
                                    <Index.Box className='connect-title '>
                                        <Index.Typography className='user-box' varient='p'>
                                            Country *
                                        </Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="form-group txtfeld-cus register-input validation">
                                        <Index.TextField
                                            fullWidth
                                            error={errorObject['country'] !== undefined}
                                            id="fullWidth"
                                            className="form-control input-register"
                                            value={country}
                                            onChange={(e) => setCountry(e.target.value)}
                                            helperText={errorObject?.country}
                                        />
                                    </Index.Box>
                                </Index.Box>
                                <Index.Box className='gap-cus-2 sub-right-box'>
                                    <Index.Box className='connect-title '>
                                        <Index.Typography className='user-box' varient='p'>
                                            Email *
                                        </Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="form-group txtfeld-cus register-input validation">
                                        <Index.TextField
                                            fullWidth
                                            error={errorObject['email'] !== undefined}
                                            id="fullWidth"
                                            className="form-control input-register"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            helperText={errorObject?.email}
                                        />
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>

                            <Index.Box className="first-last">
                                <Index.Box className='gap-cus-2'>
                                    <Index.Box className='connect-title '>
                                        <Index.Typography className='user-box' varient='p'>
                                            Password *
                                        </Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="form-group form-group-2  txtfeld-cus register-input validation">
                                        <Index.TextField
                                            fullWidth
                                            error={errorObject['password'] !== undefined}
                                            id="fullWidth"
                                            type="password"
                                            className="form-control form-control-2 input-register"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            helperText={errorObject?.password}
                                        />
                                    </Index.Box>
                                </Index.Box>
                                <Index.Box className='gap-cus-2 sub-right-box'>
                                    <Index.Box className='connect-title '>
                                        <Index.Typography className='user-box' varient='p'>
                                            Confirm Password *
                                        </Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="form-group txtfeld-cus register-input validation">
                                        <Index.TextField
                                            fullWidth
                                            error={errorObject['confirmPassword'] !== undefined}
                                            id="fullWidth"
                                            type="password"
                                            className="form-control input-register"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            helperText={errorObject?.confirmPassword}
                                        />
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>

                            <Index.Box className="first-last">
                                <Index.Box className='gap-cus-2'>
                                    <Index.Box className='connect-title '>
                                        <Index.Typography className='user-box' varient='p'>
                                            Code
                                        </Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="form-group form-group-2  txtfeld-cus register-input validation">
                                        <Index.TextField
                                            fullWidth
                                            disabled
                                            id="fullWidth"
                                            className="form-control form-control-2 input-register"
                                            value={code}
                                        />
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>

                        <Index.Box className='remember-box remember-box2'>
                            <Index.FormControlLabel className='inline-checkbox' 
                            control={<Index.Checkbox checked={terms}
                            onChange={(e) => setTerms(e.target.checked)}
                            />} label="I agree with policy, terms and conditions *" />
                        </Index.Box>
                        <Index.Box style={{fontSize:'0.82rem', color:'#d32f2f'}} className='register-error2'>{errorObject?.terms}</Index.Box>
                    </Index.Box>
                    <Index.Box className=' register-btn'>
                        <Index.Button onClick={()=>handleSubmit()} variant="contained" >Register Now</Index.Button>
                    </Index.Box>
                    <Index.Box>
                        <Index.Box className='signup-text'>
                            <Index.Typography variant='p' component="p" >I have already an account,  <Index.RoutLink className="link-style" to="/login"><span>Signin here.</span></Index.RoutLink> </Index.Typography>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Box>
            <Index.ToastContainer/>
        </div >
    )
}