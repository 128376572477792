import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import FormControl from "@mui/material/FormControl";
import { Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import AuthSagaActions from "../../../redux/authsaga/action";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import DeleteIcon from '@mui/icons-material/Delete';
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

const { getContactEnquirySaga, removeEnquirySaga } = AuthSagaActions;

const ContactEnquiry = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getContactEnquiry = useSelector((state) => {
    return state.authSaga.ContactEnquiry;
  });
  const token = useSelector((state) => {
    return state.authSaga.idToken;
  });
  const [page, setPage] = useState(0);
  const [contactEnquiry, setContactEnquiryList] = useState([]);
  const [rows, Setrows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
 
  const columns = [
    {
      id: "Name",
      label: "Name",
      minWidth: 150,
    },
    {
      id: "Email",
      label: "Email",
      minWidth: 150,
    },
    {
      id: "Subject",
      label: "Subject",
      minWidth: 200,
    },
    {
      id: "Message",
      label: "Message",
      minWidth: 270,
    },
    {
      id: "b3",
      label: "Action",
      minWidth: 170,
      align: "right",
    },
  ];

  function createData(Name, Email, Subject, Message, id) {
    let b3 = (
      <DeleteIcon sx={{cursor:'pointer'}} onClick={() => deletehandler(id)} />
    );
    return {Name, Email, Subject, Message, b3};
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const deletehandler = async (id) => {
    Swal.fire({
      title: "",
      text: "Are you sure you want to delete enquiry ?",
      icon: "",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let urlencoded = new URLSearchParams();
        urlencoded.append("id", id);
        dispatch(removeEnquirySaga(urlencoded, navigate, token));
      }
    });
  };

  useEffect(() => {
    dispatch(getContactEnquirySaga(navigate));
    setContactEnquiryList(getContactEnquiry);
  }, []);

  useEffect(() => {
    setContactEnquiryList(getContactEnquiry);
  }, [getContactEnquiry]);
  
  useEffect(() => {
    let data = [];
    contactEnquiry &&
      contactEnquiry[0] &&
      contactEnquiry.map((element, index) => {
        data = [
          ...data,
          createData(
            element.name,
            element.email,
            element.subject,
            element.message,
            element._id
          ),
        ];
       
      });
      Setrows(data);
  }, [contactEnquiry]);

  return (
    <Box className="page-content-main" sx={{marginTop:5}}>
      <ToastContainer />
      <Box className='main-title-box'>
        <Typography className='main-title'  variant="h4">Contact Enquiry</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        <FormControl sx={{ m: 1, minWidth: 220, maxHeight: 50 }}></FormControl>
      </Box>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer className="table-main-box">
          <Table stickyHeader aria-label="sticky table" className="table-main">
            <TableHead className="table-head">
              <TableRow className="table-row">
                {columns.map((column) => (
                  <TableCell className="table-cell"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <h4 style={{ margin: 0 }}>{column.label}</h4>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="table-body">
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow className="table-row"
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align} className="table-cell">
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default ContactEnquiry;
