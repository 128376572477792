import React, { useState, useEffect } from "react";
import Index from "../Index";
import AuthSagaActions from "../../../redux/authsaga/action";
import { useSelector, useDispatch } from "react-redux";
import dateFormat from "dateformat";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import StatusCheck from "./StatusCheck";

const { getCalenderSaga } = AuthSagaActions;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const IcoCalender = () => {
  const dispatch = useDispatch();
  const [calenderList, SetCalenderList] = useState([]);
  const calenderData = useSelector((state) => {
    return state.authSaga.IcoCalenderList;
  });
  useEffect(() => {
    dispatch(getCalenderSaga());
    SetCalenderList(calenderData);
  }, []);

  useEffect(() => {
    SetCalenderList(calenderData);
  }, [calenderData]);

  return (
    <>
      <Index.Box className="ico-calender-section">
        <Index.Box className="container">
          <Index.Box className="ico-calender">
            <Index.Typography className="pt-55" variant="p" component="p">
              ICO Management
            </Index.Typography>
          </Index.Box>
          <Index.Box className="icolab-features-inner icolab-features-inner-box ico-calender-inner">
            <Index.Typography className="" variant="p" component="p">
             
            </Index.Typography>
          </Index.Box>

          <Index.Box className="pt-52">
            <Index.TableContainer component={Index.Paper} className="tabel-box">
              <Index.Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
                className="icolab-tabel"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    {/* <Index.TableCell>Name</Index.TableCell> */}
                    <Index.TableCell>Start Date</Index.TableCell>
                    <Index.TableCell align="right">End Date</Index.TableCell>
                    <Index.TableCell align="right">Quantity</Index.TableCell>
                    <Index.TableCell align="right">Price</Index.TableCell>
                    <Index.TableCell align="right">Sold </Index.TableCell>
                    <Index.TableCell align="right">Status</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>

                <Index.TableBody>
                  {calenderList?.length
                    ? calenderList?.map((calender, index) => (
                        <Index.TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          {/* <Index.TableCell component="" scope="row">{calender?.phaseName}</Index.TableCell> */}
                          <Index.TableCell component="" scope="row">
                            {dateFormat(
                              calender?.startDate,
                              "d mmm, yyyy h:MM TT"
                            )}
                          </Index.TableCell>
                          <Index.TableCell align="right">
                            {dateFormat(
                              calender?.endDate,
                              "d mmm, yyyy h:MM TT"
                            )}
                          </Index.TableCell>
                          <Index.TableCell align="right">
                            {calender?.quantity}
                          </Index.TableCell>
                          <Index.TableCell align="right">
                            {calender?.price}
                          </Index.TableCell>

                          <Index.TableCell align="right">
                            <Index.Box className="progress-box ">
                              <Index.Box
                                sx={{ flexGrow: 1 }}
                                className={`${
                                  calender?.sold !== "0"
                                    ? "progress-color-up"
                                    : "progress-color"
                                }`}
                              >
                                <BorderLinearProgress
                                  variant="determinate"
                                  value={calender?.sold}
                                />
                                {calender?.sold ? calender?.sold : 0}%
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell align="right">
                            {/* Check Status and component import */}
                            <StatusCheck
                              key={index}
                              startDate={calender?.startDate}
                              endDate={calender?.endDate}
                            />
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    : ""}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default IcoCalender;
