import React, {useState, useEffect, useRef} from "react";
import Index from "../Index";

const { getUserProfile,getReferralTransaction } = Index.AuthSagaActions;

export const UserDashboard = () => {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();

  const token = Index.useSelector((state) => {
    return state.authSaga.idTokenUser;
  });
  const userProfileDetails = Index.useSelector((state) => {
    return state.authSaga.userProfile;
  });
  const userReferralTransaction = Index.useSelector((state) => {
    return state.authSaga.referralTransaction;
  });

  const [userProfile, setUserProfileDetails] = useState([]);
  const [copySuccess, setCopySuccess] = useState('');
  const [refferLink, setRefferLink] = useState('');
  const [TranscationDetails, setTranscationDetails] = useState([]);
  console.log(userReferralTransaction,"userReferralTransaction");

  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand('copy');
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus();
    setCopySuccess('Copied!');
  };

  useEffect(() => {
    dispatch(getUserProfile(token));
    dispatch(getReferralTransaction(navigate,token));
    setUserProfileDetails(userProfileDetails);
  }, []);

  useEffect(() => {
    setUserProfileDetails(userProfileDetails);
  }, [userProfileDetails]);

  useEffect(() => {
    if(userProfile){
      setRefferLink(userProfile?.referralCode);
    }
  }, [userProfile]);

  useEffect(() => {
    setTranscationDetails(userReferralTransaction);
  }, [userReferralTransaction]);
  

  return (
    <div>
      <Index.Header />

      {/* Up Coming Third Phase*/}
      <Index.Box className="third-phase-section about-third-phase-section about-text-main">
        <Index.Box className="container">
          <Index.Box className="">
            <Index.Box className="about-text">
              <Index.Typography variant="p" component="p">
                Dashboard{" "}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="about-text-inner">
              <Index.Typography variant="p" component="p">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et{" "}
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="roadmap-section  dashboard-inner-section">
        <Index.Box className="container">
          <Index.Box className="set-copy-link">
            <Index.Box className="add-copy-link-main">
              <Index.Box className="counter-text2">
                <Index.Typography variant="p" component="p">
                  Your Referral link
                </Index.Typography>
              </Index.Box>
              <Index.Box className="email-box email-box2 email-btn-set position-relative">
                <Index.TextField
                  id="fullWidth"
                  className="form-control textfield-set  textfield-set2"
                  value={refferLink ? `${window.location.host}/refer/${refferLink}` : ""}
                  placeholder="Your link"
                />
                <textarea ref={textAreaRef} value={`${window.location.host}/refer/${refferLink}`} />
                <Index.Box className="subscribe-btn subscribe-btn2  email-small-btn email-small-btn2">
                  <Index.Button onClick={copyToClipboard} variant=""> {!copySuccess ? "Copy link" : copySuccess}</Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="dashboard-box">
            <Index.Box>
              <Index.Box>
                <Index.List className="dashbord-ul">
                  <Index.ListItem>
                    <Index.Box className="main-dashboard">
                      <Index.Box className="round-main">
                        <Index.Box className="roadmap-border2">
                          <Index.Box className="dot-box2">
                            <Index.Box className="counter-text">
                              <Index.Typography variant="p" component="p">
                                1000
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="squrebox-main">
                        <Index.Box className="squre-border2">
                          <Index.Box className="squre-box2">
                            <Index.Box className="counter-text squre-box-set">
                              <Index.Typography variant="p" component="p">
                                Total Transaction
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.ListItem>

                  <Index.ListItem>
                    <Index.Box className="main-dashboard">
                      <Index.Box className="round-main">
                        <Index.Box className="roadmap-border2">
                          <Index.Box className="dot-box2">
                            <Index.Box className="counter-text">
                              <Index.Typography variant="p" component="p">
                                1000
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="squrebox-main">
                        <Index.Box className="squre-border2">
                          <Index.Box className="squre-box2">
                            <Index.Box className="counter-text squre-box-set">
                              <Index.Typography variant="p" component="p">
                                Coin History
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.ListItem>
                  <Index.ListItem>
                    <Index.Box className="main-dashboard">
                      <Index.Box className="round-main">
                        <Index.Box className="roadmap-border2">
                          <Index.Box className="dot-box2">
                            <Index.Box className="counter-text">
                              <Index.Typography variant="p" component="p">
                                1000
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="squrebox-main">
                        <Index.Box className="squre-border2">
                          <Index.Box className="squre-box2">
                            <Index.Box className="counter-text squre-box-set">
                              <Index.Typography variant="p" component="p">
                                Total Referral
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.ListItem>
                  <Index.ListItem>
                    <Index.Box className="main-dashboard">
                      <Index.Box className="round-main">
                        <Index.Box className="roadmap-border2">
                          <Index.Box className="dot-box2">
                            <Index.Box className="counter-text">
                              <Index.Typography variant="p" component="p">
                                1000
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="squrebox-main">
                        <Index.Box className="squre-border2">
                          <Index.Box className="squre-box2">
                            <Index.Box className="counter-text squre-box-set">
                              <Index.Typography variant="p" component="p">
                                Total Referral Coins
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.ListItem>
                  <Index.ListItem>
                    <Index.Box className="main-dashboard">
                      <Index.Box className="round-main">
                        <Index.Box className="roadmap-border2">
                          <Index.Box className="dot-box2">
                            <Index.Box className="counter-text">
                              <Index.Typography variant="p" component="p">
                                1000
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="squrebox-main">
                        <Index.Box className="squre-border2">
                          <Index.Box className="squre-box2">
                            <Index.Box className="counter-text squre-box-set">
                              <Index.Typography variant="p" component="p">
                                Balance
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.ListItem>
                 
                </Index.List>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="res-mob-set-btn">
         
            <Index.Box>
              <Index.Box className="userdashbord-btn">
                <Index.Button variant="contained">
                  Recent Transaction
                </Index.Button>
              </Index.Box>
            </Index.Box>

            <Index.Box sx={{pb:2}}>
            <Index.TableContainer component={Index.Paper} className="tabel-box">
              <Index.Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
                className="icolab-tabel coinlogs-tabel"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell>Date</Index.TableCell>
                    <Index.TableCell align="left">Coin</Index.TableCell>
                    {/* <Index.TableCell align="left">Rate</Index.TableCell> */}
                    {/* <Index.TableCell align="left">Amount</Index.TableCell> */}
                    <Index.TableCell align="left">Referral User</Index.TableCell>
                    {/* <Index.TableCell align="left">Status</Index.TableCell> */}
                    <Index.TableCell align="left">Status</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody >
                  {TranscationDetails.length?TranscationDetails?.map((row, index) => {
                    return (
                      <>
                        <Index.TableRow
                        key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <Index.TableCell>
                            {row?.createdAt ? new Date(row?.createdAt).toLocaleString() : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {row?.coin ? row?.coin : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {row?.referredUserId.userName ? row?.referredUserId.userName : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.status ? row?.status : "-"}
                          </Index.TableCell>
                          {/* <Index.TableCell align="left">
                            {row?.balance ? row?.balance : "-"}{" "}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {row?.status ? row?.status : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {row?.details ? row?.details : "-"}
                          </Index.TableCell> */}
                        </Index.TableRow>
                      </>
                    );
                  }):  <Index.TableRow
              
                  
                  >
                    <Index.TableCell colSpan={4} align="center">
                    No recent transactions
                    </Index.TableCell>
                    
                  </Index.TableRow>}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      {/* Subscribe to Our Newsletter */}
        <Index.Subscribe />
      {/* End Subscribe Section */}

      <Index.Footer />
    </div>
  );
};
