import React, { useState, useEffect, useRef } from "react";
import Index from "../Index";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    maxHeight: "90vh",
    bgcolor: "background.paper",
    overflow: "auto",
    boxShadow: 24,
    p: 4,
  };

const { getUserProfile, editUserProfileSaga } = Index.AuthSagaActions;
export const MyProfile = () => {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();

  const token = Index.useSelector((state) => {
    return state.authSaga.idTokenUser;
  });

  const userProfileDetails = Index.useSelector((state) => {
    return state.authSaga.userProfile;
  });

  const [userProfile, setUserProfileDetails] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  // const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [mobile, setMobile] = useState("");
  const [country, setCountry] = useState("");
  const [editId, setEditId] = useState("");
  const [copySuccess, setCopySuccess] = useState('');
  const [refferLink, setRefferLink] = useState('');
  const textAreaRef = useRef(null);
  const [errorObject,setErrorObject] = useState({});

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand('copy');
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
     e.target.focus();
     setCopySuccess('Copied!');
  };

useEffect(() => {
    dispatch(getUserProfile(token));
    setUserProfileDetails(userProfileDetails);
  }, []);

  useEffect(() => {
    setUserProfileDetails(userProfileDetails);
    setEditId(userProfile?._id ? userProfile._id : "");
    setFirstName(userProfile?.firstName ? userProfile.firstName : "");
    setLastName(userProfile?.lastName ? userProfile.lastName : "");
    //setEmail(userProfile?.email ? userProfile.email : "");
    setUserName(userProfile?.userName ? userProfile.userName : "");
    setMobile(userProfile?.mobileNo ? userProfile.mobileNo : "");
    setCountry(userProfile?.country ? userProfile.country : "");
  }, [userProfileDetails]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if(editId){
      setFirstName(userProfileDetails?.firstName);
      setLastName(userProfileDetails?.lastName);
      setUserName(userProfileDetails?.userName);
      setMobile(userProfileDetails?.mobileNo);
      setCountry(userProfileDetails?.country);
    }
  }, [editId, userProfileDetails ]);

  useEffect(() => {
    if(userProfile){
      setRefferLink(userProfile?.referralCode);
    }
  }, [userProfile]);

  const validationFun=(firstName,lastName,mobile,country)=>{
    let errors = {};
    if(firstName == "" || firstName.replace( /\s\s+/g, ' ')?.length<3){
      errors.FirstName = "Enter at least 3 characters in First Name";
    }
    else if(firstName.replace( /\s\s+/g, ' ') == "" || firstName.replace( /\s\s+/g, ' ')?.length>25){
      errors.FirstName = "Enter not more then 25 characters in First Name";
    }

    if(lastName == "" || lastName.replace( /\s\s+/g, ' ')?.length<3){
      errors.LastName = "Enter at least 3 characters in Last Name";
    }
    else if(lastName.replace( /\s\s+/g, ' ') == "" || lastName.replace( /\s\s+/g, ' ')?.length>25){
      errors.LastName = "Enter not more then 25 characters in Last Name";
    } 

    if(mobile == "" || mobile.length!==10){
      errors.Mobile = "Enter at least 10 numbers in Mobile Number";
    }

    if(country == "" || country.replace( /\s\s+/g, ' ')?.length<3){
      errors.Country = "Enter at least 3 characters in Country";
    }
    else if(country.replace( /\s\s+/g, ' ') == "" || country.replace( /\s\s+/g, ' ')?.length>25){
      errors.Country = "Enter not more then 25 characters in Country";
    } 
    return errors;
  }

  const handleSubmit = (e) => {
    if (Object.keys(validationFun(firstName,lastName,mobile,country)).length === 0) {
        submitHandler();
        setErrorObject({});
    } else {
      setErrorObject(validationFun(firstName,lastName,mobile,country))
    }
  };

  const submitHandler = () => {
    const apidata = new URLSearchParams();
    if (editId !== "") {
      apidata.append("id", editId);
    }
    //apidata.append("email", email);
    apidata.append("firstName", firstName.replace( /\s\s+/g, ' ' ));
    apidata.append("lastName", lastName.replace( /\s\s+/g, ' ' ));
    // apidata.append("userName", userName.replace( /\s\s+/g, ' ' ));
    apidata.append("mobileNo", mobile.replace( /\s\s+/g, ' ' ));
    apidata.append("country", country.replace( /\s\s+/g, ' ' ));
    dispatch(editUserProfileSaga(apidata, navigate, token))
    // dispatch(getUserProfile(token));
    // setUserProfileDetails(userProfileDetails);
    // setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <div>
      <Index.Header />

      {/* Up Coming Third Phase*/}
      <Index.Box className="third-phase-section about-third-phase-section about-text-main">
        <Index.Box className="container">
          <Index.Box className="about-text">
            <Index.Typography variant="p" component="p">
              My Profile
            </Index.Typography>
          </Index.Box>
          {/* <Index.Box className="about-text-inner">
            <Index.Typography variant="p" component="p">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et{" "}
            </Index.Typography>
          </Index.Box> */}
        </Index.Box>
      </Index.Box>
      <Index.Box className="myprofile-section">
        <Index.Box className="container">
          <Index.Box className="profile-section">
            <Index.Box className="main-login">
              <Index.Box className="profile-btn">
                <Index.Box className="login-inner-text login-inner-text2 myprofile-set ">
                  <Index.Typography variant="p" component="p">
                    My Profile
                  </Index.Typography>
                </Index.Box>
                <Index.Box className=" userdashbord-btn button userdashbord-btn2">
                  <Index.Button 
                    variant="contained"
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget)
                        setEditId(userProfile?._id ? userProfile?._id : "");
                    }}>
                    <img className="edit-icone" src={Index.Svg.edit1} alt=""></img>
                    Edit Profile
                  </Index.Button>
                </Index.Box>
              </Index.Box>
              <Index.Box className="first-last">
                <Index.Box className="gap-cus gap-cus-2">
                  <Index.Box className="connect-title ">
                    <Index.Typography className="user-box" varient="p">
                      First Name
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="form-group txtfeld-cus register-input register-input2">
                    <Index.TextField
                      disabled
                      fullWidth
                      id="fullWidth"
                      className="form-control input-register"
                      value={userProfile?.firstName ? userProfile.firstName : ""}
                    />
                  </Index.Box>
                </Index.Box>

                <Index.Box className="gap-cus gap-cus-2 sub-right-box2">
                  <Index.Box className="connect-title ">
                    <Index.Typography className="user-box" varient="p">
                      Last Name
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="form-group txtfeld-cus register-input register-input2">
                    <Index.TextField
                      disabled
                      fullWidth
                      id="fullWidth"
                      className="form-control input-register"
                      value={userProfile?.lastName ? userProfile.lastName : ""}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="first-last">
                <Index.Box className="gap-cus-2">
                  <Index.Box className="connect-title ">
                    <Index.Typography className="user-box" varient="p">
                      User Name
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="form-group txtfeld-cus register-input register-input2">
                    <Index.TextField
                      disabled
                      fullWidth
                      id="fullWidth"
                      className="form-control input-register"
                      value={userProfile?.userName ? userProfile.userName : ""}
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box className="gap-cus-2 sub-right-box2">
                  <Index.Box className="connect-title ">
                    <Index.Typography className="user-box" varient="p">
                      Mobile Number
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="form-group txtfeld-cus register-input register-input2">
                    <Index.TextField
                      disabled
                      fullWidth
                      id="fullWidth"
                      className="form-control input-register"
                      value={userProfile?.mobileNo ? userProfile.mobileNo : ""}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="first-last">
                <Index.Box className="gap-cus-2">
                  <Index.Box className="connect-title ">
                    <Index.Typography className="user-box" varient="p">
                      Country
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="form-group txtfeld-cus register-input register-input2">
                    <Index.TextField
                      disabled
                      fullWidth
                      id="fullWidth"
                      className="form-control input-register"
                      value={userProfile?.country ? userProfile.country : ""}
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box className="gap-cus-2 sub-right-box2">
                  <Index.Box className="connect-title ">
                    <Index.Typography className="user-box" varient="p">
                      Email
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="form-group txtfeld-cus register-input register-input2">
                    <Index.TextField
                      disabled
                      fullWidth
                      id="fullWidth"
                      className="form-control input-register"
                      value={userProfile?.email ? userProfile.email : ""}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="first-last">
                <Index.Box className="gap-cus-2">
                  <Index.Box className="connect-title ">
                    <Index.Typography className="user-box" varient="p">Referral link</Index.Typography>
                  </Index.Box>
                  
                  <Index.Box className="email-box email-box2 email-box3 email-btn-set email-btn-set3 position-relative">
                    <Index.TextField
                      id="fullWidth"
                      className="form-control textfield-set  textfield-set2"
                      value={refferLink ? `${window.location.host}/refer/${refferLink}` : ""}
                      placeholder="Your link"
                    />
                    <textarea ref={textAreaRef} value={`${window.location.host}/refer/${refferLink}`} />
                    <Index.Box className="subscribe-btn subscribe-btn2 subscribe-btn3  email-small-btn email-small-btn2 email-small-btn3">
                      <Index.Button onClick={copyToClipboard} variant=""> {!copySuccess ? "Copy link" : copySuccess}</Index.Button>
                    </Index.Box>
                  </Index.Box>

                </Index.Box>
              </Index.Box>

            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      {/* Edit Profile Popup */}

      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Index.Box sx={style}>
          <Index.Grid item xs={6}>
            <Index.Box
              sx={{
                margin: "auto",
                width: 400,
                maxWidth: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
              className="validation"
            >
              <br />
              <Index.TextField
                sx={{width: "400px"}}
                fullWidth
                required
                error={errorObject['FirstName'] !== undefined}
                label="First Name"
                id="firstName"
                value={firstName}
                name="firstName"
                onChange={(e) => setFirstName(e.target.value)}
                helperText={errorObject?.FirstName}
              />

              <Index.TextField
                sx={{ marginTop: 2, width: "400px" }}
                fullWidth
                required
                error={errorObject['LastName'] !== undefined}
                label="Last Name"
                id="lastName"
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                helperText={errorObject?.LastName}
              />

              <Index.TextField
                sx={{ marginTop: 2, width: "400px" }}
                fullWidth
                label="User Name"
                id="userName"
                value={userName}
                disabled
                //onChange={(e) => setUserName(e.target.value)}
              />
              
              {/* <Index.TextField
                sx={{ marginTop: 2, width: "400px" }}
                disabled
                fullWidth
                label="Email"
                id="email"
                value={email}
              /> */}

              <Index.TextField
                sx={{ marginTop: 2, width: "400px" }}
                required
                fullWidth
                error={errorObject['Mobile'] !== undefined}
                label="Mobile No"
                id="mobile"
                name="mobile"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                helperText={errorObject?.Mobile}
                onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
              />

              <Index.TextField
                sx={{ marginTop: 2, width: "400px" }}
                fullWidth
                required
                error={errorObject['Country'] !== undefined}
                label="Country"
                id="country"
                name="country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                helperText={errorObject?.Country}
              />

              <br />
              <Index.Box className="save-btn-box">
                <Index.Button
                  className="save-btn"
                  variant="contained"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </Index.Button>
                
                <Index.Button
                  className="save-btn"
                  color="error"
                  variant="contained"
                  sx={{ml:5}}
                  onClick={() => setAnchorEl(null)}
                >
                  Cancel
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Grid>
        </Index.Box>
      </Index.Modal>
      {/* End Popup */}
      <Index.ToastContainer />
      <Index.Footer />
    </div>
  );
};
