import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Button, Typography } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from '@mui/material/FormControl';
import { Grid, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import AuthSagaActions from "../../../redux/authsaga/action";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import "react-toastify/dist/ReactToastify.css";
import Modal from "@mui/material/Modal";
import Swal from "sweetalert2";

const { addteammembersaga, getTeamMembersaga, rmoveteammembersaga, updateteammembersaga } =
  AuthSagaActions;

const Input = styled("input")({
  display: "none",
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  maxHeight: "70vh",
  bgcolor: "background.paper",
  overflow: "auto",
  boxShadow: 24,
  p: 4,
};
const TeamMember = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const teamMemberList = useSelector((state) => {
    return state.authSaga.TeamMemberList;
  });


  const token = useSelector((state) => {
    return state.authSaga.idToken;
  });
  const [page, setPage] = useState(0);
  const [Url, Seturl] = useState("");
  const [memberList, Setmemberlist] = useState([]);
  const [rows, Setrows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [Name, Setname] = useState("");
  const [Designation, SetDesignation] = useState("");
  const [Editid, Seteditid] = useState("");
  const [count, Setcount] = useState(0);
  const [Oldurl, SetOldurl] = useState("");
  const [Images, Setimages] = useState();
  const [ImagesError, SetimagesError] = useState("");
  const [errorObject,setErrorObject] = useState({});

  const columns = [
    { id: "Name", label: "Name", minWidth: 200 },
    { id: "Designation", label: "Designation", minWidth: 200 },
    {
      id: "b2",
      label: "Action",
      align: "right",
    },
    {
      id: "b3",
      label: "",
      align: "right",
    },
  ];
  const imagehendeler = (e) => {
    let urllink = window.URL.createObjectURL(e[0]);
    const imageFile = e[0];
    if (!imageFile.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      SetimagesError("Please upload valid image of jpg,png,gif");
      Seturl("");
      return false;
    } else{
      SetimagesError("");
      Seturl(urllink);
    }
  };
  function createData(Name, Designation, id) {
    let b2 = (
      <EditIcon 
          sx={{cursor:'pointer'}}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            Seteditid(id);
            Setcount((pre) => pre + 1);
          }}
        />
    );
    let b3 = (
      <DeleteIcon sx={{cursor:'pointer'}} onClick={() => deletehandler(id)} />
    );
    return {Name, Designation, b2, b3};
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    memberList.map((element, index) => {
      if (element._id === Editid) {
        setErrorObject({});
        SetimagesError("");
        Setname(element.name);
        Seturl(element.images);
        SetOldurl(element.images);
        SetDesignation(element.designation);
      }
    });
    if (Editid === "") {
      setErrorObject({});
      SetimagesError("");
      Setname("");
      Seturl("");
      SetOldurl("");
      SetDesignation("");
    }
  }, [Editid, count, memberList]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deletehandler = async (id) => {
    Swal.fire({
      title: "",
      text: "Are you sure you want to delete member ?",
      icon: "",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let urlencoded = new URLSearchParams();
        urlencoded.append("id", id);
        dispatch(rmoveteammembersaga(urlencoded, navigate, token));
      }
    });
  };

  const validationFun=(Name,Designation)=>{
    let errors = {};
    if(Name == "" || Name.replace( /\s\s+/g, ' ')?.length<3){
        errors.Name = "Enter at least 3 characters in Name";
    } else if(Name.replace( /\s\s+/g, ' ') == "" || Name.replace( /\s\s+/g, ' ')?.length>25){
        errors.Name = "Enter not more then 25 characters in Name";
    }
  
    if(Designation == "" || Designation.replace( /\s\s+/g, ' ')?.length<2){
        errors.Designation = "Enter at least 2 characters in Designation";
    } else if(Designation.replace( /\s\s+/g, ' ') == "" || Designation.replace( /\s\s+/g, ' ')?.length>25){
        errors.Designation = "Enter not more then 25 characters in Designation";
    } 
    return errors;
  }
  
  const handleValidation = (e) => {
    if (Object.keys(validationFun(Name,Designation)).length === 0) {
        handleSubmit();
        setErrorObject({});
    } else {
        setErrorObject(validationFun(Name,Designation))
    }
   console.log(validationFun(Name,Designation));
  };
  
  const handleSubmit = () => {
    if(ImagesError !== ""){
      SetimagesError(ImagesError);
    } else {
      const apidata = new FormData();
      apidata.append("name", Name);
      apidata.append("designation", Designation);
      let imagedata = Images && Images[0] ? [...Images] : [];
      apidata.append("images", imagedata[0]);

      if (Editid !== "") {
        apidata.append("id", Editid);
        if (imagedata[0]) {
          apidata.append("oldurl", Oldurl);
        } else {
          apidata.append("oldurl", "");
        }
        apidata.append("fileurl", Url);
      }
      Editid === "" ? dispatch(addteammembersaga(apidata, navigate, token)) : dispatch(updateteammembersaga(apidata, navigate, token))
      setAnchorEl(null);
      Setimages([]);
    }
  };

  useEffect(() => {
    dispatch(getTeamMembersaga(navigate));
    Setmemberlist(teamMemberList);
  }, [])

  useEffect(() => {
    Setmemberlist(teamMemberList);
  }, [teamMemberList]);

  useEffect(() => {
    let data = [];
    memberList?.map((element, index) => {
      data = [
        ...data,
        createData(element.name, element.designation, element._id),
      ];
    });
    Setrows(data);
  }, [memberList]);

  useEffect(() => {
    dispatch(getTeamMembersaga(navigate));
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Box className="page-content-main">
      <ToastContainer />
      <Box className='main-title-box'>
        <Typography className='main-title' variant="h4">Team Members</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        <FormControl sx={{ m: 1, minWidth: 220, maxHeight: 50 }}></FormControl>
        <Button
          variant="contained"
          sx={{
            maxHeight: 50, marginTop: 2, backgroundColor: '#271e09',
            "&:hover": { backgroundColor: "#f49d04", opacity: [0.9, 0.8, 0.7] },
          }}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            Seteditid("");
            Setcount(count + 1);
          }}
        >
          Add New
        </Button>
      </Box>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer className="table-main-box">
          <Table stickyHeader aria-label="sticky table" className="table-main">
            <TableHead className="table-head">
              <TableRow className="table-row">
                {columns?.map((column) => (
                  <TableCell className="table-cell"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <h4 style={{ margin: 0 }}>{column.label}</h4>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="table-body" sx={{ padding: '18px' }}>

              {rows.length ? rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row) => {
                return (
                  <TableRow className="table-row"
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}
                  >
                    {columns?.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} className="table-cell">
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              }) : "No Data"}
            </TableBody>
          </Table>
        </TableContainer>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid item xs={6}>
              <Box
                sx={{
                  margin: "auto",
                  width: 400,
                  maxWidth: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
                className="validation"
              >
                <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                  <img
                    height={80}
                    width={80}
                    style={{ borderRadius: "50%", margin: "auto" }}
                    alt=""
                    src={
                      Url && Url !== ""
                        ? Url
                        : "https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                    }
                  ></img>
                </Box>

                  <label
                    htmlFor="contained-button-file"
                    style={{ margin: "auto", marginTop: "15px" }}
                  >
                    <Input
                      accept="image/*"
                      id="contained-button-file"
                      multiple
                      type="file"
                      alt=""
                      onChange={(e) => {
                        Setimages(e.target.files);
                        imagehendeler(e.target.files);
                      }}
                    />
                    <Button
                      variant="contained"
                      sx={{ width: "180px" }}
                      component="span"
                    >
                      Upload Image
                    </Button>
                  <FormHelperText sx={{ color: "#d32f2f" }}>{ImagesError}</FormHelperText>
                  </label>
                  <br />
                  <TextField
                    sx={{width:"400px"}}
                    fullWidth
                    required
                    error={errorObject['Name'] !== undefined}
                    label="Name"
                    id="Name"
                    value={Name}
                    onChange={(e) => Setname(e.target.value)}
                    helperText={errorObject?.Name}
                  />

                  <TextField
                    sx={{ marginTop: 2, width:"400px"}}
                    required
                    fullWidth
                    error={errorObject['Designation'] !== undefined}
                    label="Designation"
                    id="Designation"
                    value={Designation}
                    onChange={(e) => SetDesignation(e.target.value)}
                    helperText={errorObject?.Designation}
                  />
                 
                  <br />
                  <Box className="save-btn-box">
                    <Button className="save-btn"
                      variant="contained"
                      onClick={() => handleValidation()}
                    >
                      Submit
                    </Button>
                  </Box>
              </Box>
            </Grid>
          </Box>
        </Modal>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default TeamMember;
