import React, { useState, useEffect } from "react";
import Index from "../../Index";
const { resetPasswordSaga } = Index.AuthSagaActions;


export default function ForgotPassword() {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const { key } = Index.useParams();
  const [user, setUser] = useState({
    password: "",
    confirmPassword: "",
  });

  // Input text fields handler
  const handleInputs = ({ target: { name, value } }) => {
    let temp = { ...user };
    temp[name] = value;
    setUser(temp);
  };

  useEffect(() => {
    // custom rule will have name 'isPasswordMatch'
    Index.ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== user.password) {
        return false;
      }
      return true;
    });

    return () => {
      // remove rule when it is not needed
      Index.ValidatorForm.removeValidationRule("isPasswordMatch");
    };
  }, [user]);

  const handleResetPasswordSubmit = async (e) => {
    e.preventDefault();
    let urlencoded = new URLSearchParams();
    urlencoded.append("newPass", user.password?.trim());
    urlencoded.append("resetLink", key);
    dispatch(resetPasswordSaga(urlencoded, navigate));
  };

  return (
    <>
      <Index.Typography
        variant="h5"
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          mt: 2,
        }}
        color="black"
      >
        Password Reset
      </Index.Typography>
      <Index.Card
        variant="outlined"
        sx={{
          margin: "auto",
          marginTop: 4,
          marginBottom: "10%",

          width: { xs: "345px", sm: "500px", md: "500px", lg: "500px" },
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Index.Box
          sx={{
            margin: "auto",
            width: 500,
            maxWidth: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            py: 4,
          }}
        >
          <Index.ValidatorForm onSubmit={handleResetPasswordSubmit}>
            <Index.InputLabel
              htmlFor="component-simple"
              sx={{ pl: { xs: 7, sm: 10, md: 10, lg: 10 }, pt: 2 }}
            >
              New Password
            </Index.InputLabel>
            <Index.TextValidator
              sx={{ margin: "auto", width: "70%", ml: 10 }}
              fullWidth
              id="New Password"
              type="password"
              name="password"
              value={user.password}
              onChange={handleInputs}
              validators={["required"]}
              errorMessages={["Please enter new password"]}
            />
            <Index.InputLabel
              htmlFor="component-simple"
              sx={{ pl: { xs: 7, sm: 10, md: 10, lg: 10 }, pt: 2 }}
            >
              {" "}
              Confirm Password
            </Index.InputLabel>
            <Index.TextValidator
              size="small"
              sx={{ margin: "auto", width: "70%", ml: 10 }}
              fullWidth
              id="confirmPassword"
              type="password"
              name="confirmPassword"
              value={user.confirmPassword}
              onChange={handleInputs}
              validators={["isPasswordMatch", "required"]}
              errorMessages={[
                "Password mismatch",
                "Please enter confirm password",
              ]}
            />

            <Index.Box
              sx={{ display: "flex", justifyContent: "center", width: "100%" }}
            >
              <Index.Button
                sx={{
                  marginTop: 2,
                  marginBottom: 2,
                  width: { xs: "auto", sm: "auto", md: "170px", lg: "170px" },
                  textTransform: "none",
                }}
                variant="contained"
                type="submit"
              >
                Reset
              </Index.Button>
            </Index.Box>
          </Index.ValidatorForm>
        </Index.Box>
        <Index.ToastContainer />
        <Index.Typography>
          <Index.Link component={Index.routerLink} to="/admin/login">
            Back To Login Page
          </Index.Link>
        </Index.Typography>
      </Index.Card>
    </>
  );
}
