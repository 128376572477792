import IMAGE1 from "../assets/images/jpg/IMAGE1.jpg";
import IMAGE2 from "../assets/images/jpg/IMAGE2.jpg";
import IMAGE3 from "../assets/images/jpg/IMAGE3.jpg";
import IMAGE4 from "../assets/images/jpg/IMAGE4.jpg";
import IMAGE5 from "../assets/images/jpg/IMAGE5.jpg";
import IMAGE6 from "../assets/images/jpg/IMAGE6.jpg";
import IMAGE7 from "../assets/images/jpg/IMAGE7.jpg";
import IMAGE8 from "../assets/images/jpg/IMAGE8.jpg";
import IMAGE9 from "../assets/images/jpg/IMAGE9.jpg";

import tokenomicsWhitePaper from "../assets/images/jpg/TokenomicsWhitePaper.jpg";
import Ecosyatem from "../assets/images/jpg/Ecosyatem.jpg";

export default {
    IMAGE1,
    IMAGE2,
    IMAGE3,
    IMAGE4,
    IMAGE5,
    IMAGE6,
    IMAGE7,
    IMAGE8,
    IMAGE9,
    tokenomicsWhitePaper,
    Ecosyatem,
}
