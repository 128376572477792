import React, { useState, useEffect } from 'react'
import Index from "../Index";
import AuthSagaActions from "../../../redux/authsaga/action";
import { useSelector, useDispatch } from "react-redux";
const { getRoadmapsaga} = AuthSagaActions;


const RoadMap = () => {
    const dispatch = useDispatch();
    const [roadMapList, setRoadMapList] = useState([]);
    const roadList = useSelector((state) => {
        return state.authSaga.Road;
      });

    useEffect(() => {
        dispatch(getRoadmapsaga());
        setRoadMapList(roadList);
    }, []);

    useEffect(() => {
        setRoadMapList(roadList);
    }, [roadList]);

  return (
    <>
        <Index.Box className="roadmap-section">
        <Index.Box className="container">
          <Index.Box className="icolab-features roadmap-text">
            <Index.Typography className="" variant="p" component="p">
              RoadMap Management
            </Index.Typography>
          </Index.Box>
          <Index.Box className="roadmap-text-inner roadmap-inner-box ">
            <Index.Typography className="" variant="p" component="p">
              Lorem ipsum dolor sit amet, conset sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            </Index.Typography>
          </Index.Box>

          <Index.Box className="roadmap-main-section">
            <Index.List className="roadmap-ul">
              {
                roadMapList.length
                ? roadMapList.map((road,index) => (
                  <Index.ListItem key={index}>
                    <Index.Box className="roadmap-card">
                      <Index.Box className="col1 roadmap-border">
                        <Index.Box className="dot">
                          <span></span>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="col2">
                        <Index.Box className="line"></Index.Box>
                      </Index.Box>
    
                      <Index.Box className="col3">
                        <Index.Box className="round round-set">
                          <Index.Box className="round-inner">
                            <Index.Box className="round-inner-text1">
                              <Index.Typography className="" variant="p" component="p">
                               ${road?.price}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="round-inner-text2">
                              <Index.Typography
                                className=""
                                variant="p"
                                component="p"
                              >
                                {road?.title}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
    
                      <Index.Box className="col4">
                        <Index.Box className="content">
                          <Index.Typography className="" variant="p" component="p">
                            {road?.description}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.ListItem>
                ))
                : ""
              }
            </Index.List>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  )
}

export default RoadMap