import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { ToastContainer } from "react-toastify";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuthSagaActions from "../../../redux/authsaga/action";

const { addEditIcoLearn, getIcoLearn } = AuthSagaActions;
const IcoLearn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const icoList = useSelector((state) => {
    return state.authSaga.IcoLearnList;
  });
  
  const [icoLearnList, SetIcoLearnlist] = useState([]);
  const [Title, Settitle] = useState("");
  const [Editid, Seteditid] = useState("");
  const [Description, Setdescription] = useState("Description...");
  const [errorObject,setErrorObject] = useState({});

  useEffect(() => {
    icoLearnList &&
      icoLearnList[0] &&
      icoLearnList.map((element, index) => {
        if (element._id || element._id === Editid) {
          setErrorObject({});
          Settitle(element.title);
          Setdescription(element.description);
        }
      });
  }, [icoLearnList]);

  const validationFun=(Title,Description)=>{
    let errors = {};
    if(Title == "" || Title.replace( /\s\s+/g, ' ')?.length<3){
        errors.Title = "Enter at least 3 characters in Title";
    } else if(Title.replace( /\s\s+/g, ' ') == "" || Title.replace( /\s\s+/g, ' ')?.length>100){
        errors.Title = "Enter not more then 100 characters in Title";
    }
    if(Description == "" || Description.replace( /\s\s+/g, ' ')?.length<3){
        errors.Description = "Enter at least 3 characters in Description";
    }
    return errors;
  }
  
  const handleValidation = (e) => {
    if (Object.keys(validationFun(Title,Description)).length === 0) {
        handleSubmit();
        setErrorObject({});
    } else {
        setErrorObject(validationFun(Title,Description))
    }
   //console.log(validationFun(Title,Description));
  };

  const handleSubmit = () => {
    const apidata = new URLSearchParams();
    apidata.append("title", Title);
    apidata.append("description", Description);
    if (Editid !== "") {
      apidata.append("editid", Editid);
    }
    dispatch(addEditIcoLearn(apidata, navigate));
  };

  useEffect(() => {
    dispatch(getIcoLearn(navigate));
    SetIcoLearnlist(icoList);
  }, []);

  useEffect(() => {
    SetIcoLearnlist(icoList);
  }, [icoList]);

  useEffect(() => {
    icoLearnList &&
      icoLearnList[0] &&
      icoLearnList.map((element, index) => {
        Seteditid(element._id);
      });
  }, [icoLearnList]);

  return (
    <>
      <Box className="page-content-main">
        <ToastContainer />
        <Box sx={{ w: 100 }}>
          <Box className='main-title-box'>
            <Typography className='main-title' variant="h4">ICO Learn Solution</Typography>
          </Box>
          <Box>
            <Box sx={{ mt: 3 }}>
              <Grid container>
                <Grid item xs={12} sm={12}  md={3} lg={9}>
                  <Box className="ckeditor-main validation">
                    <TextField
                      sx={{ marginTop: 2, mb:2 }}
                      required
                      error={errorObject['Title'] !== undefined}
                      fullWidth
                      label="Title"
                      id="Title"
                      value={Title}
                      onChange={(e) => {
                        Settitle(e.target.value);
                      }}
                      helperText={errorObject?.Title}
                    />
                    
                    <Box className="ckeditor-main">
                      <CKEditor
                        editor={ClassicEditor}
                        data={Description}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          Setdescription(data);
                        }}
                        className="ckeditor-tag"
                      />
                    </Box>
                    <FormHelperText sx={{ color: "#d32f2f" }}>{errorObject?.Description}</FormHelperText>
                    <br />
                    <Box className="save-btn-box">
                      <Button className="save-btn"
                        variant="contained"
                        onClick={() => {
                          handleValidation();
                        }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default IcoLearn;
