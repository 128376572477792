import menubtn from "../assets/images/svg/menubtn.svg"
import call from "../assets/images/svg/call.svg"
import mail from "../assets/images/svg/mail.svg"
import fb from "../assets/images/svg/fb.svg"
import insta from "../assets/images/svg/insta.svg"
import twitter from "../assets/images/svg/twitter.svg"
import twitterGray from "../assets/images/svg/twitter-gray.svg"
import youtube from "../assets/images/svg/youtube.svg"
import youtubeGray from "../assets/images/svg/youtube-gray.svg"
import coinlogs from "../assets/images/svg/coinlogs.svg"
import edit1 from "../assets/images/svg/edit1.svg"
import primaryclick  from "../assets/images/svg/primary-click.svg";
// import user1 from "../assets/images/svg/user1.svg"
// import user2 from "../assets/images/svg/user2.svg"

export default {
   menubtn,
   call,
   mail,
   fb,
   insta,
   twitter,
   youtube,
   coinlogs,
   edit1,
   primaryclick,
   twitterGray,
   youtubeGray
   // user1,
   // user2,
   
}
