import React, {useState, useEffect} from 'react'
import Index from "../Index";

const StatusCheck = (props) => {
    const [todayDate, setTodayDate] = useState(new Date());
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState("");
    const [status, setStatus] = useState('');   
    
    useEffect(() => {
        //console.log(props, "props")
        setStartDate(props?.startDate);
        setEndDate(props?.endDate);
    }, [props])

    function toTimestamp(strDate){
        var datum = Date.parse(strDate);
        return datum/1000;
    }

    let todayDateCheck =  toTimestamp(todayDate);
    let startDateCheck = toTimestamp(startDate);
    let endDateCheck = toTimestamp(endDate);

    //console.log(todayDateCheck, "todayDateCheck", todayDate);
    //console.log(startDateCheck, "startDateCheck");
    //console.log(endDateCheck, "endDateCheck" ,endDate );

    const checkStatus = () => {
        if(startDateCheck <= todayDateCheck && endDateCheck >= todayDateCheck){
            setStatus("OnGoing")
        }
        else if(startDateCheck >= todayDateCheck){
            setStatus("Upcomming")
        }
        else if(endDateCheck <= todayDateCheck){
            setStatus("Closed")
        }
        else{
            setStatus("Closed")
        }
    }

    useEffect(() => {
        checkStatus()
    },[status])
  
  return (
    <>
        <Index.Box className="completed-color , upcoming-color">
           {status}  
        </Index.Box>
    </>
  )
}

export default StatusCheck