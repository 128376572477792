import React from "react";
import { BrowserRouter, Route, Routes as Routess } from "react-router-dom";
import { createBrowserHistory } from "history";
import { useSelector } from "react-redux";
import Home from "../Container/screens/home/Home";
import About from "../Container/screens/about/About";
import Footer from "../Component/user/defaultLayout/Footer";
import Header from "../Component/user/defaultLayout/Header";
import Contact from "../Container/screens/contact/Contact";
import Register from "../Container/screens/register/Register";
import Login from "../Container/screens/login/Login";
import Forgotpassword from "../Container/screens/forgotpassword/Forgotpassword";
import Reset from "../Container/screens/reset/Reset";
// import { ToastContainer, toast } from "react-toastify";
import AdminSideBar from "../Component/admin/SideBar/SideBarAdmin";
import Dashboard from "../Container/admin/links/Dashboard";
import SecurityPrivacy from "../Container/admin/Cms/SecurityPrivacy";
import AdminAboutUs from "../Container/admin/Cms/AboutUs";
import ContactUs from "../Container/admin/ContectUs/ContectUs";
import TermsAndCondition from "../Container/admin/Cms/TermsAndCondition";
import TeamMember from "../Container/admin/team/TeamMember";
import UserList from "../Container/admin/users/UserList";
import Newsmanagement from "../Container/admin/NewsManagement/NewsManagement";
import AdminLogin from "../Container/admin/auth/Login";
import RoadMap from "../Container/admin/RoadMap/RoadMap";
import WhitePaper from "../Container/admin/WhitePaper/WhitePaper";
import { Navigate } from "react-router-dom";
import WebsiteSetting from "../Container/admin/websiteSetting/WebsiteSetting";

import Vision from "../Container/admin/ecoSystem/Vision";
import AdminForgotPassword from "../Container/admin/auth/forgotPassword";
import ResetPassword from "../Container/admin/auth/resetPassword";
import { CoinLogs } from "../Container/screens/coinlogs/CoinLogs";
import { ChangePassword } from "../Container/screens/changepassword/ChangePassword";
import { UserTermsandConditions } from "../Container/screens/usertermsandconditions/UserTermsandConditions";
import { Privacypolicy } from "../Container/screens/privacypolicy/Privacypolicy";
import { UserDashboard } from "../Container/screens/userdashboard/UserDashboard";
import { MyProfile } from "../Container/screens/myprofile/MyProfile";

import IcoWebSolution from "../Container/admin/icoWeb/IcoWebSolution";
import IcoLab from "../Container/admin/icoLab/IcoLab";
import IcoLearn from "../Container/admin/icoLearn/IcoLearn";
import FAQ from "../Container/admin/faq/FaqList";
// import SubscriberList from "../Container/admin/subscribers/SubscriberList";
import NewsLetter from "../Container/admin/subscribers/NewsLetter";
import CoinPrice from "../Container/admin/coinprice/CoinPrice";
import CalenderList from "../Container/admin/calender/CalenderList";
import Clients from "../Container/admin/ecoSystem/Clients";
import ContactEnquiry from "../Container/admin/ContectUs/ContactEnquiry";
import WhiteListUser from "../Container/admin/whitelist/WhiteListUser";
import TransactionHistory from "../Container/admin/transactions/TransactionHistory";

// IDO Management 
import IdoWebSolution from "../Container/admin/idoManagement/idoWeb/IdoWebSolution";
import IdoLearn from "../Container/admin/idoManagement/idoLearn/IdoLearn";
import IdoLab from "../Container/admin/idoManagement/idoLab/IdoLab";
import IdoCalenderList from "../Container/admin/idoManagement/calender/IdoCalenderList";
import Deposite from "../Container/screens/deposite/Deposite";
import UserWhitePaper from '../Container/screens/whitepaper/WhitePaper';
import ReferralSystem from "../Container/admin/ReferralSystem/ReferralSystem";
import { ReferralLogs } from "../Container/screens/ReferralLogs/ReferralLogs";
import ApproveCoins from "../Container/admin/approveCoins/approveCoins";

const history = createBrowserHistory();
export default function Routes() {
  const login = useSelector((state) => {
    return state.authSaga.islogin;
  });
  const isLoginUser = useSelector((state) => {
    return state.authSaga.isloginUser;
  });
  return (
    <>
      <BrowserRouter history={history}>
        <Routess>
          <Route exact path="/admin" element={<Navigate to="/admin/login" replace={true} />} />
          <Route path="admin/login" element={<AdminLogin />} />
          <Route path="admin/forgotpassword" element={<AdminForgotPassword />} />
          <Route path="admin/resetpassword/:key" element={<ResetPassword />} />
          {login ? (
            <>
              <Route exact path="/admin" element={<AdminSideBar />}>
                <Route path="roadmap" element={<RoadMap />} />
                <Route path="referral-system" element={<ReferralSystem />} />
                <Route path={"approve-coins"} element={<ApproveCoins />}></Route>
                <Route path="whitepaper" element={<WhitePaper />} />
                <Route path={`dashboard`} element={<Dashboard />} />
                <Route path={`securityandprivacy`} element={<SecurityPrivacy />} />
                <Route path={`contactus`} element={<ContactUs />} />
                <Route path={`websitesetting`} element={<WebsiteSetting />} />
                <Route path={`ecovision`} element={<Vision />} />
                <Route path={`aboutus`} element={<AdminAboutUs />} />
                <Route path="users" element={<UserList />} />
                <Route path="teammembers" element={<TeamMember />} />
                <Route path="newsmanagement" element={<Newsmanagement />} />
                
                <Route path="icowebsolution" element={<IcoWebSolution />} />
                <Route path="icolab" element={<IcoLab />} />
                <Route path="icolearn" element={<IcoLearn />} />
                <Route path="calender" element={<CalenderList />} />

                {/* IDO Management */}
                <Route path="idowebsolution" element={<IdoWebSolution />} />
                <Route path="idolearn" element={<IdoLearn />} />
                <Route path="idolab" element={<IdoLab />} />
                <Route path="idoCalender" element={<IdoCalenderList />} />

                <Route path="faq" element={<FAQ />} />
                <Route path="subscribers" element={<NewsLetter />} />
                <Route path="coinprice" element={<CoinPrice />} />
                <Route path={`ourclients`} element={<Clients />} />
                <Route path={`contactEnquiry`} element={<ContactEnquiry />} />
                <Route path={`termsandcondition`} element={<TermsAndCondition />} />
                                
                <Route path="transactionHistory" element={<TransactionHistory />} />
                <Route path="whitelistuser" element={<WhiteListUser />} />
              </Route>
            </>
          ) : (
            <Route
              exact
              path="/admin"
              element={<Navigate to="/admin/login" replace={true} />}
            />
          )}
          {/* <Route path="*" element={<Navigate to="/admin/login" replace={true} />} /> */}

        {/* User Routes */}
          {isLoginUser ? (
            <>
              <Route path={"dashboard"} element={<UserDashboard />}></Route>
              <Route path={"myprofile"} element={<MyProfile />}></Route>
              <Route path={"changepassword"} element={<ChangePassword />}></Route>
            </>
          ) : (
            <Route
              exact
              path="*"
              element={<Navigate to="/login" replace={true} />}
            />
          )}

          <Route path={"/"} element={<Home />}></Route>
          <Route path={"about"} element={<About />}></Route>
          <Route path={"deposite"} element={<Deposite />}></Route>
          <Route path={"whitepaper"} element={<UserWhitePaper />}></Route>
          <Route path={"header"} element={<Header />}></Route>
          <Route path={"footer"} element={<Footer />}></Route>
          <Route path={"contact"} element={<Contact />}></Route>
          <Route path={"register"} element={<Register />}></Route>
          <Route path={"refer/:referer"} element={<Register />}></Route>
          <Route path={"login"} element={<Login />}></Route>
          <Route path={"forgotpassword"} element={<Forgotpassword />}></Route>
          <Route path={"resetpassword/:key"} element={<Reset />}></Route>
          <Route path={"coinlogs"} element={<CoinLogs />}></Route>
          <Route path={"referral-logs"} element={<ReferralLogs />}></Route>
          <Route path={"teamsandconditions"} element={<UserTermsandConditions />}></Route>
          <Route path={"privacypolicy"} element={<Privacypolicy />}></Route>
        </Routess>
      </BrowserRouter>
    </>
  );
}
