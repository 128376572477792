import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Button, Typography } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import { Grid, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import AuthSagaActions from "../../../redux/authsaga/action";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "@mui/material/Modal";
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Stack from '@mui/material/Stack';
import Swal from "sweetalert2";

const { getUserSaga, addUserSaga, updateUserSaga, removeUserSaga, getWhiteListedUser } = AuthSagaActions;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  maxHeight: "70vh",
  bgcolor: "background.paper",
  overflow: "auto",
  boxShadow: 24,
  p: 4,
};


const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));


const UserList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userList = useSelector((state) => {
    return state.authSaga.Users;
  });
  const whiteListUserList = useSelector((state) => {
    return state.authSaga.whitelistUser;
  });

  const token = useSelector((state) => {
    return state.authSaga.idToken;
  });

  const [page, setPage] = useState(0);
  const [users, setUserlist] = useState([]);
  const [whiteList, setWhiteList] = useState([]);
  const [rows, Setrows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [count, Setcount] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [mobile, setMobile] = useState("");
  const [country, setCountry] = useState("");
  const [password, setPassword] = useState("");
  const [Editid, Seteditid] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userStatus , setUserStatus] = useState(false);
  const [active , setActive] = useState(false)
  const [errorObject,setErrorObject] = useState({});
 
  const columns = [
    { id: "firstName", label: "First Name" },
    { id: "lastName", label: "Last Name" },
    { id: "email", label: "Email", minWidth: 100 },
    { id: "userName", label: "UserName", minWidth: 100 },
    { id: "mobileNo", label: "Mobile No", minWidth: 100 },
    { id: "country", label: "Country", minWidth: 100 },
    { id: "whiteListed", label: "White listed", minWidth: 100 },
    {
      id: "b1",
      label: "Status",
      minWidth: 20,
      align: "right",
    },
    {
      id: "b2",
      label: "Action",
      minWidth: 20,
      align: "right",
    },
    {
      id: "b3",
      label: "",
      minWidth: 20,
      align: "right",
    },
  ];

  function createData(firstName, lastName, email, userName, mobileNo, country, whiteListed, id,i) {

    let b1 = (
      <Switch
        aria-label= {`Size switch demo ${id}`}
        type="text"
        name={id}
        checked={active[i].isActive}
      />
    );

    let b2 = (
        <EditIcon 
          sx={{cursor:'pointer'}}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            Seteditid(id);
            Setcount((pre) => pre + 1);
          }}
        />
    );

    let b3 = (
      <DeleteIcon sx={{cursor:'pointer'}} onClick={() => deletehandler(id)} />
    );
    return {firstName, lastName, email, userName, mobileNo, country, whiteListed, b1, b2, b3};
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const deletehandler = async (id) => {
    Swal.fire({
      title: "",
      text: "Are you sure you want to delete user ?",
      icon: "",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let urlencoded = new URLSearchParams();
        urlencoded.append("id", id);
        dispatch(removeUserSaga(urlencoded, navigate, token));
      }
    });
  };

const validationFun=(firstName,lastName,userName,mobile,country,email,password,confirmPassword)=>{
  let errors = {};
  let regEmail = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if(firstName == "" || firstName.replace( /\s\s+/g, ' ')?.length<3){
      errors.firstName = "Enter at least 3 characters in First Name";
  } else if(firstName.replace( /\s\s+/g, ' ') == "" || firstName.replace( /\s\s+/g, ' ')?.length>25){
      errors.firstName = "Enter not more then 25 characters in First Name";
  }

  if(lastName == "" || lastName.replace( /\s\s+/g, ' ')?.length<3){
      errors.lastName = "Enter at least 3 characters in Last Name";
  } else if(lastName.replace( /\s\s+/g, ' ') == "" || lastName.replace( /\s\s+/g, ' ')?.length>25){
      errors.lastName = "Enter not more then 25 characters in Last Name";
  } 

  if(userName == "" || userName.replace( /\s\s+/g, ' ')?.length<3){
      errors.userName = "Enter at least 3 characters in User Name";
  } else if(userName.replace( /\s\s+/g, ' ') == "" || userName.replace( /\s\s+/g, ' ')?.length>25){
      errors.userName = "Enter not more then 25 characters in User Name";
  } 

  if(mobile == "" || mobile.length!==10){
      errors.mobile = "Enter at least 10 numbers in Mobile Number";
  }

  if(country == "" || country.replace( /\s\s+/g, ' ')?.length<3){
      errors.country = "Enter at least 3 characters in Country";
  } else if(country.replace( /\s\s+/g, ' ') == "" || country.replace( /\s\s+/g, ' ')?.length>25){
      errors.country = "Enter not more then 25 characters in Country";
  } 

  if(email.trim()==="" || !regEmail.test(email)){
      errors.email = "Enter valid Email";
  }
 
  if(Editid === "") 
  {
    if(password==="" || password.length<8){
      errors.password = "Enter at least 8 characters in Password";
    } else if(password==="" || password.length>20){
        errors.password = "Enter not more then 20 characters in Password";
    }
  
    if(confirmPassword !== password){
        errors.confirmPassword = "Password and Confirm Password are not matched";
    } 
  }

  return errors;
}

const handleSubmit = (e) => {
  if (Object.keys(validationFun(firstName,lastName,userName,mobile,country,email,password,confirmPassword)).length === 0) {
      handelSubmit();
      setErrorObject({});
  } else {
      setErrorObject(validationFun(firstName,lastName,userName,mobile,country,email,password,confirmPassword))
  }
  //console.log(validationFun(firstName,lastName,userName,mobile,country,email,password,confirmPassword));
};


const handelSubmit = () => {
    const apidata = new URLSearchParams();
    apidata.append("id", Editid);
    apidata.append("email", email);
    apidata.append("firstName", firstName);
    apidata.append("lastName", lastName);
    apidata.append("userName", userName);
    apidata.append("mobileNo", mobile);
    apidata.append("country", country);
    apidata.append("password", password);
    apidata.append("isActive", userStatus === true ? '1' : '0');

    Editid===""?dispatch(addUserSaga(apidata, navigate, token)):dispatch(updateUserSaga(apidata, navigate, token))
    //setAnchorEl(null);
  };

  useEffect(() => {
    dispatch(getUserSaga(navigate));
    setUserlist(userList);

    dispatch(getWhiteListedUser(navigate,token));
    setWhiteList(whiteListUserList);
  }, [])

  useEffect(() => {
    setUserlist(userList);
    setWhiteList(whiteListUserList);
    setActive(userList?.map((data) =>{return {id:data._id,isActive:data.isActive==="0"?false:true}}));
  }, [userList, whiteListUserList]); 

  useEffect(() => {
    users &&
      users[0] &&
      users.map((element, index) => {
        if (element._id === Editid) {
            setFirstName(element.firstName);
            setLastName(element.lastName);
            setEmail(element.email);
            setUserName(element.userName);
            setMobile(element.mobileNo);
            setCountry(element.country);
            setUserStatus(element.isActive == '1' ? true : false)
            setErrorObject({});
        }
      });
    if (Editid === "") {
        setFirstName("");
        setLastName("");
        setEmail("");
        setUserName("");
        setMobile("");
        setCountry("");
        setErrorObject({});
    }
  }, [Editid, count]);

  useEffect(() => {
    if(users){
      let data = [];
      users?.map((element, i) => {
        let matchedWhiteList =  whiteList.length&&whiteList.filter(single=>single?.email===element?.email)?.length;
        const whiteListStatus = matchedWhiteList?'Yes':'No';
        data = [
          ...data,
          createData(element.firstName, element.lastName, element.email, element.userName, element.mobileNo, element.country, whiteListStatus, element._id, i),
        ];
      });
      Setrows(data);
    }
  }, [users]);


  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Box className="page-content-main" sx={{mt:3}}>
      <ToastContainer />
      <Box className='main-title-box'>
        <Typography className='main-title' variant="h4">User Management</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        <FormControl sx={{ m: 1, minWidth: 220, maxHeight: 50 }}></FormControl>
        <Button
          variant="contained"
          sx={{
            maxHeight: 50, marginTop: 2, backgroundColor: '#271e09',
            "&:hover": { backgroundColor: "#f49d04", opacity: [0.9, 0.8, 0.7] },
          }}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            Seteditid('');
            Setcount(count + 1);
          }}
        >
          Add New
        </Button>
      </Box>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer className="table-main-box">
          <Table stickyHeader aria-label="sticky table" className="table-main">
            <TableHead className="table-head">
              <TableRow className="table-row">
                {columns?.map((column) => (
                  <TableCell className="table-cell"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <h4 style={{ margin: 0 }}>{column.label}</h4>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody className="table-body" sx={{ padding: '18px' }}>

              {rows.length ? rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row) => {
                return (
                  <TableRow className="table-row"
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}

                  >
                    {columns?.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} className="table-cell">
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              }) : "No Data"}
            </TableBody>
          </Table>
        </TableContainer>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid item xs={6}>
              <Box
                className="validation"
                sx={{
                  margin: "auto",
                  width: 400,
                  maxWidth: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}>
                  <TextField
                    sx={{width: "400px"}}
                    fullWidth
                    required
                    error={errorObject['firstName'] !== undefined}
                    label="First Name"
                    id="firstName"
                    value={firstName}
                    name="firstName"
                    onChange={(e) => setFirstName(e.target.value)}
                    helperText={errorObject?.firstName}
                  />
                  <TextField
                    sx={{ marginTop: 2, width: "400px" }}
                    fullWidth
                    required
                    error={errorObject['lastName'] !== undefined}
                    label="Last Name"
                    id="lastName"
                    name="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    helperText={errorObject?.lastName}
                  />

                  <TextField
                    sx={{ marginTop: 2, width: "400px" }}
                    fullWidth
                    required
                    error={errorObject['email'] !== undefined}
                    label="Email"
                    id="email"
                    value={email}
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    helperText={errorObject?.email}
                  /> 

                  <TextField
                    sx={{ marginTop: 2, width: "400px" }}
                    required
                    error={errorObject['userName'] !== undefined}
                    fullWidth
                    label="User Name"
                    id="userName"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    helperText={errorObject?.userName}
                  />
             
                  <TextField
                    sx={{ marginTop: 2, width: "400px" }}
                    required
                    fullWidth
                    error={errorObject['mobile'] !== undefined}
                    label="Mobile No"
                    id="mobile"
                    name="mobile"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    helperText={errorObject?.mobile}
                    onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                  />
                  <TextField
                    sx={{ marginTop: 2, width: "400px" }}
                    fullWidth
                    required
                    error={errorObject['country'] !== undefined}
                    label="Country"
                    id="country"
                    name="country"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    helperText={errorObject?.country}
                  />

                  {
                    Editid === '' ? 
                    <>
                      <TextField
                        sx={{ marginTop: 2, width: "400px" }}
                        fullWidth
                        required
                        error={errorObject['password'] !== undefined}
                        label="Password"
                        id="password"
                        type="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        helperText={errorObject?.password}
                      />
                      <TextField
                        sx={{ marginTop: 2, width: "400px" }}
                        fullWidth
                        required
                        error={errorObject['confirmPassword'] !== undefined}
                        label="Confirm Password"
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        helperText={errorObject?.confirmPassword}
                      />
                    </>
                    : ""
                  }
                  
                  <Box sx={{ marginTop: 2, width: "400px" }}>
                    <Typography>Change User Status</Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>Inactive</Typography>
                      <AntSwitch 
                        checked={userStatus}
                        onClick={() => setUserStatus(!userStatus)}
                        inputProps={{ 'aria-label': 'ant design' }}
                        />
                      <Typography>Active</Typography>
                    </Stack>
                  </Box> 
                  <br />
                  <Box className="save-btn-box">
                    <Button className="save-btn"
                      variant="contained"
                      onClick={() => handleSubmit()}
                    >
                      Submit
                    </Button>
                    <Button
                      className="save-btn"
                      color="error"
                      variant="contained"
                      sx={{ml:5}}
                      onClick={() => setAnchorEl(null)}
                    >
                      Cancel
                    </Button>
                  </Box>
              </Box>
            </Grid>
          </Box>
        </Modal>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default UserList;
