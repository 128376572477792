import React, { useState, useEffect } from "react";
import { TextField, Typography, Button, Container } from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import "./AboutUs.css";
import { useSelector, useDispatch } from "react-redux";
import AuthSagaActions from "../../../redux/authsaga/action";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import FormHelperText from "@mui/material/FormHelperText";
import "react-toastify/dist/ReactToastify.css";

const Input = styled("input")({
  display: "none",
});

const { addeditaboutdetailsaga, getaboutdetailsaga } = AuthSagaActions;

const AboutUs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useSelector((state) => {
    return state.authSaga.idToken;
  });
  const contectdetail = useSelector((state) => {
    return state.authSaga.aboutDetails;
  });

  const [SortTitle, Setsorttitle] = useState("");
  const [sortDescription, setSortDescription] = useState("");
  const [Editid, Seteditid] = useState("");
  const [Description, Setdescription] = useState("");
  const [Url, Seturl] = useState([]);
  const [Images, Setimages] = useState();
  const [titleError, SetTitleError] = useState("");
  const [shortDescError, SetShortDescError] = useState("");
  const [descriptionError, SetDescriptionError] = useState("");

  const handelSubmit = () => {

    if(SortTitle.trim() ==="" || SortTitle.trim()?.length<3){
      SetTitleError("Enter at least 3 characters in title")
      return false;
    }
    else if(SortTitle.trim() ==="" || SortTitle.trim()?.length>100){
      SetTitleError("Enter not more then 100 characters in title")
      return false;
    } else {
      SetTitleError("");
    }

    if(sortDescription.trim() ==="" || sortDescription.trim()?.length<3){
      SetShortDescError("Enter at least 3 characters in Sort description")
      return false;
    }
    else {
      SetShortDescError("");
    }

    if(Description.trim() ==="" || Description.trim()?.length<3){
      SetDescriptionError("Enter at least 3 characters in description")
      return false;
    }
    else {
      SetDescriptionError("");
    }
    
    if (SortTitle !== "") {
      const apidata = new FormData();
      apidata.append("sorttitle", SortTitle);
      apidata.append("description", Description);
      apidata.append("sortdescription", sortDescription);
      let imagedata = Images && Images[0] ? [...Images] : [""];
      apidata.append("images", imagedata[0]);

      if (Editid !== "") {
        apidata.append("id", Editid);
        apidata.append("fileurl", Url);
      }
      dispatch(addeditaboutdetailsaga(apidata, navigate, token));
    }
  };

  useEffect(() => {
    dispatch(getaboutdetailsaga(navigate));
  }, []);

  useEffect(() => {
    Setsorttitle(contectdetail?.sortTitle ? contectdetail.sortTitle : "");
    setSortDescription(
      contectdetail?.sortDescription ? contectdetail.sortDescription : ""
    );
    Seteditid(contectdetail?._id ? contectdetail._id : "");
    Seturl(contectdetail?.images ? contectdetail.images : "");
    Setdescription(contectdetail?.description ? contectdetail.description : "");
  }, [contectdetail]);

  const imagehendeler = (e) => {
    let temp = [];
    [...e].map((element) => {
      temp = [...temp, window.URL.createObjectURL(element)];
      Seturl(temp);
    });
  };
  return (
    <Box className="page-content-main" sx={{marginBottom:5, marginTop:3}}>
    <Container maxWidth="false" className="editor-main">
      <ToastContainer />
      <Typography variant="h4" align="center">
        About Us
      </Typography>

      <Box sx={{ marginTop: 5, display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", justifyContent: "space-around" }}>
          {Url && Url !== "" ? (
            <img
              height="130px"
              width="130px"
              src={Url}
              alt=''
            ></img>
          ) : (
            ""
          )}
        </Box>

        <label
          htmlFor="contained-button-file"
          style={{ margin: "auto", marginTop: "15px" }}
        >
          <Input
            accept="files/*"
            id="contained-button-file"
            multiple
            type="file"
            onChange={(e) => {
              Setimages(e.target.files);
              imagehendeler(e.target.files);
            }}
          />
          <Button
            variant="contained"
            sx={{ width: "180px", bgcolor: "#f5ae19" }}
            component="span"
          >
            Upload Image
          </Button>
        </label>
       
        {/* <Typography variant="h6" sx={{margin:"15px 0px"}}>Sort Description:-</Typography> */}
        <FormHelperText sx={{ fontSize: 14, color:'black', mt:5 }} >Sort Description *</FormHelperText>
        <Box  className="ckeditor-main">
        <CKEditor
          editor={ClassicEditor}
          data={sortDescription}
          onChange={(event, editor) => {
            const data = editor.getData();
            setSortDescription(data);
          }}
        />
        <FormHelperText sx={{ color: "red" }}>{shortDescError}</FormHelperText>
        </Box>

        <br />
        <TextField
          sx={{ marginTop: 2 }}
          fullWidth
          required
          label="Main Title"
          id="SortTitle"
          value={SortTitle}
          onChange={(e) => Setsorttitle(e.target.value)}
        />
        <FormHelperText sx={{ color: "red" }}>{titleError}</FormHelperText>

        <br />
        <FormHelperText sx={{ fontSize: 14, color:'black' }} >Description *</FormHelperText>
        <Box className="ckeditor-main">
        <CKEditor
          editor={ClassicEditor}
          data={Description}
          onChange={(event, editor) => {
            const data = editor.getData();
            Setdescription(data);
          }}
        />
        <FormHelperText sx={{ color: "red" }}>{descriptionError}</FormHelperText>
        </Box>
        <br />
        <Box className="save-btn-box">
          <Button className="save-btn"
            variant="contained"
            onClick={() => {
              handelSubmit();
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Container>
    </Box>
  );
};

export default AboutUs;
