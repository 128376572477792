import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Button } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Grid, TextField, Typography } from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useSelector, useDispatch } from "react-redux";
import AuthSagaActions from "../../../../redux/authsaga/action"
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Parser from "html-react-parser";
import "react-toastify/dist/ReactToastify.css";
import Modal from "@mui/material/Modal";
const { addIdoLab, getIdoLabListSaga, updateIdoLab, removeIdoLab } = AuthSagaActions;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  maxHeight: "70vh",
  bgcolor: "background.paper",
  overflow: "auto",
  boxShadow: 24,
  p: 4,
};
const IdoLab = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getIdolab = useSelector((state) => {
    return state.authSaga.idoLabFeatures;
  });

  const token = useSelector((state) => {
    return state.authSaga.idToken;
  });

  const [page, setPage] = useState(0);
  const [IdoList, setIdolist] = useState([]);
  const [rows, Setrows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [Title, Settitle] = useState("");
  const [Editid, Seteditid] = useState("");
  const [count, Setcount] = useState(0);
  const [Description, Setdescription] = useState("Description...");
  const [TitleError, Settitleerror] = useState("");
  const [DescriptionError, Setdescriptionerror] = useState("");
 
  const columns = [
    { id: "Title", label: "Title", minWidth: 170 },
    {
      id: "Description",
      label: "Description",
      minWidth: 250,
    },
    {
      id: "b2",
      label: "Action",
      minWidth: 50,
      align: "right",
    },
    {
      id: "b3",
      label: "",
      minWidth: 50,
      align: "right",
    },
  ];

  function createData(Title, Description, id) {
    // let b1=<Button variant="contained">View</Button>
    let b2 = (
      <EditIcon 
          sx={{cursor:'pointer'}}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            Seteditid(id);
            Setcount((pre) => pre + 1);
          }}
        />
    );
    let b3 = (
      <DeleteIcon sx={{cursor:'pointer'}} onClick={() => deletehandler(id)} />
    );
    return { Title, Description, b2, b3 };
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    IdoList &&
      IdoList[0] &&
      IdoList.map((element, index) => {
        if (element?._id === Editid) {
          Settitle(element?.title);
          Setdescription(element?.description);
        }
      });
    if (Editid === "") {
      Settitle("");
      Setdescription("");
    }
  }, [Editid, count]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const deletehandler = (id) => {
    const apidata = new URLSearchParams();
    apidata.append("id", id);
    dispatch(removeIdoLab(apidata, navigate, token));
  };

  const handelSubmit = () => {
    if (Title === "") {
      Settitleerror("Please enter news title");
    } else {
      Settitleerror("");
    }
    if (Description === "") {
      Setdescriptionerror("Please enter Description");
    } else {
      Setdescriptionerror("");
    }

    if (Title !== "" && Description !== "") {
      const apidata = new URLSearchParams();
      apidata.append("title", Title);
      apidata.append("description", Description);
      if (Editid !== "") {
        apidata.append("id", Editid);
      }
      Editid===""?dispatch(addIdoLab(apidata, navigate, token)):dispatch(updateIdoLab(apidata, navigate, token))
      setAnchorEl(null);
    }
  };

  useEffect(() => {
    dispatch(getIdoLabListSaga(navigate));
    setIdolist(getIdolab);
  }, []);

  useEffect(() => {
    setIdolist(getIdolab);
  }, [getIdolab]);
  
  useEffect(() => {
    let data = [];
    IdoList &&
      IdoList[0] &&
      IdoList.map((element, index) => {
        data = [
          ...data,
          createData(
            element?.title,
            <td>{Parser(element?.description)}</td>,
            element?._id
          ),
        ];
       
      });
      Setrows(data);
  }, [IdoList]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box className="page-content-main">
      <ToastContainer />
      <Box className='main-title-box'>
        <Typography className='main-title' variant="h4">IDO Lab Features</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        <FormControl sx={{ m: 1, minWidth: 220, maxHeight: 50 }}></FormControl>
        <Button
          variant="contained"
          sx={{ maxHeight: 50, marginTop: 2 ,   backgroundColor:'#271e09',
          "&:hover": { backgroundColor: "#f49d04", opacity: [0.9, 0.8, 0.7] },}}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            Seteditid("");
            Setcount(count + 1);
          }}
        >
          Add New
        </Button>
      </Box>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer className="table-main-box">
          <Table stickyHeader aria-label="sticky table" className="table-main">
            <TableHead className="table-head">
              <TableRow className="table-row">
                {columns.map((column) => (
                  <TableCell className="table-cell"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <h4 style={{ margin: 0 }}>{column.label}</h4>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="table-body">
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow className="table-row"
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align} className="table-cell">
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid item xs={6}>
              <Box
                sx={{
                  margin: "auto",
                  width: 500,
                  maxWidth: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
             
                <TextField
                  sx={{ marginTop: 2 }}
                  required
                  fullWidth
                  label="Title"
                  id="Title"
                  value={Title}
                  onChange={(e) => Settitle(e.target.value)}
                />
                <FormHelperText sx={{ color: "red" }}>
                  {TitleError}
                </FormHelperText>

                <CKEditor
                  editor={ClassicEditor}
                  data={Description}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    Setdescription(data);
                  }}
                />
                <FormHelperText sx={{ color: "red" }}>
                  {DescriptionError}
                </FormHelperText>

                <Box className="save-btn-box">
                  <Button className="save-btn"
                    variant="contained"
                    onClick={() => handelSubmit()}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Box>
         
        </Modal>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default IdoLab;
