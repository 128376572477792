import logo from "../assets/images/png/logo.png"
// import footerBackground from '../../../assets/images/footerBackground.png';
import fb from '../assets/images/png/fb.png';
import insta from '../assets/images/png/insta.png';
import twitter from '../assets/images/png/twitter.png';
import linkedIn from '../assets/images/png/linkedIn.png';
import yt from '../assets/images/png/yt.png';
import discord from '../assets/images/png/discord.png';
import telegram from '../assets/images/png/telegram.png';
import footerlogo from '../assets/images/png/footerlogo.png';
import footerbg from '../assets/images/png/footerbg.png';
import client from '../assets/images/png/client1.png';
import openicone from '../assets/images/png/openicone.png';
import client2 from '../assets/images/png/client2.png';
import meet1 from '../assets/images/png/meet1.png';
import meet2 from '../assets/images/png/meet2.png';
import meet3 from '../assets/images/png/meet3.png';
import meet4 from '../assets/images/png/meet4.png';
import trendingup from '../assets/images/png/trendingup.png';
import ico from '../assets/images/png/ico.png';
import ico1 from '../assets/images/png/ico1.png';
import frequently from '../assets/images/png/frequently.png';
import roademap from '../assets/images/png/roademap.png';
import roadmap2 from '../assets/images/png/roadmap2.png';
import roadmap3 from '../assets/images/png/roadmap3.png';
import img1 from '../assets/images/png/img1.png';
import aboutimg from '../assets/images/png/aboutimg.png';
import iochome from '../assets/images/png/iochome.png';
import notificationimg from '../assets/images/png/notificationimg.png';
import binancecoin from "../assets/images/png/binance-coin.png";
import Apnacast from '../assets/images/png/ApnaCast.png'
import arrowupdown from "../assets/images/png/arrow-up-down.png";
import yourLogoHere from '../assets/images/png/your-logo-here.png'

export default {
    logo,
    // footerBackground,
    fb,
    insta,
    twitter,
    linkedIn,
    yt,
    discord,
    telegram,
    footerlogo,
    footerbg,
    client,
    openicone,
    client2,
    meet1,
    meet2,
    meet3,
    meet4,
    trendingup,
    ico,
    ico1,
    frequently,
    roademap,
    roadmap2,
    roadmap3,
    img1,
    aboutimg,
    iochome,
    notificationimg,
    binancecoin,
    Apnacast,
    arrowupdown,
    yourLogoHere
}