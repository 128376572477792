import React, { useState, useEffect } from 'react'
import Index from "../Index";
import AuthSagaActions from "../../../redux/authsaga/action";
import { useSelector, useDispatch } from "react-redux";
import OwlCarousel from 'react-owl-carousel3';





const { getTeamMembersaga } = AuthSagaActions;

const OurTeam = () => {



  const [responsive2, setresponsive2] = useState({
    responsive2: {
      0: {
        items: 1,
        margin: 0,
      },
      768: {
        items: 2,
        margin: 0,
      },
      1024: {
        items: 2,
      },
      1200: {
        items: 4,
      }
    }
  })


  const dispatch = useDispatch();
  const [memberList, SetMemberList] = useState([]);

  const teamMemberList = useSelector((state) => {
    return state.authSaga.TeamMemberList;
  });

  useEffect(() => {
    dispatch(getTeamMembersaga());
    SetMemberList(teamMemberList);
  }, []);

  useEffect(() => {
    SetMemberList(teamMemberList);
  }, [teamMemberList]);




  return (
    <>
      <Index.Box className="meet-our-team-section">
        <Index.Typography className="main-titel pt-55 res-pt-30">
          Meet Our Team
        </Index.Typography>
      </Index.Box>

      <Index.Box className="meet-our-team-section-img">

        <Index.Box className="container">
          <Index.Box className="meet-box">
            <OwlCarousel
              className="owl-theme owl-theme-new "

              responsive={responsive2.responsive2}
              loop={true}
                autoplay={true}
                autoplayTimeout={5000}
                margin={10}
                nav={false}
                dots={true}
            // stagePadding={0}
            >
              {
                memberList.length
                  ? memberList.map((member, index) => (
                    <div class="item">
                      <Index.Box className="res-mr-30 meet-cards" key={index}>
                        <Index.Box className="img">
                          <Index.Box className="meet-img">
                            <Index.Box className="meet-inner">
                              <Index.Box className="team-text-box">
                                <Index.Box className={`${(index === 0 || index === 2 || index === 4 || index === 6) ? 'team-border' : 'team-border-next'}`}></Index.Box>
                                <img
                                  className="meet-img-inner"
                                  src={
                                    member?.images && member?.images !== ""
                                      ? member?.images
                                      : "https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                                  }
                                  alt=""
                                ></img>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box className="text">
                          <Index.Box className="meet-text-box">
                            <Index.Box className="team-name team-name-big">
                              <Index.Typography className="">
                                {member?.name}
                              </Index.Typography>
                            </Index.Box>

                            <Index.Box className="team-position">
                              <Index.Typography className="">
                                {member?.designation}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </div>
                  ))
                  : ""
              }

            </OwlCarousel>

          </Index.Box>
        </Index.Box>

      </Index.Box>
    </>
  )
}

export default OurTeam