import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { ToastContainer } from "react-toastify";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuthSagaActions from "../../../redux/authsaga/action";

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const { addEditIcoWeb, getIcoWeb } = AuthSagaActions;
const IcoWebSolution = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const icoList = useSelector((state) => {
    //console.log('Bharat == ', state.authSaga);
    return state.authSaga.IcoWebSolutionList;
  });
  
  const [icoWebList, SetIcoWeblist] = useState([]);
  const [Title, Settitle] = useState("");
  const [currentPrice, SetCurrentPrice] = useState("");
  const [Editid, Seteditid] = useState("");
  const [Description, Setdescription] = useState(""); 
  const [startDate, SetStartDate] = useState(dayjs(new Date()));
  const [errorObject,setErrorObject] = useState({});

  useEffect(() => {
    icoWebList &&
      icoWebList[0] &&
      icoWebList.map((element, index) => {
        if (element._id || element._id === Editid) {
          setErrorObject({});
          Settitle(element?.title);
          SetCurrentPrice(element?.currentPrice);
          Setdescription(element?.description);
          SetStartDate(element?.date);
        }
      });
  }, [icoWebList]);

  const validationFun=(Title,Description)=>{
    let errors = {};
    if(Title == "" || Title.replace( /\s\s+/g, ' ')?.length<3){
        errors.Title = "Enter at least 3 characters in Title";
    } else if(Title.replace( /\s\s+/g, ' ') == "" || Title.replace( /\s\s+/g, ' ')?.length>100){
        errors.Title = "Enter not more then 100 characters in Title";
    }
    if(Description == "" || Description.replace( /\s\s+/g, ' ')?.length<3){
        errors.Description = "Enter at least 3 characters in Description";
    }
    return errors;
  }
  
  const handleValidation = (e) => {
    if (Object.keys(validationFun(Title,Description)).length === 0) {
        handleSubmit();
        setErrorObject({});
    } else {
        setErrorObject(validationFun(Title,Description))
    }
   console.log(validationFun(Title,Description));
  };


  const handleSubmit = () => {
    const apidata = new URLSearchParams();
    apidata.append("title", Title);
    apidata.append("currentPrice", currentPrice);
    apidata.append("date", startDate);
    apidata.append("description", Description);
    if (Editid !== "") {
      apidata.append("id", Editid);
    }
    dispatch(addEditIcoWeb(apidata, navigate));
  };

  useEffect(() => {
    dispatch(getIcoWeb(navigate));
    SetIcoWeblist(icoList);
  }, []);

  useEffect(() => {
    SetIcoWeblist(icoList);
  }, [icoList]);

  useEffect(() => {
    icoWebList &&
      icoWebList[0] &&
      icoWebList.map((element, index) => {
        Seteditid(element._id);
      });
  }, [icoWebList]);

  return (
    <>
      <Box className="page-content-main">
        <ToastContainer />
        <Box sx={{ w: 100 }}>
          <Box className='main-title-box'>
            <Typography className='main-title' variant="h4">ICO Website Solution</Typography>
          </Box>
          <Box>
            <Box sx={{ mt: 3 }}>
              <Grid container>
                <Grid item xs={12} sm={12} md={3} lg={9}>
                  <Box className="ckeditor-main validation">
                    <TextField
                      sx={{ marginTop: 2 }}
                      error={errorObject['Title'] !== undefined}
                      required
                      fullWidth
                      label="Title"
                      id="Title"
                      value={Title}
                      onChange={(e) => {
                        Settitle(e.target.value);
                      }}
                      helperText={errorObject?.Title}
                    />
                   
                    <TextField
                      sx={{ marginTop: 2 }}
                      required
                      fullWidth
                      label="Current Price"
                      id="currentPrice"
                      value={currentPrice}
                      onChange={(e) => {
                        SetCurrentPrice(e.target.value);
                      }}
                    />
                    <br /><br /> 

                    <Box className="Datetime-main">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                              renderInput={(props) => <TextField {...props} />}
                              label="Start Date"
                              value={startDate}
                              onChange={(newValue) => {
                                  SetStartDate(newValue);
                              }}
                          />
                      </LocalizationProvider>
                    </Box>
                    <br />
                    
                    <Box className="ckeditor-main">
                      <CKEditor
                        editor={ClassicEditor}
                        data={Description}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          Setdescription(data);
                        }}
                        className="ckeditor-tag"
                      />
                    </Box>
                    <FormHelperText sx={{ color: "#d32f2f" }}>{errorObject?.Description}</FormHelperText>
                    <br />
                    <Box className="save-btn-box">
                      <Button className="save-btn"
                        variant="contained"
                        onClick={() => {
                          handleValidation();
                        }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default IcoWebSolution;

