import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { ToastContainer } from "react-toastify";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuthSagaActions from "../../../redux/authsaga/action";

const { addEditRemoveVision, getVisions } = AuthSagaActions;


const Vision = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const newslist = useSelector((state) => {
    return state.authSaga.Vision;
  });
  // const logging = useSelector((state) => {
  //   return state.authSaga.loading;
  // });
  // const token = useSelector((state) => {
  //   return state.authSaga.idToken;
  // });

  const [Url, Seturl] = React.useState([]);
  const [NewsList, Setnewslist] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [Title, Settitle] = useState("");
  const [Editid, Seteditid] = useState("");
  const [Images, Setimages] = useState();
  const [Description, Setdescription] = useState("Description...");
  const [TitleError, Settitleerror] = useState("");
  const [DescriptionError, Setdescriptionerror] = useState("");


  const imagehendeler = (e) => {
    console.log("enter1");
    let urllink = window.URL.createObjectURL(e[0]);

    Seturl(urllink);
  };



  useEffect(() => {
    NewsList &&
      NewsList[0] &&
      NewsList.map((element, index) => {
        if (element._id || element._id === Editid) {
          Settitle(element.Title);
          Setdescription(element.Description);
          Seturl(element.Images);
          Setimages([]);
        }
      });
  }, [NewsList]);



  const handelSubmit = () => {
    if (Title === "") {
      Settitleerror("Please enter  title");
    } else {
      Settitleerror("");
    }

    if (Description === "") {
      Setdescriptionerror("Please enter Description");
    } else {
      Setdescriptionerror("");
    }

    if (Title !== "" && Description !== "") {
      const apidata = new FormData();
      apidata.append("Title", Title);
      apidata.append("Description", Description);
      apidata.append("images", Images);
      if (Editid !== "") {
        apidata.append("editid", Editid);
        apidata.append("fileurl", Url);
      }
      dispatch(addEditRemoveVision(apidata, navigate));
      setAnchorEl(null);
    }
  };

  useEffect(() => {
    dispatch(getVisions(navigate));
    Setnewslist(newslist);
  }, []);
  useEffect(() => {
    // dispatch(getVisions(navigate));
    Setnewslist(newslist);
  }, [newslist]);

  useEffect(() => {
    NewsList &&
      NewsList[0] &&
      NewsList.map((element, index) => {
        Seteditid(element._id);
      });
  }, [NewsList]);

  return (
    <>
      <Box className="page-content-main">
        <ToastContainer />
        <Box sx={{ w: 100 }}>
          <Box className='main-title-box'>
            <Typography className='main-title' variant="h4">Eco System Aim & Vision</Typography>
          </Box>
          <Box>
            <Box sx={{ mt: 3 }}>
              <Grid container>
                <Grid item xs={12} sm={12}  md={3} lg={3}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      
                    }}
                  >
                    <img
                      className="res-img"
                      height={100}
                      width={100}
                      style={{
                        // borderRadius: "50%",
                        margin: "auto",
                        marginTop: "10px",
                      }}
                      alt=""
                      src={Url && Url !== "" ? Url : Images}
                    ></img>
                    <label
                      htmlFor="contained1-button-file"
                      style={{ margin: "auto", marginTop: "15px" }}
                    >
                      <Button
                        variant="contained"
                        component="label"
                        sx={{ width: "150px" ,   backgroundColor:'#271e09',
                        "&:hover": { backgroundColor: "#f49d04", opacity: [0.9, 0.8, 0.7] },}}
                      >
                        Upload Image
                        <input
                          type="file"
                          hidden
                          onChange={(e) => {
                            console.log(e.target.files[0], "Aaaaaaaaaaa");
                            Setimages(e.target.files[0]);
                            imagehendeler(e.target.files);
                          }}
                        />
                      </Button>
                    </label>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12}  md={3} lg={9}>
                  <Box className="ckeditor-main">
                    {/* <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>{OldImages.map((element, index) => (<Box sx={{ display: 'flex' }}><img height={80} width={80} style={{ margin: "auto" }} src={element}></img><CloseIcon sx={{ position: "relative", right: "20px" }} onClick={() => { removeurl(element) }} /></Box>))}{Url.map((element, index) => (<img height={80} width={80} style={{ margin: "auto" }} src={element}></img>))}</Box> */}
                    <TextField
                      sx={{ marginTop: 2 }}
                      required
                      fullWidth
                      label="Title"
                      id="Title"
                      value={Title}
                      onChange={(e) => {
                        console.log(e.target.value, "title");
                        Settitle(e.target.value);
                      }}
                    />
                    <FormHelperText sx={{ color: "red", ml: 1, mb: 1 }}>
                      {TitleError}
                    </FormHelperText>
                    <Box className="ckeditor-main">
                      <CKEditor
                        editor={ClassicEditor}
                        data={Description}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          console.log(data);
                          Setdescription(data);
                        }}
                        className="ckeditor-tag"
                      />
                    </Box>
                    <FormHelperText sx={{ color: "red" }}>
                      {DescriptionError}
                    </FormHelperText>
                    <Box className="save-btn-box">
                      <Button className="save-btn"
                        variant="contained"
                        onClick={() => {
                          handelSubmit();
                        }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Vision;
