import React, { useState, useEffect } from 'react'
import Index from "../Index";
import AuthSagaActions from "../../../redux/authsaga/action";
import { useSelector, useDispatch } from "react-redux";
import Parser from "html-react-parser";
const { getFaqsaga} = AuthSagaActions;

const Faq = () => {
  const dispatch = useDispatch();
  const [faqList, SetFaqlist] = useState([]);

    const getFaqs = useSelector((state) => {
      return state.authSaga.faqsList;
    });

    useEffect(() => {
        dispatch(getFaqsaga());
        SetFaqlist(getFaqs);
    }, []);

    useEffect(() => {
        SetFaqlist(getFaqs);
    }, [getFaqs]);

  return (
    <>
        <Index.Box className="frequently-section">
        <Index.Box className="container">
          <Index.Box className="ico-calender frequently-text">
            <Index.Typography className="pt-55" variant="p" component="p">
              Frequently Asked ?
            </Index.Typography>
          </Index.Box>
          <Index.Box className="icolab-features-inner icolab-features-inner-box pb-73">
            <Index.Typography className="" variant="p" component="p">
              Lorem ipsum dolor sit amet, conset sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            </Index.Typography>
          </Index.Box>

          <Index.Box className="frequently-box-main">
            <Index.List className="set-clom">
              {
                faqList.length
                ? faqList.map((faq, index) => (
                  <Index.ListItem key={index}>
                    <Index.Box className="fre-box-main">
                      <Index.Box className="">
                        <Index.Box className="fre-box-inner">
                          <Index.Box className="dot-border">
                            <Index.Box className="dot-box"></Index.Box>
                          </Index.Box>

                          <Index.Box className="dot-text">
                            <Index.Typography
                              className=""
                              variant="p"
                              component="p"
                            >
                              {faq?.question}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="text-box">
                          <Index.Box className="text-border"></Index.Box>
                          <Index.Box className="text-box-inner pl-16 pt-17">
                            <Index.Typography
                              className=""
                              variant="p"
                            >
                              {Parser(faq?.answer)}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="fre-box-subinner"></Index.Box>
                    </Index.Box>
                  </Index.ListItem>
                ))
                : ""
              }

            </Index.List>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  )
}

export default Faq