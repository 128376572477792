import { all, takeEvery, put, fork } from "redux-saga/effects"; // You can import select
import actions from "./action";
import {
  loginApiHandler,
  forgotApiHandler,
  resetApiHandler,
  updateUserStatusApiHandler,
  addWhiteListApiHandler,
  getWhiteListUserApiHandler,
  userLoginApiHandler,
  userContactEnquiryApiHandler,
  userRegisterApiHandler,
  userChangePasswordApiHandler,
  userForgotPasswordApiHandler,
  userResetPasswordApiHandler,
  getUserProfileApiHandler,
  editUserProfileApiHandler,
  addEditAboutApiHandler,
  getRoadApiHandler,
  addRoadApiHandler,
  updateRoadApiHandler,
  deleteNewsApiHandler,
  getNewsApiHandler,
  addNewsApiHandler,
  updateNewsApiHandler,
  sendNewslatterApiHandler,
  deleteTeamMemberApiHandler,
  getIcoLabApiHandler,
  addIcoLabApiHandler,
  updateIcoLabApiHandler,
  deleteIcoLabApiHandler,
  getTeamMemberApiHandler,
  addTeamMemberApiHandler,
  updateTeamMemberApiHandler,
  editTermsApiHandler,
  getAboutApiHandler,
  editSecurityApiHandler,
  getCmsApiHandler,
  getContect_Detail_ApiHandler,
  getCoinPrice_ApiHandler,
  addCoinPriceApiHandler,
  editcontectApiHandler,
  getwebsiteApiHandler,
  addEditWebsiteApiHandler,
  addClientApiHandler,
  updateClientApiHandler,
  deleteClientApiHandler,
  getClientApiHandler,
  addEditRemoveVisionApiHandler,
  getVisionApiHandler,
  addEditIcoLearnApiHandler,
  getIcoLearnApiHandler,
  addFaqApiHandler,
  updateFaqApiHandler,
  getFaqApiHandler,
  deleteFaqApiHandler,
  getContactEnquiryApiHandler,
  deleteContactEnquiryApiHandler,
  getSubscriberApiHandler,
  deleteSubscriberApiHandler,
  addSubscribeApiHandler,
  getCalenderApiHandler,
  addCalenderApiHandler,
  updateCalenderApiHandler,
  deleteCalenderApiHandler,
  addEditIcoWebApiHandler,
  getIcoWebApiHandler,
  getUserApiHandler,
  addUserApiHandler,
  updateUserApiHandler,
  deleteUserApiHandler,
  getIdoWebApiHandler,
  addEditIdoWebApiHandler,
  getIdoLearnApiHandler,
  addEditIdoLearnApiHandler,
  getIdoLabApiHandler,
  addIdoLabApiHandler,
  updateIdoLabApiHandler,
  deleteIdoLabApiHandler,
  getIdoCalenderApiHandler,
  addIdoCalenderApiHandler,
  updateIdoCalenderApiHandler,
  deleteIdoCalenderApiHandler,
  removeWhiteListApiHandler,
  createTranscationApiHandler,
  getTranscationApiHandler,
  getTransactionChartApiHandler,
  getTransactionHistoryApiHandler,
  getPriceApiHandler,
  getReferralUserApiHandler,
  getApproveReferralUserApiHandler,
  getReferralTransactionApiHandler,
  updateTransactionStatusApiHandler,
  getReferralUserIDApiHandler,
  createRedeemApiHandler,
  getNotificationApiHandler
} from "../../services/index";

export function* loginRequest() {
  yield takeEvery("LOGIN_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield loginApiHandler(payload.payload);

    if (response.data && response.data[0] && response.status == 1) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        data: response.data[0],
        token: response.data[0].auth,
        profile: "Profile",
      });
      payload.navigate("/admin/dashboard");
      // yield put(push("/dashbord"))
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* logOutRequest() {
  yield takeEvery("LOGOUT_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    localStorage.clear();
    yield put({
      type: actions.LOGOUT,
    });
    payload.navigate("/admin/login");
    // yield put(push("/dashbord"))
  });
}

export function* forgotPasswordRequest() {
  yield takeEvery("FORGOT_PASSWORD_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield forgotApiHandler(payload.payload);

    if (response.data && response.status == 1) {
      yield put({
        type: actions.FORGOT_PASSWORD_SUCCESS,
      });
      // yield put(push("/dashbord"))
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* resetPasswordRequest() {
  yield takeEvery("RESET_PASSWORD_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield resetApiHandler(payload.payload);

    if (response.data && response.status == 1) {
      yield put({
        type: actions.RESET_PASSWORD_SUCCESS,
        message: response.message,
      });
      // yield put(push("/dashbord"))
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* userLoginRequest() {
  yield takeEvery("USER_LOGIN_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield userLoginApiHandler(payload.payload);
    console.log(response, 77);
    if (response.data && response.status == '1') {
      yield put({
        type: actions.USER_LOGIN_SUCCESS,
        data: response.data[0],
        token: response.data[0].auth,
        profile: "Profile",
      });
      payload.navigate("/dashboard");
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* userLogOutRequest() {
  yield takeEvery("USER_LOGOUT_REQUEST", function* (payload) {
    yield put({
      type: actions.USER_LOGOUT_SUCCESS,
    });
    localStorage.clear();
  });
}

export function* userContactEnquiryRequest() {
  yield takeEvery("User_ContactEnquiry_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield userContactEnquiryApiHandler(payload.payload);
    if (
      response.data &&
      response.status == 1 &&
      response.message !== "Some error occure!"
    ) {
      yield put({
        type: actions.User_ContactEnquiry_SUCCESS,
        data: response.data[0],
        profile: "Profile",
      });
    } else if (response.message === "Some error occure!") {
      console.log("done");
      yield put({ type: actions.ERROR, message: response.message });
    } else {
      yield put({
        type: actions.ERROR,
        message: response.message || response.msg,
      });
    }
  });
}

export function* userRegisterRequest() {
  yield takeEvery("USER_REGISTER_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield userRegisterApiHandler(payload.payload);
    if (
      response.data &&
      response.status == 1 &&
      response.message !== "Some error occure!"
    ) {
      yield put({
        type: actions.USER_REGISTER_SUCCESS,
        data: response.data[0],
        profile: "Profile",
      });
      setTimeout(() => {
        payload.navigate("/login")
      }, 3000);
    } else if (response.message === "Some error occure!") {
      yield put({ type: actions.ERROR, message: response.message });
    } else {
      yield put({
        type: actions.ERROR,
        message: response.message || response.msg,
      });
    }
  });
}


export function* userChangePasswordRequest() {
  yield takeEvery("USER_ChangePassword_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield userChangePasswordApiHandler(payload.payload);
    //console.log('abcd=', response);
    if (
      response.data &&
      response.status == 1 &&
      response.message !== "Some error occure!"
    ) {
      yield put({
        type: actions.USER_ChangePassword_SUCCESS,
        data: response.data[0],
        profile: "Profile",
      });
      
      setTimeout(() => {
        payload.navigate("/dashboard")
      }, 3000);

    } else if (response.message === "Some error occure!") {
      yield put({ type: actions.ERROR, message: response.message });
    } else {
      yield put({
        type: actions.ERROR,
        message: response.message || response.msg,
      });
    }
  });
}

export function* userForgotPasswordRequest() {
  yield takeEvery("USER_ForgotPassword_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield userForgotPasswordApiHandler(payload.payload);
    //console.log('abcd=', response);
    if (
      response.data &&
      response.status == 1 &&
      response.message !== "Some error occure!"
    ) {
      yield put({
        type: actions.USER_ForgotPassword_SUCCESS,
        data: response.data[0],
        profile: "Profile",
      });
      //payload.navigate("/reset")
    } else if (response.message === "Some error occure!") {
      yield put({ type: actions.ERROR, message: response.message });
    } else {
      yield put({
        type: actions.ERROR,
        message: response.message || response.msg,
      });
    }
  });
}

export function* userResetPasswordRequest() {
  yield takeEvery("USER_ResetPassword_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield userResetPasswordApiHandler(payload.payload);
    //console.log('abcd=', response);
    if (
      response.data &&
      response.status == 1 &&
      response.message !== "Some error occure!"
    ) {
      yield put({
        type: actions.USER_ResetPassword_SUCCESS,
        data: response.data[0],
        profile: "Profile",
      });

    setTimeout(() => {
      payload.navigate("/login")
    }, 3000);

    } else if (response.message === "Some error occure!") {
      yield put({ type: actions.ERROR, message: response.message });
    } else {
      yield put({
        type: actions.ERROR,
        message: response.message || response.msg,
      });
    }
  });
}


export function* getUserProfileRequest() {
  yield takeEvery("GET_UserProfile_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getUserProfileApiHandler(payload.token);
    if (response.data && response.status === 200) {
      //console.log('user profile data is =', response, 33);
      yield put({
        type: actions.GET_UserProfile_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* editUserProfileRequest() {
  yield takeEvery("Edit_UserProfile_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield editUserProfileApiHandler(
      payload.payload,
      payload.token
    );
    //console.log('abcd =', response, 88)
    if (response.data && response.data[0] && response.status === 200) {
      yield put({
        type: actions.Edit_UserProfile_SUCCESS,
        data: response.data[0],
        profile: "Profile",
      });
      //payload.navigate("/myprofile")
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* addAboutDetailRequest() {
  yield takeEvery("About_Edit_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield addEditAboutApiHandler(
      payload.payload,
      payload.token
    );

    if (response.data && response.data[0] && response.status === "1") {
      yield put({
        type: actions.About_Edit_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
      payload.navigate("/admin/aboutus");
      // yield put(push("/dashbord"))
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* getAboutDetailRequest() {
  yield takeEvery("GET_About_REQUEST", function* (payload) {
    // alert(payload.token)
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getAboutApiHandler();
    if (response.status === "1" && response.data) {
      yield put({
        type: actions.GET_About_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
      // payload.navigate("/admin/aboutus")
      // yield put(push("/admin/teammembers"))
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* getCMSRequest() {
  yield takeEvery("GET_CMS_REQUEST", function* (payload) {
    // alert(payload.token)
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getCmsApiHandler(payload.token);
    if (response.data[0]) {
      yield put({
        type: actions.GET_CMS_SUCCESS,
        data: response.data[0],
        profile: "Profile",
      });
      // payload.navigate("/contectus")
      // yield put(push("/dashbord"))
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* addCoinPriceRequest() {
  yield takeEvery("Add_CoinPrice_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield addCoinPriceApiHandler(
      payload.payload,
      payload.token
    );

    if (response.data && response.data[0] && response.status === "1") {
      yield put({
        type: actions.Add_CoinPrice_SUCCESS,
        data: response.data[0],
        profile: "Profile",
      });
      payload.navigate("/admin/coinprice");
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* editContectRequest() {
  yield takeEvery("Edit_Contect_Detail_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield editcontectApiHandler(
      payload.payload,
      payload.token
    );

    if (response.data && response.data[0] && response.status === "1") {
      yield put({
        type: actions.Edit_Contect_Detail_SUCCESS,
        data: response.data[0],
        profile: "Profile",
      });
      payload.navigate("/admin/contactus");
      // yield put(push("/dashbord"))
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* getContectRequest() {
  yield takeEvery("GET_Contect_Detail_REQUEST", function* (payload) {
    // alert(payload.token)
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getContect_Detail_ApiHandler(payload.token);
    if (response.data[0]) {
      yield put({
        type: actions.GET_Contect_Detail_SUCCESS,
        data: response.data[0],
        profile: "Profile",
      });
      // payload.navigate("/contectus")
      // yield put(push("/dashbord"))
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}


export function* getCoinPriceRequest() {
  yield takeEvery("GET_CoinPrice_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getCoinPrice_ApiHandler(payload.token);
    if (response.data[0]) {
      yield put({
        type: actions.GET_CoinPrice_SUCCESS,
        data: response.data[0],
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* editSecurityRequest() {
  yield takeEvery("Security_Edit_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield editSecurityApiHandler(
      payload.payload,
      payload.token
    );

    if (response.data && response.data[0] && response.status === "1") {
      yield put({
        type: actions.Security_Edit_SUCCESS,
        data: response.data[0],
        profile: "Profile",
      });
      payload.navigate("/admin/securityandprivacy");
      // yield put(push("/dashbord"))
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* editTermsRequest() {
  yield takeEvery("Terms_Edit_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield editTermsApiHandler(payload.payload, payload.token);

    if (response.data && response.data[0] && response.status === "1") {
      yield put({
        type: actions.Terms_Edit_SUCCESS,
        data: response.data[0],
        profile: "Profile",
      });
      payload.navigate("/admin/termsandcondition");
      // yield put(push("/dashbord"))
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* addTeamMemberRequest() {
  yield takeEvery("Add_Team_Member_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield addTeamMemberApiHandler(
      payload.payload,
      payload.token
    );
    console.log(payload, 180);
    if (response.data && response.status == 1) {
      yield put({
        type: actions.Add_Team_Member_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
      // payload.navigate("/admin/teammembers")
      // yield put(push("/dashbord"))
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* updateTeamMemberRequest() {
  yield takeEvery("Update_Team_Member_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield updateTeamMemberApiHandler(
      payload.payload,
      payload.token
    );

    if (response.data && response.status == 1) {
      yield put({
        type: actions.Update_Team_Member_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
      // payload.navigate("/admin/teammembers")
      // yield put(push("/dashbord"))
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* getTeamMemberRequest() {
  yield takeEvery("GET_Team_Members_REQUEST", function* (payload) {
    // alert(payload.token)
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getTeamMemberApiHandler();
    if (response.status === "1" && response.data) {
      yield put({
        type: actions.GET_Team_Members_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
      // payload.navigate("/admin/teammembers")
      // yield put(push("/admin/teammembers"))
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* removeTeamMemberRequest() {
  yield takeEvery("Delete_Team_Members_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield deleteTeamMemberApiHandler(
      payload.payload,
      payload.token
    );

    if (response.data && response.status == 1) {
      yield put({
        type: actions.Delete_Team_Members_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
      // payload.navigate("/admin/teammembers")
      // yield put(push("/dashbord"))
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* addNewsRequest() {
  yield takeEvery("Add_News_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield addNewsApiHandler(payload.payload, payload.token);

    if (response.data && response.status == 1) {
      yield put({
        type: actions.Update_News_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
      payload.navigate("/admin/newsmanagement");
      // yield put(push("/dashbord"))
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* updateNewsRequest() {
  yield takeEvery("Update_News_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield updateNewsApiHandler(payload.payload, payload.token);

    if (response.data && response.status == 1) {
      yield put({
        type: actions.Update_News_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
      payload.navigate("/admin/newsmanagement");
      // yield put(push("/dashbord"))
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* sendNewsRequest() {
  yield takeEvery("Send_News_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield sendNewslatterApiHandler(payload.payload, payload.token);

    if (response.data && response.status == 1) {
      yield put({
        type: actions.Send_News_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
      payload.navigate("/admin/subscribers");
      // yield put(push("/dashbord"))
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* getNewsRequest() {
  yield takeEvery("GET_NEWS_REQUEST", function* (payload) {
    // alert(payload.token)
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getNewsApiHandler(payload.token);
    if (response.data && response.status == 1) {
      yield put({
        type: actions.GET_NEWS_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
      // payload.navigate("/contectus")
      // yield put(push("/admin/newsmanagement"))
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* removeNewsRequest() {
  yield takeEvery("Delete_NEWS_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield deleteNewsApiHandler(payload.payload, payload.token);

    if (response.data && response.status == 1) {
      yield put({
        type: actions.Delete_News_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
      payload.navigate("/admin/newsmanagement");
      // yield put(push("/dashbord"))
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* getIcoLabRequest() {
  yield takeEvery("GET_ICOLab_REQUEST", function* (payload) {
    // alert(payload.token)
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getIcoLabApiHandler();
    if (response.status === "1" && response.data) {
      yield put({
        type: actions.GET_ICOLab_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* addIcoLabRequest() {
  yield takeEvery("Add_ICOLab_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield addIcoLabApiHandler(payload.payload, payload.token);
    if (response.data && response.status == 1) {
      yield put({
        type: actions.Add_ICOLab_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
      //payload.navigate("/admin/icolearn")
      // yield put(push("/dashbord"))
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* updateIcoLabRequest() {
  yield takeEvery("Update_ICOLab_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield updateIcoLabApiHandler(
      payload.payload,
      payload.token
    );
    if (response.data && response.status == 1) {
      yield put({
        type: actions.Update_ICOLab_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* removeIcoLabRequest() {
  yield takeEvery("Delete_ICOLab_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield deleteIcoLabApiHandler(
      payload.payload,
      payload.token
    );
    if (response.data && response.status == 1) {
      yield put({
        type: actions.Delete_ICOLab_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
      //payload.navigate("/admin/icolab")
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* addRoadmapRequest() {
  yield takeEvery("Add_Roadmap_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield addRoadApiHandler(payload.payload, payload.token);

    if (response.data && response.status == 1) {
      yield put({
        type: actions.Add_Roadmap_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
      // payload.navigate("/admin/nftclaimrequests")
      // yield put(push("/dashbord"))
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* updateRoadmapRequest() {
  yield takeEvery("Update_Roadmap_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield updateRoadApiHandler(payload.payload, payload.token);

    if (response.data && response.status == 1) {
      yield put({
        type: actions.Update_Roadmap_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
      // payload.navigate("/admin/nftclaimrequests")
      // yield put(push("/dashbord"))
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* deleteRoadmapRequest() {
  yield takeEvery("Delete_Roadmap_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield addRoadApiHandler(payload.payload, payload.token);

    if (response.data && response.status == 1) {
      yield put({
        type: actions.Delete_Roadmap_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
      // payload.navigate("/admin/nftclaimrequests")
      // yield put(push("/dashbord"))
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* getRoadRequest() {
  yield takeEvery("GET_Road_REQUEST", function* (payload) {
    // alert(payload.token)
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getRoadApiHandler();
    if (response.status === "1" && response.data) {
      yield put({
        type: actions.GET_Road_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
      // payload.navigate("/admin/userManagement")
      // yield put(push("/admin/teammembers"))
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* getWebsiteDataRequest() {
  yield takeEvery("GET_Website_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getwebsiteApiHandler();
    if (response.status === 1 && response.data) {
      yield put({
        type: actions.GET_Website_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: "response.message" });
    }
  });
}

export function* addEditWebsiteDataRequest() {
  yield takeEvery("Add_Edit_Website_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield addEditWebsiteApiHandler(payload.payload);
    if (response.status === 1 && response.data) {
      yield put({
        type: actions.Add_Edit_Website_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* addClientRequest() {
  yield takeEvery("Add_Client_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield addClientApiHandler(payload.payload);
    if (response.status === "1" && response.data) {
      yield put({
        type: actions.Add_Client_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* updateClientRequest() {
  yield takeEvery("Update_Client_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield updateClientApiHandler(payload.payload);
    if (response.status === "1" && response.data) {
      yield put({
        type: actions.Update_Client_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* deleteClientRequest() {
  yield takeEvery("Delete_Client_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield deleteClientApiHandler(payload.payload);
    if (response.status === "1" && response.data) {
      yield put({
        type: actions.Delete_Client_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* getClients() {
  yield takeEvery("GET_Client_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getClientApiHandler();
    if (response.status === "1" && response.data) {
      yield put({
        type: actions.GET_Client_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* addEditRemoveVisionRequest() {
  yield takeEvery("Add_Vision_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield addEditRemoveVisionApiHandler(payload.payload);
    if (response.status === "1" && response.data) {
      yield put({
        type: actions.Add_Vision_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* getVisionRequest() {
  yield takeEvery("GET_Vision_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getVisionApiHandler();
    if (response.status === "1" && response.data) {
      yield put({
        type: actions.GET_Vision_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* addEditIcoLearnRequest() {
  yield takeEvery("Add_ICOLearn_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield addEditIcoLearnApiHandler(payload.payload);
    if (response.status === "1" && response.data) {
      yield put({
        type: actions.Add_ICOLearn_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* getIcoLearnRequest() {
  yield takeEvery("GET_ICOLearn_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getIcoLearnApiHandler();
    if (response.status === "1" && response.data) {
      yield put({
        type: actions.GET_ICOLearn_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* addFaqRequest() {
  yield takeEvery("Add_Faq_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield addFaqApiHandler(payload.payload, payload.token);
    if (response.data && response.status == 1) {
      yield put({
        type: actions.Add_Faq_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* updateFaqRequest() {
  yield takeEvery("Update_Faq_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield updateFaqApiHandler(payload.payload, payload.token);
    if (response.data && response.status == 1) {
      yield put({
        type: actions.Update_Faq_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* getFaqRequest() {
  yield takeEvery("GET_Faq_REQUEST", function* (payload) {
    // alert(payload.token)
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getFaqApiHandler();
    if (response.status === "1" && response.data) {
      yield put({
        type: actions.GET_Faq_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* removeFaqRequest() {
  yield takeEvery("Delete_Faq_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield deleteFaqApiHandler(payload.payload, payload.token);
    if (response.data && response.status == 1) {
      yield put({
        type: actions.Delete_Faq_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}



export function* getTransactionChartRequest() {
  yield takeEvery("GET_TransactionChart_REQUEST", function* (payload) {
    // alert(payload.token)
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getTransactionChartApiHandler(payload.token);
    if (response.status === "1" && response.data) {
      yield put({
        type: actions.GET_TransactionChart_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}




export function* getContactEnquiryRequest() {
  yield takeEvery("GET_ContactEnquiry_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getContactEnquiryApiHandler();
    if (response.status === "1" && response.data) {
      yield put({
        type: actions.GET_ContactEnquiry_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* removeContactEnquiryRequest() {
  yield takeEvery("Delete_ContactEnquiry_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield deleteContactEnquiryApiHandler(
      payload.payload,
      payload.token
    );
    if (response.data && response.status == 1) {
      yield put({
        type: actions.Delete_ContactEnquiry_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* getSubscriberRequest() {
  yield takeEvery("GET_Subscriber_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getSubscriberApiHandler();
    if (response.status === "1" && response.data) {
      yield put({
        type: actions.GET_Subscriber_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* removeSubscriberRequest() {
  yield takeEvery("Delete_Subscriber_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield deleteSubscriberApiHandler(
      payload.payload,
      payload.token
    );
    if (response.data && response.status == 1) {
      yield put({
        type: actions.Delete_Subscriber_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* addSubscribeRequest() {
  yield takeEvery("Add_Subscribe_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield addSubscribeApiHandler(payload.payload);
    if (response.data && response.status == 1) {
      yield put({
        type: actions.Add_Subscribe_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* getCalenderRequest() {
  yield takeEvery("GET_Calender_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getCalenderApiHandler();
    if (response.status === "1" && response.data) {
      yield put({
        type: actions.GET_Calender_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* addCalenderRequest() {
  yield takeEvery("Add_Calender_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield addCalenderApiHandler(
      payload.payload,
      payload.token
    );
    if (response.data && response.status == 1) {
      yield put({
        type: actions.Add_Calender_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}
export function* updateCalenderRequest() {
  yield takeEvery("Update_Calender_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield updateCalenderApiHandler(
      payload.payload,
      payload.token
    );
    if (response.data && response.status === "1") {
      yield put({
        type: actions.Update_Calender_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* deleteCalenderRequest() {
  yield takeEvery("Delete_Calender_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield deleteCalenderApiHandler(payload.payload);
    if (response.data && response.status == 1) {
      yield put({
        type: actions.Delete_Calender_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* addEditIcoWebRequest() {
  yield takeEvery("Add_ICOWeb_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield addEditIcoWebApiHandler(payload.payload);
    if (response.status === "1" && response.data) {
      yield put({
        type: actions.Add_ICOWeb_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* getIcoWebRequest() {
  yield takeEvery("GET_ICOWeb_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getIcoWebApiHandler();
    if (response.status === "1" && response.data) {
      yield put({
        type: actions.GET_ICOWeb_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* getTransactionHistoryRequest() {
  yield takeEvery("GET_TransactionHistory_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getTransactionHistoryApiHandler(payload.payload, payload.token);
    if (response.status == '1' && response.data) {
      yield put({
        type: actions.GET_TransactionHistory_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* getUserRequest() {
  yield takeEvery("GET_User_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getUserApiHandler();
    if (response.status === 200 && response.data) {
      yield put({
        type: actions.GET_User_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* removeUserRequest() {
  yield takeEvery("Delete_User_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield deleteUserApiHandler(payload.payload, payload.token);
    if (response.data && response.status === 200) {
      yield put({
        type: actions.Delete_User_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* updateUserStatusRequest() {
  yield takeEvery("Update_UserStatus_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield updateUserStatusApiHandler(
      payload.payload,
      payload.token
    );
    if (response.data && response.status === 200) {
      yield put({
        type: actions.Update_UserStatus_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* addUserRequest() {
  yield takeEvery("Add_User_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield addUserApiHandler(payload.payload, payload.token);
    if (response.data && response.status == "1") {
      yield put({
        type: actions.Add_User_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.msg });
    }

  });
}

export function* updateUserRequest() {
  yield takeEvery("Update_User_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield updateUserApiHandler(payload.payload, payload.token);
    if (response.data && response.status == 1) {
      yield put({
        type: actions.Update_User_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
      //payload.navigate("/admin/users")
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* addWhiteListedUserRequest() {
  yield takeEvery("Add_WhiteListUser_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield addWhiteListApiHandler(
      payload.payload,
      payload.token
    );
    if (response.data && response.status == "1") {
      yield put({
        type: actions.Add_WhiteListUser_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* getWhitelistedUserRequest() {
  yield takeEvery("GET_WhiteListUser_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getWhiteListUserApiHandler(payload.token);
    if (response.status === "1" && response.data) {
      yield put({
        type: actions.GET_WhiteListUser_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* RemoveWhiteListedUserRequest() {
  yield takeEvery("REMOVE_WhiteListUser_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield removeWhiteListApiHandler(
      payload.payload,
      payload.token
    );
    if (response.data && response.status == "1") {
      yield put({
        type: actions.REMOVE_WhiteListUser_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* getIdoWebRequest() {
  yield takeEvery("GET_IDOWeb_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getIdoWebApiHandler();
    if (response.status === "1" && response.data) {
      yield put({
        type: actions.GET_IDOWeb_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* addEditIdoWebRequest() {
  yield takeEvery("Add_IDOWeb_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield addEditIdoWebApiHandler(payload.payload);
    if (response.status === "1" && response.data) {
      yield put({
        type: actions.Add_IDOWeb_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* getIdoLearnRequest() {
  yield takeEvery("GET_IDOLearn_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getIdoLearnApiHandler();
    if (response.status === "1" && response.data) {
      yield put({
        type: actions.GET_IDOLearn_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* addEditIdoLearnRequest() {
  yield takeEvery("Add_IDOLearn_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield addEditIdoLearnApiHandler(payload.payload);
    if (response.status === "1" && response.data) {
      yield put({
        type: actions.Add_IDOLearn_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* getIdoLabRequest() {
  yield takeEvery("GET_IDOLab_REQUEST", function* (payload) {
    // alert(payload.token)
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getIdoLabApiHandler();
    if (response.status === "1" && response.data) {
      yield put({
        type: actions.GET_IDOLab_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* addIdoLabRequest() {
  yield takeEvery("Add_IDOLab_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield addIdoLabApiHandler(payload.payload, payload.token);
    if (response.data && response.status == 1) {
      yield put({
        type: actions.Add_IDOLab_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* updateIdoLabRequest() {
  yield takeEvery("Update_IDOLab_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield updateIdoLabApiHandler(
      payload.payload,
      payload.token
    );
    if (response.data && response.status == 1) {
      yield put({
        type: actions.Update_IDOLab_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* removeIdoLabRequest() {
  yield takeEvery("Delete_IDOLab_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield deleteIdoLabApiHandler(
      payload.payload,
      payload.token
    );
    if (response.data && response.status == 1) {
      yield put({
        type: actions.Delete_IDOLab_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* getIdoCalenderRequest() {
  yield takeEvery("GET_IDO_Calender_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getIdoCalenderApiHandler();
    if (response.status === "1" && response.data) {
      yield put({
        type: actions.GET_IDO_Calender_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* addIdoCalenderRequest() {
  yield takeEvery("Add_IDO_Calender_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield addIdoCalenderApiHandler(
      payload.payload,
      payload.token
    );
    if (response.data && response.status == 1) {
      yield put({
        type: actions.Add_IDO_Calender_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* updateIdoCalenderRequest() {
  yield takeEvery("Update_IDO_Calender_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield updateIdoCalenderApiHandler(
      payload.payload,
      payload.token
    );
    if (response.data && response.status === "1") {
      yield put({
        type: actions.Update_IDO_Calender_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* deleteIdoCalenderRequest() {
  yield takeEvery("Delete_IDO_Calender_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield deleteIdoCalenderApiHandler(payload.payload);
    if (response.data && response.status == 1) {
      yield put({
        type: actions.Delete_IDO_Calender_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* createTranscationSaga() {
  yield takeEvery("CREATE_TRANSCATION_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });

    console.log(payload, "payload")
    const response = yield createTranscationApiHandler(
      payload.payload,
      payload.token
    );
    console.log(response , "response")


    if (response.data && response.status == 1) {
      yield put({
        type: actions.CREATE_TRANSCATION_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* getTranscationRequest() {
  yield takeEvery("GET_TRANSCATION_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });

    console.log(payload, "payload")
    const response = yield getTranscationApiHandler(
      payload.token
    );
    console.log(response , "response")


    if (response.data && response.status == 1) {
      yield put({
        type: actions.GET_TRANSCATION_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* getPriceRequest() {
  yield takeEvery("GET_PRICE_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });

    console.log(payload, "payload")
    const response = yield getPriceApiHandler(
      payload.token
    );
    console.log(response , "response")


    if (response.data && response.status == 1) {
      yield put({
        type: actions.GET_PRICE_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* getReferralUserRequest() {
  yield takeEvery("GET_Referral_User_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });

    console.log(payload, "payload")
    const response = yield getReferralUserApiHandler(
      payload.token
    );
    console.log(response , "response --admin ---referral list")


    if (response.data && response.status == 1) {
      yield put({
        type: actions.GET_Referral_User_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* getApproveReferralUserRequest() {
  yield takeEvery("GET_Approve_User_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });

    console.log(payload, "payload")
    const response = yield getApproveReferralUserApiHandler(
      payload.token
    );
    console.log(response , "response-redeem transaction")


    if (response.data && response.status == 1) {
      yield put({
        type: actions.GET_Approve_User_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* getReferralTransactionRequest() {
  yield takeEvery("GET_REFERRAL_TRANSACTION_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });

    console.log(payload, "payload")
    const response = yield getReferralTransactionApiHandler(
      payload.token
    );
    console.log(response , "response-user transaction")


    if (response.data && response.status == 1) {
      yield put({
        type: actions.GET_REFERRAL_TRANSACTION_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* updateWithdrwalStatusRequest() {
  yield takeEvery("UPDATE_WITHDRWAL_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });

    console.log(payload, "payload")
    const response = yield updateTransactionStatusApiHandler(
      payload.payload,
      payload.token
    );
    console.log(response , "response-update status")


    if (response.data && response.status === 200) {
      yield put({
        type: actions.UPDATE_WITHDRWAL_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
      payload.navigate("/admin/approve-coins");
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* getRedeemUserTransactionRequest() {
  yield takeEvery("REDEEM_USER_TRANSACTION_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });

    console.log(payload, "payload")
    const response = yield getReferralUserIDApiHandler(
      payload.token
    );
    console.log(response , "response-user transaction")


    if (response.data && response.status == 1) {
      yield put({
        type: actions.REDEEM_USER_TRANSACTION_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* createWithdrawalRequest() {
  yield takeEvery("CREATE_REDEEM_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });

    console.log(payload, "payload")
    const response = yield createRedeemApiHandler(
      payload.payload,
      payload.token
    );
    console.log(response , "response-update status")


    if (response.data && response.status === 200) {
      yield put({
        type: actions.CREATE_REDEEM_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* getNotificationRequest() {
  yield takeEvery("GET_NOTIFICATION_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });

    console.log(payload, "payload")
    const response = yield getNotificationApiHandler(
      payload.token
    );
    console.log(response , "response-user transaction")


    if (response.data && response.status == 1) {
      yield put({
        type: actions.GET_NOTIFICATION_SUCCESS,
        data: response.data,
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}


export default function* authSagas() {
  yield all([
    fork(loginRequest),
    fork(forgotPasswordRequest),
    fork(resetPasswordRequest),
    fork(updateUserStatusRequest),
    fork(userRegisterRequest),
    fork(userChangePasswordRequest),
    fork(userForgotPasswordRequest),
    fork(userResetPasswordRequest),
    fork(getUserProfileRequest),
    fork(editUserProfileRequest),
    fork(userContactEnquiryRequest),
    fork(userLoginRequest),
    fork(userLogOutRequest),
    fork(getRoadRequest),
    fork(deleteRoadmapRequest),
    fork(updateRoadmapRequest),
    fork(addRoadmapRequest),
    fork(getNewsRequest),
    fork(removeNewsRequest),
    fork(addNewsRequest),
    fork(updateNewsRequest),
    fork(sendNewsRequest),
    fork(getIcoLabRequest),
    fork(addIcoLabRequest),
    fork(removeIcoLabRequest),
    fork(updateIcoLabRequest),
    fork(removeTeamMemberRequest),
    fork(getTeamMemberRequest),
    fork(addTeamMemberRequest),
    fork(updateTeamMemberRequest),
    fork(logOutRequest),
    fork(addAboutDetailRequest),
    fork(getAboutDetailRequest),
    fork(getCMSRequest),
    fork(getContectRequest),
    fork(getCoinPriceRequest),
    fork(addCoinPriceRequest),
    fork(editContectRequest),
    fork(editSecurityRequest),
    fork(editTermsRequest),
    fork(getWebsiteDataRequest),
    fork(addEditWebsiteDataRequest),
    fork(addClientRequest),
    fork(updateClientRequest),
    fork(deleteClientRequest),
    fork(getClients),
    fork(addEditRemoveVisionRequest),
    fork(getVisionRequest),
    fork(addEditIcoLearnRequest),
    fork(getIcoLearnRequest),
    fork(addFaqRequest),
    fork(updateFaqRequest),
    fork(getFaqRequest),
    fork(removeFaqRequest),
    fork(getContactEnquiryRequest),
    fork(removeContactEnquiryRequest),
    fork(getSubscriberRequest),
    fork(removeSubscriberRequest),
    fork(addSubscribeRequest),
    fork(getCalenderRequest),
    fork(addCalenderRequest),
    fork(updateCalenderRequest),
    fork(deleteCalenderRequest),
    fork(addEditIcoWebRequest),
    fork(getIcoWebRequest),
    fork(getUserRequest),
    fork(addUserRequest),
    fork(updateUserRequest),
    fork(removeUserRequest),
    fork(getIdoWebRequest),
    fork(addEditIdoWebRequest),
    fork(getIdoLearnRequest),
    fork(addEditIdoLearnRequest),
    fork(getIdoLabRequest),
    fork(addIdoLabRequest),
    fork(updateIdoLabRequest),
    fork(removeIdoLabRequest),
    fork(getIdoCalenderRequest),
    fork(addIdoCalenderRequest),
    fork(updateIdoCalenderRequest),
    fork(deleteIdoCalenderRequest),
    fork(addWhiteListedUserRequest),
    fork(getWhitelistedUserRequest),
    fork(RemoveWhiteListedUserRequest),
    fork(createTranscationSaga),
    fork(getTranscationRequest),
    fork(getTransactionChartRequest),
    fork(getTransactionHistoryRequest),
    fork(getPriceRequest),
    fork(getReferralUserRequest),
    fork(getApproveReferralUserRequest),
    fork(getReferralTransactionRequest),
    fork(updateWithdrwalStatusRequest),
    fork(getRedeemUserTransactionRequest),
    fork(createWithdrawalRequest),
    fork(getNotificationRequest),
  ]);
}
