import React, { useState, useEffect } from "react";
import Index from "../Index";
import AuthSagaActions from "../../../redux/authsaga/action";
import { useSelector, useDispatch } from "react-redux";
import Parser from "html-react-parser";
import OwlCarousel from "react-owl-carousel3";
const { getClients } = AuthSagaActions;

const Testimonials = () => {
  const [responsive2, setresponsive2] = useState({
    responsive2: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 2,
      },
      1200: {
        items: 2,
      },
    },
  });
  const dispatch = useDispatch();
  const [clientList, SetClientList] = useState([]);

  const clients = useSelector((state) => {
    return state.authSaga.Clients;
  });

  useEffect(() => {
    dispatch(getClients());
    SetClientList(clients);
  }, []);

  useEffect(() => {
    SetClientList(clients);
  }, [clients]);

  return (
    <>
      <Index.Box className="client-section">
        <Index.Box className="container">
          <Index.Box className="client-section-box">
            <Index.Typography className="main-titel">
              Our client say about us
            </Index.Typography>
            <Index.Box className="client-inner-text">
              <Index.Typography className="client-inner">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam
              </Index.Typography>
            </Index.Box>

            <Index.Box className="client-about-main">
              <OwlCarousel
                className="owl-theme owl-theme-new owl-theme2 "
                responsive={responsive2.responsive2}
                loop={true}
                autoplay={true}
                autoplayTimeout={5000}
                margin={10}
                nav={false}
                dots={true}
                // stagePadding={0}
              >
                {clientList.length
                  ? clientList.map((client, index) => (
                      <Index.Box
                        key={index}
                        className="client-about mr-30 client-box res-client-box res-ipad-team-set"
                      >
                          <Index.Box className="demo">
                        <Index.Box className="mt-111">
                          <Index.Box className="client-about-img2">
                            <img
                              className="client-about-img"
                              src={
                                client?.images && client?.images !== ""
                                  ? client?.images
                                  : "https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                              }
                              alt=""
                            ></img>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box className="openicone-main openicone-main2">
                          <Index.Box className="">
                            <Index.Box className=" openicone">
                              <img
                                className="openicone-img"
                                src={Index.Png.openicone}
                                alt=""
                              ></img>
                            </Index.Box>
                            <Index.Box className="openicone-inner-text">
                              <Index.Typography className="openicone-text">
                                {client?.description
                                  ? Parser(client?.description)
                                  : ""}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="">
                              <Index.Typography className="about-name">
                                {client?.name}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="">
                              <Index.Typography className="about-position">
                                {client?.position}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    ))
                  : ""}
              </OwlCarousel>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default Testimonials;
