import axios from "axios";
const BASE_URL = 'http://35.177.56.74:3009';
// const BASE_URL = 'http://localhost:3009';

const setHeadersWithAccessToken = (token) => {
  //console.log(token, "darshit");
  axios.defaults.headers.post["auth"] = token;
};

const setHeadersWithAccessTokenPut = (token) => {
  //console.log(token, "darshit");
  axios.defaults.headers.put["Auth"] = token;
};
const setGetHeadersWithAccessToken = (token) => {
  //console.log(token, "darshit");
  axios.defaults.headers.get["auth"] = token;
};
const setHeadersWithContentType = () => {
  axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
};
const setHeadersWithContentType2 = () => {
  axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
};
const handleError = error => new Promise(() => { });

export const loginApiHandler = params => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/login`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const forgotApiHandler = params => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/forgotpassword`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const resetApiHandler = params => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/resetpassword`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const userLoginApiHandler = params => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/userlogin`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const userContactEnquiryApiHandler = params => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/contactus`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const userRegisterApiHandler = params => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/registeruser`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const userChangePasswordApiHandler = (params) => {
  setHeadersWithContentType();
  // setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/changepasswordUser`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const userForgotPasswordApiHandler = (params) => {
  setHeadersWithContentType();
  // setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/forgotpasswordUser`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const userResetPasswordApiHandler = (params) => {
  setHeadersWithContentType();
  // setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/resetpasswordUser`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const getUserProfileApiHandler = (token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/getLoginUser`)
    .then(e => e.data)
    .catch(handleError);
};

export const editUserProfileApiHandler = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessTokenPut(token);
  return axios
    .put(`${BASE_URL}/editUser`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const addEditAboutApiHandler = (params, token) => {
  setHeadersWithContentType2();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/addeditaboutus`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const getAboutApiHandler = () => {
  return axios.get(`${BASE_URL}/aboutusdetail`)
    .then(e => e.data)
    .catch(handleError);
};

export const getCmsApiHandler = (token) => {
  setGetHeadersWithAccessToken(token);
  return axios.get(`${BASE_URL}/getcmsdetail`)
    .then(e => e.data)
    .catch(handleError);
};

export const getContect_Detail_ApiHandler = (token) => {
  setGetHeadersWithAccessToken(token);
  return axios.get(`${BASE_URL}/getcontactdetail`)
    .then(e => e.data)
    .catch(handleError);
};

export const getCoinPrice_ApiHandler = (token) => {
  setGetHeadersWithAccessToken(token);
  return axios.get(`${BASE_URL}/getBNBPrice`)
    .then(e => e.data)
    .catch(handleError);
};

export const addCoinPriceApiHandler = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/addBNBPrice`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const editcontectApiHandler = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/addeditcontactdetail`, params)
    .then(e => e.data)
    .catch(handleError);
};


export const editSecurityApiHandler = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/editsecurity`, params)
    .then(e => e.data)
    .catch(handleError);
};


export const editTermsApiHandler = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/editterms`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const addTeamMemberApiHandler = (params, token) => {
  setHeadersWithContentType2();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/addeditteammember`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const updateTeamMemberApiHandler = (params, token) => {
  setHeadersWithContentType2();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/addeditteammember`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const getTeamMemberApiHandler = () => {
  return axios.get(`${BASE_URL}/getteammebers`)
    .then(e => e.data)
    .catch(handleError);
};

export const deleteTeamMemberApiHandler = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/removeteammember`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const addNewsApiHandler = (params, token) => {
  setHeadersWithContentType2();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/addeditnews`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const updateNewsApiHandler = (params, token) => {
  setHeadersWithContentType2();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/addeditnews`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const sendNewslatterApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/sendmail`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const getNewsApiHandler = () => {
  return axios.get(`${BASE_URL}/newslist`)
    .then(e => e.data)
    .catch(handleError);
};

export const deleteNewsApiHandler = (params, token) => {
  setHeadersWithContentType2();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/newsremove`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const getIcoLabApiHandler = () => {
  return axios.get(`${BASE_URL}/icoLabFeaturesList`)
    .then(e => e.data)
    .catch(handleError);
};

export const addIcoLabApiHandler = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/addEditIcoLabFeatures`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const updateIcoLabApiHandler = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/addEditIcoLabFeatures`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const deleteIcoLabApiHandler = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/removeicolabfeature`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const addRoadApiHandler = (params, token) => {
  setHeadersWithContentType2();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/addandremoveroadmap`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const updateRoadApiHandler = (params, token) => {
  setHeadersWithContentType2();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/addandremoveroadmap`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const deleteRoadApiHandler = (params, token) => {
  setHeadersWithContentType2();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/addandremoveroadmap`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const getRoadApiHandler = () => {
  return axios.get(`${BASE_URL}/getroadmap`)
    .then(e => e.data)
    .catch(handleError);
};

export const getwebsiteApiHandler = () => {
  return axios.get(`${BASE_URL}/getAllSetting`)
      .then(e => e.data)
      .catch(handleError);
}

export const addEditWebsiteApiHandler = (params) => {
  setHeadersWithContentType2();
  return axios.post(`${BASE_URL}/websiteSetting`, params)
  .then(e => e.data)
  .catch(handleError);
}

export const addClientApiHandler = (params) => {
  setHeadersWithContentType2();
  return axios.post(`${BASE_URL}/addeditourclient`, params)
  .then(e => e.data)
  .catch(handleError);
}

export const updateClientApiHandler = (params) => {
  setHeadersWithContentType2();
  return axios.post(`${BASE_URL}/addeditourclient`, params)
  .then(e => e.data)
  .catch(handleError);
}

export const deleteClientApiHandler = (params) => {
  setHeadersWithContentType();
  return axios.post(`${BASE_URL}/removeourclient`, params)
  .then(e => e.data)
  .catch(handleError);
}

export const getClientApiHandler = () => {
  setHeadersWithContentType();
  return axios.get(`${BASE_URL}/getourclient`)
  .then(e => e.data)
  .catch(handleError);
}

export const addEditRemoveVisionApiHandler = (parmas) => {
  setHeadersWithContentType();
  return axios.post(`${BASE_URL}/addandremovevision`, parmas)
  .then(e => e.data)
  .catch(handleError);
}

export const getVisionApiHandler = () => {
  setHeadersWithContentType();
  return axios.get(`${BASE_URL}/visionlist`)
  .then(e => e.data)
  .catch(handleError);
}

export const addEditIcoLearnApiHandler = (parmas) => {
  setHeadersWithContentType();
  return axios.post(`${BASE_URL}/addediticolearn`, parmas)
  .then(e => e.data)
  .catch(handleError);
}

export const getIcoLearnApiHandler = () => {
  setHeadersWithContentType();
  return axios.get(`${BASE_URL}/geticolearn`)
  .then(e => e.data)
  .catch(handleError);
}

export const addFaqApiHandler = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/addeditfaqs`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const updateFaqApiHandler = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/addeditfaqs`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const getFaqApiHandler = () => {
  return axios.get(`${BASE_URL}/faqslist`)
    .then(e => e.data)
    .catch(handleError);
};

export const deleteFaqApiHandler = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/removefaqs`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const getTransactionChartApiHandler = (token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  //console.log(token, "himanshu")
  return axios
    .post(`${BASE_URL}/chartDetails`)
    .then(e => e.data)
    .catch(handleError);
};

export const getContactEnquiryApiHandler = () => {
  return axios.get(`${BASE_URL}/getcontactus`)
    .then(e => e.data)
    .catch(handleError);
};

export const deleteContactEnquiryApiHandler = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/removecontactusdetail`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const getSubscriberApiHandler = () => {
  return axios.get(`${BASE_URL}/getsubscribers`)
    .then(e => e.data)
    .catch(handleError);
};

export const deleteSubscriberApiHandler = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/removesubscriber`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const addSubscribeApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/subscribeupdates`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const getCalenderApiHandler = () => {
  return axios.get(`${BASE_URL}/icoCalenderList`)
    .then(e => e.data)
    .catch(handleError);
};

export const addCalenderApiHandler = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/addEditIcoCalender`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const updateCalenderApiHandler = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/addEditIcoCalender`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const deleteCalenderApiHandler = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/removeicocalender`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const addEditIcoWebApiHandler = (parmas) => {
  setHeadersWithContentType();
  return axios.post(`${BASE_URL}/addediticosolution`, parmas)
  .then(e => e.data)
  .catch(handleError);
}

export const getIcoWebApiHandler = () => {
  setHeadersWithContentType();
  return axios.get(`${BASE_URL}/geticosolution`)
  .then(e => e.data)
  .catch(handleError);
}

export const getTransactionHistoryApiHandler = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/getTransaction`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const getUserApiHandler = () => {
  return axios.get(`${BASE_URL}/getUserList`)
    .then(e => e.data)
    .catch(handleError);
};

export const addUserApiHandler = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/addUserByAdmin`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const updateUserApiHandler = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/addUserByAdmin`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const updateUserStatusApiHandler = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/ `, params)
    .then(e => e.data)
    .catch(handleError);
};

export const deleteUserApiHandler = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/removeUser`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const addWhiteListApiHandler = (params, token) => {
  setHeadersWithContentType2();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/addwhitelistuser`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const getWhiteListUserApiHandler = (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/getWhiteUserList`)
    .then(e => e.data)
    .catch(handleError);
};

export const removeWhiteListApiHandler = (params, token) => {
  setHeadersWithContentType2();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/removeWhitelistuser`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const addIdoLabApiHandler = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios.post(`${BASE_URL}/addEditIdoLabFeatures`, params)
    .then(e => e.data)
    .catch(handleError);
};


export const getIdoLearnApiHandler = () => {
  setHeadersWithContentType();
  return axios.get(`${BASE_URL}/getidolearn`)
  .then(e => e.data)
  .catch(handleError);
}

export const addEditIdoLearnApiHandler = (parmas) => {
  setHeadersWithContentType();
  return axios.post(`${BASE_URL}/addeditidolearn`, parmas)
  .then(e => e.data)
  .catch(handleError);
}

export const getIdoLabApiHandler = () => {
  return axios.get(`${BASE_URL}/idoLabFeaturesList`)
    .then(e => e.data)
    .catch(handleError);
};

export const getIdoWebApiHandler = () => {
  setHeadersWithContentType();
  return axios.get(`${BASE_URL}/getidosolution`)
  .then(e => e.data)
  .catch(handleError);
}

export const addEditIdoWebApiHandler = (parmas) => {
  setHeadersWithContentType();
  return axios.post(`${BASE_URL}/addeditidosolution`, parmas)
  .then(e => e.data)
  .catch(handleError);
}

export const updateIdoLabApiHandler = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/addEditIdoLabFeatures`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const deleteIdoLabApiHandler = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/removeidolabfeature`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const getIdoCalenderApiHandler = () => {
  return axios.get(`${BASE_URL}/idoCalenderList`)
    .then(e => e.data)
    .catch(handleError);
};


export const addIdoCalenderApiHandler = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/addEditIdoCalender`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const updateIdoCalenderApiHandler = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/addEditIdoCalender`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const deleteIdoCalenderApiHandler = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/removeidocalender`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const createTranscationApiHandler = (params , token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  //console.log(params , "himanshu" , token)
  return axios
    .post(`${BASE_URL}/createTransaction`, params)
    .then(e => e.data)
    .catch(handleError);
}

export const getTranscationApiHandler = (token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  //console.log("himanshu" , token)

  return axios
    .post(`${BASE_URL}/getUserTransaction`)
    .then(e => e.data)
    .catch(handleError);
}

export const getPriceApiHandler = (token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  //console.log("himanshu" , token)

  return axios
    .get(`${BASE_URL}/getBNBPrice`)
    .then(e => e.data)
    .catch(handleError);
}

export const getReferralUserApiHandler = (token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  //console.log("himanshu" , token)

  return axios
    .get(`${BASE_URL}/referralTransaction`)
    .then(e => e.data)
    .catch(handleError);
}

export const getApproveReferralUserApiHandler = (token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  //console.log("himanshu" , token)

  return axios
    .get(`${BASE_URL}/redeemTransaction`)
    .then(e => e.data)
    .catch(handleError);
}

export const getReferralTransactionApiHandler = (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  //console.log("himanshu" , token)

  return axios
    .get(`${BASE_URL}/referralTransactionUser`)
    .then(e => e.data)
    .catch(handleError);
}

export const updateTransactionStatusApiHandler = (params,token) => {
    setHeadersWithContentType();
    setHeadersWithAccessToken(token);
    console.log(params , "himanshu" , token)
    return axios
      .post(`${BASE_URL}/approveRedeem`, params)
      .then(e => e.data)
      .catch(handleError);
};

export const getReferralUserIDApiHandler = (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);

  return axios
    .get(`${BASE_URL}/redeemTransactionUser`)
    .then(e => e.data)
    .catch(handleError);
}

export const createRedeemApiHandler = (params,token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
 
  return axios
    .post(`${BASE_URL}/createRedeem`, params)
    .then(e => e.data)
    .catch(handleError);
};

export const getNotificationApiHandler = (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);

  return axios
    .get(`${BASE_URL}/getNotification`)
    .then(e => e.data)
    .catch(handleError);
}