import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Button } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import { Grid, TextField, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import AuthSagaActions from "../../../../redux/authsaga/action"
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "@mui/material/Modal";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Swal from "sweetalert2";
import { makeStyles } from "@mui/styles";

const { getIdoCalenderSaga,addIdoCalenderSaga,updateIdoCalenderSaga,deleteIdoCalenderSaga } = AuthSagaActions;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "30%",
  transform: "translate(-50%, -50%)",
  maxHeight: "70vh",
  bgcolor: "background.paper",
  overflow: "auto",
  boxShadow: 24,
  p: 4,
};

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "TextValidator",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const IdoCalenderList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();

  const calenderData = useSelector((state) => {
    return state.authSaga.IdoCalenderList;
  });
  const token = useSelector((state) => {
    return state.authSaga.idToken;
  });

  function toTimestamp(strDate){
    var datum = Date.parse(strDate);
    return datum/1000;
  }

  const [page, setPage] = useState(0);
  const [CalenderList, SetCalenderList] = useState([]);
  const [rows, Setrows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);

  const [Editid, Seteditid] = useState("");
  const [count, Setcount] = useState(0);

  const [startDate, setStartDate] = useState(dayjs(new Date()));
  const [endDate, setEndDate] = useState(dayjs(new Date()));
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");
  const [phaseName, setPhaseName] = useState("");
  const [errorObject,setErrorObject] = useState({});

  const columns = [
    { id: "phaseName", label: "Phase Name", minWidth: 150, },
    { id: "startDate", label: "Start Date", minWidth: 150, },
    { id: "endDate", label: "End Date", minWidth: 150, },
    { id: "quantity", label: "Total Quantity", minWidth: 90, },
    { id: "price", label: "Price", minWidth: 90, },
    {
      id: "b2",
      label: "Action",
      minWidth: 50,
      align: "right",
    },
    {
      id: "b3",
      label: "",
      minWidth: 50,
      align: "right",
    },
  ];

  function createData(phaseName, startDate, endDate, quantity, price, id ) {
    let b2 = "";

    let todayDateCheck =  toTimestamp(new Date());
    let startDateCheck = toTimestamp(startDate);
    if((startDateCheck >= todayDateCheck)){
        b2 = (
          <EditIcon 
            sx={{cursor:'pointer'}}
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
              Seteditid(id);
              Setcount((pre) => pre + 1);
            }}
          />
        );
      }
    let b3 = (
          <DeleteIcon sx={{cursor:'pointer'}} onClick={() => deletehandler(id)} />
        );
    return { phaseName, startDate, endDate, quantity, price, b2, b3 };
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    CalenderList &&
      CalenderList[0] &&
      CalenderList.map((element, index) => {
        if (element._id === Editid) {
          setErrorObject({});
          setStartDate(element?.startDate);
          setEndDate(element?.endDate);
          setPhaseName(element?.phaseName);
          setQuantity(element?.quantity);
          setPrice(element?.price);
        }
      });

    if (Editid === "") {
        setErrorObject({});
        setStartDate(null);
        setEndDate(null);
        setPhaseName("");
        setPrice("");
        setQuantity("");
    }
  }, [Editid, count]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const deletehandler = async (id) => {
    Swal.fire({
      title: "",
      text: "Are you sure you want to delete this record ?",
      icon: "",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let urlencoded = new URLSearchParams();
        urlencoded.append("id", id);
        urlencoded.append("deletstatus", "1");
        dispatch(deleteIdoCalenderSaga(urlencoded, navigate));
      }
    });
  };

  const validationFun=(startDate,endDate,phaseName,quantity,price)=>{
    let errors = {};
    if(startDate === "" || startDate === null || startDate === 'Invalid Date'){
      errors.startDate = "Please select Start Date";
    }
  
    if(endDate === "" || endDate === null || endDate === 'Invalid Date'){
      errors.endDate = "Please select End Date";
    } 
  
    if(phaseName == "" || phaseName.replace( /\s\s+/g, ' ')?.length<3){
      errors.phaseName = "Enter at least 3 characters in Phase Name";
    } else if(phaseName.replace( /\s\s+/g, ' ') == "" || phaseName.replace( /\s\s+/g, ' ')?.length>25){
      errors.phaseName = "Enter not more then 25 characters in Phase Name";
    } 

    if(quantity == "" || quantity.length===0){
      errors.quantity = "Enter at least 1 number in Quantity";
    }

    if(price == "" || price.replace( /\s\s+/g, ' ')?.length<2){
      errors.price = "Please enter Price";
    } 

    return errors;
  }
  
  const handleValidation = (e) => {
    if (Object.keys(validationFun(startDate,endDate,phaseName,quantity,price)).length === 0) {
        handleSubmit();
        setErrorObject({});
    } else {
        setErrorObject(validationFun(startDate,endDate,phaseName,quantity,price))
    }
   //console.log(validationFun(startDate,endDate,phaseName,quantity,price));
  };


  const handleSubmit = () => {
    const apidata = new URLSearchParams();
    apidata.append("phaseName", phaseName);
    apidata.append("startdate", startDate);
    apidata.append("enddate", endDate);
    apidata.append("quantity", quantity);
    apidata.append("price", price);
    if (Editid !== "") {
      apidata.append("id", Editid);
    }
    
    Editid===""?dispatch(addIdoCalenderSaga(apidata, navigate, token)):dispatch(updateIdoCalenderSaga(apidata, navigate, token))
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch(getIdoCalenderSaga(navigate));
    SetCalenderList(calenderData);
  }, []);

  useEffect(() => {
    SetCalenderList(calenderData);
  }, [calenderData]);

  useEffect(() => {
    let data = [];
    CalenderList &&
      CalenderList[0] &&
      CalenderList.map((element, index) => {
        data = [
          ...data,
          createData(
            element?.phaseName,
            element?.startDate,
            element?.endDate,
            element?.quantity,
            element?.price,
            element?._id
          ),
        ];
        
      });
      Setrows(data);
  }, [CalenderList]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box>
      <Box className="page-content-main">
      <ToastContainer />
      <Box className='main-title-box'>
        <Typography className='main-title'  variant="h4">IDO Management</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        <FormControl sx={{ m: 1, minWidth: 220, maxHeight: 50 }}></FormControl>
        <Button
          variant="contained"
          sx={{
            maxHeight: 50,
            marginTop: 2,
            backgroundColor:'#271e09',
            "&:hover": { backgroundColor: "#f49d04", opacity: [0.9, 0.8, 0.7] },
          }}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            Seteditid("");
            Setcount(count + 1);
          }}
        >
          Add New
        </Button>
      </Box>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer className="table-main-box">
          <Table stickyHeader aria-label="sticky table" className="table-main">
            <TableHead className="table-head"> 
              <TableRow className="table-row">
                {columns.map((column) => (
                  <TableCell className="table-cell"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <h4 style={{ margin: 0 }}>{column.label}</h4>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="table-body">
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow className="table-row"
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align} className="table-cell">
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid item xs={6}>
              <Box className="common-modal validation">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="Start Date *"
                        value={startDate}
                        onChange={(newValue) => {
                            setStartDate(newValue);
                        }}
                    />
                    <FormHelperText sx={{ color: "#d32f2f" }}>{errorObject?.startDate}</FormHelperText>
                   
                    <DateTimePicker
                        required
                        fullWidth
                        renderInput={(props) => <TextField sx={{ marginTop: 1.4 }} {...props} />}
                        label="End Date *"
                        value={endDate}
                        onChange={(newValue) => {
                            setEndDate(newValue);
                        }}
                    />
                    <FormHelperText sx={{ color: "#d32f2f" }}>{errorObject?.endDate}</FormHelperText>
                  </LocalizationProvider>

                  <TextField
                    sx={{ marginTop: 1.5 }}
                    required
                    fullWidth
                    error={errorObject['phaseName'] !== undefined}
                    type="text"
                    label="Phase Name"
                    id="phaseName"
                    value={phaseName}
                    onChange={(e) => setPhaseName(e.target.value)}
                    helperText={errorObject?.phaseName}
                  />
                
                  <TextField
                    sx={{ marginTop: 1.5 }}
                    className={classes.input}
                    required
                    onWheel={(e) => e.target.blur()}
                    fullWidth
                    error={errorObject['quantity'] !== undefined}
                    type="number"
                    label="Tokens Quantity"
                    id="quantity"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    helperText={errorObject?.quantity}
                  />
                  
                  <TextField
                    sx={{ marginTop: 1.5 }}
                    onWheel={(e) => e.target.blur()}
                    error={errorObject['price'] !== undefined}
                    required
                    fullWidth
                    type="text"
                    label="Price"
                    id="price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    helperText={errorObject?.price}
                  />
            
                  <Box className="save-btn-box" sx={{mt:4}}>
                    <Button className="save-btn"
                      variant="contained"
                      onClick={() => handleValidation()}
                    >
                      Submit
                    </Button>
                  </Box>
              </Box>
            </Grid>
          </Box>
        </Modal>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
    </Box>
  );
};

export default IdoCalenderList;
