import React, { useState, useEffect } from "react";
import Index from "../Index";

const { getContectDetailSaga, userContactEnquirySaga } = Index.AuthSagaActions;
export default function Contact() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();

  const [contactDetails, setContactDetails] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [error,setError]=useState("");
  const [errorObject,setErrorObject] = useState({});

  const ContactDetail = Index.useSelector((state) => {
    return state.authSaga.contactUs;
  });

  useEffect(() => {
    dispatch(getContectDetailSaga());
    setContactDetails(ContactDetail);
  }, []);

  useEffect(() => {
    setContactDetails(ContactDetail);
  }, [ContactDetail]);

  const validationFun=(name,email,subject,message)=> {
        let regEmail =/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
        let errors = {};
        if(name === "" || name.replace( /\s\s+/g, ' ')?.length<3){
          errors.name = "Enter at least 3 characters in Name";
        }
        else if(name.replace( /\s\s+/g, ' ') == "" || name.replace( /\s\s+/g, ' ')?.length>25){
          errors.name = "Enter not more then 25 characters in Name";
        }

        if(email === "" || !regEmail.test(email)){
          errors.email = "Please enter your valid Email";
        }
      
        if(subject === "" || subject.replace( /\s\s+/g, ' ')?.length<3){
          errors.subject = "Enter at least 3 characters in Subject";
        }
        else if(subject.replace( /\s\s+/g, ' ') === "" || subject.replace( /\s\s+/g, ' ')?.length>25){
          errors.subject = "Enter not more then 25 characters in Subject";
        }

        if(message === "" || message.replace( /\s\s+/g, ' ')?.length<3){
          errors.message = "Please enter your Message";
        }

        return errors;
    }

    const handleSubmit = (e) => {
      if (Object.keys(validationFun(name,email,subject,message)).length === 0) {
          handleContact();
          setErrorObject({});
      } else {
        setErrorObject(validationFun(name,email,subject,message))
      }
      //console.log(validationFun(name,email,subject,message),55);
    };

    const handleContact=async()=>{
        setError("");
        const apidata = new URLSearchParams();
        apidata.append("name", name);
        apidata.append("email", email);
        apidata.append("subject", subject);
        apidata.append("message", message);
        dispatch(userContactEnquirySaga(apidata, navigate));
        setName('');
        setSubject('');
        setMessage('');
        setEmail('');
    }

  return (
    <div>
      <Index.Header />

      <Index.Box className="third-phase-section about-third-phase-section about-text-main">
        <Index.Box className="container">
          <Index.Box className="">
            <Index.Box className="about-text">
              <Index.Typography variant="p" component="p">
                Contact Us{" "}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="about-text-inner">
              <Index.Typography variant="p" component="p">
                {contactDetails?.description ? contactDetails?.description : ""}
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="header-sec">
        <Index.Box className="container">
          <Index.Box className="row spaces-contactus">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 4",
                    lg: "span 4",
                  }}
                  className="grid-column">
                  <Index.Item className="grid-item left-info">
                    <Index.Box>
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="connect-us-text"
                      >
                        CONNECT WITH US
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="ipade-flex">
                      <Index.Box className="main-info">
                        <Index.Box className="">
                          <Index.Typography
                            variant="p"
                            component="p"
                            className="information-text"
                          >
                            Information
                          </Index.Typography>
                          <Index.Box className="">
                            <Index.Box className="call">
                              <Index.Box className="call-icon">
                                <img
                                  src={Index.Svg.call}
                                  className="call-icon"
                                  alt="Bgherofripto"
                                ></img>
                              </Index.Box>

                              <Index.Box className="info-num">
                                <Index.Typography
                                  variant="p"
                                  component="p"
                                  className="first-num"
                                >
                                 {contactDetails?.phone1 ? contactDetails?.phone1 : ""}
                                </Index.Typography>
                                <Index.Typography
                                  variant="p"
                                  component="p"
                                  className=""
                                >
                                 {contactDetails?.phone2 ? contactDetails?.phone2 : ""}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="mail">
                              <Index.Box className="mail-icon">
                                <img
                                  src={Index.Svg.mail}
                                  className="call-icon"
                                  alt="Bgherofripto"
                                ></img>
                              </Index.Box>

                              <Index.Box className="info-num">
                                <Index.Typography
                                  variant="p"
                                  component="p"
                                  className=""
                                >
                                  {contactDetails?.email1 ? contactDetails?.email1 : ""}
                                </Index.Typography>
                                <Index.Typography
                                  variant="p"
                                  component="p"
                                  className=""
                                >
                                  {contactDetails?.email2 ? contactDetails?.email2 : ""}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box className="social-part">
                        <Index.Box className="connectus-text">
                          <Index.Typography
                            variant="p"
                            component="p"
                            className=""
                          >
                            Connect with Us
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="social-icons">

                        { 
                            contactDetails?.facebook 
                            ?
                                <Index.Link className="icon" target="_blank" href={contactDetails?.facebook}>
                                    <img src={Index.Svg.fb} className="" alt="facebook"></img>
                                </Index.Link>
                            : ""
                        }
                          
                        { 
                            contactDetails?.instagram 
                            ?
                                <Index.Link className="icon" target="_blank" href={contactDetails?.instagram}>
                                    <img src={Index.Svg.insta} className="" alt="instagram"></img>
                                </Index.Link>
                            : ""
                        }

                        {                        
                            contactDetails?.twitter 
                            ?
                                <Index.Link className="icon" target="_blank" href={contactDetails?.twitter}>
                                    <img src={Index.Svg.twitterGray} className="" alt="twitter"></img>
                                </Index.Link>
                            : ""
                        }

                        {                        
                            contactDetails?.youtube 
                            ?
                                <Index.Link className="icon" target="_blank" href={contactDetails?.youtube}>
                                    <img src={Index.Svg.youtubeGray} className="" alt="youtube"></img>
                                </Index.Link>
                            : ""
                        }

                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Item>
                </Index.Box>


                {/* Contact Form */}
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 8",
                    lg: "span 8",
                  }}
                  className="grid-column">
                  <Index.Item className="grid-item">
                    <Index.Box className="main-right">
                      <Index.Box className="get-touch">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="get-touch-text"
                        >
                          Get In Touch
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="name-mail-input">
                        <Index.Box className="input-box cm-search-box name-mail">
                          <Index.Box className="form-group name validation">
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="name-text text"
                            >
                              Your Name*
                            </Index.Typography>
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              required
                              error={errorObject['name'] !== undefined}
                              className="form-control form-control-3"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              helperText={errorObject?.name}
                            />
                          </Index.Box>

                          <Index.Box className="form-group mail-input validation">
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="mail-text text"
                            >
                              Your Email*
                            </Index.Typography>
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              required
                              error={errorObject['email'] !== undefined}
                              className="form-control  form-control-3"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              helperText={errorObject?.email}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box className="subject">
                        <Index.Box className="input-box cm-search-box ">
                          <Index.Box className="form-group sub validation">
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="name-text text"
                            >
                              Subject*
                            </Index.Typography>
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              error={errorObject['subject'] !== undefined}
                              className="form-control subject-box"
                              value={subject}
                              onChange={(e) => setSubject(e.target.value)}
                              helperText={errorObject?.subject}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box className="message">
                        <Index.Box className="input-box cm-search-box ">
                          <Index.Box className="form-group msg validation">
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="name-text text"
                            >
                              Message*
                            </Index.Typography>
                            <Index.TextareaAutosize
                              aria-label="empty textarea"
                              className="input-field message-input"
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                            />
                          <Index.Box style={{fontSize:13, color:'#d32f2f'}} className='contact-msg-error'>{errorObject?.message}</Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box className="send">
                        <Index.Button variant="contained" onClick={()=>handleSubmit()} className="form-btn">
                          Send
                        </Index.Button>
                      </Index.Box>
                    <Index.Box className='register-error'>
                       {error}
                    </Index.Box>

                    </Index.Box>
                  </Index.Item>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.ToastContainer/>
      </Index.Box>

      <Index.Footer />
    </div>
  );
}
