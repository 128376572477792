import actions from "./action";
import { toast } from "react-toastify";
const initState = {
  idToken: null,
  loading: false,
  islogin: false,
  isloginUser: false,
  adminData: {},
  userData: {},
  cms: {},
  newsList: [],
  icoLabFeatures: [],
  idoLabFeatures: [],
  categotyList: [],
  TeamMemberList: [],
  aboutDetails: {},
  faqsList: [],
  NFTList: [],
  CollactionList: [],
  PurchaseRequest: [],
  ClaimRequestList: [],
  NFTDetails: [],
  Users: [],
  TransactionHistory: [],
  Road: [],
  Perks: [],
  Homedata: [],
  subscriberList: [],
  WhitePaperDetails: [],
  Tokendata: [],
  ServicesList: [],
  Admindetail: [],
  Transactionlist: [],
  TransactionChartNumbers: [],
  website: [],
  Clients: [],
  Vision: [],
  IcoLearnList: [],
  IdoLearnList: [],
  IcoWebSolutionList: [],
  IdoWebSolutionList: [],
  IcoCalenderList: [],
  IdoCalenderList: [],
  ContactEnquiry: [],
  contactUs: [],
  coinPrice: [],
  userProfile: [],
  whitelistUser: [],
  transcation: [],
  price:[],
  referralUser:[],
  approveReferralUser:[],
  referralTransaction:[],
  userRedeemTransaction:[],
  userNotification:[]
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        idToken: action.token,
        adminData: action.data,
        loading: false,
        islogin: true,
      };

    case actions.LOGOUT:
      return initState;

    case actions.USER_LOGIN_SUCCESS:
      return {
        ...state,
        idTokenUser: action.token,
        userData: action.data,
        loading: false,
        isloginUser: true,
      };

    case actions.USER_LOGOUT_SUCCESS:
      return {
        ...state,
        idTokenUser: action.token,
        userData: action.data,
        loading: false,
        isloginUser: false,
      };

    case actions.GET_UserProfile_SUCCESS:
      return {
        ...state,
        userProfile: action.data,
        loading: false,
      };

    case actions.USER_REGISTER_SUCCESS:
      toast.success("User created");
      return {
        ...state,
        loading: false,
      };

    case actions.USER_ChangePassword_SUCCESS:
      toast.success("Your Password has been changed");
      return {
        ...state,
        loading: false,
      };

    case actions.USER_ForgotPassword_SUCCESS:
      toast.success("Email send you. please check your email");
      return {
        ...state,
        loading: false,
      };
      
    case actions.USER_ResetPassword_SUCCESS:
      toast.success("Your password has been changed");
      return {
        ...state,
        loading: false,
      };

    case actions.Edit_UserProfile_SUCCESS:
      toast.success("Successfully update profile details");
      return {
        ...state,
        userProfile: action.data,
        loading: false,
      };

    case actions.User_ContactEnquiry_SUCCESS:
      toast.success("Thank you for enquiry");
      return {
        ...state,
        loading: false,
      };

    case actions.ERROR:
      toast.error(action.message);
      return {
        ...state,
        loading: false,
      };

    case actions.LODER_ON:
      return {
        ...state,
        loading: true,
      };

    case actions.FORGOT_PASSWORD_SUCCESS:
      toast.success("Email Send");
      return {
        ...state,
        loading: false,
      };

    case actions.RESET_PASSWORD_SUCCESS:
      toast.success("Password Reset");
      return {
        ...state,
        loading: false,
      };

    case actions.LODER_OFF:
      return {
        ...state,
        loading: false,
      };

    case actions.About_Edit_SUCCESS:
      toast.success("Successfully updated");
      return {
        ...state,
        aboutDetails: action.data[0],
        loading: false,
      };

    case actions.GET_About_SUCCESS:
      // toast.success('Successfully fetch about detail')
      return {
        ...state,
        aboutDetails: action.data[0],
        loading: false,
      };
    case actions.GET_Contect_Detail_SUCCESS:
      return {
        ...state,
        contactUs: action.data,
        loading: false,
      };
    case actions.GET_CoinPrice_SUCCESS:
      return {
        ...state,
        coinPrice: action.data,
        loading: false,
      };
    case actions.Add_CoinPrice_SUCCESS:
      toast.success("Successfully record updated");
      return {
        ...state,
        coinPrice: action.data,
        loading: false,
      };
    case actions.Edit_Contect_Detail_SUCCESS:
      toast.success("Successfully updated");
      return {
        ...state,
        contactUs: action.data,
        loading: false,
      };
    case actions.GET_CMS_SUCCESS:
      // toast.success("Successfully  fetch CMS detail");
      return {
        ...state,
        cms: action.data,
        loading: false,
      };
    case actions.Security_Edit_SUCCESS:
      toast.success("Successfully update privacy policy");
      return {
        ...state,
        cms: action.data,
        loading: false,
      };
    case actions.Terms_Edit_SUCCESS:
      toast.success("Successfully update terms & conditions");
      return {
        ...state,
        cms: action.data,
        loading: false,
      };
    case actions.Add_Team_Member_SUCCESS:
      toast.success("Team member added");
      // toast.success("Team Member Added");
      return {
        ...state,
        TeamMemberList: action.data,
        loading: false,
      };
    case actions.Update_Team_Member_SUCCESS:
      toast.success("Team member updated");
      // toast.success("Team Member Added");
      return {
        ...state,
        TeamMemberList: action.data,
        loading: false,
      };
    case actions.GET_Team_Members_SUCCESS:
      // toast.success('Successfully fetch team member')
      return {
        ...state,
        TeamMemberList: action.data,
        loading: false,
      };
    case actions.Delete_Team_Members_SUCCESS:
      toast.success("Deleted team member");
      return {
        ...state,
        TeamMemberList: action.data,
        loading: false,
      };
    case actions.Add_News_SUCCESS:
      toast.success("News added");
      return {
        ...state,
        newsList: action.data,
        loading: false,
      };
    case actions.Update_News_SUCCESS:
      toast.success("News updated");
      return {
        ...state,
        newsList: action.data,
        loading: false,
      };
    case actions.Send_News_SUCCESS:
      toast.success("News sent");
      return {
        ...state,
        subscriberList: action.data,
        loading: false,
      };
    case actions.GET_NEWS_SUCCESS:
      // toast.success('Successfully add news')
      //console.log(action.data, 144);
      return {
        ...state,
        newsList: action.data,
        loading: false,
      };
    case actions.Delete_News_SUCCESS:
      toast.success("News deleted");
      return {
        ...state,
        newsList: action.data,
        loading: false,
      };
    case actions.Add_ICOLab_SUCCESS:
      toast.success("ICO added");
      return {
        ...state,
        icoLabFeatures: action.data,
        loading: false,
      };
    case actions.Update_ICOLab_SUCCESS:
      toast.success("ICO updated");
      return {
        ...state,
        icoLabFeatures: action.data,
        loading: false,
      };
    case actions.GET_ICOLab_SUCCESS:
      return {
        ...state,
        icoLabFeatures: action.data,
        loading: false,
      };
    case actions.Delete_ICOLab_SUCCESS:
      toast.success("ICO deleted");
      return {
        ...state,
        icoLabFeatures: action.data,
        loading: false,
      };
    case actions.Delete_Roadmap_SUCCESS:
      toast.success("Roadmap deleted");
      return {
        ...state,
        Road: action.data,
        loading: false,
      };
    case actions.Update_Roadmap_SUCCESS:
      toast.success("Roadmap updated");
      return {
        ...state,
        Road: action.data,
        loading: false,
      };
    case actions.Add_Roadmap_SUCCESS:
      toast.success("Roadmap added");
      return {
        ...state,
        Road: action.data,
        loading: false,
      };

    case actions.GET_Road_SUCCESS:
      // toast.success('Your action successfully Complited')
      return {
        ...state,
        Road: action.data,
        loading: false,
      };
    case actions.GET_Website_SUCCESS:
      // toast.success('Your action successfully Complited')
      return {
        ...state,
        website: action.data,
        loading: false,
      };
    case actions.Add_Edit_Website_SUCCESS:
      toast.success("Successfully updated");
      return {
        ...state,
        website: action.data,
        loading: false,
      };
    case actions.Add_Client_SUCCESS:
      toast.success("Client added");
      return {
        ...state,
        Clients: action.data,
        loading: false,
      };
    case actions.Update_Client_SUCCESS:
      toast.success("Client updated");
      return {
        ...state,
        Clients: action.data,
        loading: false,
      };
    case actions.Delete_Client_SUCCESS:
      toast.success("Client deleted");
      return {
        ...state,
        Clients: action.data,
        loading: false,
      };
    case actions.GET_Client_SUCCESS:
      // toast.success('Your action successfully Complited')
      return {
        ...state,
        Clients: action.data,
        loading: false,
      };
    case actions.Add_Vision_SUCCESS:
      toast.success("Your action successfully complited");
      return {
        ...state,
        Vision: action.data,
        loading: false,
      };
    case actions.GET_Vision_SUCCESS:
      // toast.success('Your action successfully Complited')
      return {
        ...state,
        Vision: action.data,
        loading: false,
      };
    case actions.Add_ICOLearn_SUCCESS:
      toast.success("Your record successfully updated");
      return {
        ...state,
        IcoLearnList: action.data,
        loading: false,
      };
    case actions.GET_ICOLearn_SUCCESS:
      return {
        ...state,
        IcoLearnList: action.data,
        loading: false,
      };
    case actions.Add_Faq_SUCCESS:
      toast.success("Successfully added FAQ");
      return {
        ...state,
        faqsList: action.data,
        loading: false,
      };
    case actions.Update_Faq_SUCCESS:
      toast.success("Successfully updated FAQ");
      return {
        ...state,
        faqsList: action.data,
        loading: false,
      };
    case actions.GET_Faq_SUCCESS:
      return {
        ...state,
        faqsList: action.data,
        loading: false,
      };
    case actions.Delete_Faq_SUCCESS:
      toast.success("Successfully deleted FAQ");
      return {
        ...state,
        faqsList: action.data,
        loading: false,
      };
    case actions.GET_TransactionChart_SUCCESS:
      return {
        ...state,
        TransactionChartNumbers: action.data,
        loading: false,
      };
    case actions.GET_ContactEnquiry_SUCCESS:
      return {
        ...state,
        ContactEnquiry: action.data,
        loading: false,
      };
    case actions.Delete_ContactEnquiry_SUCCESS:
      toast.success("Contact enquiry deleted");
      return {
        ...state,
        ContactEnquiry: action.data,
        loading: false,
      };
    case actions.GET_Subscriber_SUCCESS:
      return {
        ...state,
        subscriberList: action.data,
        loading: false,
      };
    case actions.Delete_Subscriber_SUCCESS:
      toast.success("Subscriber record deleted");
      return {
        ...state,
        subscriberList: action.data,
        loading: false,
      };
    case actions.Add_Subscribe_SUCCESS:
      toast.success("Thank you for subscribing");
      return {
        ...state,
        faqsList: action.data,
        loading: false,
      };
    case actions.GET_Calender_SUCCESS:
      return {
        ...state,
        IcoCalenderList: action.data,
        loading: false,
      };
    case actions.Add_Calender_SUCCESS:
      toast.success("Calender added");
      return {
        ...state,
        IcoCalenderList: action.data,
        loading: false,
      };
    case actions.Update_Calender_SUCCESS:
      toast.success("Calender updated");
      return {
        ...state,
        IcoCalenderList: action.data,
        loading: false,
      };
    case actions.Delete_Calender_SUCCESS:
      toast.success("Calender record deleted");
      return {
        ...state,
        IcoCalenderList: action.data,
        loading: false,
      };
    case actions.Add_ICOWeb_SUCCESS:
      toast.success("Your record successfully updated");
      return {
        ...state,
        IcoWebSolutionList: action.data,
        loading: false,
      };
    case actions.GET_ICOWeb_SUCCESS:
      return {
        ...state,
        IcoWebSolutionList: action.data,
        loading: false,
      };
    case actions.GET_TransactionHistory_SUCCESS:
      return {
        ...state,
        TransactionHistory: action.data,
        loading: false,
      };
    case actions.GET_User_SUCCESS:
      return {
        ...state,
        Users: action.data,
        loading: false,
      };
    case actions.Delete_User_SUCCESS:
      toast.success("User deleted.");
      return {
        ...state,
        Users: action.data,
        loading: false,
      };
    case actions.Update_UserStatus_SUCCESS:
      toast.success("User status modified.");
      return {
        ...state,
        Users: action.data,
        loading: false,
      };
    case actions.Add_User_SUCCESS:
      toast.success("User added");
      return {
        ...state,
        Users: action.data,
        loading: false,
      };
    case actions.Update_User_SUCCESS:
      toast.success("User updated");
      return {
        ...state,
        Users: action.data,
        loading: false,
      };
    case actions.GET_IDOWeb_SUCCESS:
      return {
        ...state,
        IdoWebSolutionList: action.data,
        loading: false,
      };
    case actions.Add_IDOWeb_SUCCESS:
      toast.success("Your record successfully updated");
      return {
        ...state,
        IdoWebSolutionList: action.data,
        loading: false,
      };
    case actions.GET_IDOLearn_SUCCESS:
      return {
        ...state,
        IdoLearnList: action.data,
        loading: false,
      };
    case actions.Add_IDOLearn_SUCCESS:
      toast.success("Your record successfully updated");
      return {
        ...state,
        IdoLearnList: action.data,
        loading: false,
      };
    case actions.GET_IDOLab_SUCCESS:
      return {
        ...state,
        idoLabFeatures: action.data,
        loading: false,
      };
    case actions.Add_IDOLab_SUCCESS:
      toast.success("IDO added");
      return {
        ...state,
        idoLabFeatures: action.data,
        loading: false,
      };
    case actions.Update_IDOLab_SUCCESS:
      toast.success("IDO updated");
      return {
        ...state,
        idoLabFeatures: action.data,
        loading: false,
      };
    case actions.Delete_IDOLab_SUCCESS:
      toast.success("IDO deleted");
      return {
        ...state,
        idoLabFeatures: action.data,
        loading: false,
      };
    case actions.GET_IDO_Calender_SUCCESS:
      return {
        ...state,
        IdoCalenderList: action.data,
        loading: false,
      };
    case actions.Add_IDO_Calender_SUCCESS:
      toast.success("IDO calender added");
      return {
        ...state,
        IdoCalenderList: action.data,
        loading: false,
      };
    case actions.Update_IDO_Calender_SUCCESS:
      toast.success("IDO calender updated");
      return {
        ...state,
        IdoCalenderList: action.data,
        loading: false,
      };

    case actions.Delete_IDO_Calender_SUCCESS:
      toast.success("IDO calender record deleted");
      return {
        ...state,
        IdoCalenderList: action.data,
        loading: false,
      };

    case actions.Add_WhiteListUser_SUCCESS:
      toast.success("User added");
      return {
        ...state,
        whitelistUser: action.data,
        loading: false,
      };

    case actions.GET_WhiteListUser_SUCCESS:
      return {
        ...state,
        whitelistUser: action.data,
        loading: false,
      };

    case actions.REMOVE_WhiteListUser_SUCCESS:
      toast.success("Record deleted");
      return {
        ...state,
        whitelistUser: action.data,
        loading: false,
      };

    case actions.CREATE_TRANSCATION_SUCCESS:
      return {
        ...state,
        transcation: action.data,
        loading: false,
      };

    case actions.GET_TRANSCATION_SUCCESS:
      return {
        ...state,
        transcation: action.data,
        loading: false,
      };
      case actions.GET_PRICE_SUCCESS:
        return {
          ...state,
          price: action.data[0],
          loading: false,
        };
        case actions.GET_Referral_User_SUCCESS:
          return {
            ...state,
            referralUser: action.data,
            loading: false,
          };
          case actions.GET_Approve_User_SUCCESS:
          return {
            ...state,
            approveReferralUser: action.data,
            loading: false,
          };
          case actions.GET_REFERRAL_TRANSACTION_SUCCESS:
            return {
              ...state,
              referralTransaction: action.data,
              loading: false,
            };
            case actions.UPDATE_WITHDRWAL_SUCCESS:
              toast.success("Transaction updated");
              console.log(action.data,714);
            return {
              ...state,
              approveReferralUser: action.data,
              loading: false,
            };
            case actions.REDEEM_USER_TRANSACTION_SUCCESS:
              return {
                ...state,
                userRedeemTransaction: action.data,
                loading: false,
              };
              case actions.CREATE_REDEEM_SUCCESS:
                toast.success("Coin redeemed");
              return {
                ...state,
                userRedeemTransaction: action.data,
                loading: false,
              };
              case actions.GET_NOTIFICATION_SUCCESS:
                return {
                  ...state,
                  userNotification: action.data,
                  loading: false,
                };
    default:
      return state;
  }
}
