import React from 'react'
import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import Png from '../../../assets/png'

const TechnicalOverview = () => {
      return (
            <div>
                  <Box className="technical-overview-main">
                        <Box className="w2-technical-content-box">
                              <Typography className='w2-technical-text'>Technical Overview</Typography>
                              <Typography className='w2-technical-para'>The main interaction point between Demo and its user base is the mobile app, fuelled by the backend. The integrated platform also connects to the bsc blockchain which hosts its token and smart contract components.</Typography>
                        </Box>
                        <Box className="w2-token-main">
                              <Box className="w2-token-top-box">
                                    <Box className="w2-apna-token-box">
                                          <Box className="w2-apna-token-text">DEMOtoken Functions</Box>
                                          <Box className="w2-token-btns-box">
                                                <Button className='w2-token-btn w2-token-gap btn'>Stacking</Button>
                                                <Button className='w2-token-btn btn'>Content access</Button>
                                                <Button className='w2-token-btn w2-token-gap btn'>Tipping</Button>
                                                <Button className='w2-token-btn btn'>Payments</Button>
                                          </Box>
                                          <Box className="w2-middle-btn-box">
                                                <Button className='w2-middle-btn'>Backend</Button>
                                                <Button className='w2-middle-btn'>BSC nodes</Button>
                                          </Box>
                                    </Box>
                                    <Box className="w2-token-bottom-squre">
                                          <Box className="w2-token-squre-inner-box">
                                                <Box className="w2-binancecoin-img-box">
                                                      <img src={Png.binancecoin} className="w2-binancecoin-img" alt=''></img>
                                                </Box>
                                                <Box className="w2-token-fill-btns-box">
                                                      <Button className='w2-fill-btns we-big-btn'>DEMOtoken</Button>
                                                      <Button className='w2-fill-btns'>DEMOtoken</Button>
                                                      <Button className='w2-fill-btns'>DEMOtoken</Button>
                                                </Box>
                                          </Box>
                                    </Box>
                              </Box>
                              <Box className="w2-middle-main-box">
                                    <Box className="w2-white-round-main">
                                          <Box className="w2-white-round">
                                                <img src={Png.Apnacast} className="w2-apna-cast-logo" alt=''></img>
                                          </Box>
                                    </Box>
                              </Box>
                              <Box className="w2-bottom-main-box">
                                    <Box className="w2-bottom-inner-main">
                                          <Box className="w2-bottom-box">
                                                <Box className="w2-bottom-small-squre w2-arrow-left-right">
                                                      <Box className="w2-bottom-squre-btn-box">
                                                            <Button className='w2-bottom-squre-btn'>Mobile Key store</Button>
                                                            <Box className="w2-bottome-squre-text">User's private keys</Box>
                                                      </Box>
                                                </Box>
                                                <Box className="e2-arrow-up-box">
                                                      <img src={Png.arrowupdown} className="e2-updown-arrow-img" alt=''></img>
                                                </Box>
                                                <Box className="w2-bottom-small-squre">
                                                      <Box className="w2-bottom-squre-btn-box">
                                                            <Button className='w2-bottom-squre-btn'>Backup Key Store</Button>
                                                            <Box className="w2-bottome-squre-text">Encrypted key backups, locked from Demo</Box>
                                                      </Box>
                                                </Box>
                                          </Box>
                                          <Box className="w2-bottom-box">
                                                <Box className="w2-bottom-small-squre">
                                                      <Box className="w2-bottom-squre-btn-box">
                                                            <Button className='w2-bottom-squre-btn'>Bsc nodes</Button>
                                                            <Box className="w2-bottome-squre-text">DEMOtoken</Box>
                                                      </Box>
                                                </Box>
                                          </Box>
                                    </Box>
                              </Box>
                        </Box>
                        <Box className="w2-token-bottom-content">
                              <Box className="w2-token-bottom-title">Demo Backend</Box>
                              <Box className="w2-token-bottom-para">The mobile app connects the user to the blockchain components in a non-custodial way: all keys are generated and owned by the user and unknown to the company. Users hold a key pair in their mobile key store, with password-protected backups on the backend.</Box>
                        </Box>
                  </Box>
            </div>
      )
}

export default TechnicalOverview