import React, {useState,useEffect} from "react";
import Index from "../../Index";
import png from "../../../assets/png";
import AuthSagaActions from "../../../redux/authsaga/action";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const { getContectDetailSaga } = AuthSagaActions;
export default function Footer() {
  const dispatch = useDispatch();
  const [contactDetails, setContactDetails] = useState("");
  const ContactDetail = useSelector((state) => {
    return state.authSaga.contactUs;
  });

  useEffect(() => {
    dispatch(getContectDetailSaga());
    setContactDetails(ContactDetail);
  }, []);

  useEffect(() => {
    setContactDetails(ContactDetail);
  }, [ContactDetail]);
  return (
    <>
      <Index.Box className="footer-img">
        <Index.Box
          className="copytext-box"
          sx={{
            width: "100%",
            height: 365,
            alignItems: "center",
            // mt: 5
            // bottom: 0,
            // position: 'fixed',
          }}
        >
          <Index.Container
            className="container container-set"
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              // mt: 8,
            }}
          >
            <Index.Link className="link-style" href="/">
              <Index.Box
                className="footer-logo"
                sx={{
                  height: "120px",
                  width: "120px",
                  position: "relative",
                  alignItems: "center",
                  mt: 10,
                }}
                component="img"
                src={png.footerlogo}
              />
            </Index.Link>
          </Index.Container>
          
          <Index.Container
            className="logoset  container-set"
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              mt: 5,
            }}
          >
            <Index.Grid
              container
              sx={{ width: "400px", justifyContent: "space-around" }}
              columnSpacing={1}
              align="center"
            >

              { 
                contactDetails?.facebook 
                ?
                    <Index.Grid item xs={1.7} md={1.7} lg={1.7}>
                    <Index.Link target="_blank" href={contactDetails?.facebook}>
                      <Index.Box
                        className="social-media-icon"
                        component="img"
                        src={png.fb}
                      />
                    </Index.Link>
                    </Index.Grid>
                : ""
              }            
              
              {
                contactDetails?.instagram 
                ?
                <Index.Grid item xs={1.7} md={1.7} lg={1.7}>
                  <Index.Link target="_blank" href={contactDetails?.instagram}>
                    <Index.Box
                      className="social-media-icon"
                      component="img"
                      src={png.insta}
                    />
                  </Index.Link>
                </Index.Grid>
                : ""
              }

              {                        
                contactDetails?.twitter 
                ?
                <Index.Grid item xs={1.7} md={1.7} lg={1.7}>
                  <Index.Link target="_blank" href={contactDetails?.twitter}>
                    <Index.Box
                      className="social-media-icon"
                      component="img"
                      src={png.twitter}
                    />
                  </Index.Link>
                </Index.Grid>
                : ""
              }

              {                        
                contactDetails?.linkedin 
                ?
                <Index.Grid item xs={1.7} md={1.7} lg={1.7}>
                  <Index.Link target="_blank" href={contactDetails?.linkedin}>
                    <Index.Box
                      className="social-media-icon"
                      component="img"
                      src={png.linkedIn}
                    />
                  </Index.Link>
                </Index.Grid>
                : ""
              }

              {                        
                contactDetails?.youtube 
                ?
                <Index.Grid item xs={1.7} md={1.7} lg={1.7}>
                  <Index.Link target="_blank" href={contactDetails?.youtube}>
                    <Index.Box
                      className="social-media-icon"
                      component="img"
                      src={png.yt}
                    />
                  </Index.Link>
                </Index.Grid>
                : ""
              }

              {                        
                contactDetails?.discord 
                ?
                <Index.Grid item xs={1.7} md={1.7} lg={1.7}>
                  <Index.Link target="_blank" href={contactDetails?.discord}>
                    <Index.Box
                      className="social-media-icon"
                      component="img"
                      src={png.discord}
                    />
                  </Index.Link>
                </Index.Grid>
                : ""
              }

              {                        
                contactDetails?.telegram 
                ?
                <Index.Grid item xs={1.7} md={1.7} lg={1.7}>
                  <Index.Link target="_blank" href={contactDetails?.telegram}>
                    <Index.Box
                      className="social-media-icon"
                      component="img"
                      src={png.telegram}
                    />
                  </Index.Link>
                </Index.Grid>
                : ""
              }

            </Index.Grid>
          </Index.Container>
        </Index.Box>
      </Index.Box>
      <Index.Container>
        <Index.Box
          sx={{
            alignItems: "center",
            // bottom: 0,
            // position: 'fixed',
            height: "48px",
            bgcolor: "white",
            width: "100%",
            display: "flex",
            justifyContent: "space-between;",
          }}
        >
          <Index.Typography
            className="copy-text copy-text2"
            sx={{
              color: "black",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            Ⓒ 2022 All Rights Reserved.
          </Index.Typography>
          
          <Index.Box className="footer-link footer-link2">
            <Link to="/teamsandconditions">
              <Index.Typography
                className="copy-text copy-text2"
                sx={{
                  color: "black",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                Terms and Conditions
              </Index.Typography>
            </Link>
            <Link to="/privacypolicy" className="set-policy-box">
              <Index.Typography
                className="copy-text copy-text2"
                sx={{
                  color: "black",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                Privacy Policy
              </Index.Typography>
            </Link>
          </Index.Box>

          {/* <Index.Box className="footer-link">
            <Link to="/privacypolicy">
              <Index.Typography
                className="copy-text copy-text2"
                sx={{
                  color: "black",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                Privacy Policy
              </Index.Typography>
            </Link>
          </Index.Box> */}

        </Index.Box>
      </Index.Container>
    </>
  );
}
