import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Typography } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import { useSelector, useDispatch } from "react-redux";
import AuthSagaActions from "../../../redux/authsaga/action";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from '@mui/material/Switch';

const { getTransactionHistorySaga } = AuthSagaActions;

const TransactionHistory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const transactionList = useSelector((state) => {
    return state.authSaga.TransactionHistory;
  });

  const token = useSelector((state) => {
    return state.authSaga.idToken;
  });

  const [page, setPage] = useState(0);
  const [transactions, setTransactionList] = useState([]);
  const [rows, Setrows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [active , setActive] = useState(false)
  const [count, Setcount] = useState(0);

  const columns = [
    { id: "userId", label: "Users Id"},
    { id: "userName", label: "User Name"},
    { id: "amount", label: "Amount"},
    { id: "rate", label: "Rate"},
    {
      id: "b1",
      label: "Status",
      minWidth: 20,
      align: "right",
    },
  ];

  function createData(userId, userName, amount, rate, id,i) {
    let b1 = (
      <Switch
        aria-label= {`Size switch demo ${id}`}
        name={id}
        checked={active[i].isActive}
      />
    );
    return {userId, userName, amount, rate, b1};
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    dispatch(getTransactionHistorySaga(navigate, token));
    setTransactionList(transactionList);
  }, [])

  useEffect(() => {
    setTransactionList(transactionList);
    setActive(transactionList?.map((data) =>{return {id:data._id,isActive:data.status==="0"?false:true}}));
  }, [transactionList]); 

  useEffect(() => {
    if(transactions){
      let data = [];
      transactions?.map((element, i) => {
        data = [
          ...data,
          createData(element.userId?._id, element.userId?.userName, element?.amount, element?.rate, element?._id, i),
        ];
      });
      Setrows(data);
    }
  }, [transactions]);

  return (
    <Box className="page-content-main" sx={{mt:3}}>
      <ToastContainer />
      <Box className='main-title-box'>
        <Typography className='main-title' variant="h4">Transaction History</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 5,
        }}
      >
      <FormControl sx={{ m: 1, minWidth: 220, maxHeight: 50 }}></FormControl>
      </Box>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer className="table-main-box">
          <Table stickyHeader aria-label="sticky table" className="table-main">
            <TableHead className="table-head">
              <TableRow className="table-row">
                {columns?.map((column) => (
                  <TableCell className="table-cell"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <h4 style={{ margin: 0 }}>{column.label}</h4>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody className="table-body" sx={{ padding: '18px' }}>

              {rows.length ? rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row) => {
                return (
                  <TableRow className="table-row"
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}
                  >
                    {columns?.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} className="table-cell">
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              }) : "No Data"}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default TransactionHistory;
