import React, { useState, useEffect } from "react";
import Index from "../Index";

const { userChangePasswordSaga } = Index.AuthSagaActions;

export const ChangePassword = () => {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const isLoginUser = Index.useSelector((state) => { return state.authSaga.userProfile });

  const [editId, setEditId] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [errorObject,setErrorObject] = useState({});

  const validationFun=(oldPassword,password,newPassword)=>
  {
      let errors = {};
      if (oldPassword === "" || oldPassword.length < 8) {
        errors.oldPassword = "Enter at least 8 characters in Old Password";
      }

      if(password==="" || password.length<8){
        errors.password = "Enter at least 8 characters in New Password";
      } else if(password==="" || password.length>20){
        errors.password = "Enter not more then 20 characters in New Password";
      }

      if(newPassword !== password){
        errors.newPassword = "Password and Confirm Password are not matched";
      } 

      return errors;
  }

  const handleSubmit = (e) => {
      if (Object.keys(validationFun(oldPassword,password,newPassword)).length === 0) {
          handleChangePassword();
          setErrorObject({});
      } else {
          setErrorObject(validationFun(oldPassword,password,newPassword))
      }
  };

  const handleChangePassword = async () => {
    const apidata = new URLSearchParams();
    apidata.append("id", editId);
    apidata.append("oldPassword", oldPassword.trim());
    apidata.append("newPassword", newPassword.trim());
    dispatch(userChangePasswordSaga(apidata, navigate));
    setOldPassword("");
    setPassword("");
    setNewPassword("");
  };

  useEffect(()=>{
    if(isLoginUser){
      setEditId(isLoginUser._id);
    }
  }, [isLoginUser])

  return (
    <div>
      <Index.Box className=" login-main-box">
        <Index.Box className="login-box">
          <Index.Box className="login-container">
            <Index.Link className="link-style" href="/">
              <Index.Box className="login-logo">
                <img src={Index.Png.logo} className="" alt=""></img>
              </Index.Box>
            </Index.Link>
            <Index.Box className="login-inner-text">
              <Index.Typography variant="p" component="p">
                Change Password
              </Index.Typography>
            </Index.Box>
            <Index.Box className="user-box">
              <Index.Box className="gap-cus">
                <Index.Box className="connect-title ">
                  <Index.Typography className="user-box" varient="p">
                    Old Password
                  </Index.Typography>
                </Index.Box>

                <Index.Box className="form-group txtfeld-cus validation">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    error={errorObject['oldPassword'] !== undefined}
                    className="form-control form-control-login"
                    value={oldPassword}
                    type="password"
                    onChange={(e) => setOldPassword(e.target.value)}
                    helperText={errorObject?.oldPassword}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="password-box">
              <Index.Box className="gap-cus ">
                <Index.Box className="connect-title ">
                  <Index.Typography className="user-box" varient="p">
                    New Password
                  </Index.Typography>
                </Index.Box>

                <Index.Box className="form-group txtfeld-cus validation">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    error={errorObject['password'] !== undefined}
                    className="form-control form-control-login"
                    value={password}
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    helperText={errorObject?.password}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="password-box">
              <Index.Box className="gap-cus ">
                <Index.Box className="connect-title ">
                  <Index.Typography className="user-box" varient="p">
                    Confirm Password
                  </Index.Typography>
                </Index.Box>

                <Index.Box className="form-group txtfeld-cus validation">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    error={errorObject['newPassword'] !== undefined}
                    className="form-control form-control-login"
                    value={newPassword}
                    type="password"
                    onChange={(e) => setNewPassword(e.target.value)}
                    helperText={errorObject?.newPassword}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="change-btn-set">
              <Index.Box className="change-btn change-btn3 ">
                <Index.Link className="link-style" href="/">
                  <Index.Button variant="contained">Cancel</Index.Button>
                </Index.Link>
              </Index.Box>

              <Index.Box className="login-but-account change-btn change-btn2 ">
                <Index.Button
                  onClick={() => handleSubmit()}
                  variant="contained"
                >
                  Save
                </Index.Button>
              </Index.Box>
            </Index.Box>
            <Index.Box></Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.ToastContainer />
      </Index.Box>
    </div>
  );
};
