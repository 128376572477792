import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { ToastContainer } from "react-toastify";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuthSagaActions from "../../../redux/authsaga/action";
import Parser from "html-react-parser";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Swal from "sweetalert2";

const { addClients,updateClients,deleteClient,getClients } = AuthSagaActions;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  maxHeight: "70vh",
  bgcolor: "background.paper",
  overflow: "auto",
  boxShadow: 24,
  p: 4,
};

const Clients = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const clients = useSelector((state) => {
    return state.authSaga.Clients;
  });
  const token = useSelector((state) => {
    return state.authSaga.idToken;
  });

  const [page, setPage] = useState(0);
  const [Url, Seturl] = useState();
  const [clientList, SetClientList] = useState([]);
  const [rows, Setrows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [Name, SetName] = useState("");
  const [Editid, Seteditid] = useState("");
  const [count, Setcount] = useState(0);
  const [Images, Setimages] = useState();
  const [Designation, SetDesignation] = useState("");
  const [Description, Setdescription] = useState("Description...");
  const [ImagesError, SetimagesError] = useState("");
  const [errorObject,setErrorObject] = useState({});

  const columns = [
    { id: "Name", label: "Name", minWidth: 170 },
    {
      id: "Description",
      label: "Description",
      minWidth: 270,
    },
    {
      id: "Designation",
      label: "Designation",
      minWidth: 170,
    },
    // {
    //   id: "Year",
    //   label: "",
    //   minWidth: 170,
    // },
    {
      id: "editbtn",
      label: "Action",
      minWidth: 50,
      align: "right",
    },
    {
      id: "removebtn",
      label: "",
      minWidth: 50,
      align: "right",
    },
  ];

  const imagehendeler = (e) => {
    let urllink = window.URL.createObjectURL(e[0]);
    const imageFile = e[0];
    if (!imageFile.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      Seturl("");
      SetimagesError("Please upload valid image of jpg,png,gif");
      return false;
    } else{
      SetimagesError("");
      Seturl(urllink);
    }
  };

  function createData(Name, Description, id, Designation) {
    let editbtn = (
      <EditIcon 
          sx={{cursor:'pointer'}}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            Seteditid(id);
            Setcount((pre) => pre + 1);
          }}
        />
    );
    let removebtn = (
      <DeleteIcon sx={{cursor:'pointer'}} onClick={() => deletehandler(id)} />
    );
    return { Name, Description, Designation, editbtn, removebtn };
  }

  const handleChangePage = (event, featurePage) => {
    setPage(featurePage);
  };

  useEffect(() => {
    clientList &&
      clientList[0] &&
      clientList.map((element, index) => {
        if (element._id === Editid) {
          setErrorObject({});
          SetimagesError("");
          SetName(element.name);
          Setdescription(element.description);
          Seturl(element.images);
          SetDesignation(element.position);
          Setimages([]);
        }
      });
    if (Editid === "") {
      setErrorObject({});
      SetimagesError("");
      SetName("");
      Setdescription("");
      Seturl();
      SetDesignation("");
      Setimages("");
    }
  }, [Editid, count]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deletehandler = async (id) => {
    Swal.fire({
      title: "",
      text: "Are you sure you want to delete client ?",
      icon: "",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let urlencoded = new URLSearchParams();
        urlencoded.append("id", id);
        urlencoded.append("deletstatus", "1");
        dispatch(deleteClient(urlencoded, navigate));
      }
    });
  };

  const validationFun=(Name,Designation,Description)=>{
    let errors = {};
    if(Name == "" || Name.replace( /\s\s+/g, ' ')?.length<3){
        errors.Name = "Enter at least 3 characters in Name";
    } else if(Name.replace( /\s\s+/g, ' ') == "" || Name.replace( /\s\s+/g, ' ')?.length>25){
        errors.Name = "Enter not more then 25 characters in Name";
    }
  
    if(Designation == "" || Designation.replace( /\s\s+/g, ' ')?.length<2){
        errors.Designation = "Enter at least 2 characters in Designation";
    } else if(Designation.replace( /\s\s+/g, ' ') == "" || Designation.replace( /\s\s+/g, ' ')?.length>25){
        errors.Designation = "Enter not more then 25 characters in Designation";
    } 
  
    if(Description == "" || Description.replace( /\s\s+/g, ' ')?.length<3){
        errors.Description = "Enter at least 3 characters in Description";
    }
    return errors;
  }
  
  const handleValidation = (e) => {
    if (Object.keys(validationFun(Name,Designation,Description,Images)).length === 0) {
        handleSubmit();
        setErrorObject({});
    } else {
        setErrorObject(validationFun(Name,Designation,Description,Images))
    }
   // console.log(validationFun(Name,Designation,Description,Images));
  };
  
  const handleSubmit = () => {
    if(ImagesError !== ""){
      SetimagesError("Please upload image");
    } else {
        const apidata = new FormData();
        apidata.append("name", Name);
        apidata.append("description", Description);
        apidata.append("position", Designation);
        apidata.append("images", Images);
        if (Editid !== "") {
          apidata.append("id", Editid);
          apidata.append('fileurl', Url)
        }
      
        Editid===""?dispatch(addClients(apidata, navigate, token)):dispatch(updateClients(apidata, navigate, token))
        SetName("");
        Setdescription("");
        Seturl();
        SetDesignation("");
        Setimages("");
        setAnchorEl(null);
    }
  };

  useEffect(() => {
    dispatch(getClients(navigate));
    SetClientList(clients);
  }, []);

  useEffect(() => {
    SetClientList(clients);
  }, [clients]);

  useEffect(() => {
    let data = [];
    clientList &&
      clientList[0] &&
      clientList.map((element, index) => {
        data = [
          ...data,
          createData(
            element.name,
            <td>{Parser(element.description)}</td>,
            element._id,
            element.position
          ),
        ];
        
      });
      Setrows(data);
  }, [clientList]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <Box className="page-content-main">
        <ToastContainer />
        <Box sx={{ w: 100 }}>
          <Box className='main-title-box'>
            <Typography className='main-title' variant="h4">Our Clients</Typography>
          </Box>
          <Box sx={{ float: "right" }}>
            <FormControl
              sx={{ m: 1, minWidth: 220, maxHeight: 50 }}
            ></FormControl>
            <Button
              variant="contained"
              sx={{
                display:"flex",
                justifyContent:"flex-end",
                alignItems:"flex-end",
                maxHeight: 50,
                marginLeft:"auto",
                marginBottom:"17px",
                backgroundColor:'#271e09',
                "&:hover": { backgroundColor: "#f49d04", opacity: [0.9, 0.8, 0.7] },
              }}
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                Seteditid("");
                //   Setcount(count + 1);
              }}
            >
              Add Client
            </Button>
          </Box>

          <Box>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer className="table-main-box">
                <Table stickyHeader aria-label="sticky table" className="table-main">
                  <TableHead className="table-head">
                    <TableRow className="table-row">
                      {columns.map((column) => (
                        <TableCell className="table-cell"
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          <h4 style={{ margin: 0 }}>{column.label}</h4>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow className="table-row"
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <>
                                  <TableCell className="table-cell"
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                </>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                    {
                      Url && Url !== "" ? (
                        <img
                          height={80}
                          width={100}
                          style={{ margin: "auto" }}
                          src={Url && Url !== "" ? Url : ""}
                          alt=""
                        ></img>
                      ) : ""
                    }

                      <label
                        htmlFor="contained1-button-file"
                        style={{ margin: "auto", marginTop: "15px" }}
                      >
                        <Button
                          variant="contained"
                          component="label"
                          sx={{ width: "150px" }}
                        >
                          Upload Image
                          <input
                            type="file"
                            hidden
                            onChange={(e) => {
                              console.log(e.target.files[0], "Aaaaaaaaaaa");
                              Setimages(e.target.files[0]);
                              imagehendeler(e.target.files);
                            }}
                          />
                        </Button>
                        <FormHelperText sx={{ color: "#d32f2f" }}>{ImagesError}</FormHelperText>
                      </label>
                    </Box>
                    <Box
                      sx={{
                        margin: "auto",
                        width: 500,
                        maxWidth: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                      className="validation"
                    >
                      <TextField
                        sx={{ marginTop: 2 }}
                        required
                        fullWidth
                        error={errorObject['Name'] !== undefined}
                        label="Name"
                        id="Name"
                        value={Name}
                        onChange={(e) => {
                          SetName(e.target.value);
                        }}
                        helperText={errorObject?.Name}
                      />
                      
                      <TextField
                        sx={{ marginTop: 2 }}
                        required
                        fullWidth
                        error={errorObject['Designation'] !== undefined}
                        type="text"
                        label="Designation"
                        id="Designation"
                        value={Designation}
                        onChange={(e) => SetDesignation(e.target.value)}
                        helperText={errorObject?.Designation}
                      />
                      <br />

                      <FormHelperText sx={{ fontSize: 14, color:'black' }}>Short Description *</FormHelperText>
                      <CKEditor
                        editor={ClassicEditor}
                        data={Description}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          Setdescription(data);
                        }}
                      />
                      <FormHelperText sx={{ color: "#d32f2f" }}>{errorObject?.Description}</FormHelperText>
                      <br />
                      <Box className="save-btn-box">
                        <Button
                         className="save-btn"
                          variant="contained"
                          onClick={() => handleValidation()}
                        >
                          Submit
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Box>
                {/* </Popover> */}
              </Modal>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Clients;
