import React from "react";
import Header from "../../../Component/user/defaultLayout/Header";
import Index from "../../../Component/Index";

const Deposite = () => {
  return (
    <>
      <Header />

      <Index.Box className="third-phase-section comming-section">
        <Index.Box className="container">
          <Index.Box className="comming-main">
            <Index.Typography variant="h4">comming soon</Index.Typography>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default Deposite;
