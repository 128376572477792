import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { ToastContainer } from "react-toastify";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuthSagaActions from "../../../../redux/authsaga/action"

const { addEditIdoLearn, getIdoLearn } = AuthSagaActions;
const IdoLearn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const idoList = useSelector((state) => {
    //console.log('Bharat == ', state.authSaga);
    return state.authSaga.IdoLearnList;
  });
  
  const [idoLearnList, setIdoLearnlist] = useState([]);
  const [Title, Settitle] = useState("");
  const [Editid, Seteditid] = useState("");
  const [Description, Setdescription] = useState("Description...");
  const [TitleError, Settitleerror] = useState("");
  const [DescriptionError, Setdescriptionerror] = useState("");

  useEffect(() => {
    idoLearnList &&
      idoLearnList[0] &&
      idoLearnList.map((element, index) => {
        if (element._id || element._id === Editid) {
          Settitle(element.title);
          Setdescription(element.description);
        }
      });
  }, [idoLearnList]);

  const handelSubmit = () => {
    if (Title === "") {
      Settitleerror("Please enter title");
    } else {
      Settitleerror("");
    }

    if (Description === "") {
      Setdescriptionerror("Please enter description");
    } else {
      Setdescriptionerror("");
    }

    if (Title !== "" && Description !== "") {
      const apidata = new URLSearchParams();
      apidata.append("title", Title);
      apidata.append("description", Description);
      if (Editid !== "") {
        apidata.append("editid", Editid);
      }
      dispatch(addEditIdoLearn(apidata, navigate));
    }
  };

  useEffect(() => {
    dispatch(getIdoLearn(navigate));
    setIdoLearnlist(idoList);
  }, []);

  useEffect(() => {
    setIdoLearnlist(idoList);
  }, [idoList]);

  useEffect(() => {
    idoLearnList &&
      idoLearnList[0] &&
      idoLearnList.map((element, index) => {
        Seteditid(element._id);
      });
  }, [idoLearnList]);

  return (
    <>
      <Box className="page-content-main">
        <ToastContainer />
        <Box sx={{ w: 100 }}>
          <Box className='main-title-box'>
            <Typography className='main-title' variant="h4">IDO Learn Solution</Typography>
          </Box>
          <Box>
            <Box sx={{ mt: 3 }}>
              <Grid container>

                <Grid item xs={12} sm={12}  md={3} lg={9}>
                  <Box className="ckeditor-main">
                    <TextField
                      sx={{ marginTop: 2 }}
                      required
                      fullWidth
                      label="Title"
                      id="Title"
                      value={Title}
                      onChange={(e) => {
                        Settitle(e.target.value);
                      }}
                    />
                    <FormHelperText sx={{ color: "red", ml: 1, mb: 1 }}>
                      {TitleError}
                    </FormHelperText>
                    <Box className="ckeditor-main">
                      <CKEditor
                        editor={ClassicEditor}
                        data={Description}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          //console.log(data);
                          Setdescription(data);
                        }}
                        className="ckeditor-tag"
                      />
                    </Box>
                    <FormHelperText sx={{ color: "red" }}>
                      {DescriptionError}
                    </FormHelperText>
                    <Box className="save-btn-box">
                      <Button className="save-btn"
                        variant="contained"
                        onClick={() => {
                          handelSubmit();
                        }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default IdoLearn;
