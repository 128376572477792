import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Button, Typography } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import DeleteIcon from '@mui/icons-material/Delete';
import FormControl from "@mui/material/FormControl";
import { Grid, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import AuthSagaActions from "../../../redux/authsaga/action";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Swal from "sweetalert2";

import "react-toastify/dist/ReactToastify.css";
import Modal from "@mui/material/Modal";

const {
  addWhiteListedUser,
  getWhiteListedUser,
  removeWhiteListedUser,
} = AuthSagaActions;

const Input = styled("input")({
  display: "none",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  maxHeight: "70vh",
  bgcolor: "background.paper",
  overflow: "auto",
  boxShadow: 24,
  p: 4,
};

const WhiteListUser = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const whiteListUserList = useSelector((state) => {
    return state.authSaga.whitelistUser;
  });

  const token = useSelector((state) => {
    return state.authSaga.idToken;
  });
  const [page, setPage] = useState(0);
  const [userList, Setuserlist] = useState([]);
  const [rows, Setrows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [email, SetEmail] = useState("");
  const [count, Setcount] = useState(0);
  const [Files, setFiles] = useState("");
  const [FileError, setFileError] = useState("");
  const [EmailError, setEmailError] = useState("");

  const columns = [
    { id: "Email", label: "Email", minWidth: 170 },
    {
      id: "b3",
      label: "Action",
      minWidth: 70,
      align: "right",
    },
  ];

  function createData(Email, id) {
    let b3 = (
      <DeleteIcon sx={{cursor:'pointer'}} onClick={() => deletehandler(id)} />
    );
    return { Email, b3 };
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    userList?.map((element, index) => {
      SetEmail(element.name);
    });
  }, [whiteListUserList, userList]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const deletehandler = async (id) => {
    Swal.fire({
      title: "",
      text: "Are you sure you want to delete this email ?",
      icon: "",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let urlencoded = new URLSearchParams();
        urlencoded.append("id", id);
        dispatch(removeWhiteListedUser(urlencoded, navigate, token));
      }
    });
  };

  const handelSubmit = () => {
    let regEmail = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if(email === "" || email === undefined) {
      setEmailError("Please enter email");
      return false;
    } else if(email.trim() === "" || !regEmail.test(email)){
      setEmailError("Enter valid email");
      return false;
    } else {
      setEmailError("");
    }

    if (email !== "") {
      const apidata = new FormData();
      apidata.append("email", email);
      dispatch(addWhiteListedUser(apidata, navigate, token));
      setAnchorEl(null);
    }
  };

  const fileHandler = (e) => {
    const imageFile = e[0];
    if (!imageFile.name.match(/\.(doc|docx|xls|xlsx)$/)) {
      setFileError("Please upload valid file type");
      return false;
    } else{
      setFileError("");
    }
  };

  const FileSubmit = () => {
    if (Files === "") {
      setFileError("Please select file");
      return false;
    }
    if (Files !== "" && FileError === "") {
      const apidata = new FormData();
      apidata.append("csv", Files[0]);
      dispatch(addWhiteListedUser(apidata, navigate, token));
      setAnchorEl1(null);
    }
  };

  useEffect(() => {
    dispatch(getWhiteListedUser(navigate,token));
    Setuserlist(whiteListUserList);
  }, []);

  useEffect(() => {
    Setuserlist(whiteListUserList);
  }, [whiteListUserList]);

  useEffect(() => {
    let data = [];
    userList?.map((element, index) => {
      data = [...data, createData(element.email, element._id)];
    });
    Setrows(data);
  }, [userList]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? "simple-popover" : undefined;

  return (
    <Box className="page-content-main">
      <ToastContainer />
      <Box className="main-title-box">
        <Typography className="main-title" variant="h4">
          WhiteList User
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 2,
        }}
      >
        <FormControl sx={{ m: 1, minWidth: 220, maxHeight: 50 }}></FormControl>
        <Button
          variant="contained"
          sx={{
            maxHeight: 50,
            marginTop: 2,
            backgroundColor: "#271e09",
            "&:hover": { backgroundColor: "#f49d04", opacity: [0.9, 0.8, 0.7] },
            mx: 2,
          }}
          onClick={(event) => {
            setFiles("");
            setFileError("");
            setAnchorEl1(event.currentTarget);
            Setcount(count + 1);
          }}
        >
          Import File
        </Button>
        <Button
          variant="contained"
          sx={{
            maxHeight: 50,
            marginTop: 2,
            backgroundColor: "#271e09",
            "&:hover": { backgroundColor: "#f49d04", opacity: [0.9, 0.8, 0.7] },
          }}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            setEmailError("");
            Setcount(count + 1);
          }}
        >
          Add New
        </Button>
      </Box>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer className="table-main-box">
          <Table stickyHeader aria-label="sticky table" className="table-main">
            <TableHead className="table-head">
              <TableRow className="table-row">
                {columns?.map((column) => (
                  <TableCell
                    className="table-cell"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <h4 style={{ margin: 0 }}>{column.label}</h4>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="table-body" sx={{ padding: "18px" }}>
              {rows.length
                ? rows
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row) => {
                      return (
                        <TableRow
                          className="table-row"
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          {columns?.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                className="table-cell"
                              >
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                : "No Any Record Available"}
            </TableBody>
          </Table>
        </TableContainer>

        <Modal
          open={open1}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid item xs={6}>
              <Box
                sx={{
                  margin: "auto",
                  width: 400,
                  maxWidth: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                  <Typography>{Files ? Files[0].name : ""}</Typography>
                </Box>

                <label
                  htmlFor="contained-button-file"
                  style={{ margin: "auto", marginTop: "15px" }}
                >
                  <Input
                    accept="csv/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    alt=""
                    onChange={(e) => {
                      setFiles(e.target.files);
                      fileHandler(e.target.files);
                    }}
                  />
                  <Button
                    variant="contained"
                    sx={{ width: "180px" }}
                    component="span"
                  >
                    Upload File
                  </Button>
                </label>
                <FormHelperText sx={{ color: "#d32f2f" }}>
                  {FileError}
                </FormHelperText>

                <br />

                <Box className="save-btn-box">
                  <Button
                    className="save-btn"
                    variant="contained"
                    onClick={() => FileSubmit()}
                  >
                    Submit
                  </Button>
                </Box>
                
              </Box>
            </Grid>
          </Box>
        </Modal>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid item xs={6}>
              <Box
                sx={{
                  margin: "auto",
                  width: 400,
                  maxWidth: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <TextField
                  sx={{ my: 2 }}
                  fullWidth
                  label="Email"
                  id="Email"
                  value={email}
                  onChange={(e) => SetEmail(e.target.value)}
                />
                <FormHelperText sx={{ color: "#d32f2f",mt:-2}}>
                  {EmailError}
                </FormHelperText>
                <br />
                <Box className="save-btn-box">
                  <Button
                    className="save-btn"
                    variant="contained"
                    onClick={() => handelSubmit()}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Box>
        </Modal>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default WhiteListUser;
