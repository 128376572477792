import React, { useEffect, useState } from "react";
import { Box, Card, Button, Grid, TextField, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AuthSagaActions from "../../../redux/authsaga/action";

const { addEditWebsiteSetting, getWebsiteSetting } = AuthSagaActions;

const WebsiteSetting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const websiteData = useSelector((state) => {
    return state.authSaga.website;
  });

  const [title, SetTitle] = useState("");
  const [id, SetId] = useState("");
  const [Wname, SetWname] = useState("");
  const [logo, Setlogo] = useState("");
  const [fevicon, Setfevicon] = useState("https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg");
  const [Url, Seturl] = useState("");
  const [Url2, Seturl2] = useState("");
  const [error, setError] = useState("");
  const [logoError, setLogoError] = useState("");
  const [favLogoError, setFavLogoError] = useState("");

  const getWebsiteSettingData = () => {
    websiteData?.map((row) => {
      SetId(row[0]?._id ? row[0]._id : "");
      SetTitle(row[0]?.title ? row[0]?.title : "");
      SetWname(row[0]?.name ? row[0]?.name : "");
      Seturl(row[0]?.logo ? row[0]?.logo : "");
      Seturl2(row[0]?.favicon ? row[0]?.favicon : "");
    });
  };

  useEffect(() => {
    if(websiteData){
      getWebsiteSettingData();
    }
  }, [websiteData]);


  const imagehendeler = (e) => {
    let urllink = window.URL.createObjectURL(e[0]);
    const imageFile = e[0];
    if (!imageFile.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setLogoError("Please upload valid logo of jpg,png,gif");
      setError("");
      Seturl(fevicon);
      return false;
    } else{
      setLogoError("");
      Seturl(urllink);
    }
  };


  const imagehendelerfev = (e) => {
    let urllink = window.URL.createObjectURL(e[0]);
    const imageFile = e[0];
    if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|ico)$/)) {
      setFavLogoError("Please upload valid fav icon of jpg,png,gif,ico");
      setError("");
      Seturl2(fevicon);
      return false;
    } else{
      setFavLogoError("");
      Seturl2(urllink);
    }
  };

  useEffect(() => {
    dispatch(getWebsiteSetting(navigate));
  }, []);

  const handelSubmit = () => {
    if(title === undefined || title.trim() ==="" || title.trim()?.length<3){
      setError("Enter at least 3 characters in website title")
      return false;
    }
    else if(title.trim() ==="" || title.trim()?.length>25){
      setError("Enter not more then 25 characters in website title")
      return false;
    } else {
      setError("");
    }

    if(Url == "" || Url == undefined){
      setLogoError("Please upload logo image");
      return false;
    }
    else if(Url2 == "" || Url2 == undefined){
      setFavLogoError("Please upload fav icon image");
      return false;
    }
    else {
      setLogoError("");
      setFavLogoError("");
    }

    if (title !== "" && logoError == "" && favLogoError == "") {
      let urlencoded = new FormData();
      if (id != "") {
        urlencoded.append("id", id);
      }
      urlencoded.append("name", Wname);
      urlencoded.append("title", title);
      urlencoded.append("logo", logo ? logo : "");
      urlencoded.append("favicon", fevicon ? fevicon : "");
      urlencoded.append("fileurl", Url);
      urlencoded.append("sourcefileurl", Url2);
      dispatch(addEditWebsiteSetting(urlencoded, navigate));
    }
  };

  return (
    <>
      <Box className="page-content-main">
        <ToastContainer />
        <Box sx={{ height: 100, width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Box className='main-title-box'>
              <Typography className='main-title' variant="h4">Website Setting</Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box sx={{ m: 3, width: "100%" }}>
    
                <Box sx={{ display: "flex", justifyContent: "center",}}>
                  
                  <Card className="websitesetting-card set-web" >
                    <TextField
                      sx={{ marginTop: 2 }}
                      fullWidth
                      required
                      size="small"
                      label="Website Title"
                      id="title"
                      value={title}
                      onChange={(e) => SetTitle(e.target.value)}
                    />
                   
                    <TextField
                      sx={{ marginTop: 2 }}
                      fullWidth
                      required
                      className="textbox-label"
                      size="small"
                      label="Website Name"
                      id="Wname"
                      value={Wname}
                      onChange={(e) => {
                        SetWname(e.target.value);
                      }}
                    />
                    <br />
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item lg={6} xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              className="res-img"
                              height={80}
                              width={80}
                              style={{
                                borderRadius: "50%",
                                margin: "auto",
                                marginTop: "10px",
                              }}
                              alt=""
                              src={Url && Url !== "" && Url !== "undefined" ? Url : fevicon}
                            ></img>
                            <label
                              htmlFor="contained1-button-file"
                              style={{ margin: "auto", marginTop: "15px" }}
                            >
                              <Button
                              className="set-web-btn"
                                variant="contained"
                                component="label"
                                sx={{ width: "150px" ,  backgroundColor:'#271e09',
                                "&:hover": { backgroundColor: "#f49d04", opacity: [0.9, 0.8, 0.7] }, }}
                              >
                                Update Logo
                                <input
                                  type="file"
                                  hidden
                                  onChange={(e) => {
                                    Setlogo(e.target.files[0]);
                                    imagehendeler(e.target.files);
                                  }}
                                />
                              </Button>
                            </label>
                            <Typography className='main-title' variant="p" sx={{color:'red'}}>{logoError}</Typography>
                          </Box>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              className="res-img"
                              height={80}
                              width={80}
                              style={{
                                borderRadius: "50%",
                                margin: "auto",
                                marginTop: "10px",
                              }}
                              alt=""
                              src={Url2 && Url2 !== "" && Url2 !== "undefined" ? Url2 : fevicon}
                            ></img>
                            <label
                              htmlFor="contained-button-file"
                              style={{ margin: "auto", marginTop: "15px" }}
                            >
                              <Button
                               className="set-web-btn"
                                variant="contained"
                                component="label"
                                sx={{ width: "150px",   backgroundColor:'#271e09',
                                "&:hover": { backgroundColor: "#f49d04", opacity: [0.9, 0.8, 0.7] }, }}
                              >
                                Update Favicon
                                <input
                                  type="file"
                                  hidden
                                  onChange={(e) => {
                                    Setfevicon(e.target.files[0]);
                                    imagehendelerfev(e.target.files);
                                  }}
                                />
                              </Button>
                            </label>
                            <Typography className='main-title' variant="p" sx={{color:'red'}}>{favLogoError}</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    
                    <Box className="save-btn-box save-btn-box1" sx={{justifyContent:"center", mt:4, mb:4}}>
                      <Button className="save-btn"
                        variant="contained"
                        onClick={() => {
                          handelSubmit();
                        }}
                      >
                        Save
                      </Button>
                    </Box>
                    <Typography className='main-title' variant="p" sx={{color:'red'}}>{error}</Typography>
                    <br />
                  </Card>
                </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default WebsiteSetting;