import React, {useState,useEffect} from 'react'
import Index from '../Index';
const { userResetPasswordSaga } = Index.AuthSagaActions;

export default function Reset() {

    const dispatch = Index.useDispatch();
    const navigate = Index.useNavigate();
    const { key } =  Index.useParams();

    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [resetLink, setResetLink] = useState("");
    const [errorObject,setErrorObject] = useState({});

    useEffect(()=> {
        if(key){
          setResetLink(key);
        }
    }, [key])

    const validationFun = (password,newPassword) => {
        let errors = {};
        if(password==="" || password.length<8){
            errors.password = "Enter at least 8 characters in New Password";
        }
        else if(password==="" || password.length>20){
            errors.password = "Enter not more then 20 characters in New Password";
        }

        if(newPassword !== password){
            errors.newPassword = "Password and Confirm Password are not matched";
        } 
        return errors;
      };

    const handleSubmit = (e) => {
        if (Object.keys(validationFun(password,newPassword)).length === 0) {
            handleResetPassword();
            setErrorObject({});
        } else {
            setErrorObject(validationFun(password,newPassword))
        }
    };

    const handleResetPassword = async () => {
        let apidata = new URLSearchParams();
        apidata.append("newPass", newPassword.trim());
        apidata.append("resetLink", resetLink.trim());
        dispatch(userResetPasswordSaga(apidata, navigate));
        setPassword("");
        setNewPassword("");
    };

    return (
        <div>
            <Index.Box className=' login-main-box reset-bg'>
                <Index.Box className='login-box'>
                    <Index.Box className='login-container'>
                        <Index.Link className="link-style" href="/">
                            <Index.Box className='login-logo'>
                                <img src={Index.Png.logo} className="" alt=""></img>
                            </Index.Box>
                        </Index.Link>
                        <Index.Box className='login-inner-text reset-text'>
                            <Index.Typography variant='p' component="p" >Reset Password </Index.Typography>
                        </Index.Box >
                        <Index.Box className="user-box email">
                            <Index.Box className='gap-cus forgot-pass'>
                                <Index.Box className='connect-title '>
                                    <Index.Typography className='user-box' varient='p'>
                                        Create New Password *
                                    </Index.Typography>
                                </Index.Box>

                                <Index.Box className="form-group txtfeld-cus forgotpass-input validation">
                                    <Index.TextField
                                        fullWidth
                                        id="fullWidth"
                                        error={errorObject['password'] !== undefined}
                                        className="form-control form-control-login form-forgot-pass"
                                        placeholder="Enter New Password"
                                        autocomplete="off"
                                        value={password}
                                        type="password"
                                        onChange={(e) => setPassword(e.target.value)}
                                        helperText={errorObject?.password}
                                    />
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                        <Index.Box className="user-box email">
                            <Index.Box className='gap-cus forgot-pass'>
                                <Index.Box className='connect-title '>
                                    <Index.Typography className='user-box' varient='p'>
                                        Confirm New Password *
                                    </Index.Typography>
                                </Index.Box>

                                <Index.Box className="form-group txtfeld-cus forgotpass-input validation">
                                    <Index.TextField
                                        fullWidth
                                        id="fullWidth"
                                        error={errorObject['newPassword'] !== undefined}
                                        className="form-control form-control-login form-forgot-pass"
                                        placeholder="Enter Confrim Password"
                                        autocomplete="off"
                                        value={newPassword}
                                        type="password"
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        helperText={errorObject?.newPassword}
                                    />
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>

                        <Index.Box className="user-box email">
                            <Index.Box className='gap-cus forgot-pass'>
                                <Index.Box className='connect-title '>
                                    <Index.Typography className='user-box' varient='p'>
                                        Reset Code *
                                    </Index.Typography>
                                </Index.Box>

                                <Index.Box className="form-group txtfeld-cus forgotpass-input">
                                    <Index.TextField
                                        fullWidth
                                        id="fullWidth"
                                        disabled
                                        className="form-control form-control-login form-forgot-pass"
                                        placeholder="Enter Confrim Password"
                                        autocomplete="off"
                                        value={resetLink}
                                        type="text"
                                        onChange={(e) => setResetLink(e.target.value)}
                                    />
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>

                        <Index.Box className='login-but-account reser-pw-btn'>
                            <Index.Button onClick={() => handleSubmit()} variant="">Change Password</Index.Button>
                        </Index.Box>

                        <Index.Box>
                            <Index.Box className='signup-text backto-text-fotgot'>
                                <Index.Typography variant='p' component="p" className='icon-text'>
                                    <Index.Box className='backto-text'>
                                        <Index.RoutLink className="link-style link-style2" to="/login">Back to</Index.RoutLink>
                                        <Index.RoutLink className="link-style" to="/login"><span>Login</span> </Index.RoutLink>
                                    </Index.Box>
                                </Index.Typography>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
                <Index.ToastContainer />
            </Index.Box >
        </div >
    )
}