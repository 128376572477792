import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Button } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Popover from "@mui/material/Popover";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Grid, TextField, Typography } from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useSelector, useDispatch } from "react-redux";
import AuthSagaActions from "../../../redux/authsaga/action";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Parser from "html-react-parser";
import CloseIcon from "@mui/icons-material/Close";
import "react-toastify/dist/ReactToastify.css";
import Modal from "@mui/material/Modal";
const { addnewssaga, getnewssaga, rmovenewssaga,updatenewssaga } = AuthSagaActions;
const Input = styled("input")({
  display: "none",
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  maxHeight: "70vh",
  bgcolor: "background.paper",
  overflow: "auto",
  // display:"flex",flexDirection:'column',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const Newsmanagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const newslist = useSelector((state) => {
    return state.authSaga.newsList;
  });
  console.log(newslist,51);
  const logging = useSelector((state) => {
    return state.authSaga.loading;
  });
  const token = useSelector((state) => {
    return state.authSaga.idToken;
  });
  const [page, setPage] = React.useState(0);
  const [Url, Seturl] = React.useState([]);
  const [NewsList, Setnewslist] = React.useState([]);
  const [rows, Setrows] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [age, setAge] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [Title, Settitle] = useState("");
  const [Editid, Seteditid] = useState("");
  const [count, Setcount] = useState(0);
  const [OldImages, SetOldimages] = useState([]);
  const [Images, Setimages] = useState();
  const [Description, Setdescription] = useState("Description...");
  const [TitleError, Settitleerror] = useState("");
  const [DescriptionError, Setdescriptionerror] = useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const columns = [
    { id: "Title", label: "News Title", minWidth: 170 },
    {
      id: "Description",
      label: "Description",
      minWidth: 270,
    },
    {
      id: "b2",
      label: "",
      minWidth: 70,
      align: "right",
    },
    {
      id: "b3",
      label: "",
      minWidth: 70,
      align: "right",
    },
  ];
  const imagehendeler = (e) => {
    let temp = [];
    [...e].map((element) => {
      temp = [...temp, window.URL.createObjectURL(element)];
      Seturl(temp);
    });
  };
  function createData(Title, Description, id) {
    // let b1=<Button variant="contained">View</Button>
    let b2 = (
      <Button
        variant="contained"
        sx={{
          backgroundColor:'#271e09',
          "&:hover": { backgroundColor: "#f49d04", opacity: [0.9, 0.8, 0.7] },
        }}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          Seteditid(id);
          Setcount((pre) => pre + 1);
        }}
      >
        Edit
      </Button>
    );
    let b3 = (
      <Button 
        variant="contained" 
        sx={{
          backgroundColor:'#271e09',
          "&:hover": { backgroundColor: "#f49d04", opacity: [0.9, 0.8, 0.7] },
        }}
        onClick={() => deletehandler(id)}>
        Remove
      </Button>
    );
    return { Title, Description, b2, b3 };
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  useEffect(() => {
    NewsList &&
      NewsList[0] &&
      NewsList.map((element, index) => {
        if (element._id === Editid) {
          Settitle(element.Title);
          Setdescription(element.Description);
          SetOldimages(element.Images);
          Seturl([]);
          Setimages([]);
        }
      });
    if (Editid === "") {
      Settitle("");
      Setdescription("");
      SetOldimages([]);
      Seturl([]);
      Setimages([]);
    }
  }, [Editid, count]);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const deletehandler = (id) => {
    const apidata = new URLSearchParams();
    apidata.append("id", id);
    dispatch(rmovenewssaga(apidata, navigate, token));
  };

  const handelSubmit = () => {
    if (Title === "") {
      Settitleerror("Please enter news title");
    } else {
      Settitleerror("");
    }
    if (Description === "") {
      Setdescriptionerror("Please enter Description");
    } else {
      Setdescriptionerror("");
    }

    if (Title !== "" && Description !== "") {
      const apidata = new FormData();
      apidata.append("Title", Title);
      apidata.append("Description", Description);
      Images &&
        Images[0] &&
        [...Images].map((element) => apidata.append("images", element));
      if (Editid !== "") {
        apidata.append("id", Editid);
        apidata.append("oldimagearray", JSON.stringify(OldImages));
      }
      Editid===""?dispatch(addnewssaga(apidata, navigate, token)):dispatch(updatenewssaga(apidata, navigate, token))
     
      setAnchorEl(null);
    }
  };
  const removeurl = (element) => {
    let array = [...OldImages];
    array.splice(array.indexOf(element), 1);
    SetOldimages(array);
  };
  useEffect(() => {
    dispatch(getnewssaga(navigate));
    Setnewslist(newslist);
  }, []);
  useEffect(() => {
    // dispatch(getnewssaga(navigate));
    Setnewslist(newslist);
  }, [newslist]);
  useEffect(() => {
    let data = [];
    NewsList &&
      NewsList[0] &&
      NewsList.map((element, index) => {
        data = [
          ...data,
          createData(
            element.Title,
            <td>{Parser(element.Description)}</td>,
            element._id
          ),
        ];
       
      });
      Setrows(data);
  }, [NewsList]);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Box className="page-content-main">
      <ToastContainer />
      <Box className='main-title-box'>
        <Typography className='main-title'  variant="h4">News Management</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        <FormControl sx={{ m: 1, minWidth: 220, maxHeight: 50 }}></FormControl>
        <Button
          variant="contained"
          sx={{ maxHeight: 50, marginTop: 2 ,   backgroundColor:'#271e09',
          "&:hover": { backgroundColor: "#f49d04", opacity: [0.9, 0.8, 0.7] },}}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            Seteditid("");
            Setcount(count + 1);
          }}
        >
          Add New
        </Button>
      </Box>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer className="table-main-box">
          <Table stickyHeader aria-label="sticky table" className="table-main">
            <TableHead className="table-head">
              <TableRow className="table-row">
                {columns.map((column) => (
                  <TableCell className="table-cell"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <h4 style={{ margin: 0 }}>{column.label}</h4>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="table-body">
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow className="table-row"
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align} className="table-cell">
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid item xs={6}>
              <Box
                sx={{
                  margin: "auto",
                  width: 500,
                  maxWidth: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                  {OldImages.map((element, index) => (
                    <Box sx={{ display: "flex" }}>
                      <img
                        height={80}
                        width={80}
                        style={{ margin: "auto" }}
                        src={element}
                        alt=""
                      ></img>
                      <CloseIcon
                        sx={{ position: "relative", right: "20px" }}
                        onClick={() => {
                          removeurl(element);
                        }}
                      />
                    </Box>
                  ))}
                  {Url.map((element, index) => (
                    <img
                      height={80}
                      width={80}
                      style={{ margin: "auto" }}
                      src={element}
                      alt=""
                    ></img>
                  ))}
                </Box>
                
                <label
                  htmlFor="contained-button-file"
                  style={{ margin: "auto", marginTop: "15px" }}
                >
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(e) => {
                      Setimages(e.target.files);
                      imagehendeler(e.target.files);
                    }}
                  />
                  <Button
                    variant="contained"
                    sx={{ width: "180px" }}
                    component="span"
                  >
                    Upload Image
                  </Button>
                </label>
                <TextField
                  sx={{ marginTop: 2 }}
                  required
                  fullWidth
                  label="Title"
                  id="Title"
                  value={Title}
                  onChange={(e) => Settitle(e.target.value)}
                />
                <FormHelperText sx={{ color: "red" }}>
                  {TitleError}
                </FormHelperText>
                <CKEditor
                  editor={ClassicEditor}
                  data={Description}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    Setdescription(data);
                  }}
                />

                <FormHelperText sx={{ color: "red" }}>
                  {DescriptionError}
                </FormHelperText>
                <Box className="save-btn-box">
                  <Button
                className="save-btn"
                    variant="contained"
                    onClick={() => handelSubmit()}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Box>
         
        </Modal>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default Newsmanagement;
