import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Button, Typography } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import { Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import AuthSagaActions from "../../../redux/authsaga/action";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "@mui/material/Modal";
import Switch from '@mui/material/Switch';

const { getApproveReferralUser, updateWithdrwalStatus } = AuthSagaActions;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  maxHeight: "70vh",
  bgcolor: "background.paper",
  overflow: "auto",
  boxShadow: 24,
  p: 4,
};


const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));


const ApproveCoins = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userList = useSelector((state) => {
    return state.authSaga.approveReferralUser;
  });

  const token = useSelector((state) => {
    return state.authSaga.idToken;
  });

  const [page, setPage] = useState(0);
  const [users, setUserlist] = useState([]);
  const [whiteList, setWhiteList] = useState([]);
  const [rows, Setrows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);

  console.log(userList,"114");

  const columns = [
    { id: "userName", label: "User" },
    { id: "status", label: "Status" },
    { id: "coin", label: "Coin", minWidth: 100 },
    { id: "date", label: "Date", minWidth: 100 },
    {
      id: "b2",
      label: "Approve",
      minWidth: 20,
      align: "right",
    },
    {
      id: "b3",
      label: "Reject",
      minWidth: 20,
      align: "right",
    },
  ];


  function createData(userName, status, coin, date, id) {

    let b2 = (
        <Button 
          sx={{cursor:'pointer'}}
          disabled={status!=="Pending"}
          onClick={(event) => {
            withdrwalReq("Success",id);
          }}
        >
          Approve
        </Button>)

let b3 = (
  <Button 
    sx={{cursor:'pointer'}}
    disabled={status!=="Pending"}
    onClick={(event) => {
      withdrwalReq("Rejected",id);
    }}
  >
    Reject
  </Button>
    );
    return {userName, status, coin,date,b2,b3};
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


const withdrwalReq =(data,id)=>{
  const apidata = new URLSearchParams();
    apidata.append("id", id);
    apidata.append("status", data);
    dispatch(updateWithdrwalStatus(apidata, navigate, token))
}


  useEffect(() => {
    dispatch(getApproveReferralUser(navigate));
    setUserlist(userList);
  }, [])

  useEffect(() => {
    setUserlist(userList);
  }, [userList]); 



  useEffect(() => {
    if(users){
      let data = [];
      users?.map((element, i) => {
        data = [
          ...data,
          createData(element?.userId?.userName, element.status, element.coin,new Date(element.createdAt).toLocaleString(),element._id),
        ];
      });
      Setrows(data);
    }
  }, [users]);


  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Box className="page-content-main" sx={{mt:3}}>
      <ToastContainer />
      <Box className='main-title-box'>
        <Typography className='main-title' variant="h4">Approve redeem coins</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        <FormControl sx={{ m: 1, minWidth: 220, maxHeight: 50 }}></FormControl>
      </Box>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer className="table-main-box">
          <Table stickyHeader aria-label="sticky table" className="table-main">
            <TableHead className="table-head">
              <TableRow className="table-row">
                {columns?.map((column) => (
                  <TableCell className="table-cell"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <h4 style={{ margin: 0 }}>{column.label}</h4>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody className="table-body" sx={{ padding: '18px' }}>

              {rows.length ? rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row) => {
                return (
                  <TableRow className="table-row"
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}

                  >
                    {columns?.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} className="table-cell">
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              }) : "No Data"}
            </TableBody>
          </Table>
        </TableContainer>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid item xs={6}>
              <Box
                sx={{
                  margin: "auto",
                  width: 400,
                  maxWidth: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}>
                  <Box className="save-btn-box">
                    <Button className="save-btn"
                      variant="contained"
                      onClick={() => withdrwalReq("Success")}
                    >
                      Approve
                    </Button>
                    <Button className="save-btn"
                      variant="contained"
                      onClick={() => withdrwalReq("Rejected")}
                    >
                      Reject
                    </Button>
                  </Box>
              </Box>
            </Grid>
          </Box>
        </Modal>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default ApproveCoins;