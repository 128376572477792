import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import { useSelector, useDispatch } from "react-redux";
import AuthSagaActions from "../../../redux/authsaga/action";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextareaAutosize from '@mui/material/TextareaAutosize'
import { Button, Card, Typography, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "TextValidator",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const { getContectDetailSaga, addcontectdetailsaga } = AuthSagaActions;
const ContectUs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();

  const token = useSelector((state) => {
    return state.authSaga.idToken;
  });
  const contectdetail = useSelector((state) => {
    return state.authSaga.contactUs;
  });
  const [email1, setEmailOne] = useState("");
  const [email2, setEmailTwo] = useState("");
  const [phone1, setPhoneOne] = useState("");
  const [phone2, setPhoneTwo] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [youtube, setYoutube] = useState("");
  const [telegram, setTelegram] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [discord, setDiscord] = useState("");
  const [description, setDescription] = useState("Short Description...");
  const [EmailError, setEmailError] = useState("");
  const [EmailErrorTwo, setEmailErrorTwo] = useState("");
  const [phone1Error, setPhone1Error] = useState("");
  const [phone2Error, setPhone2Error] = useState("");
  const [descriptionError, SetDescriptionError] = useState("");

  const handelSubmit = () => {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    if (email1.trim() === "" || !regEmail.test(email1)) {
      setEmailError("Please enter valid first email");
      return false;
    } else if(email1.trim()?.length<3){
      setEmailError("Enter at least 3 characters in first email")
      return false;
    } else {
      setEmailError("");
    }

    if (!regEmail.test(email2)) {
      setEmailErrorTwo("Please enter valid second email");
      return false;
    } else if(email2.trim()?.length<3){
      setEmailErrorTwo("Enter at least 3 characters in second email")
      return false;
    } else {
      setEmailErrorTwo("");
    }
    
    if(phone1==="" || phone1?.length!==10){
      setPhone1Error("Enter at least 10 characters in mobile number one")
      return false;
    } else {
      setPhone1Error("");
    }

    if(phone2?.length!==10){
      setPhone2Error("Enter at least 10 characters in mobile number two")
      return false;
    } else {
      setPhone2Error("");
    }

    if(description.trim() ==="" || description.trim()?.length<3){
      SetDescriptionError("Enter at least 3 characters in description")
      return false;
    } else {
      SetDescriptionError("");
    }

    if (email1 != "" && phone1 != "" && description != "") {
      const apidata = new URLSearchParams();
      apidata.append("id", contectdetail._id);
      apidata.append("email1", email1);
      apidata.append("email2", email2);
      apidata.append("phone1", phone1);
      apidata.append("phone2", phone2);
      apidata.append("facebook", facebook);
      apidata.append("instagram", instagram);
      apidata.append("twetter", twitter);
      apidata.append("youtube", youtube);
      apidata.append("telegram", telegram);
      apidata.append("linkedin", linkedin);
      apidata.append("discord", discord);
      apidata.append("description", description);
      dispatch(addcontectdetailsaga(apidata, navigate, token));
    } else {
      const apidata = new URLSearchParams();
      apidata.append("id", contectdetail._id);
      apidata.append("email1", email1);
      apidata.append("email2", email2);
      apidata.append("phone1", phone1);
      apidata.append("phone2", phone2);
      apidata.append("facebook", facebook);
      apidata.append("instagram", instagram);
      apidata.append("twetter", twitter);
      apidata.append("youtube", youtube);
      apidata.append("telegram", telegram);
      apidata.append("linkedin", linkedin);
      apidata.append("discord", discord);
      apidata.append("description", description);
      dispatch(addcontectdetailsaga(apidata, navigate, token));
    }
  };


  useEffect(() => {
    if(contectdetail) {
      setEmailOne(contectdetail?.email1 ? contectdetail.email1 : "");
      setEmailTwo(contectdetail?.email2 ? contectdetail.email2 : "");
      setPhoneOne(contectdetail?.phone1 ? contectdetail.phone1 : "");
      setPhoneTwo(contectdetail?.phone2 ? contectdetail.phone2 : "");
      setFacebook(contectdetail?.facebook ? contectdetail.facebook : "");
      setInstagram(contectdetail?.instagram ? contectdetail.instagram : "");
      setTwitter(contectdetail?.twitter ? contectdetail.twitter : "");
      setYoutube(contectdetail?.youtube ? contectdetail.youtube : "");
      setTelegram(contectdetail?.telegram ? contectdetail.telegram : "");
      setLinkedin(contectdetail?.linkedin ? contectdetail.linkedin : "");
      setDiscord(contectdetail?.discord ? contectdetail.discord : "");
      setDescription(contectdetail?.description ? contectdetail.description : "Short Description...");
    }
  }, [contectdetail]);

  useEffect(() => {
    dispatch(getContectDetailSaga(navigate, token));
  }, []);

  return (
    <Box className="page-content-main">
      <Container maxWidth="false" className="editor-main">
        <ToastContainer />
        <Box className="main-title-box">
          <Typography className="main-title" variant="h4">
            Contact Us
          </Typography>
        </Box>
        <Card
          sx={{
            maxWidth: "500px",
            display: "flex",
            flexDirection: "column",
            margin: "auto",
            marginTop: 5,
            padding: "20px",
            backgroundColor: "#f1f1f1",
          }}
        >
          <FormHelperText sx={{ fontSize: 14, color:'black' }}>Short Description</FormHelperText>
          <TextareaAutosize
            aria-label="minimum height"
            minRows={5}
            placeholder="Short Description..."
            style={{ width: 460 }}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
          <FormHelperText sx={{ color: "red" }}>{descriptionError}</FormHelperText>

          <TextField
            sx={{ marginTop: 2 }}
            required
            fullWidth
            label="Email First"
            id="Email First"
            value={email1}
            onChange={(e) => setEmailOne(e.target.value)}
          />
          <FormHelperText sx={{ color: "red" }}>{EmailError}</FormHelperText>

          <TextField
            sx={{ marginTop: 2 }}
            fullWidth
            required
            label="Email Second"
            id="Email Second"
            value={email2}
            onChange={(e) => setEmailTwo(e.target.value)}
          />
          <FormHelperText sx={{ color: "red" }}>{EmailErrorTwo}</FormHelperText>

          <TextField
            sx={{ marginTop: 2, height: 50 }}
            required
            className={classes.input}
            fullWidth
            label="Mobile Number One"
            type="number"
            id="phone1"
            onWheel={(event) => event.target.blur()}
            value={phone1}
            onChange={(e) => setPhoneOne(e.target.value)}
          />
          <FormHelperText sx={{ color: "red" }}>{phone1Error}</FormHelperText>

          <TextField
            sx={{ marginTop: 2, height: 50 }}
            fullWidth
            className={classes.input}
            label="Mobile Number Two"
            type="number"
            id="phone2"
            onWheel={(event) => event.target.blur()}
            value={phone2}
            onChange={(e) => setPhoneTwo(e.target.value)}
          />
          <FormHelperText sx={{ color: "red" }}>{phone2Error}</FormHelperText>

          <TextField
            sx={{ marginTop: 2 }}
            fullWidth
            label="Facebook Url"
            id="Facebook"
            type="url"
            value={facebook}
            onChange={(e) => setFacebook(e.target.value)}
          />

          <TextField
            sx={{ marginTop: 2 }}
            fullWidth
            label="Instagram Url"
            id="Instagram"
            value={instagram}
            onChange={(e) => setInstagram(e.target.value)}
          />

          <TextField
            sx={{ marginTop: 2 }}
            fullWidth
            label="Twitter Url"
            id="Twitter"
            value={twitter}
            onChange={(e) => setTwitter(e.target.value)}
          />

          <TextField
            sx={{ marginTop: 2 }}
            fullWidth
            label="Youtube Url"
            id="Youtube"
            value={youtube}
            onChange={(e) => setYoutube(e.target.value)}
          />

          <TextField
            sx={{ marginTop: 2 }}
            fullWidth
            label="Linkedin Url"
            id="Linkedin"
            value={linkedin}
            onChange={(e) => setLinkedin(e.target.value)}
          />

          <TextField
            sx={{ marginTop: 2 }}
            fullWidth
            label="Telegram Url"
            id="Telegram"
            value={telegram}
            onChange={(e) => setTelegram(e.target.value)}
          />

          <TextField
            sx={{ marginTop: 2 }}
            fullWidth
            label="Discord Url"
            id="Discord"
            value={discord}
            onChange={(e) => setDiscord(e.target.value)}
          />
        
          <br />
          <Box className="save-btn-box">
            <Button
              className="save-btn"
              variant="contained"
              onClick={() => handelSubmit()}
            >
              Save
            </Button>
          </Box>
        </Card>
      </Container>
    </Box>
  );
};

export default ContectUs;
