import React, {useState} from "react";
import { Link as routerLink } from "react-router-dom";
import Index from "../../Index";
const { forgotPasswordSaga } = Index.AuthSagaActions;

export default function AdminForgotPassword() {
  const [email, setEmail] = useState("");
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();

  const submitForm = async (e) => {
    e.preventDefault();
    if (email !== "") {
        const apidata = new URLSearchParams();
        apidata.append("email", email);
        dispatch(forgotPasswordSaga(apidata, navigate));
    }
  };

  return (
    <>
      <br />
      <Index.Container maxWidth="false">
        <Index.Typography align="center" variant="h4">
          Forgot Password
        </Index.Typography>
        <Index.Box sx={{ width: 500, margin: "auto" }}>
          <Index.Grid
            container
            rowSpacing={4}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              border: 2,
              mt: 10,
            }}
          >
            <Index.Grid item xs={6}>
              <Index.Typography
                sx={{ textAlign: "center" }}
                color="black"
                gutterBottom
                marginBottom={2}
              >
                To reset password,enter youe email address
              </Index.Typography>{" "}
            </Index.Grid>
  
            <Index.ValidatorForm onSubmit={submitForm}>
              <Index.Grid item xs={6}>
                <Index.TextValidator
                  id="standard-helperText"
                  label="Email *"
                  sx={{ width: "330px" }}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  type="email"
                  validators={["required", "isEmail"]}
                  errorMessages={[
                    "Please enter Email",
                    "Please enter valid Email",
                  ]}
                />
              </Index.Grid>

              <Index.Grid item xs={6}>
                <Index.Button
                  type="submit"
                  variant="contained"
                  sx={{ textTransform: "none", marginTop: 3 }}
                  onSubmit={submitForm}
                >
                  Get Reset Link
                </Index.Button>
              </Index.Grid>
            </Index.ValidatorForm>

            <Index.Grid item xs={6} sx={{ mb: 2 }}>
              <Index.Typography>
                <Index.Link component={routerLink} to="/admin/login">
                  Back To Login Page
                </Index.Link>
              </Index.Typography>
            </Index.Grid>
          </Index.Grid>
        </Index.Box>
        <Index.ToastContainer />
      </Index.Container>
      <br />
    </>
  );
}
