import React, { useState, useEffect } from "react";
import Chart from "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AuthSagaActions from "../../../redux/authsaga/action";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Paper from "@mui/material/Paper";

const { getTransactionChartSaga } = AuthSagaActions;
const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [chartData, setChartData] = useState([])
  const chartNumbers = useSelector((state) => {
    return state.authSaga.TransactionChartNumbers;
  });

  const token = useSelector((state) => {
    return state.authSaga.idToken;
  });

  useEffect(() => {
    dispatch(getTransactionChartSaga(navigate, token));
  }, []);

  useEffect(() => {
    setChartData([chartNumbers]);
  }, [chartNumbers]);

  const [state, setState] = useState({
    labels: ["Weekly", "Monthly", "Quarterly", "Yearly"],
    datasets: [
      {
        label: "Rainfall",
        backgroundColor: ["#B21F00", "#C9DE00", "#2FDE00", "#00A6B4"],
        hoverBackgroundColor: ["#501800", "#4B5000", "#175000", "#003350"],
        data: chartNumbers.length ? chartNumbers : chartData,
      },
    ],
  });

  console.log("chartNumbers ==", chartNumbers);
  console.log('setState ==', state);

  return (
    <>
      <Box className="page-content-main">
        <Box className="main-title-box">
          <Typography className="main-title" variant="h4">
            Dashboard
          </Typography>

          <Grid sx={{ flexGrow: 1 }} container className="set-box">
            <Grid item xs={6}>
              <Paper sx={{ p: 2 }}>
                <Grid container>
                  <Grid item>
                    <FormControl component="fieldset">
                      <Typography className="main-title" variant="h6"> Transactions Chart Box </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: 4,
                          marginBottom: 2,
                          width: 450,
                        }}
                      >
                       

                      {chartNumbers ? (
                        <Doughnut
                          data={state}
                          options={{
                            title: {
                              display: true,
                              text: "Average Rainfall per month",
                              fontSize: 20,
                            },
                            legend: {
                              display: true,
                              position: "right",
                            },
                          }}
                        />
                      ) : (
                        <p>No user found, please try again</p>
                      )}

                      </Box>
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
        
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
