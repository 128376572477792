import React, { useState } from "react";
import "./Login.css";
import Index from "../../Index";
const { loginSaga } = Index.AuthSagaActions;

const Login = () => {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();

  const [Email, Setemail] = useState("");
  const [Password, Setpassword] = useState("");
  const [EmailError, Setemailerror] = useState("");
  const [PasswordError, Setpassworderror] = useState("");

  const handelSubmit = () => {
    let regEmail =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (Email === "") {
      Setemailerror("Please enter email Id");
    } else if (!regEmail.test(Email)) {
      Setemailerror("Please enter valid email Id");
    } else {
      Setemailerror("");
    }
    if (Password === "") {
      Setpassworderror("Please enter password");
    } else {
      Setpassworderror("");
    }
    if (Email !== "" && Password !== "") {
      const apidata = new URLSearchParams();
      apidata.append("email", Email);
      apidata.append("password", Password);
      dispatch(loginSaga(apidata, navigate));
    }
  };

  return (
    <Index.Box className="page-content-main"
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#271e09",
      }}
    >
      <Index.Card
        variant="outlined"
        sx={{
          margin: "auto",
          width: 500,
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Index.ToastContainer />
        <Index.Box
          sx={{
            margin: "auto",
            maxWidth: "100%",
            display: "flex",
            padding:"20px",
            flexDirection: "column",
            justifyContent: "center",
          }}
          className="login1"
        >
          <h1 style={{ textAlign: "center", color: "#271e09" }}>Login</h1>

          <Index.TextField
            sx={{ margin: "auto", marginTop: 2, width: "100%" , borderColor:"#271e09" }}
            focused
            required
            fullWidth
            label="Email"
            id="Email"
            value={Email}
            onChange={(e) => Setemail(e.target.value)}
          />
          <Index.FormHelperText sx={{ margin: "auto", color: "red" }}>
            {EmailError}
          </Index.FormHelperText>
          <Index.TextField
            sx={{ margin: "auto", marginTop: 2, width: "100%" ,borderColor:"#271e09" }}
            focused
            required
            fullWidth
            type="password"
            label="Password"
            id="password"
            value={Password}
            onChange={(e) => Setpassword(e.target.value)}
          />
          <Index.FormHelperText sx={{ margin: "auto", color: "red" }}>
            {PasswordError}
          </Index.FormHelperText>
          <Index.Box sx={{display:"flex",justifyContent:"end",mt:2}}>
            <Index.Link component={Index.routerLink} to={"/admin/forgotpassword"}>Forgot password?</Index.Link>
          </Index.Box>
          <Index.Box sx={{ display: "flex", justifyContent: "center" }}>
            <Index.Button
              sx={{ marginTop: 6, marginBottom: 2, width: "120px" ,   backgroundColor:'#271e09',
              "&:hover": { backgroundColor: "#f49d04", opacity: [0.9, 0.8, 0.7] }, borderRadius:"30px" }}
              variant="contained"
              onClick={() => handelSubmit()}
            >
              Submit
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Card>
    </Index.Box>
  );
};

export default Login;
