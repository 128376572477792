const actions = {
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGOUT_REQUEST: "LOGOUT_REQUEST",
    LODER_ON: "LODER_ON", 
    LODER_OFF: "LODER_OFF",
    LOGOUT: "LOGOUT",
    ERROR: "ERROR",
    LOGIN_SUCCESS:"LOGIN_SUCCESS",
    USER_LOGIN_REQUEST:"USER_LOGIN_REQUEST",
    USER_LOGIN_SUCCESS:"USER_LOGIN_SUCCESS",
    USER_LOGOUT_REQUEST: "USER_LOGOUT_REQUEST",
    USER_LOGOUT_SUCCESS:"USER_LOGOUT_SUCCESS",
    USER_REGISTER_REQUEST:"USER_REGISTER_REQUEST",
    USER_REGISTER_SUCCESS:"USER_REGISTER_SUCCESS",
    USER_ChangePassword_REQUEST:"USER_ChangePassword_REQUEST",
    USER_ChangePassword_SUCCESS:"USER_ChangePassword_SUCCESS",
    USER_ForgotPassword_REQUEST:"USER_ForgotPassword_REQUEST",
    USER_ForgotPassword_SUCCESS:"USER_ForgotPassword_SUCCESS",
    USER_ResetPassword_REQUEST:"USER_ResetPassword_REQUEST",
    USER_ResetPassword_SUCCESS:"USER_ResetPassword_SUCCESS",
    Edit_UserProfile_REQUEST:"Edit_UserProfile_REQUEST",
    Edit_UserProfile_SUCCESS:"Edit_UserProfile_SUCCESS",
    GET_UserProfile_REQUEST:"GET_UserProfile_REQUEST",
    GET_UserProfile_SUCCESS:"GET_UserProfile_SUCCESS",
    FORGOT_PASSWORD_REQUEST:"FORGOT_PASSWORD_REQUEST",
    FORGOT_PASSWORD_SUCCESS:"FORGOT_PASSWORD_SUCCESS",
    RESET_PASSWORD_REQUEST:"RESET_PASSWORD_REQUEST",
    RESET_PASSWORD_SUCCESS:"RESET_PASSWORD_SUCCESS",
    User_ContactEnquiry_REQUEST:"User_ContactEnquiry_REQUEST",
    User_ContactEnquiry_SUCCESS:"User_ContactEnquiry_SUCCESS",
    About_Edit_REQUEST:"About_Edit_REQUEST",
    About_Edit_SUCCESS:"About_Edit_SUCCESS",
    GET_About_REQUEST:"GET_About_REQUEST",
    GET_About_SUCCESS:"GET_About_SUCCESS",
    GET_CMS_REQUEST:"GET_CMS_REQUEST",
    GET_CMS_SUCCESS:"GET_CMS_SUCCESS",
    Add_CoinPrice_REQUEST:"Add_CoinPrice_REQUEST",
    Add_CoinPrice_SUCCESS:"Add_CoinPrice_SUCCESS",
    Edit_Contect_Detail_REQUEST:"Edit_Contect_Detail_REQUEST",
    Edit_Contect_Detail_SUCCESS:"Edit_Contect_Detail_SUCCESS",
    GET_Contect_Detail_REQUEST:"GET_Contect_Detail_REQUEST",
    GET_Contect_Detail_SUCCESS:"GET_Contect_Detail_SUCCESS",
    GET_CoinPrice_REQUEST:"GET_CoinPrice_REQUEST",
    GET_CoinPrice_SUCCESS:"GET_CoinPrice_SUCCESS",
    Security_Edit_REQUEST:"Security_Edit_REQUEST",
    Security_Edit_SUCCESS:"Security_Edit_SUCCESS",
    Terms_Edit_REQUEST:"Terms_Edit_REQUEST",
    Terms_Edit_SUCCESS:"Terms_Edit_SUCCESS",
    Add_Team_Member_REQUEST:"Add_Team_Member_REQUEST",
    Update_Team_Member_REQUEST:"Update_Team_Member_REQUEST",
    Add_Team_Member_SUCCESS:"Add_Team_Member_SUCCESS",
    Update_Team_Member_SUCCESS:"Update_Team_Member_SUCCESS",
    GET_Team_Members_REQUEST:"GET_Team_Members_REQUEST",
    GET_Team_Members_SUCCESS:"GET_Team_Members_SUCCESS",
    Delete_Team_Members_REQUEST:"Delete_Team_Members_REQUEST",
    Delete_Team_Members_SUCCESS:"Delete_Team_Members_SUCCESS",
    Add_News_REQUEST:"Add_News_REQUEST",
    Add_News_SUCCESS:"Add_News_SUCCESS",
    Update_News_REQUEST:"Update_News_REQUEST",
    Update_News_SUCCESS:"Update_News_SUCCESS",
    GET_NEWS_REQUEST:"GET_NEWS_REQUEST",
    GET_NEWS_SUCCESS:"GET_NEWS_SUCCESS",
    Delete_NEWS_REQUEST:"Delete_NEWS_REQUEST",
    Delete_News_SUCCESS:"Delete_News_SUCCESS",
    Send_News_REQUEST:"Send_News_REQUEST",
    Send_News_SUCCESS:"Send_News_SUCCESS",
    GET_ICOLab_REQUEST:"GET_ICOLab_REQUEST",
    GET_ICOLab_SUCCESS:"GET_ICOLab_SUCCESS",
    Add_ICOLab_REQUEST:"Add_ICOLab_REQUEST",
    Add_ICOLab_SUCCESS:"Add_ICOLab_SUCCESS",
    Delete_ICOLab_REQUEST:"Delete_ICOLab_REQUEST",
    Delete_ICOLab_SUCCESS:"Delete_ICOLab_SUCCESS",
    Update_ICOLab_REQUEST:"Update_ICOLab_REQUEST",
    Update_ICOLab_SUCCESS:"Update_ICOLab_SUCCESS",
    Delete_Roadmap_SUCCESS:"Delete_Roadmap_SUCCESS",
    Delete_Roadmap_REQUEST:"Delete_Roadmap_REQUEST",
    Update_Roadmap_SUCCESS:"Update_Roadmap_SUCCESS",
    Update_Roadmap_REQUEST:"Update_Roadmap_REQUEST",
    Add_Roadmap_SUCCESS:"Add_Roadmap_SUCCESS",
    Add_Roadmap_REQUEST:"Add_Roadmap_REQUEST",
    GET_Road_REQUEST:"GET_Road_REQUEST",
    GET_Road_SUCCESS:"GET_Road_SUCCESS",
    Add_Edit_Website_REQUEST:"Add_Edit_Website_REQUEST",
    Add_Edit_Website_SUCCESS:"Add_Edit_Website_SUCCESS",
    GET_Website_REQUEST:"GET_Website_REQUEST",
    GET_Website_SUCCESS:"GET_Website_SUCCESS",
    Add_Client_REQUEST:"Add_Client_REQUEST",
    Add_Client_SUCCESS:"Add_Client_SUCCESS",
    Update_Client_REQUEST:"Update_Client_REQUEST",
    Update_Client_SUCCESS:"Update_Client_SUCCESS",
    Delete_Client_REQUEST:"Delete_Client_REQUEST",
    Delete_Client_SUCCESS:"Delete_Client_SUCCESS",
    GET_Client_REQUEST:"GET_Client_REQUEST",
    GET_Client_SUCCESS:"GET_Client_SUCCESS",
    Add_Vision_REQUEST:"Add_Vision_REQUEST",
    Add_Vision_SUCCESS:"Add_Vision_SUCCESS",
    GET_Vision_REQUEST:"GET_Vision_REQUEST",
    GET_Vision_SUCCESS:"GET_Vision_SUCCESS",
    Add_ICOLearn_REQUEST:"Add_ICOLearn_REQUEST",
    Add_ICOLearn_SUCCESS:"Add_ICOLearn_SUCCESS",
    GET_ICOLearn_REQUEST:"GET_ICOLearn_REQUEST",
    GET_ICOLearn_SUCCESS:"GET_ICOLearn_SUCCESS",
    Add_Faq_REQUEST:"Add_Faq_REQUEST",
    Add_Faq_SUCCESS:"Add_Faq_SUCCESS",
    Update_Faq_REQUEST:"Update_Faq_REQUEST",
    Update_Faq_SUCCESS:"Update_Faq_SUCCESS",
    GET_Faq_REQUEST:"GET_Faq_REQUEST",
    GET_Faq_SUCCESS:"GET_Faq_SUCCESS",
    Delete_Faq_REQUEST:"Delete_Faq_REQUEST",
    Delete_Faq_SUCCESS:"Delete_Faq_SUCCESS",
    GET_TransactionChart_REQUEST:"GET_TransactionChart_REQUEST",
    GET_TransactionChart_SUCCESS:"GET_TransactionChart_SUCCESS",
    GET_ContactEnquiry_REQUEST:"GET_ContactEnquiry_REQUEST",
    GET_ContactEnquiry_SUCCESS:"GET_ContactEnquiry_SUCCESS",
    Delete_ContactEnquiry_REQUEST:"Delete_ContactEnquiry_REQUEST",
    Delete_ContactEnquiry_SUCCESS:"Delete_ContactEnquiry_SUCCESS",
    GET_Subscriber_REQUEST:"GET_Subscriber_REQUEST",
    GET_Subscriber_SUCCESS:"GET_Subscriber_SUCCESS",
    Delete_Subscriber_REQUEST:"Delete_Subscriber_REQUEST",
    Delete_Subscriber_SUCCESS:"Delete_Subscriber_SUCCESS",
    GET_Calender_REQUEST:"GET_Calender_REQUEST",
    GET_Calender_SUCCESS:"GET_Calender_SUCCESS",
    Add_Calender_REQUEST:"Add_Calender_REQUEST",
    Add_Calender_SUCCESS:"Add_Calender_SUCCESS",
    Update_Calender_REQUEST:"Update_Calender_REQUEST",
    Update_Calender_SUCCESS:"Update_Calender_SUCCESS",
    Delete_Calender_REQUEST:"Delete_Calender_REQUEST",
    Delete_Calender_SUCCESS:"Delete_Calender_SUCCESS",
    Add_ICOWeb_REQUEST:"Add_ICOWeb_REQUEST",
    Add_ICOWeb_SUCCESS:"Add_ICOWeb_SUCCESS",
    GET_ICOWeb_REQUEST:"GET_ICOWeb_REQUEST",
    GET_ICOWeb_SUCCESS:"GET_ICOWeb_SUCCESS",
    Add_Subscribe_REQUEST:"Add_Subscribe_REQUEST",
    Add_Subscribe_SUCCESS:"Add_Subscribe_SUCCESS",
    GET_TransactionHistory_REQUEST:"GET_TransactionHistory_REQUEST",
    GET_TransactionHistory_SUCCESS:"GET_TransactionHistory_SUCCESS",
    GET_User_REQUEST:"GET_User_REQUEST",
    GET_User_SUCCESS:"GET_User_SUCCESS",
    Delete_User_REQUEST:"Delete_User_REQUEST",
    Delete_User_SUCCESS:"Delete_User_SUCCESS",
    Add_User_REQUEST:"Add_User_REQUEST",
    Add_User_SUCCESS:"Add_User_SUCCESS",
    Update_User_REQUEST:"Update_User_REQUEST",
    Update_User_SUCCESS:"Update_User_SUCCESS",
    GET_IDOWeb_REQUEST:"GET_IDOWeb_REQUEST",
    GET_IDOWeb_SUCCESS:"GET_IDOWeb_SUCCESS",
    Add_IDOWeb_REQUEST:"Add_IDOWeb_REQUEST",
    Add_IDOWeb_SUCCESS:"Add_IDOWeb_SUCCESS",
    GET_IDOLearn_REQUEST:"GET_IDOLearn_REQUEST",
    GET_IDOLearn_SUCCESS:"GET_IDOLearn_SUCCESS",
    Add_IDOLearn_REQUEST:"Add_IDOLearn_REQUEST",
    Add_IDOLearn_SUCCESS:"Add_IDOLearn_SUCCESS",
    GET_IDOLab_REQUEST:"GET_IDOLab_REQUEST",
    GET_IDOLab_SUCCESS:"GET_IDOLab_SUCCESS",
    Add_IDOLab_REQUEST:"Add_IDOLab_REQUEST",
    Add_IDOLab_SUCCESS:"Add_IDOLab_SUCCESS",
    Update_IDOLab_REQUEST:"Update_IDOLab_REQUEST",
    Update_IDOLab_SUCCESS:"Update_IDOLab_SUCCESS",
    Delete_IDOLab_REQUEST:"Delete_IDOLab_REQUEST",
    Delete_IDOLab_SUCCESS:"Delete_IDOLab_SUCCESS",
    GET_IDO_Calender_REQUEST:"GET_IDO_Calender_REQUEST",
    GET_IDO_Calender_SUCCESS:"GET_IDO_Calender_SUCCESS",
    Add_IDO_Calender_REQUEST:"Add_IDO_Calender_REQUEST",
    Add_IDO_Calender_SUCCESS:"Add_IDO_Calender_SUCCESS",
    Update_IDO_Calender_REQUEST:"Update_IDO_Calender_REQUEST",
    Update_IDO_Calender_SUCCESS:"Update_IDO_Calender_SUCCESS",
    Delete_IDO_Calender_REQUEST:"Delete_IDO_Calender_REQUEST",
    Delete_IDO_Calender_SUCCESS:"Delete_IDO_Calender_SUCCESS",
    Add_WhiteListUser_REQUEST: "Add_WhiteListUser_REQUEST",
    Add_WhiteListUser_SUCCESS: "Add_WhiteListUser_SUCCESS",
    GET_WhiteListUser_REQUEST: "GET_WhiteListUser_REQUEST",
    GET_WhiteListUser_SUCCESS: "GET_WhiteListUser_SUCCESS",
    Update_UserStatus_REQUEST:"Update_UserStatus_REQUEST",
    Update_UserStatus_SUCCESS:"Update_UserStatus_SUCCESS",
    REMOVE_WhiteListUser_REQUEST: "REMOVE_WhiteListUser_REQUEST",
    REMOVE_WhiteListUser_SUCCESS: "REMOVE_WhiteListUser_SUCCESS",
    CREATE_TRANSCATION_REQUEST : "CREATE_TRANSCATION_REQUEST",
    CREATE_TRANSCATION_SUCCESS : "CREATE_TRANSCATION_SUCCESS",
    GET_TRANSCATION_REQUEST : "GET_TRANSCATION_REQUEST",
    GET_TRANSCATION_SUCCESS : "GET_TRANSCATION_SUCCESS",
    GET_PRICE_REQUEST : "GET_PRICE_REQUEST",
    GET_PRICE_SUCCESS : "GET_PRICE_SUCCESS",
    GET_Referral_User_REQUEST:"GET_Referral_User_REQUEST",
    GET_Referral_User_SUCCESS:"GET_Referral_User_SUCCESS",
    GET_Approve_User_REQUEST:"GET_Approve_User_REQUEST",
    GET_Approve_User_SUCCESS:"GET_Approve_User_SUCCESS",
    GET_REFERRAL_TRANSACTION_REQUEST:"GET_REFERRAL_TRANSACTION_REQUEST",
    GET_REFERRAL_TRANSACTION_SUCCESS:"GET_REFERRAL_TRANSACTION_SUCCESS",
    UPDATE_WITHDRWAL_REQUEST:"UPDATE_WITHDRWAL_REQUEST",
    UPDATE_WITHDRWAL_SUCCESS:"UPDATE_WITHDRWAL_SUCCESS",
    REDEEM_USER_TRANSACTION_REQUEST:"REDEEM_USER_TRANSACTION_REQUEST",
    REDEEM_USER_TRANSACTION_SUCCESS:"REDEEM_USER_TRANSACTION_SUCCESS",
    CREATE_REDEEM_REQUEST:"CREATE_REDEEM_REQUEST",
    CREATE_REDEEM_SUCCESS:"CREATE_REDEEM_SUCCESS",
    GET_NOTIFICATION_REQUEST:"GET_NOTIFICATION_REQUEST",
    GET_NOTIFICATION_SUCCESS:"GET_NOTIFICATION_SUCCESS",

    loginSaga: (payload,navigate) => ({
      type: actions.LOGIN_REQUEST,
      payload:payload,
      navigate:navigate
    }),
    logoutSaga: (navigate) => ({
      type: actions.LOGOUT_REQUEST,
      navigate:navigate
    }),
    forgotPasswordSaga: (payload,navigate) => ({
      type: actions.FORGOT_PASSWORD_REQUEST,
      payload:payload,
      navigate:navigate
    }),
    resetPasswordSaga: (payload,navigate) => ({
      type: actions.RESET_PASSWORD_REQUEST,
      payload:payload,
      navigate:navigate
    }),
    registerUserSaga: (payload,navigate) => ({
      type: actions.USER_REGISTER_REQUEST,
      payload:payload,
      navigate:navigate
    }),
    
    userChangePasswordSaga: (payload,navigate) => ({
      type: actions.USER_ChangePassword_REQUEST,
      payload:payload,
      navigate:navigate
    }),

    userForgotPasswordSaga: (payload,navigate) => ({
      type: actions.USER_ForgotPassword_REQUEST,
      payload:payload,
      navigate:navigate
    }),
    
    userResetPasswordSaga: (payload,navigate) => ({
      type: actions.USER_ResetPassword_REQUEST,
      payload:payload,
      navigate:navigate
    }),

    editUserProfileSaga: (payload,navigate,token)=>({
      type: actions.Edit_UserProfile_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),

    getUserProfile:(token)=>({
      type: actions.GET_UserProfile_REQUEST,
      //payload:payload,
      //navigate:navigate,
      token:token
    }),

    userContactEnquirySaga: (payload,navigate) => ({
      type: actions.User_ContactEnquiry_REQUEST,
      payload:payload,
      navigate:navigate
    }),
    loginUserSaga: (payload,navigate) => ({
      type: actions.USER_LOGIN_REQUEST,
      payload:payload,
      navigate:navigate
    }),
    userLogoutSaga: (payload, navigate) => ({
      type: actions.USER_LOGOUT_REQUEST,
      payload:payload,
      navigate:navigate
    }),
    addeditaboutdetailsaga:(payload,navigate,token)=>({
      type: actions.About_Edit_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    getaboutdetailsaga:(navigate)=>({
      type: actions.GET_About_REQUEST,
      navigate:navigate,
    }),
    getcmssaga:(navigate,token)=>({
      type: actions.GET_CMS_REQUEST,
      navigate:navigate,
      token:token
    }),
    addCoinPriceSaga:(payload,navigate,token)=>({
      type: actions.Add_CoinPrice_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    addcontectdetailsaga:(payload,navigate,token)=>({
      type: actions.Edit_Contect_Detail_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    getContectDetailSaga:(navigate,token)=>({
      type: actions.GET_Contect_Detail_REQUEST,
      navigate:navigate,
      token:token
    }),
    getCoinPriceSaga:(navigate,token)=>({
      type: actions.GET_CoinPrice_REQUEST,
      navigate:navigate,
      token:token
    }),
    addsecuritysaga:(payload,navigate,token)=>({
      type: actions.Security_Edit_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    addtermsandconditiondetailsaga:(payload,navigate,token)=>({
      type: actions.Terms_Edit_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    addteammembersaga:(payload,navigate,token)=>({
      type: actions.Add_Team_Member_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    updateteammembersaga:(payload,navigate,token)=>({
      type: actions.Update_Team_Member_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    getTeamMembersaga:(navigate)=>({
      type: actions.GET_Team_Members_REQUEST,
      navigate:navigate
    }),
    rmoveteammembersaga:(payload,navigate,token)=>({
      type: actions.Delete_Team_Members_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    addnewssaga:(payload,navigate,token)=>({
      type: actions.Add_News_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    updatenewssaga:(payload,navigate,token)=>({
      type: actions.Update_News_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),

    sendnewsSaga:(payload,navigate,token)=>({
      type: actions.Send_News_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),

    getnewssaga:(navigate,token)=>({
      type: actions.GET_NEWS_REQUEST,
      navigate:navigate,
      token:token
    }),
    rmovenewssaga:(payload,navigate,token)=>({
      type: actions.Delete_NEWS_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    addicolab:(payload,navigate,token)=>({
      type: actions.Add_ICOLab_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    updateicolab:(payload,navigate,token)=>({
      type: actions.Update_ICOLab_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    removeicolab:(payload,navigate,token)=>({
      type: actions.Delete_ICOLab_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    geticolablistsaga:(navigate,token)=>({
      type: actions.GET_ICOLab_REQUEST,
      navigate:navigate,
      token:token
    }),
    addRoadmapsaga:(payload,navigate,token)=>({
      type: actions.Add_Roadmap_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }), 
    updateRoadmapsaga:(payload,navigate,token)=>({
      type: actions.Update_Roadmap_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    deleteRoadmapsaga:(payload,navigate,token)=>({
      type: actions.Delete_Roadmap_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    getRoadmapsaga:(navigate,token)=>({
      type: actions.GET_Road_REQUEST,
      navigate:navigate,
    }),
    addEditWebsiteSetting:(payload,navigate, token) => ({
      type: actions.Add_Edit_Website_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    getWebsiteSetting:(navigate, token) => ({
      type: actions.GET_Website_REQUEST,
      navigate:navigate,
    }),
    addClients:(payload, navigate) => ({
      type: actions.Add_Client_REQUEST,
      payload:payload,
      navigate:navigate
    }),
    updateClients:(payload, navigate) => ({
      type: actions.Update_Client_REQUEST,
      payload:payload,
      navigate:navigate
    }),
    deleteClient:(payload, navigate) => ({
      type: actions.Delete_Client_REQUEST,
      payload:payload,
      navigate:navigate
    }),
    getClients:(navigate) => ({
      type: actions.GET_Client_REQUEST,
      navigate:navigate
    }),
    addEditRemoveVision:(payload, navigate) => ({
      type: actions.Add_Vision_REQUEST,
      payload:payload,
      navigate:navigate
    }),
    getVisions:(navigate) => ({
      type: actions.GET_Vision_REQUEST,
      navigate:navigate
    }),
    addEditIcoLearn:(payload, navigate) => ({
      type: actions.Add_ICOLearn_REQUEST,
      payload:payload,
      navigate:navigate
    }),
    getIcoLearn:(navigate) => ({
      type: actions.GET_ICOLearn_REQUEST,
      navigate:navigate
    }),
    addFaq:(payload,navigate,token)=>({
      type: actions.Add_Faq_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    updateFaq:(payload,navigate,token)=>({
      type: actions.Update_Faq_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    removeFaq:(payload,navigate,token)=>({
      type: actions.Delete_Faq_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    getFaqsaga:(navigate,token)=>({
      type: actions.GET_Faq_REQUEST,
      navigate:navigate,
      token:token
    }),
    
    getTransactionChartSaga:(navigate,token)=>({
      type: actions.GET_TransactionChart_REQUEST,
      navigate:navigate,
      token:token
    }),

    getContactEnquirySaga:(navigate,token)=>({
      type: actions.GET_ContactEnquiry_REQUEST,
      navigate:navigate,
      token:token
    }),
    removeEnquirySaga:(payload,navigate,token)=>({
      type: actions.Delete_ContactEnquiry_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    getSubscriberSaga:(navigate,token)=>({
      type: actions.GET_Subscriber_REQUEST,
      navigate:navigate,
      token:token
    }),
    removeSubscriber:(payload,navigate,token)=>({
      type: actions.Delete_Subscriber_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    getCalenderSaga:(navigate,token)=>({
      type: actions.GET_Calender_REQUEST,
      navigate:navigate,
    }),
    addCalenderSaga:(payload,navigate,token)=>({
      type: actions.Add_Calender_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }), 
    updateCalenderSaga:(payload,navigate,token)=>({
      type: actions.Update_Calender_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    deleteCalenderSaga:(payload,navigate)=>({
      type: actions.Delete_Calender_REQUEST,
      payload:payload,
      navigate:navigate
    }),
    addEditIcoWeb:(payload, navigate) => ({
      type: actions.Add_ICOWeb_REQUEST,
      payload:payload,
      navigate:navigate
    }),
    getIcoWeb:(navigate) => ({
      type: actions.GET_ICOWeb_REQUEST,
      navigate:navigate
    }),
    addSubscriber:(payload)=>({
      type: actions.Add_Subscribe_REQUEST,
      payload:payload
    }),
    getTransactionHistorySaga:(navigate,token)=>({
      type: actions.GET_TransactionHistory_REQUEST,
      navigate:navigate,
      token:token
    }),
    getUserSaga:(navigate)=>({
      type: actions.GET_User_REQUEST,
      navigate:navigate
    }),
    removeUserSaga:(payload,navigate,token)=>({
      type: actions.Delete_User_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    handelStatusSaga:(payload,navigate,token)=>({
      type: actions.Update_UserStatus_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    addUserSaga:(payload,navigate,token)=>({
      type: actions.Add_User_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    updateUserSaga:(payload,navigate,token)=>({
      type: actions.Update_User_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    getIdoWeb:(navigate) => ({
      type: actions.GET_IDOWeb_REQUEST,
      navigate:navigate
    }),
    addEditIdoWeb:(payload, navigate) => ({
      type: actions.Add_IDOWeb_REQUEST,
      payload:payload,
      navigate:navigate
    }),
    getIdoLearn:(navigate) => ({
      type: actions.GET_IDOLearn_REQUEST,
      navigate:navigate
    }),
    addEditIdoLearn:(payload, navigate) => ({
      type: actions.Add_IDOLearn_REQUEST,
      payload:payload,
      navigate:navigate
    }),
    getIdoLabListSaga:(navigate,token)=>({
      type: actions.GET_IDOLab_REQUEST,
      navigate:navigate,
      token:token
    }),
    addIdoLab: (payload,navigate, token)=>({
      type: actions.Add_IDOLab_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    addWhiteListedUser: (payload, navigate, token) => ({
      type: actions.Add_WhiteListUser_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    updateIdoLab:(payload,navigate,token)=>({
      type: actions.Update_IDOLab_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    removeIdoLab:(payload,navigate,token)=>({
      type: actions.Delete_IDOLab_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    getIdoCalenderSaga:(navigate,token)=>({
      type: actions.GET_IDO_Calender_REQUEST,
      navigate:navigate,
    }),
    addIdoCalenderSaga:(payload,navigate,token)=>({
      type: actions.Add_IDO_Calender_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }), 
    updateIdoCalenderSaga:(payload,navigate,token)=>({
      type: actions.Update_IDO_Calender_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    deleteIdoCalenderSaga:(payload,navigate)=>({
      type: actions.Delete_IDO_Calender_REQUEST,
      payload:payload,
      navigate:navigate
    }),
    

    getWhiteListedUser: (navigate, token) => ({
      type: actions.GET_WhiteListUser_REQUEST,
      navigate:navigate,
      token:token
    }),

     removeWhiteListedUser: (payload,navigate, token) => ({
      type: actions.REMOVE_WhiteListUser_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),

    createTransaction : (payload , navigate , token) => ({
      type: actions.CREATE_TRANSCATION_REQUEST,
      payload: payload,
      navigate:navigate,
      token: token
    }),

    getTranscation : (navigate , token) => ({
      type: actions.GET_TRANSCATION_REQUEST,
      navigate:navigate,
      token:token,
    }),

    getPrice : (navigate , token) => ({
      type: actions.GET_PRICE_REQUEST,
      navigate:navigate,
      token:token,
    }),
    getReferralUser:(navigate)=>({
      type: actions.GET_Referral_User_REQUEST,
      navigate:navigate
    }),
    getApproveReferralUser:(navigate)=>({
      type: actions.GET_Approve_User_REQUEST,
      navigate:navigate
    }),
    getReferralTransaction:(navigate,token)=>({
      type: actions.GET_REFERRAL_TRANSACTION_REQUEST,
      navigate:navigate,
      token: token
    }),
    updateWithdrwalStatus:(payload , navigate , token)=>({
      type: actions.UPDATE_WITHDRWAL_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    getRedeemTransactionUser:(navigate,token)=>({
      type: actions.REDEEM_USER_TRANSACTION_REQUEST,
      navigate:navigate,
      token: token
    }),
    createRedeemReq:(payload , navigate , token)=>({
      type: actions.CREATE_REDEEM_REQUEST,
      payload:payload,
      navigate:navigate,
      token:token
    }),
    getNotificationUser:(navigate,token)=>({
      type: actions.GET_NOTIFICATION_REQUEST,
      navigate:navigate,
      token: token
    }),
  };
  export default actions;