import React, { useEffect, useState } from "react";
import Index from "../Index";
import { ethers } from "ethers";
import { getAddress } from "ethers/lib/utils";

const { getRedeemTransactionUser,getUserProfile,createRedeemReq } = Index.AuthSagaActions;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
export const ReferralLogs = () => {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();

  const token = Index.useSelector((state) => {
    return state.authSaga.idTokenUser;
  });

  const transcationData = Index.useSelector((state) => {
    return state.authSaga.userRedeemTransaction;
  });

  const userProfileDetails = Index.useSelector((state) => {
    return state.authSaga.userProfile;
  });
 
  const [userProfile, setUserProfileDetails] = useState({});
  const [TranscationDetails, setTranscationDetails] = useState([]);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const [data, setdata] = useState({
    address: "",
    Balance: null,
  });

  // Payment Start
  useEffect(() => {
    dispatch(getRedeemTransactionUser(navigate, token));
  }, []);

  useEffect(() => {
    setTranscationDetails(transcationData);
  }, [transcationData]);

  useEffect(() => {
    dispatch(getUserProfile(token));
    setUserProfileDetails(userProfileDetails);
  }, []);

  useEffect(() => {
    setUserProfileDetails(userProfileDetails);
  }, [userProfileDetails]);


  const withdrwal=()=>{
    const apidata = new URLSearchParams();
    apidata.append("coin", value);
    dispatch(createRedeemReq(apidata, navigate, token))
  }

  //console.log(TranscationDetails, "TranscationDetails");

  // Payment End

  // Button handler button for handling a
  // request event for metamask
  const btnhandler = () => {
    // Asking if metamask is already present or not
    if (window.ethereum) {
      // res[0] for fetching a first wallet
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((res) => accountChangeHandler(res[0]));
    } else {
      alert("install metamask extension!!");
    }
  };

  // getbalance function for getting a balance in
  // a right format with help of ethers
  const getbalance = (address) => {
    // Requesting balance method
    window.ethereum
      .request({
        method: "eth_getBalance",
        params: [address, "latest"],
      })
      .then((balance) => {
        // Setting balance
        setdata({
          address: address,
          Balance: ethers.utils.formatEther(balance),
        });
      });
  };

  // Function for getting handling all events
  const accountChangeHandler = (account) => {
    // Setting an address data
    setdata({
      address: account,
    });

    // Setting a balance
    getbalance(account);
    getAddress(account);
  };
  return (
    <div>
      <Index.Header />

      {/* Up Coming Third Phase*/}
      <Index.Box className="third-phase-section about-third-phase-section about-text-main">
        <Index.Box className="container">
          <Index.Box className="">
            <Index.Box className="about-text">
              <Index.Typography variant="p" component="p">
                Referral Logs{" "}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="about-text-inner">
              <Index.Typography variant="p" component="p">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et{" "}
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      {/* ICO Calender */}


      <Index.Box className="coinlogs-section">
        <Index.Box className="container">
          <Index.Box sx={{ pb: 2 }}>
            <Index.TableContainer component={Index.Paper} className="tabel-box">
              <Index.Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
                className="icolab-tabel coinlogs-tabel"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell>Date</Index.TableCell>
                    <Index.TableCell align="left">Coin</Index.TableCell>

                    <Index.TableCell align="left">Status</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody >
                  {TranscationDetails.length?TranscationDetails?.map((row, index) => {
                    return (
                      <>
                        <Index.TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <Index.TableCell>
                            {row?.createdAt ? new Date(row?.createdAt).toLocaleString() : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {row?.coin ? row?.coin : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.status ? row?.status : "-"}
                          </Index.TableCell>
                        </Index.TableRow>
                      </>
                    );
                  }):
                  <Index.TableRow
              
                  
                  >
                    <Index.TableCell colSpan={3} align="center">
                    No recent transactions
                    </Index.TableCell>
                    
                  </Index.TableRow>
                  }
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
        </Index.Box>
        <Index.Box className="userdashbord-btn">
          <Index.Button onClick={handleOpen} variant="contained">
            withdrwal
          </Index.Button>
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Index.Box sx={style}>
          Balance : {userProfile?.balance}
          <br/>
          <Index.TextField
            type={"number"}
            value={value}
            onChange={handleChange}
          />
          <Index.ButtonGroup variant="contained" aria-label="outlined primary button group">
            <Index.Button onClick={()=>setValue(((25/ 100) * userProfile?.balance).toFixed(2))}>25 %</Index.Button>
            <Index.Button onClick={()=>setValue(((50/ 100) * userProfile?.balance).toFixed(2))}>50 %</Index.Button>
            <Index.Button onClick={()=>setValue(((75/ 100) * userProfile?.balance).toFixed(2))}>75 %</Index.Button>
            <Index.Button onClick={()=>setValue(((100/ 100) * userProfile?.balance).toFixed(2))}>100 %</Index.Button>
          </Index.ButtonGroup>
          <br />
          <br />
          <Index.Button onClick={withdrwal} variant='contained'>Redeem</Index.Button>
          <Index.Button onClick={handleClose} variant='contained'>Cancel</Index.Button>
        </Index.Box>
      </Index.Modal>

      {/* Subscribe to Our Newsletter */}
       <Index.Subscribe />
      {/* End Subscribe Section */}

      <Index.Footer />
    </div>
  );
};
