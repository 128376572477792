import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import AuthSagaActions from "./redux/authsaga/action";
const { getWebsiteSetting } = AuthSagaActions;

const SiteSetting = () => {
    const dispatch = useDispatch();
    const [title, SetTitle] = useState("");
    const [fevicon, Setfevicon] = useState("");
    const websiteData = useSelector((state) => {
      return state.authSaga.website;
    });

    useEffect(()=>{
        dispatch(getWebsiteSetting());
    }, []);

    useEffect(() => {
        if(websiteData) {
            websiteData?.map((row) => {
                SetTitle(row[0]?.title ? row[0]?.title : "");
                Setfevicon(row[0]?.favicon ? row[0]?.favicon : "");
            });

            if(title !== ""){
                document.title = title;
            }
            if(fevicon !== ""){
                let link = document.querySelector("link[rel~='icon']");
                link.href = fevicon;
            }
        }
    }, [websiteData]);
}

export default SiteSetting