import React, { useState, useEffect } from 'react'
import Index from "../Index";
import AuthSagaActions from "../../../redux/authsaga/action";
import { useSelector, useDispatch } from "react-redux";
import Parser from "html-react-parser";

const { geticolablistsaga} = AuthSagaActions;

const IcoLabFeatures = () => {
    const dispatch = useDispatch();
    const [IcoLabList, SetIcoLabList] = useState([]);

    const geticolab = useSelector((state) => {
        return state.authSaga.icoLabFeatures;
      });

    useEffect(() => {
        dispatch(geticolablistsaga());
        SetIcoLabList(geticolab);
    }, []);

    useEffect(() => {
        SetIcoLabList(geticolab);
    }, [geticolab]);

  return (
    <>
     <Index.Box className="icolab-features-section">
        <Index.Box className="container">
          <Index.Box className="icolab-features">
            <Index.Typography
              className="pt-55 res-pt-30"
              variant="p"
              component="p"
            >
              ICOLab Features
            </Index.Typography>
          </Index.Box>
          <Index.Box className="icolab-features-inner icolab-features-inner-box">
            <Index.Typography className="" variant="p" component="p">
              Lorem ipsum dolor sit amet, conset sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            </Index.Typography>
          </Index.Box>

          <Index.Box className="icolab-features-card-box">
            {IcoLabList?.length
              ? IcoLabList?.map((feature, index) => (
                  <Index.Box className={`icolab-features-card ${index%2 === 0 ? 'mlr-20' : ''}`} key={index}>
                    <Index.Box className="">
                      <Index.Box className="icolab-icone">
                        <img
                          className="icolab-icone-img"
                          alt=""
                          src={Index.Png.trendingup}
                        ></img>
                      </Index.Box>
                      <Index.Box className="icolab-features-text">
                        <Index.Typography
                          className=""
                          variant="p"
                          component="p"
                        >
                          {feature?.title}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="icolab-features-inner-text icolab-features-inner">
                        {/* <Index.Typography className=""> */}
                          {Parser(feature?.description)}
                        {/* </Index.Typography> */}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                ))
              : ""}
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  )
}

export default IcoLabFeatures