import React, {useState} from 'react'
import Index from '../Index';
const { loginUserSaga } = Index.AuthSagaActions;

export default function Login() {
    const dispatch = Index.useDispatch();
    const navigate = Index.useNavigate();

    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [remember, setRemember] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [errorObject,setErrorObject] = useState({});

    const validationFun=(userName,password)=>{
        let errors = {};
        if(userName.trim() === ""){
            errors.userName = "Please enter User Name";
        }
        else if(userName.trim()==="" || userName.trim().length<3){
            errors.userName = "Enter at least 3 characters in User Name";
        }
        if(password === ""){
            errors.password = "Please enter Password";
        }
        else if(password==="" || password.length<8){
            errors.password = "Enter at least 8 characters in Password";
        }
        return errors;
    }

    const handleSubmit = (e) => {
        if (Object.keys(validationFun(userName,password)).length === 0) {
            handleLogin();
            setErrorObject({});
        } else {
            setErrorObject(validationFun(userName,password))
        }
    };

    const handleLogin = async () => {
        const apidata = new URLSearchParams();
        apidata.append("userName", userName);
        apidata.append("password", password);
        dispatch(loginUserSaga(apidata, navigate));
    }
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
      };

    const handleKeypress = e => {
        //it triggers by pressing the enter key
        //console.log('key press', e);
        if (e.charCode === 13) {
          //console.log('key press entered', 55);
          validationFun();
        }
      };

    return (
        <div>
            <Index.Box className=' login-main-box'>
                <Index.Box className='login-box'>
                    <Index.Box className='login-container'>
                        <Index.Link className="link-style" href="/">
                            <Index.Box className='login-logo'>
                                <img src={Index.Png.logo} className="" alt=""></img>
                            </Index.Box>
                        </Index.Link>
                        <Index.Box className='login-inner-text'>
                            <Index.Typography variant='p' component="p" >Login Your account.</Index.Typography>
                        </Index.Box >
                        <Index.Box className="user-box">
                            <Index.Box className='gap-cus'>
                                <Index.Box className='connect-title '>
                                    <Index.Typography className='user-box' varient='p'>
                                        User Name *
                                    </Index.Typography>
                                </Index.Box>

                                <Index.Box className="form-group txtfeld-cus validation">
                                    <Index.TextField
                                        fullWidth
                                        id="fullWidth"
                                        error={errorObject['userName'] !== undefined}
                                        className="form-control form-control-login"
                                        value={userName}
                                        onChange={(e) => setUserName(e.target.value)}
                                        helperText={errorObject?.userName}
                                    />
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>

                        <Index.Box className="password-box">
                            <Index.Box className='gap-cus '>
                                <Index.Box className='connect-title '>
                                    <Index.Typography className='user-box' varient='p'>
                                        Password *
                                    </Index.Typography>
                                </Index.Box>

                                <Index.Box className="form-group txtfeld-cus validation">
                                    <Index.OutlinedInput
                                        fullWidth
                                        id="fullWidth"
                                        name="password"
                                        error={errorObject['password'] !== undefined}
                                        type={showPassword ? 'text' : 'password'}
                                        className="form-control form-control-login"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        helperText={errorObject?.password}
                                        onKeyPress={handleKeypress}
                                        endAdornment={
                                          <Index.InputAdornment position="end">
                                            <Index.IconButton
                                              aria-label="toggle password visibility"
                                              onClick={handleClickShowPassword}
                                              edge="end"
                                            >
                                            {showPassword ? <Index.VisibilityOff /> : <Index.Visibility />}
                                            </Index.IconButton>
                                          </Index.InputAdornment>
                                        }
                                    />
                                    <Index.Box style={{fontSize:12, color:'#d32f2f'}} className='login-error'>{errorObject?.password}</Index.Box>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>

                        <Index.Box className='remember-box'>
                            <Index.FormControlLabel className='inline-checkbox' control={<Index.Checkbox checked={remember}
                                onChange={(e) => setRemember(e.target.checked)} />} label="Remember Me" />
                        </Index.Box>
                        <Index.Box className='login-but-account'>
                            <Index.Button onClick={()=>handleSubmit()} variant="contained" >Login</Index.Button>
                        </Index.Box>

                        <Index.Box>
                            <Index.Box className='forgot-text'>
                                <Index.Typography variant='p' component="p"><Index.RoutLink className="link-style forgot-link" to="/forgotpassword">Forgot Password ?</Index.RoutLink></Index.Typography>
                            </Index.Box>
                            <Index.Box className='signup-text'>
                                <Index.Typography variant='p' component="p" >New to Demo IDO ICO?  <Index.RoutLink className="link-style" to="/register"><span>Signup here.</span> </Index.RoutLink> </Index.Typography>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Box>
            <Index.ToastContainer/>
        </div>
    )
}