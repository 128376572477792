import React from "react";
import { Typography, Grid } from "@mui/material";
import { Box } from "@mui/system";
import Jpg from "../../../assets/jpg";

const Introduction = () => {
  return (
    <div>
      <Box className="intro-sec-main">
        <Box className="intro-title-box">
          <Typography className="intro-title">Introduction</Typography>
        </Box>
        <Box className="intro-para-box">
          <Typography className="intro-para">
            Human interactions (for example, discussing ideas) and value
            exchanges (for example, purchase of goods and services) come in
            handy with social activities. As social spaces are no longer limited
            by space or time, the Internet has made our social interactions more
            rich and efficient. In the past few years, social networks have
            established themselves at the heart of the Internet: they have
            become a gathering place for user traffic, a place to exchange
            information, and a place to exchange assets and assets. Almost all
            applications depend on social network development. Due to the
            explosion of Internet socialization, a huge amount of social data
            was produced, and this has created a huge amount of value.
          </Typography>

          <Typography className="intro-para">
            User-generated content and interactivity are the hallmarks of social
            media. Social media have become an essential part of day-to-day life
            in the modern world. As well as accessing news and information,
            social media is commonly used for social interaction and
            decision-making. As well as a tool for communicating with others
            locally and internationally, it is also a way to create, share, and
            spread information. Through reviews, marketing tactics, and
            advertising, social media can influence consumers' purchase
            decisions. It is important to note that social media has majorly
            helped in impacting our communication abilities and we can form new
            effective relations too.
          </Typography>

          <Typography className="intro-para">
            As a result of their social activities, people generate intangible
            assets called social assets and relationships with others. Identity,
            status, connections, and reputation are all social assets. It is
            one's own abilities and social networks that determine one's social
            assets.
          </Typography>

          <Typography className="intro-para">
            People complete their asset transactions primarily through social
            networking sites. Thus, social assets are capable of transforming
            into personal wealth and various tangible assets. In order to live a
            fulfilling life, we must maintain and develop these resources.
          </Typography>
          <Typography className="intro-para">
            From the beginning, you can find centralized social networks all
            around us. The one-to-one connection was our priority since the
            first USENET group, Friendster, MySpace & Facebook came into
            existence. The end of centralization took around 2500 years in our
            governance system. As soon as democracy took birth in Athens, it
            completely re-shaped humanity. Now comes the moment when
            centralization is ending and is changing the way we interact with
            each other.
          </Typography>
          <Typography className="intro-para">
            Demo serves our community to make social interactions with
            crypto rewards. It is developed on blockchain technology so that it
            unlocks the opportunity for better community interaction. Our
            primary purpose in building this social platform was to combine the
            concept of social media's latest developments in the crypto &
            blockchain world. Our platform enables you to make end-to-end
            communication, share photos & videos safely, make payments, and
            share creative content with each other.
          </Typography>
          <Typography className="intro-para">
            To elaborate, Demo crypto is a social community-based platform
            where influencers and users get a chance to gather in one place. It
            will allow plenty of audiences to communicate mutually in an
            intrusive environment. Through this social media platform, the users
            open a window of opportunity for themselves as they can communicate
            with one another, disseminate information and devour content, along
            with posting their own unique content on the internet.
          </Typography>
          <Typography className="intro-para">
            Billions of people across the globe utilize social media sites to
            remain in contact with each other which also helps with knowledge
            sharing. Moreover, the shareholders using this social media
            community-based platform will grant access to resources that would
            assist them in learning and experiencing something new, progress in
            the areas where they are already endeavoring, along with having fun
            when this happens.
          </Typography>
          <Typography className="intro-para">
            You cannot exaggerate the potential of social media as it can be
            substituted with growth platforms. Consequently, all the industry
            firms can interact with their intended clientele in a superior way.
            You can observe a growth in the number of people utilizing social
            networking sites as well. Every month, there are approximately 4.70
            billion people utilizing social media.
          </Typography>
          <Typography className="intro-para">
            With the launch of the Demo platform, We have tried to resolve
            the issues that the mainstream, centralized social media were
            lacking:
          </Typography>
          <Typography className="intro-para">
            Refer to the following featured pointers of the Demo platform
            that were deficient in other social networking platforms.
          </Typography>
        </Box>
        {/* <Box className="imtro-banner-box">
                              <img src={Png.introbanner} className="intro-banner-img"></img>
                        </Box>
                        <Box className="intro-title-box">
                              <Typography className="intro-title">Problem Statement</Typography>
                        </Box>
                        <Box className="intro-para-box">
                              <Typography className='intro-para'>
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
                              </Typography>

                              <Typography className='intro-para'>
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                              </Typography>
                        </Box> */}
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <img
              src={Jpg.IMAGE1}
              className="intro-banner-img-grid"
              alt=""
            ></img>
          </Grid>
          <Grid item xs={8}>
            The platform decides the content's worth. I.e. fake IDs & fake
            followers/ likes are not entertained on this platform.
          </Grid>
          <Grid item xs={4}>
            <img
              src={Jpg.IMAGE2}
              className="intro-banner-img-grid"
              alt=""
            ></img>
          </Grid>
          <Grid item xs={8}>
            High-quality content is given complete value and has far better
            reach.
          </Grid>
          <Grid item xs={4}>
            <img
              src={Jpg.IMAGE3}
              className="intro-banner-img-grid"
              alt=""
            ></img>
          </Grid>
          <Grid item xs={8}>
            The platform value is subject to the users.
          </Grid>
          <Grid item xs={4}>
            <img
              src={Jpg.IMAGE4}
              className="intro-banner-img-grid"
              alt=""
            ></img>
          </Grid>
          <Grid item xs={8}>
            100% reliability is guaranteed with the use of blockchain technology
            at a reduced expenditure than ever before.
          </Grid>
          <Grid item xs={4}>
            <img
              src={Jpg.IMAGE5}
              className="intro-banner-img-grid"
              alt=""
            ></img>
          </Grid>
          <Grid item xs={8}>
            Reward your community with DEMOtokens.
          </Grid>
          <Grid item xs={4}>
            <img
              src={Jpg.IMAGE6}
              className="intro-banner-img-grid"
              alt=""
            ></img>
          </Grid>
          <Grid item xs={8}>
            Administer the rights of photos/videos
          </Grid>
          <Grid item xs={4}>
            <img
              src={Jpg.IMAGE7}
              className="intro-banner-img-grid"
              alt=""
            ></img>
          </Grid>
          <Grid item xs={8}>
            Decentralization in the photos/videos i.e. your content is your own.
          </Grid>
          <Grid item xs={4}>
            <img
              src={Jpg.IMAGE8}
              className="intro-banner-img-grid"
              alt=""
            ></img>
          </Grid>
          <Grid item xs={8}>
            Develop a broad community around & share useful content with each
            other.
          </Grid>
          <Grid item xs={4}>
            <img
              src={Jpg.IMAGE9}
              className="intro-banner-img-grid"
              alt=""
            ></img>
          </Grid>
          <Grid item xs={8}>
            It is important to reward the community for valuable content by
            offering fair rewards.
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Introduction;
