import React, { useState, useEffect } from "react";
import Index from "../Index";

const { getIcoWeb, getIcoLearn, getCalenderSaga } = Index.AuthSagaActions;
const checkDateRange = (From, To) => {
  var dateFrom = new Date(From);
  var dateTo = new Date(To);
  var dateCheck = new Date();
  return dateCheck.getTime() > dateFrom.getTime() && dateCheck.getTime() < dateTo.getTime()
}
const Home = () => {
  const dispatch = Index.useDispatch();
  const [icoWebList, SetIcoWeblist] = useState([]);
  const [icoLearnList, SetIcoLearnlist] = useState([]);
  const [calenderList, SetCalenderList] = useState([]);

  const icoList = Index.useSelector((state) => {
    return state.authSaga.IcoWebSolutionList;
  });
  const calenderData = Index.useSelector((state) => {
    return state.authSaga.IcoCalenderList;
  });
  const icoLearn = Index.useSelector((state) => {
    return state.authSaga.IcoLearnList;
  });

  useEffect(() => {
    dispatch(getIcoWeb());
    dispatch(getCalenderSaga());
    dispatch(getIcoLearn());
  }, []);

  useEffect(() => {
    SetIcoLearnlist(icoList);
    SetIcoWeblist(icoLearn)
    calenderData?.map((data,i,arr) => {
     if(checkDateRange(data.startDate, data.endDate) === true){
      SetCalenderList([arr[i]]);
     }
    })
  }, [calenderData, icoLearn,icoList]);


  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, days }) => {
    return (
      <Index.Item className="grid-item">
        <Index.Box className="pt-10">
          <Index.Box className="up-coming-main-box ">
            <Index.Box className="">
              <Index.Box className="up-coming-main-text">
                <Index.Typography variant="p" component="p">
                  Up Coming  {calenderList[0]?.phaseName?calenderList[0]?.phaseName:"Phase"}
                </Index.Typography>

              </Index.Box>
              <Index.Box className="up-coming-main-text-inner">
                <Index.Typography variant="p" component="p">
                  Grab Your Token Before Prices Go Up
                </Index.Typography>
              </Index.Box>
              <Index.Box className="day-main-box">
                <Index.Box className="day-clender-box">
                  <Index.Box className="clender-box">
                    <Index.Box className="clender-box-inner">
                      <Index.Typography variant="p" component="p">
                        {days}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="day-text">
                    <Index.Typography variant="p" component="p">
                      Days
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="day-clender-box">
                  <Index.Box className="clender-box">
                    <Index.Box className="clender-box-inner">
                      <Index.Typography variant="p" component="p">
                        {hours}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="day-text">
                    <Index.Typography variant="p" component="p">
                      Hours
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="day-clender-box">
                  <Index.Box className="clender-box">
                    <Index.Box className="clender-box-inner">
                      <Index.Typography variant="p" component="p">
                        {minutes}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="day-text">
                    <Index.Typography variant="p" component="p">
                      Minutes
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="day-clender-box">
                  <Index.Box className="clender-box">
                    <Index.Box className="clender-box-inner">
                      <Index.Typography variant="p" component="p">
                        {seconds}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="day-text">
                    <Index.Typography variant="p" component="p">
                      Seconds
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="price-inner">
                <Index.Box className="price-text">
                  <Index.Typography variant="p" component="p">
                    Current Price
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="usd-text">
                  <Index.Typography variant="p" component="p">
                    {calenderList[0]?.price?calenderList[0]?.price:"0"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="learn-now-btn pt-52 res-pt-30">
                <Index.Button variant="">
                  Buy Token Now
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Item>
    );
  };

  return (
    <div>
      <Index.Header />

      {/* Up Coming Third Phase*/}
      <Index.Box className="third-phase-section">
        <Index.Box className="container">
          <Index.Box className="third-phase-sec">
            <Index.Box className="row hero-set-box">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 4",
                      lg: "span 6",
                    }}
                    className="grid-column "
                  >
                    <Index.Item className="grid-item">
                      <Index.Box className="">
                        <Index.Box className="website">
                          <Index.Typography variant="p" component="p">
                            {icoWebList?.[0]?.title
                              ? icoWebList?.[0]?.title
                              : ""}
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="website-inner-text">
                          <Index.Typography variant="p">
                            {icoWebList?.[0]?.description
                              ? Index.Parser(icoWebList?.[0]?.description)
                              : ""}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Item>
                  </Index.Box>
                  <Index.Box
                    className="grid-column"
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 8",
                      lg: "span 6",
                    }}>
                    {
                      calenderList?.length !== 0 ?
                        <Index.Countdown
                          date={new Date(calenderList[0].endDate).getTime()}
                          renderer={renderer} />
                        : <Index.Countdown
                        date={Date.now()}
                        renderer={renderer} />
                    }
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      {/* What is ICO solution */}
      <Index.Box className="ico-section2 res-mob-home-set">
        <Index.Box className="container">
          <Index.Box className="ico-sec">
            <Index.Box className="">
              <Index.Box className="row">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 6",
                      }}
                      className="grid-column "
                    >
                      <Index.Item className="grid-item">
                        <Index.Box className="ico-main-img-box">
                          <img src={Index.Png.ico1} alt="" className=""></img>
                        </Index.Box>
                      </Index.Item>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 8",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Item className="grid-item">
                        <Index.Box className="ico-solution-box">
                          <Index.Box className="ico-solution">
                            <Index.Typography variant="p" component="p">
                              {icoLearnList?.[0]?.title
                                ? icoLearnList?.[0]?.title
                                : ""}
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="ico-solution-inner">
                            <Index.Typography variant="p">
                              {icoLearnList?.[0]?.description
                                ? Index.Parser(icoLearnList?.[0]?.description)
                                : ""}
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="learn-now-btn">
                            <Index.Button variant="">Learn Now</Index.Button>
                          </Index.Box>
                        </Index.Box>
                      </Index.Item>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>


      {/* ICOLab Roadmap */}
      <Index.RoadMap />
      {/* End Roadmap Section */}


      {/* ICOLab Features */}
      <Index.IcoLabFeatures />
      {/* End ICOLab Features Section */}


      {/* ICO Calender */}
      <Index.IcoCalender />
      {/* End ICO Calender Section */}


      {/* Meet Our Team */}
      <Index.OurTeam />
      {/* End Our Team Section */}


      {/* Our client say about us */}
      {/* <Index.Testimonials /> */}
      {/* End Our client Section */}


      {/* FAQ Section */}
      <Index.Faq />
      {/* End FAQ Section */}


      {/* Subscribe to Our Newsletter */}
      <Index.Subscribe />
      {/* End Subscribe Section */}

      <Index.Footer />
    </div>
  );
};

export default Home;
