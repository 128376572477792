import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Button } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import { Grid, TextField, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import AuthSagaActions from "../../../redux/authsaga/action";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import TextareaAutosize from '@mui/material/TextareaAutosize'
import "react-toastify/dist/ReactToastify.css";
import Modal from "@mui/material/Modal";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Swal from "sweetalert2";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "TextValidator",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});


const { deleteRoadmapsaga,addRoadmapsaga,updateRoadmapsaga, getRoadmapsaga } = AuthSagaActions;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  maxHeight: "80vh",
  bgcolor: "background.paper",
  overflow: "auto",
  boxShadow: 24,
  p: 4,
};

const RoadMap = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();

  const roadList = useSelector((state) => {
    return state.authSaga.Road;
  });
  const token = useSelector((state) => {
    return state.authSaga.idToken;
  });

  const [page, setPage] = useState(0);
  const [roadMapList, setRoadMapList] = useState([]);
  const [rows, Setrows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [title, setTitle] = useState("");
  const [Editid, Seteditid] = useState("");
  const [count, Setcount] = useState(0);
  const [position, setPosition] = useState(0);
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState("");
  const [errorObject,setErrorObject] = useState({});
  
  const columns = [
    { id: "title", label: "Roadmap Title", minWidth: 170, },
    {
      id: "description",
      label: "Description",
      minWidth: 250,
    },
    {
      id: "price",
      label: "Price",
      minWidth: 100,
    },
    {
      id: "position",
      label: "Position",
      minWidth: 50,
    },
    {
      id: "b2",
      label: "Action",
      minWidth: 50,
      align: "right",
    },
    {
      id: "b3",
      label: "",
      minWidth: 50,
      align: "right",
    },
  ];

  function createData(title, description, price, position, id) {
    let b2 = (
      <EditIcon 
          sx={{cursor:'pointer'}}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            Seteditid(id);
            Setcount((pre) => pre + 1);
          }}
        />
    );
    let b3 = (
      <DeleteIcon sx={{cursor:'pointer'}} onClick={() => deletehandler(id)} />
    );
    return { title, description, price, position, b2, b3 };
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    roadMapList &&
      roadMapList[0] &&
      roadMapList.map((element, index) => {
        if (element._id === Editid) {
          setErrorObject({});
          setTitle(element.title);
          setDescription(element.description);
          setPosition(element.position);
          setPrice(element.price);
        }
      });
    if (Editid === "") {
      setErrorObject({});
      setTitle("");
      setPosition("");
      setPrice("");
      setDescription("");
    }
  }, [Editid, count]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const deletehandler = async (id) => {
    Swal.fire({
      title: "",
      text: "Are you sure you want to delete roadmap ?",
      icon: "",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let urlencoded = new URLSearchParams();
        urlencoded.append("id", id);
        urlencoded.append("deletedStatus", "1");
        dispatch(deleteRoadmapsaga(urlencoded, navigate));
      }
    });
  };
  
  const validationFun=(title,description,price)=>{
    let errors = {};
    if(title == "" || title.replace( /\s\s+/g, ' ')?.length<3){
        errors.title = "Enter at least 3 characters in Title";
    } else if(title.replace( /\s\s+/g, ' ') == "" || title.replace( /\s\s+/g, ' ')?.length>25){
        errors.title = "Enter not more then 25 characters in Title";
    }
    if(description == "" || description.replace( /\s\s+/g, ' ')?.length<3){
        errors.description = "Enter at least 3 characters in Description";
    }
    if(price == "" || price.length===0){
        errors.price = "Enter at least 1 number in Price";
    }
    return errors;
  }

  const handleValidation = (e) => {
    if (Object.keys(validationFun(title,description,price)).length === 0) {
        handelSubmit();
        setErrorObject({});
    } else {
        setErrorObject(validationFun(title,description,price))
    }
    //console.log(validationFun(title,description,price));
  };

  const handelSubmit = () => {
    const apidata = new URLSearchParams();
    apidata.append("title", title);
    apidata.append("description", description);
    apidata.append("position", position);
    apidata.append("price", price);
    if (Editid !== "") {
      apidata.append("id", Editid);
    }
    Editid===""?dispatch(addRoadmapsaga(apidata, navigate, token)):dispatch(updateRoadmapsaga(apidata, navigate, token))
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch(getRoadmapsaga(navigate));
    setRoadMapList(roadList);
  }, []);
  
  useEffect(() => {
    setRoadMapList(roadList);
  }, [roadList]);

  useEffect(() => {
    let data = [];
    roadMapList &&
      roadMapList[0] &&
      roadMapList.map((element, index) => {
        data = [
          ...data,
          createData(
            element.title,
            <td>{element.description}</td>,
            element.price,
            element.position,
            element._id,
          ),
        ];
      });
      Setrows(data);
  }, [roadMapList]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Box>
      <Box className="page-content-main">
      <ToastContainer />
      <Box className='main-title-box'>
        <Typography className='main-title'  variant="h4">RoadMap Management</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        <FormControl sx={{ m: 1, minWidth: 220, maxHeight: 50 }}></FormControl>
        <Button
          variant="contained"
          sx={{
            maxHeight: 50,
            marginTop: 2,
            backgroundColor:'#271e09',
            "&:hover": { backgroundColor: "#f49d04", opacity: [0.9, 0.8, 0.7] },
          }}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            Seteditid("");
            Setcount(count + 1);
          }}
        >
          Add New
        </Button>
      </Box>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer className="table-main-box">
          <Table stickyHeader aria-label="sticky table" className="table-main">
            <TableHead className="table-head"> 
              <TableRow className="table-row">
                {columns.map((column) => (
                  <TableCell className="table-cell"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <h4 style={{ margin: 0 }}>{column.label}</h4>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="table-body">
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow className="table-row"
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align} className="table-cell">
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid item xs={6}>
              <Box className="common-modal validation">
               <TextField
                  sx={{ marginTop: 2 }}
                  required
                  fullWidth
                  error={errorObject['title'] !== undefined}
                  label="Title"
                  id="Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  helperText={errorObject?.title}
                />

                <TextField
                  className={classes.input}
                  sx={{ marginTop: 2 }}
                  fullWidth
                  type="number"
                  label="Position"
                  onWheel={(event) => event.target.blur()}
                  id="Position"
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                />
              
                <TextField
                  className={classes.input}
                  sx={{ marginTop: 2 }}
                  required
                  fullWidth
                  error={errorObject['price'] !== undefined}
                  type="number"
                  onWheel={(event) => event.target.blur()}
                  label="Price"
                  id="Price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  helperText={errorObject?.price}
                />

                <br /><br />
                <FormHelperText sx={{ fontSize:13, color:'black' }}>
                  Description *
                </FormHelperText>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={6}
                  placeholder="Description *"
                  style={{ width: 413 }}
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
                <Box style={{fontSize:'0.82rem', color:'#d32f2f'}} className='contact-error'>{errorObject?.description}</Box>
                <br />
                <Box className="save-btn-box">
                  <Button className="save-btn"
                    variant="contained"
                    onClick={() => handleValidation()}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Box>
          {/* </Popover> */}
        </Modal>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
    </Box>
  );
};

export default RoadMap;
