import React from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'

const WebCreatorEconomy = () => {
  return (
    <div>
      <Box className="web-content-box">
        <Typography className='web-subtitle'>
          Blockchain Technology
        </Typography>

        <Typography className='web-sub-para'>
          ◦ Blockchain is a system that utilises a shared, immutable ledger that renders all of the financial dealings inside a company network transparent, simple to follow, and reliable. These three terms are crucial to comprehend the technology that underpins blockchain.
          <br />
          ▪ The keys to the cryptosystem
          <br />
          ▪ A decentralised peer-to-peer network that incorporates a shared ledger.
          <br />
          ▪ A way of using computers that stores records of networks and transactions
        </Typography>

        <Typography className='web-subtitle'>
          Cryptocurrency
        </Typography>

        <Typography className='web-sub-para'>
          Using its own blockchain and cryptography to secure financial transactions, a cryptocurrency is a form of digital currency with many advantages over traditional payment systems. Cryptocurrency refers to digital money that is neither issued nor managed by a centralised bank. This system relies on the blockchain as its fundamental technology, and cryptography is the primary method for ensuring the system's integrity.
        </Typography>
        <Typography className='web-subtitle'>
          BSC
        </Typography>
        <Typography className='web-sub-para'>
          The Binance Smart Chain (BSC) is a distributed ledger system optimised for executing smart contract-based programs. Users may take advantage of both the BC's streamlined transaction processing and the BSC's advanced smart contract capabilities. Since just a limited number of confirmed nodes are intended to create blocks and the gas charge is cheap, this network supports a higher transaction throughput than Ethereum's network.
        </Typography>
        <Typography className='web-subtitle'>
        Pancake Swap
        </Typography>
        <Typography className='web-sub-para'>
        PancakeSwap is the name of the decentralised internal exchange built within the BNB Chain. When using PancakeSwap, users may add to the platform's liquidity by depositing some of their tokens into a common pool. Trading fees may be thought of as a form of remuneration for providing users with liquidity.        </Typography>
      </Box>
    </div>
  )
}

export default WebCreatorEconomy