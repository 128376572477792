import React, { useState } from 'react'
import Index from "../Index";
import AuthSagaActions from "../../../redux/authsaga/action";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
const { addSubscriber} = AuthSagaActions;

const Subscribe = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [newsLetterError, setNewsLetterError] = useState('');

    const handelSubmit = () => {
        const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
        if (email === "") {
            setNewsLetterError("Please enter email");
        } else if (!regEx.test(email)) {
            setNewsLetterError("Please enter valid email");
        } else {
            setNewsLetterError("");
          const apidata = new URLSearchParams();
          apidata.append("email", email);
          dispatch(addSubscriber(apidata))
          setEmail('');
        }
      };

  return (
    <>
    <Index.Box className="">
        <Index.Box className="email-img-big">
          <Index.Box className="container">
            <Index.Box className="sub-box">
              <Index.Typography className="sub-text">
                Subscribe to Our Newsletter
              </Index.Typography>

              <Index.Box className="sub-inner">
                <Index.Box className="sub-box-set">
                  <Index.Typography className="sub-inner-text">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et accusam et
                    just.
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
             
              <Index.Typography variant="p" style={{color:'red', maxWidth: "631px"}} component="p">
                {newsLetterError}
              </Index.Typography>
              
              <Index.Box className="email-box email-btn-set position-relative">
                <Index.TextField
                  id="fullWidth"
                  className="form-control  textfield-set "
                  value={email}
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                />
                <Index.Box className="subscribe-btn email-small-btn">
                  <Index.Button variant="" onClick={() => handelSubmit()}>Subscribe</Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <ToastContainer />
    </Index.Box>
    </>
  )
}

export default Subscribe