import React, { useState, useEffect } from "react";
import { Typography, Button, Container } from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Box from "@mui/material/Box";
import "./AboutUs.css";
import { useSelector, useDispatch } from "react-redux";
import AuthSagaActions from "../../../redux/authsaga/action";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormHelperText from "@mui/material/FormHelperText";

const { addtermsandconditiondetailsaga, getcmssaga } = AuthSagaActions;
const TermsCondition = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Terms, Setterms] = useState("");
  const [Editid, Seteditid] = useState("");
  const [descriptionError, SetDescriptionError] = useState("");

  const token = useSelector((state) => {
    return state.authSaga.idToken;
  });

  const cmsDetail = useSelector((state) => {
    return state.authSaga.cms;
  });
  
  const handelSubmit = () => {
    if(Terms.trim() ==="" || Terms.trim()?.length<3){
      SetDescriptionError("Enter at least 3 characters in description")
      return false;
    }
    else {
      SetDescriptionError("");
    }

    if (Terms !== "") {
      const apidata = new URLSearchParams();
      if (Editid !== "") {
        apidata.append("id", Editid);
      }
      apidata.append("terms", Terms);
      dispatch(addtermsandconditiondetailsaga(apidata, navigate, token));
    }
  };

  useEffect(() => {
    dispatch(getcmssaga(navigate, token));
  }, []);

  useEffect(() => {
    Seteditid(cmsDetail?._id ? cmsDetail._id : "");
    Setterms(cmsDetail?.termsandCondition ? cmsDetail.termsandCondition : "");
  }, [cmsDetail]);
  
  return (
    <Box className="page-content-main" sx={{marginTop:3}}>
    <Container maxWidth="false" className="editor-main">
      <ToastContainer />
      <Typography variant="h4" align="center">
        Terms and Conditions
      </Typography>
      <br />
      <Box className="ckeditor-main">
        <CKEditor
          editor={ClassicEditor}
          data={Terms == undefined ? "" : Terms}
          onChange={(event, editor) => {
            const data = editor.getData();
            Terms == undefined ? Setterms(event.target.value) : Setterms(data);
          }}
        />
        <FormHelperText sx={{ color: "red" }}>{descriptionError}</FormHelperText>
        <br />
        <Box className="save-btn-box">
        <Button className="save-btn"
          variant="contained"
          onClick={() => {
            handelSubmit();
          }}
        >
          Save
        </Button>
      </Box>
      </Box>
    </Container>
    </Box>
  );
};

export default TermsCondition;
