import React, { useState, useEffect } from "react";
import { Typography, Button, Container } from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Box from "@mui/material/Box";
import "./AboutUs.css";
import { useSelector, useDispatch } from "react-redux";
import AuthSagaActions from "../../../redux/authsaga/action";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import FormHelperText from "@mui/material/FormHelperText";
import "react-toastify/dist/ReactToastify.css";

const { addsecuritysaga, getcmssaga } = AuthSagaActions;

const Security_privacy = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [security, Setsecurity] = useState("");
  const [Editid, Seteditid] = useState("");
  const [descriptionError, SetDescriptionError] = useState("");
  const token = useSelector((state) => {
    return state.authSaga.idToken;
  });

  const cmsDetail = useSelector((state) => {
    return state.authSaga.cms;
  });

  const handelSubmit = () => {

    if(security.trim() ==="" || security.trim()?.length<3){
      SetDescriptionError("Enter at least 3 characters in description")
      return false;
    }
    else {
      SetDescriptionError("");
    }

    if (security !== "") {
      const apidata = new URLSearchParams();
      if (Editid !== "") {
        apidata.append("id", Editid);
      }
      apidata.append("security", security);
      dispatch(addsecuritysaga(apidata, navigate, token));
    }
  };

  useEffect(() => {
    dispatch(getcmssaga(navigate, token));
  }, []);

  useEffect(() => {
    Seteditid(cmsDetail?._id ? cmsDetail._id : "");
    Setsecurity(cmsDetail?.securityPolicy ? cmsDetail.securityPolicy : "");
  }, [cmsDetail]);

  return (
    <Box className="page-content-main" sx={{marginTop:3}}>
    <Container maxWidth="false" className="editor-main">
      <ToastContainer />
      <Box className='main-title-box'>
        <Typography className='main-title'  variant="h4">Privacy Policy</Typography>
      </Box>
      <br />
      <Box className="ckeditor-main">
        <CKEditor
          editor={ClassicEditor}
          data={security !== undefined ? security : ""}
          onChange={(event, editor) => {
            const data = editor.getData();
            security == undefined
              ? Setsecurity(event.target.value)
              : Setsecurity(data);
          }}
        />
        <FormHelperText sx={{ color: "red" }}>{descriptionError}</FormHelperText>
        <br />
        <Box className="save-btn-box">
        <Button className="save-btn"
          variant="contained"
          onClick={() => {
            handelSubmit();
          }}
        >
          Save
        </Button>
        </Box>
      </Box>
    </Container>
    </Box>
  );
};

export default Security_privacy;
