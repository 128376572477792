import React, {useState, useEffect} from "react";
import Index from "../Index";
const {getcmssaga} = Index.AuthSagaActions;

export const Privacypolicy = () => {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const [privacyPolicy, setPrivacyPolicy] = useState("");
  const cmsDetail = Index.useSelector((state) => {
    return state.authSaga.cms;
  });

  useEffect(() => {
    dispatch(getcmssaga(navigate));
  }, []);

  useEffect(() => {
    setPrivacyPolicy(cmsDetail?.securityPolicy ? cmsDetail.securityPolicy : "");
  }, [cmsDetail]);

  return (
    <div>
      <Index.Header />

      {/* Up Coming Third Phase*/}
      <Index.Box className="third-phase-section about-third-phase-section about-text-main">
        <Index.Box className="container">
          <Index.Box className="">
            <Index.Box className="about-text">
              <Index.Typography variant="p" component="p">
                Privacy Policy
              </Index.Typography>
            </Index.Box>
            {/* <Index.Box className="about-text-inner">
              <Index.Typography variant="p" component="p">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et{" "}
              </Index.Typography>
            </Index.Box> */}
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="userteam-section-text">
        <Index.Box className="container">
          <Index.Box className="userteamsandconditions-text">
            <Index.Box className="userteamsandconditions-text-inner">
              <Index.Box className="">
                <Index.Typography variant="p" component="p">
                  {privacyPolicy ? Index.Parser(privacyPolicy) : ""}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Footer />
    </div>
  );
};
